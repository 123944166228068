const AddUsers = async (
  accesslevel,
  position,
  employeeNumber,
  fname,
  minitial,
  lname,
  emailaddress,
  password,
  regionValue,
  districtValue,
  userstatus,
  md5
) => {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  try {
    let res = await fetch("https://api.nha.gov.ph/cied/addusers", {
      mode: "cors",
      method: "POST",
      headers: headers,
      credentials: "include",
      body: JSON.stringify({
        Accesslevel: accesslevel,
        Position: position,
        EmployeeNumber: employeeNumber,
        Fname: fname,
        Minitial: minitial,
        Lname: lname,
        Emailaddress: emailaddress,
        PasswordUnecrypt: password,
        Password: md5(password),
        Userstatus: userstatus,
        RegionValue: regionValue,
        DistrictValue: districtValue,
      }),
    });
    if (res.status === 200) {
      return "0000";
    } else {
      return "1111";
    }
  } catch (err) {
    console.log(err);
  }
};

const GetUsers = async (regionId) => {
  let GetUsers;
  GetUsers = await fetch("https://api.nha.gov.ph/cied/fetchusers", {
    credentials: "include",
  }).then((res) => res.json());
  return GetUsers;
};

const UpdateUsers = async (
  accesslevel,
  position,
  employeeNumber,
  fname,
  minitial,
  lname,
  emailaddress,
  password,
  regionValue,
  districtValue,
  md5,
  userstatus,
  usersid
) => {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  try {
    let res = await fetch("https://api.nha.gov.ph/cied/updateusers", {
      mode: "cors",
      method: "PUT",
      headers: headers,
      credentials: "include",
      body: JSON.stringify({
        Usersid: usersid,
        Accesslevel: accesslevel,
        Position: position,
        EmployeeNumber: employeeNumber,
        Fname: fname,
        Minitial: minitial,
        Lname: lname,
        Emailaddress: emailaddress,
        PasswordUnecrypt: password,
        Password: md5(password),
        Userstatus: userstatus,
        RegionValue: regionValue,
        DistrictValue: districtValue,
      }),
    });
    if (res.status === 200) {
      return "0000";
    } else {
      return "1111";
    }
  } catch (err) {
    console.log(err);
  }
};
export { AddUsers, GetUsers, UpdateUsers };
