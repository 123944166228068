import React from "react";
import { useState, useEffect } from "react";
import Header from "../layoutcomponents/Header";
import Sidebar from "../layoutcomponents/Sidebar";
import Footer from "../layoutcomponents/Footer";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import "leaflet/dist/leaflet.css";

import { Pie, Bar } from "react-chartjs-2";
import {
  GetTotalInventoryEstate,
  GetTotalInventory,
  GetTotalInventoryNonEstate,
  GetTotalInventoryEstateBrkDwn,
  GetTotalInventoryNonEstateBrkDwn,
  GetTotalInventoryEstateAwdStat,
  GetTotalInventoryNonEstateAwdStat,
  GetTotalInventoryNonEstatePhyStat,
  GetTotalInventoryEstatePhyStat,
  GetTotalInventoryNonEstateSesu,
  GetTotalInventoryEstateSesu,
  GetBreakdownPerRegion,
  GetTotalInventoryNonEstateModDispo,
  GetTotalInventoryEstateModDispo,
} from "../functions/fx_dashboard";

import {
  MapContainer,
  TileLayer,
  Marker,
  LayersControl,
  Polygon,
  Circle,
} from "react-leaflet";
import { WMSTileLayer } from "react-leaflet";

function Dashboard() {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title
  );

  const [totalinventory, setTotalInventory] = useState("");
  const [totalinventoryestate, setTotalInventoryEstate] = useState("");
  const [totalinventorynonestate, setTotalInventoryNonEstate] = useState("");
  const [totalinventoryestatebrkdwn, setTotalInventoryEstateBrkDwn] = useState(
    []
  );
  const [totalinventorynonestatebrkdwn, setTotalInventoryNonEstateBrkDwn] =
    useState([]);

  const [totalinventoryestateawdatatsold, setTotalInventoryEstateAwdStatSold] =
    useState("");

  const [
    totalinventoryestateawdatatunsold,
    setTotalInventoryEstateAwdStatUnsold,
  ] = useState("");

  const [
    totalinventorynonestateawdatatsold,
    setTotalInventoryNonEstateAwdStatSold,
  ] = useState("");

  const [
    totalinventorynonestateawdatatunsold,
    setTotalInventoryNonEstateAwdStatUnsold,
  ] = useState("");

  const [
    totalinventorynonestateawdatatoccu,
    setTotalInventoryNonEstateAwdStatOccu,
  ] = useState("");
  const [
    totalinventorynonestateawdatatvac,
    setTotalInventoryNonEstateAwdStatVac,
  ] = useState("");

  const [
    totalinventorynonestateawdatatoth,
    setTotalInventoryNonEstateAwdStatOth,
  ] = useState("");

  const [totalinventoryestateawdatatoccu, setTotalInventoryEstateAwdStatOccu] =
    useState("");
  const [totalinventoryestateawdatatvac, setTotalInventoryEstateAwdStatVac] =
    useState("");

  const [totalinventoryestateawdatatoth, setTotalInventoryEstateAwdStatOth] =
    useState("");

  const [totalinventorynonestatesesuhic, setTotalInventoryNonEstateSesuHic] =
    useState("");
  const [totalinventorynonestatesesumic, setTotalInventoryNonEstateSesuMic] =
    useState("");
  const [totalinventorynonestatesesulic, setTotalInventoryNonEstatesesuLic] =
    useState("");
  const [totalinventorynonestatesesumix, setTotalInventoryNonEstateSesuMix] =
    useState("");

  const [totalinventoryestatesesuhic, setTotalInventoryEstateSesuHic] =
    useState("");
  const [totalinventoryestatesesumic, setTotalInventoryEstateSesuMic] =
    useState("");
  const [totalinventoryestatesesulic, setTotalInventoryEstatesesuLic] =
    useState("");
  const [totalinventoryestatesesumix, setTotalInventoryEstateSesuMix] =
    useState("");

  const [
    totalinventorynonestatemodisposale,
    setTotalInventoryNonEstateModDispoSale,
  ] = useState("");
  const [
    totalinventorynonestatemodisponegosale,
    setTotalInventoryNonEstateModDispoNegoSale,
  ] = useState("");
  const [
    totalinventorynonestatemodispolease,
    setTotalInventoryNonEstateModDispoLease,
  ] = useState("");
  const [
    totalinventorynonestatemodisponegolease,
    setTotalInventoryNonEstateModDispoNegoLease,
  ] = useState("");
  const [
    totalinventorynonestatemodispowithagree,
    setTotalInventoryNonEstateModDispoWithAgree,
  ] = useState("");
  const [
    totalinventorynonestatemodispoothers,
    setTotalInventoryNonEstateModDispoOthers,
  ] = useState("");

  const [totalinventoryestatemodisposale, setTotalInventoryEstateModDispoSale] =
    useState("");
  const [
    totalinventoryestatemodisponegosale,
    setTotalInventoryEstateModDispoNegoSale,
  ] = useState("");
  const [
    totalinventoryestatemodispolease,
    setTotalInventoryEstateModDispoLease,
  ] = useState("");
  const [
    totalinventoryestatemodisponegolease,
    setTotalInventoryEstateModDispoNegoLease,
  ] = useState("");
  const [
    totalinventoryestatemodispowithagree,
    setTotalInventoryEstateModDispoWithAgree,
  ] = useState("");
  const [
    totalinventoryestatemodispoothers,
    setTotalInventoryEstateModDispoOthers,
  ] = useState("");

  //END FOR MODE OF DISPOSITION

  const getTotalInventory = () => {
    GetTotalInventory()
      .then((value) => {
        setTotalInventory(value.data[0].count);
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });

    GetTotalInventoryEstate()
      .then((value) => {
        setTotalInventoryEstate(value.data[0].count);
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });

    GetTotalInventoryNonEstate()
      .then((value) => {
        setTotalInventoryNonEstate(value.data[0].count);
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });

    GetTotalInventoryEstateBrkDwn()
      .then((value) => {
        setTotalInventoryEstateBrkDwn([
          value.data[0].EstateComm,
          value.data[0].EstateInd,
          value.data[0].EstateIns,
          value.data[0].EstateMix,
        ]);
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });

    GetTotalInventoryNonEstateBrkDwn()
      .then((value) => {
        setTotalInventoryNonEstateBrkDwn([
          value.data[0].NonEstateComm,
          value.data[0].NonEstateInd,
          value.data[0].NonEstateIns,
          value.data[0].NonEstateFc,
        ]);
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });

    GetTotalInventoryEstateAwdStat()
      .then((value) => {
        setTotalInventoryEstateAwdStatSold(value.data[0].EstateSold);
        setTotalInventoryEstateAwdStatUnsold(value.data[0].EstateUnsold);

        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });
    GetTotalInventoryNonEstateAwdStat()
      .then((value) => {
        console.log(value.data);
        setTotalInventoryNonEstateAwdStatSold(value.data[0].NonEstateSold);
        setTotalInventoryNonEstateAwdStatUnsold(value.data[0].NonEstateUnsold);

        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });

    GetTotalInventoryNonEstatePhyStat()
      .then((value) => {
        console.log(value.data);
        setTotalInventoryNonEstateAwdStatOccu(value.data[0].NonEstateOccu);
        setTotalInventoryNonEstateAwdStatVac(value.data[0].NonEstateVac);
        setTotalInventoryNonEstateAwdStatOth(value.data[0].NonEstateOth);
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });
    GetTotalInventoryEstatePhyStat()
      .then((value) => {
        setTotalInventoryEstateAwdStatOccu(value.data[0].EstateOccu);
        setTotalInventoryEstateAwdStatVac(value.data[0].EstateVac);
        setTotalInventoryEstateAwdStatOth(value.data[0].EstateOth);
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });
    GetTotalInventoryNonEstateSesu()
      .then((value) => {
        setTotalInventoryNonEstateSesuHic(value.data[0].HIC);
        setTotalInventoryNonEstateSesuMic(value.data[0].MIC);
        setTotalInventoryNonEstatesesuLic(value.data[0].LIC);
        setTotalInventoryNonEstateSesuMix(value.data[0].MXC);
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });

    GetTotalInventoryEstateSesu()
      .then((value) => {
        setTotalInventoryEstateSesuHic(value.data[0].HIC);
        setTotalInventoryEstateSesuMic(value.data[0].MIC);
        setTotalInventoryEstatesesuLic(value.data[0].LIC);
        setTotalInventoryEstateSesuMix(value.data[0].MXC);
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });

    GetTotalInventoryNonEstateModDispo()
      .then((value) => {
        setTotalInventoryNonEstateModDispoSale(value.data[0].SALE);
        setTotalInventoryNonEstateModDispoNegoSale(value.data[0].NEGSALE);
        setTotalInventoryNonEstateModDispoLease(value.data[0].LEASE);
        setTotalInventoryNonEstateModDispoNegoLease(value.data[0].NEGLEASE);
        setTotalInventoryNonEstateModDispoWithAgree(value.data[0].WITHAGREE);
        setTotalInventoryNonEstateModDispoOthers(value.data[0].MODISOTHER);
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });

    GetTotalInventoryEstateModDispo()
      .then((value) => {
        setTotalInventoryEstateModDispoSale(value.data[0].SALE);
        setTotalInventoryEstateModDispoNegoSale(value.data[0].NEGSALE);
        setTotalInventoryEstateModDispoLease(value.data[0].LEASE);
        setTotalInventoryEstateModDispoNegoLease(value.data[0].NEGLEASE);
        setTotalInventoryEstateModDispoWithAgree(value.data[0].WITHAGREE);
        setTotalInventoryEstateModDispoOthers(value.data[0].MODISOTHER);
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });

    GetBreakdownPerRegion()
      .then((value) => {
        // console.log(value.data);
        setBreakDownPerRegion(value.data);
        // setSetloading(false);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const [breakdownperregion, setBreakDownPerRegion] = useState([]);
  console.log(breakdownperregion);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "General Information: Count of Properties per Region",
        position: "top",
      },
    },
  };
  const labels = breakdownperregion.map((data, index) => data.BP_REGION);

  const databar = {
    labels,
    datasets: [
      {
        label: "Non-estate",
        data: breakdownperregion.map((data, index) => data.CountNonEstate),
        backgroundColor: "rgba(234, 105, 71, 1)",
        stack: "Stack 0",
      },
      {
        label: "Estate",
        data: breakdownperregion.map((data, index) => data.CountEstate),
        backgroundColor: "rgba(166, 216, 221, 1)",
        stack: "Stack 0",
      },
    ],
  };
  const piedata1 = {
    labels: [
      "Commercial",
      "Industrial",
      "Institutional",
      "Foreclosed/Acquired Property",
    ],
    datasets: [
      {
        label: "Non-Estate",
        data: totalinventorynonestatebrkdwn,
        backgroundColor: [
          "rgba(255, 100, 100, 1)", // Lightened red
          "rgba(200, 100, 200, 1)", // Lightened purple
          "rgba(100, 100, 255, 1)", // Lightened blue
          "rgba(255, 100, 100, 1)", // Lightened red
        ],
        borderColor: [
          "rgba(255, 100, 100, 1)", // Lightened red
          "rgba(200, 100, 200, 1)", // Lightened purple
          "rgba(100, 100, 255, 1)", // Lightened blue
          "rgba(255, 100, 100, 1)", // Lightened red
        ],
        borderWidth: 1,
      },
    ],
  };

  const piedata2 = {
    labels: ["Commercial", "Industrial", "Institutional"],
    datasets: [
      {
        label: "Estate",
        data: totalinventoryestatebrkdwn,
        backgroundColor: [
          "rgba(255, 100, 100, 1)", // Lightened red
          "rgba(200, 100, 200, 1)", // Lightened purple
          "rgba(100, 100, 255, 1)", // Lightened blue
          "rgba(255, 100, 100, 1)", // Lightened red
        ],
        borderColor: [
          "rgba(255, 100, 100, 1)", // Lightened red
          "rgba(200, 100, 200, 1)", // Lightened purple
          "rgba(100, 100, 255, 1)", // Lightened blue
          "rgba(255, 100, 100, 1)", // Lightened red
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    getTotalInventory();
  }, []);

  return (
    <div>
      <Header />
      <Sidebar />
      <div>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Dashboard</h1>
                </div>
              </div>
            </div>
          </div>
          <section
            className="content"
            style={{ height: "calc(100vh - 100px)" }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4 col-6">
                  <div className="small-box bg-success">
                    <div className="inner">
                      <h4>Properties</h4>
                      <h3>{totalinventory}</h3>
                    </div>
                    <div className="icon">
                      <i className="ion ion-home" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-6">
                  <div className="small-box bg-danger">
                    <div className="inner">
                      <h4>Non-estate</h4>
                      <h3>{totalinventorynonestate}</h3>
                    </div>
                    <div className="icon">
                      <i className="ion ion-stats-bars" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-6">
                  <div
                    className="small-box bg-warning  "
                    // style={{ backgroundColor: "#77DD77" }}
                  >
                    <div className="inner">
                      <h4>Estate</h4>
                      <h3>{totalinventoryestate}</h3>
                    </div>
                    <div className="icon">
                      <i className="ion ion-connection-bars" />
                    </div>
                  </div>
                </div>
              </div>
              {/* End of data bar */}
              {/* Pie chart row */}
              <div className="row ">
                <div className=" col-md-6">
                  <div className="card card-info card-outline">
                    <div className="card-header ">
                      <h3 className="card-title font-weight-bold">
                        Non-Estate
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-7">
                          <div
                            className=""
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Pie data={piedata1} />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className=" ">
                            <div className="d-flex flex-column">
                              <div>
                                <h5>Award Status</h5>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Sold/Disposed</p>
                                <p>{totalinventorynonestateawdatatsold}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Unsold/Undisposed</p>
                                <p>{totalinventorynonestateawdatatunsold}</p>
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <div>
                                <h5>Occupancy Status</h5>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Occupied</p>
                                <p>{totalinventorynonestateawdatatoccu}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Vacant</p>
                                <p>{totalinventorynonestateawdatatvac}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Others</p>
                                <p>{totalinventorynonestateawdatatoth}</p>
                              </div>
                            </div>
                            {/* <div className="d-flex flex-column">
                              <div>
                                <h5>Socio-Economic Class</h5>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>High Income Class</p>
                                <p>{totalinventorynonestatesesuhic}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Middle Income Class</p>
                                <p>{totalinventorynonestatesesumic}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Low Income Class</p>
                                <p>{totalinventorynonestatesesulic}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Mixed-Income Class</p>
                                <p>{totalinventorynonestatesesumix}</p>
                              </div>
                            </div> */}
                            <div className="d-flex flex-column">
                              <div>
                                <h5>Mode of Disposition</h5>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Sale</p>
                                <p>{totalinventorynonestatemodisposale}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Negotiated Sale</p>
                                <p>{totalinventorynonestatemodisponegosale}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Lease</p>
                                <p>{totalinventorynonestatemodispolease}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Negotiated Lease</p>
                                <p>{totalinventorynonestatemodisponegolease}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>With Agreement</p>
                                <p>{totalinventorynonestatemodispowithagree}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Others</p>
                                <p>{totalinventorynonestatemodispoothers}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-md-6">
                  <div className="card card-info card-outline">
                    <div className="card-header ">
                      <h3 className="card-title font-weight-bold">Estate</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-7">
                          <div
                            className=""
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Pie data={piedata2} />
                          </div>
                        </div>
                        <div className="col-md-5">
                          {" "}
                          <div className="">
                            <div className="d-flex flex-column">
                              <div>
                                <h5>Award Status</h5>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Sold/Disposed</p>
                                <p>{totalinventoryestateawdatatsold}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Unsold/Undisposed</p>
                                <p>{totalinventoryestateawdatatunsold}</p>
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <div>
                                <h5>Occupancy Status</h5>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Occupied</p>
                                <p>{totalinventoryestateawdatatoccu}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Vacant</p>
                                <p>{totalinventoryestateawdatatvac}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Others</p>
                                <p>{totalinventoryestateawdatatoth}</p>
                              </div>
                            </div>
                            {/* <div className="d-flex flex-column">
                              <div>
                                <h5>Socio-Economic Class</h5>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>High Income Class</p>
                                <p>{totalinventoryestatesesuhic}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Middle Income Class</p>
                                <p>{totalinventoryestatesesumic}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Low Income Class</p>
                                <p>{totalinventoryestatesesulic}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Mixed-Income Class</p>
                                <p>{totalinventoryestatesesumix}</p>
                              </div>
                            </div> */}
                            <div className="d-flex flex-column">
                              <div>
                                <h5>Mode of Disposition</h5>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Sale</p>
                                <p>{totalinventoryestatemodisposale}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Negotiated Sale</p>
                                <p>{totalinventoryestatemodisponegosale}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Lease</p>
                                <p>{totalinventoryestatemodispolease}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Negotiated Lease</p>
                                <p>{totalinventoryestatemodisponegolease}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>With Agreement</p>
                                <p>{totalinventoryestatemodispowithagree}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p>Others</p>
                                <p>{totalinventoryestatemodispoothers}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Dashboard;
