import { HashRouter, BrowserRouter, Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Inventory from "./pages/Inventory";
import User from "./pages/User";
import Form from "./pages/Form";
import Login from "./layoutcomponents/Login";
import Protected from "./components/Protected";
import Form2 from "./pages/Form2";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <Protected>
              <Dashboard />
            </Protected>
          }
        />
        <Route
          path="/user"
          element={
            <Protected>
              <User />
            </Protected>
          }
        />
        <Route
          path="/inventory"
          element={
            <Protected>
              <Inventory />
            </Protected>
          }
        />
        <Route
          path="/form"
          element={
            <Protected>
              <Form />
            </Protected>
          }
        />
        <Route
          path="/form2"
          element={
            <Protected>
              <Form2 />
            </Protected>
          }
        />
      </Routes>
    </HashRouter>
  );
}

export default App;
