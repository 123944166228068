import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  AddSignatories,
  GetSignatories,
  UpdateSignatories,
} from "../functions/fx_signatories";
import { NavLink } from "react-router-dom";

import { GetAllNotification, GetNotif } from "../functions/fx_inventory";

function Header({ inventoryProjects }) {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [accesslevel, setAccessLevel] = useState("");
  const [region, setRegion] = useState("");
  const [district, setDistrict] = useState("");

  const [preparedby, setPreparedBy] = useState("");
  const [preparedby_dg, setPreparedBy_dg] = useState("");
  const [reviewedby, setReviewedBy] = useState("");
  const [reviewedby_dg, setReviewedBy_dg] = useState("");
  const [notedby, setNotedBy] = useState("");
  const [notedby_dg, setNotedBy_dg] = useState("");
  const [signatoryid, setSignatoryId] = useState("");
  const [signatories, setSignatories] = useState([]);

  const [allNotif, setAllNotif] = useState("");
  const [getComplied, setGetComplied] = useState("");
  const [getReturned, setGetReturned] = useState("");

  const [getAllUnverified, setGetAllUnverified] = useState("");
  const [getAllReturnedByReg, setGetAllReturnedByReg] = useState("");

  const [getValidated, setGetValidated] = useState("");

  const [getadmincomplied, setGetAdminComplied] = useState("");
  const [getadmincheckunvalidate, setGetAdminCheckUnValidate] = useState("");

  const handleAddSignatories = () => {
    let res = AddSignatories(
      preparedby,
      preparedby_dg,
      reviewedby,
      reviewedby_dg,
      notedby,
      notedby_dg
    );
    res
      .then((value) => {
        if (value === "0000") {
          alert("Signatories saved!");
          setPreparedBy("");
          setPreparedBy_dg("");
          setReviewedBy("");
          setReviewedBy_dg("");
          setNotedBy("");
          setNotedBy_dg("");
        } else {
          alert("Error: Entries did not insert into datadase...");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getallnotification = async () => {
    const data = await GetAllNotification();
    console.log(data);
    if (data) {
      setAllNotif(data.data[0].count);
    } else {
      console.log("No data returned or there was an error.");
    }
  };

  const getNotif = async () => {
    const data = await GetNotif();

    if (data) {
      const {
        AllValidated,
        AllComplied,
        AllReturned,
        AllUnValidated,
        AllReturnedByReg,
        AllAdminCheckUnvalidate,
        AllAdminComplied,
      } = data.data[0];
      setGetValidated(AllValidated);
      setGetComplied(AllComplied);
      setGetReturned(AllReturned);
      setGetAllUnverified(AllUnValidated);
      setGetAllReturnedByReg(AllReturnedByReg);

      setGetAdminComplied(AllAdminComplied);
      setGetAdminCheckUnValidate(AllAdminCheckUnvalidate);
    }
  };

  const getSignatories = () => {
    GetSignatories()
      .then((value) => {
        console.log(value.data);
        setSignatories(value.data);
        setPreparedBy(value.data[0].sig_preparedby);
        setPreparedBy_dg(value.data[0].sig_preparedby_dg);
        setReviewedBy(value.data[0].sig_revieweby);
        setReviewedBy_dg(value.data[0].sig_revieweby_dg);
        setNotedBy(value.data[0].sig_notedby);
        setNotedBy_dg(value.data[0].sig_notedby_dg);
        setSignatoryId(value.data[0].sig_id);
      })
      .catch((error) => {
        // alert(error);
      });
  };

  const handleUpdateSignatories = () => {
    UpdateSignatories(
      signatoryid,
      preparedby,
      preparedby_dg,
      reviewedby,
      reviewedby_dg,
      notedby,
      notedby_dg
    )
      .then((value) => {
        if (value === "0000") {
          alert("Signatories updated!");

          //   setPreparedBy("");
          //   setPreparedBy_dg("");
          //   setReviewedBy("");
          //   setReviewedBy_dg("");
          //   setNotedBy("");
          //   setNotedBy_dg("");
          //   setSignatoryId("");
        } else {
          alert("Error: Entries did not insert into datadase...");
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleLogout = async () => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    try {
      let res = await fetch("https://api.nha.gov.ph/cied/logout", {
        mode: "cors",
        method: "POST",
        credentials: "include",
        headers: headers,
      });

      if (res.status === 200) {
        navigate("/");
      } else {
        alert("Validation Error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick1 = async () => {
    navigate("/inventory", { state: { triggerFetch: "1" } });
  };

  const handleClick2 = async () => {
    navigate("/inventory", { state: { triggerFetch: "2" } });
  };
  const handleClick3 = async () => {
    navigate("/inventory", { state: { triggerFetch: "3" } });
  };

  const handleClick4 = async () => {
    navigate("/inventory", { state: { triggerFetch: "4" } });
  };

  const handleClick5 = async () => {
    navigate("/inventory", { state: { triggerFetch: "5" } });
  };

  const handleClick6 = async () => {
    navigate("/inventory", { state: { triggerFetch: "6" } });
  };

  const handleClick7 = async () => {
    navigate("/inventory", { state: { triggerFetch: "7" } });
  };

  const handleClick8 = async () => {
    navigate("/inventory", { state: { triggerFetch: "8" } });
  };
  // const handleGetAllValidatedByRegionClick = async () => {
  //   navigate("/inventory");
  // };

  useEffect(() => {
    fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setUsername(
          responseJson.response.name + " " + responseJson.response.lname
        );
        setAccessLevel(responseJson.response.useraccess);
        setRegion(responseJson.response.reg);
        setDistrict(responseJson.response.dist);
      })
      .catch((error) => {
        console.log(error);
      });
    getallnotification();
    getNotif();
  }, []);
  useEffect(() => {
    if (inventoryProjects !== undefined) {
      getallnotification();
      getNotif();
    }
    console.log("sec invoked");
  }, [inventoryProjects]);

  return (
    <div>
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand navbar-white navbar-light fixed-top">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars" />
            </a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <div className="nav-link">
              {region} {district ? ` - ${district}` : ""}
            </div>
          </li>
          {/* <li className="nav-item d-none d-sm-inline-block">
            <a href="#" className="nav-link">
              {district ? ` - ${district}` : ""}
            </a>
          </li> */}
        </ul>
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Navbar Search */}

          {/* Messages Dropdown Menu */}

          {/* Notifications Dropdown Menu */}

          <li className="nav-item dropdown " style={{ position: "relative" }}>
            <a
              className="nav-link"
              data-toggle="dropdown"
              href="#"
              aria-expanded="false"
            >
              <i className="far fa-bell" style={{ fontSize: "20px" }} />

              {allNotif > 0 && (
                <span
                  className="badge badge-danger navbar-badge"
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "-5px",
                    transform: "translate(-50%, -50%)",
                    fontSize: "15px",
                    width: "20px", // Fixed width
                    height: "20px", // Fixed height
                    lineHeight: "20px", // Center text vertically
                    textAlign: "center", // Center text horizontally
                    borderRadius: "50%", // Make it circular
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {allNotif}
                </span>
              )}
            </a>
            {accesslevel === "1" ? (
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-right "
                style={{ left: "inherit", right: 0 }}
              >
                <span className="dropdown-item dropdown-header">
                  {`${allNotif} Notification/s`}
                </span>
                <div className="dropdown-divider" />
                <div
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={handleClick1}
                >
                  <span style={{ color: "red" }}>{getValidated} </span>Validated
                  <span className="text-muted"> (By Region)</span>
                </div>
                <div className="dropdown-divider" />
                <div
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={handleClick2}
                >
                  <span style={{ color: "green" }}>{getComplied} </span>Complied
                  <span className="text-muted"> (By Region)</span>
                </div>
                <div className="dropdown-divider" />
                <div
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={handleClick3}
                >
                  <span style={{ color: "red" }}>{getReturned} </span>Returned
                  by checker
                  <span className="text-muted"> (Non-estate)</span>
                </div>
                <div className="dropdown-divider" />
              </div>
            ) : accesslevel === "2" ? (
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-right "
                style={{ left: "inherit", right: 0 }}
              >
                <span className="dropdown-item dropdown-header">
                  {`${allNotif} Notification/s`}
                </span>
                <div className="dropdown-divider" />

                <div
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={handleClick4}
                >
                  <span style={{ color: "red" }}>{getAllReturnedByReg} </span>
                  Returned
                </div>
                <div className="dropdown-divider" />
                <div
                  className="dropdown-item"
                  onClick={handleClick5}
                  style={{ cursor: "pointer" }}
                >
                  <span style={{ color: "red" }}>{getAllUnverified} </span>
                  Unvalidate
                </div>
              </div>
            ) : accesslevel === "3" ? (
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-right  "
                style={{ left: "inherit", right: 0 }}
              >
                <span className="dropdown-item dropdown-header">
                  {`${allNotif} Notification/s`}
                </span>
                <div className="dropdown-divider" />

                <div
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={handleClick6}
                >
                  <span style={{ color: "red" }}>{allNotif} </span>Returned
                </div>
              </div>
            ) : accesslevel === "4" ? (
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-right"
                style={{ left: "inherit", right: 0 }}
              >
                <span className="dropdown-item dropdown-header">
                  {`${allNotif} Notification/s`}
                </span>
                <div className="dropdown-divider" />
                <div
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={handleClick7}
                >
                  <span style={{ color: "red" }}>
                    {getadmincheckunvalidate}{" "}
                  </span>
                  Unvalidate
                  <span className="text-muted"> (Non-estate)</span>
                </div>
                <div className="dropdown-divider" />
                <div
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={handleClick8}
                >
                  <span style={{ color: "green" }}>{getadmincomplied} </span>
                  Complied by admin
                  <span className="text-muted"> (Non-estate)</span>
                </div>
                <div className="dropdown-divider" />
              </div>
            ) : (
              ""
            )}
          </li>

          {/* 
          {accesslevel === 2 && (
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="dropdown"
                href="#"
                aria-expanded="false"
              >
                <i className="far fa-bell" />
                <span className="badge badge-danger navbar-badge">3</span>
              </a>
            </li>
          )} */}

          <li className="nav-item dropdown">
            <div className="nav-link " data-toggle="dropdown">
              <div style={{ cursor: "pointer" }}>
                <span className="username pr-4">
                  {username}
                  <span className="pl-3">
                    <i className="right fas fa-angle-down" />
                  </span>
                </span>
              </div>
            </div>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right card-border-edge">
              {/* {accesslevel === "2" || accesslevel === "1" ? (
                ""
              ) : (
                <div className="pl-2 pr-2 mt-2">
                  <a
                    type="button"
                    className="dropdown-acc p-2 dropdown-item rounded-sm "
                    data-target="#modal-signatories"
                    data-toggle="modal"
                    onClick={() => {
                      getSignatories();
                    }}
                  >
                    <i className="fa-solid fa-pen-to-square mr-2" /> Signatories
                  </a>
                </div>
              )} */}

              <div className="  p-2">
                <NavLink
                  to="/"
                  className="dropdown-acc p-2 dropdown-item rounded-sm"
                  onClick={handleLogout}
                >
                  <i className="fa-solid fa-right-from-bracket mr-2 " />
                  Logout
                </NavLink>
              </div>
            </div>
          </li>
        </ul>
      </nav>
      {/* /.navbar */}

      {/* SIGNATORIES MODAL */}

      <div
        className="modal fade "
        id="modal-signatories"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-info">
              <div className="p-0">
                <h4 className="modal-title">Edit Signatories</h4>
                <small>Check all the required fields before saving</small>
              </div>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <form>
                <div className="card-body">
                  <div className="form-group  ">
                    <label className="mb-1 font-weight-normal">
                      Prepared by
                    </label>
                    <input
                      className="form-control"
                      onChange={(e) => setPreparedBy(e.target.value)}
                      value={preparedby}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-1 font-weight-normal">
                      Designation
                    </label>
                    <input
                      className="form-control"
                      onChange={(e) => setPreparedBy_dg(e.target.value)}
                      value={preparedby_dg}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>

              {signatories.length === 0 ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleAddSignatories}
                >
                  Save
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleUpdateSignatories}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
