import React from "react";
import NhaLogo from "../images/nhalogo.png";
import Cosddlogo from "../images/cosddlogo.jpg";
import Msglogo from "../images/msglogo.jpg";

function Footer() {
  return (
    <div className="">
      <footer className="main-footer">
        {/* <img src={NhaLogo} style={{ width: "25px", height: "25px" }} />
        &nbsp; &nbsp; */}
        <strong>
          Copyright © 2023 <span>CIED-CIIS</span>.
        </strong>
        <span> All rights reserved.</span> &nbsp; &nbsp;
        {/* <img src={Msglogo} style={{ width: "25px", height: "25px" }} />
        &nbsp; &nbsp; */}
        {/* <img src={Cosddlogo} style={{ width: "25px", height: "25px" }} /> */}
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 1.0
        </div>
      </footer>
    </div>
  );
}

export default Footer;
