import React, { useState } from "react";
import "../App.css";

import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import md5 from "md5";
import Img from "../images/login-bg.png";
import NhaLogo from "../images/nhalogo.png";
import Cosddlogo from "../images/cosddlogo.png";
import Msglogo from "../images/msglogo.jpg";

import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const [hasNotified, setHasNotified] = useState(false); // New state variable
  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyWarning = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async () => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    const trimmedUsername = username.trim();
    const trimmedPassword = password.trim();
    try {
      let res = await fetch("https://api.nha.gov.ph/cied/userlogin", {
        mode: "cors",
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify({
          TRX_EMAIL: trimmedUsername,
          TRX_PASSWORD: md5(trimmedPassword),
        }),
      });
      if (res.status === 200) {
        if (!hasNotified) {
          // Check if the notification has been shown
          notifySuccess("Logged in successfully!");
          setHasNotified(true); // Set the notification state to true
        }
        setTimeout(() => {
          return navigate("/dashboard");
        }, 2000);
      } else {
        notifyWarning(
          "Your email and/or password is incorrect, please try again"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="App">
        <div className="mainCard">
          <div className="boxCard">
            <div className="innerCard">
              <div className="formsWrapCard">
                <div style={{ display: "flex" }}>
                  <div style={{ paddingTop: "10px" }}>
                    <img
                      src={NhaLogo}
                      alt="cied"
                      style={{ width: "40px", height: "40px" }}
                    />
                    &nbsp;
                    <img
                      src={Msglogo}
                      alt="cied"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                  <div
                    style={{
                      lineHeight: "0px",
                      marginTop: "10px",
                      textAlign: "left",
                      marginLeft: "10px",
                    }}
                  >
                    <p style={{ marginBottom: "10px" }}>
                      National Housing Authority
                    </p>
                    <h2 className="m-0" style={{ marginTop: "5px" }}>
                      CIED-CIIS
                    </h2>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "60px",
                    paddingBottom: "20px",
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <div style={{ marginTop: "20px", paddingBottom: "20px" }}>
                    <h1 style={{ marginTop: "20px" }}>Welcome Back</h1>
                    <p style={{ color: "darkgray", fontSize: "14px" }}>
                      Forgot password?{" "}
                      <span className="clickhere">Click Here</span>
                    </p>
                  </div>

                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <input
                      className="form-control loginInput"
                      type="text"
                      placeholder="Email Address"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <br />

                    <div
                      className="password-container"
                      style={{ position: "relative" }}
                    >
                      <input
                        className="form-control loginInput"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div
                        className="password-toggle "
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          color: "gray",
                        }}
                      >
                        {showPassword ? (
                          <BsFillEyeSlashFill />
                        ) : (
                          <BsFillEyeFill />
                        )}
                      </div>
                    </div>
                    <br />

                    <button className="loginBtn" onClick={handleLogin}>
                      Log in
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="bottom-text">
            <img
              src={Cosddlogo}
              alt="cied"
              style={{ width: "25px", height: "30px" }}
            />
            &nbsp;&nbsp;
            <p style={{ paddingTop: "10px" }}>
              Corporate Operations and Systems Development Department
            </p>
          </div>
          <div style={{ paddingTop: "10px" }}>
            Copyright © 2024 National Housing Authority. All rights reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
