import React from "react";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
import "./Modalstyle.css";
import { GetSignatories } from "../../functions/fx_signatories";
import { Modal, Button } from "react-bootstrap";

import NhaLogo from "../../images/nhalogo.png";

import domtoimage from "dom-to-image";

import {
  MapContainer,
  TileLayer,
  Marker,
  LayersControl,
  Polygon,
  Circle,
  useMap,
} from "react-leaflet";

import { WMSTileLayer } from "react-leaflet";

import {
  GetImage,
  UpdateInventoryStatus,
  UpdateInventoryStatusValidation,
} from "../../functions/fx_inventory";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";

import "leaflet/dist/leaflet.css";

function InventoryProfile({
  closeModal,
  show,
  inventoryprofile,
  passgeom,
  passcenteroid,
  fetchimagepass,
  getAllInventoryProjects,
  fetchimageflood,
  fetchimageseis,
  handleFilterResetAll,
}) {
  const borderOptions = {
    color: "red", // Border color
    weight: 2, // Border thickness
    fillColor: "yellow", // No fill color
  };
  const redOptions = { color: "red", dashArray: "10, 5", fill: false };
  const mapRef = useRef(null);
  const mapRef2 = useRef(null);
  const mapRef3 = useRef(null);
  const mapRef4 = useRef(null);

  const multiPolygon = passgeom;

  const [accesslevel, setAccessLevel] = useState("");
  const [reg, setReg] = useState("");
  const [dist, setDist] = useState("");

  const [responseResult, setresponseResult] = useState("");

  let m_names = new Array(
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  );
  let objectDate = new Date();
  let day = objectDate.getDate();
  let month = objectDate.getMonth();
  let monthname = m_names[month];
  let year = objectDate.getFullYear();
  let finalDateFormat = `${monthname}  ${day}, ${year}`;

  const [preparedBy, setPreparedBy] = useState("");
  const [preparedByPosition, setPreparedByPosition] = useState("");

  // const [preparedBy, setPreparedBy] = useState("");
  // const [reviewedBy, setReviewedBy] = useState("");
  // const [certifiedBy, setCertifiedBy] = useState("");
  // const [preparedBydg, setPreparedBydg] = useState("");
  // const [reviewedBydg, setReviewedBydg] = useState("");
  // const [certifiedBydg, setCertifiedBydg] = useState("");

  // const handleGetSignatories = () => {
  //   GetSignatories()
  //     .then((value) => {
  //       setPreparedBy(value.data[0].sig_preparedby);
  //       setReviewedBy(value.data[0].sig_revieweby);
  //       setCertifiedBy(value.data[0].sig_notedby);
  //       setPreparedBydg(value.data[0].sig_preparedby_dg);
  //       setReviewedBydg(value.data[0].sig_revieweby_dg);
  //       setCertifiedBydg(value.data[0].sig_notedby_dg);

  //       // You can use preparedBy, reviewedBy, and certifiedCorrect here as needed.
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching signatories:", error);
  //       // Handle the error appropriately (e.g., show an error message to the user).
  //     });
  // };

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyWarning = (message) =>
    toast.warn(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const [text, setText] = useState("");

  const handleValidate = () => {
    let identifier;
    let tbl_id = inventoryprofile.tbl_id;
    let invvalstatus = inventoryprofile.tbl_trxvalstatus;
    let trxstatus = inventoryprofile.tbl_trxstatus;
    let tag;
    let tag2;

    if (
      accesslevel === "2" &&
      inventoryprofile.tbl_trxstatus === "3" &&
      (inventoryprofile.tbl_trxvalstatus === "" ||
        inventoryprofile.tbl_trxvalstatus === null) &&
      inventoryprofile.catvalue === "Estate" //validate by region
    ) {
      tag = "2";
      identifier = "regval1";
    } else if (
      accesslevel === "2" &&
      inventoryprofile.tbl_trxvalstatus === "1" //complied by region the returned of admin
    ) {
      identifier = "regval2";
      tag = "2";
      tag2 = "";
    } else if (
      accesslevel === "1" &&
      inventoryprofile.tbl_trxvalstatus === "5" // validate by admin the returned by admin-checker
    ) {
      identifier = "adminval";
      tag = "";
      tag2 = "4";
    } else if (
      accesslevel === "1" &&
      inventoryprofile.tbl_trxvalstatus === "2" //validate by admin complied by region
    ) {
      identifier = "adminval2";
      tag = "1";
      tag2 = "";
    } else if (
      accesslevel === "1" &&
      inventoryprofile.tbl_trxstatus === "2" // validate by admin the complied by region
    ) {
      identifier = "adminval3";
      tag = "1";
    } else if (
      accesslevel === "4" &&
      inventoryprofile.tbl_trxstatus === "3" &&
      (inventoryprofile.tbl_trxvalstatus === "" ||
        inventoryprofile.tbl_trxvalstatus === null) &&
      inventoryprofile.catvalue === "Non-estate" //validate by admin-checker submitted by admin (non-estate prop)
    ) {
      identifier = "admincheckval";
      tag = "4";
    } else if (
      accesslevel === "4" &&
      inventoryprofile.tbl_trxvalstatus === "4" //validate by admin-checker complied by admin
    ) {
      identifier = "admincheckval2";
      tag = "4";
      tag2 = "";
    } else {
      return;
    }

    UpdateInventoryStatus(tbl_id, tag, identifier, tag2)
      .then((value) => {
        if (value === "0000") {
          notifySuccess("Successfully Updated!");
          getAllInventoryProjects();
          handleFilterResetAll();
        } else {
          notifyWarning("Error: Entries did not insert into database...");
        }
      })
      .catch((error) => {
        alert(error);
      });

    closeModal(false);
  };

  const handleValidation = (remark) => {
    let tbl_id = inventoryprofile.tbl_id;

    UpdateInventoryStatusValidation(tbl_id, remark)
      .then((value) => {
        if (value === "0000") {
          notifySuccess("Updated");
          getAllInventoryProjects();
          handleFilterResetAll();
        } else {
          notifyWarning("Error: Entries did not insert into datadase...");
        }
      })
      .catch((error) => {
        alert(error);
      });
    setText("");
    closeModal(false);
  };

  const handlePrint = async () => {
    var doc = new jsPDF("l", "pt", "a4");
    doc.setFontSize(12);

    const rowsHeader = [
      [
        "Property Identification:",
        `${inventoryprofile.propertyid}`,
        "District Office:",
        `${dist !== null ? dist : ""}`,
      ],
      ["Regional Office:", `${reg}`, "Date Prepared:", `${finalDateFormat}`],
    ];
    // AUTOTABLE TO HEADER

    // Define the initial Y position for the table and the image
    const startY = 15;
    let starY2 = 80;
    // Define logo dimensions and position
    const logoWidth = 40; // Width of the logo
    const logoHeight = 40; // Height of the logo (adjust as needed)
    const logoX = 15; // X position of the logo (left margin)
    const logoY = 16.5; // Y position of the logo (align with table start)

    // Add the logo to the PDF
    doc.addImage(NhaLogo, "PNG", logoX, logoY, logoWidth, logoHeight);
    const padding = 1.5;
    doc.rect(
      logoX - padding, // X position adjusted for padding
      logoY - padding, // Y position adjusted for padding
      logoWidth + 2 * padding, // Width adjusted for padding
      logoHeight + 2 * padding // Height adjusted for padding
    );

    // Define the table position to the right of the logo
    const tableX = logoX + logoWidth + 10; // Add a small margin to the right of the logo

    // AUTOTABLE TO HEADER
    autoTable(doc, {
      body: rowsHeader,
      startY: startY,
      theme: "plain",
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      margin: { left: tableX, right: 15 }, // Set the margin to only apply on the left
    });
    if (
      mapRef.current &&
      mapRef2.current &&
      mapRef3.current &&
      mapRef4.current
    ) {
      const mapCaptured1 = mapRef.current;
      const mapCaptured2 = mapRef2.current;
      const mapCaptured3 = mapRef3.current;
      const mapCaptured4 = mapRef4.current;

      const dataUrl1 = await domtoimage.toPng(mapCaptured1);
      const dataUrl2 = await domtoimage.toPng(mapCaptured2);
      const dataUrl3 = await domtoimage.toPng(mapCaptured3);
      const dataUrl4 = await domtoimage.toPng(mapCaptured4);

      const width = doc.internal.pageSize.getWidth();
      const height = doc.internal.pageSize.getHeight();

      // Define the margins and spacing
      const horizontalMargin = 15;
      const verticalMargin = 80;
      const spaceBetweenImagesX = 10;
      const spaceBetweenImagesY = 30; // Increased vertical space between rows

      // Calculate the width and height for each image (2x2 grid)
      const availableWidth = width - 2 * horizontalMargin - spaceBetweenImagesX;
      const availableHeight =
        height - verticalMargin - spaceBetweenImagesY - 120; // Leave extra space for labels and page number
      const imgWidth = availableWidth / 2;
      const imgHeight = availableHeight / 2;

      const borderWidth = 2;

      // Add the first image (top-left)
      const img1X = horizontalMargin;
      const img1Y = verticalMargin;
      doc.addImage(dataUrl1, "PNG", img1X, img1Y, imgWidth, imgHeight);
      doc.setLineWidth(borderWidth);
      doc.rect(
        img1X - borderWidth / 2,
        img1Y - borderWidth / 2,
        imgWidth + borderWidth,
        imgHeight + borderWidth
      );

      // Add the second image (top-right)
      const img2X = horizontalMargin + imgWidth + spaceBetweenImagesX;
      const img2Y = verticalMargin;
      doc.addImage(dataUrl2, "PNG", img2X, img2Y, imgWidth, imgHeight);
      doc.setLineWidth(borderWidth);
      doc.rect(
        img2X - borderWidth / 2,
        img2Y - borderWidth / 2,
        imgWidth + borderWidth,
        imgHeight + borderWidth
      );

      // Add the labels for the top row
      const labelYOffset = 20; // Offset below the images
      doc.text("LOCATION", img1X, img1Y + imgHeight + labelYOffset);
      doc.text("SITE PHOTO", img2X, img2Y + imgHeight + labelYOffset);

      // Add the third image (bottom-left)
      const img3X = horizontalMargin;
      const img3Y =
        verticalMargin + imgHeight + spaceBetweenImagesY + labelYOffset;
      doc.addImage(dataUrl3, "PNG", img3X, img3Y, imgWidth, imgHeight);
      doc.setLineWidth(borderWidth);
      doc.rect(
        img3X - borderWidth / 2,
        img3Y - borderWidth / 2,
        imgWidth + borderWidth,
        imgHeight + borderWidth
      );

      // Add the fourth image (bottom-right)
      const img4X = horizontalMargin + imgWidth + spaceBetweenImagesX;
      const img4Y =
        verticalMargin + imgHeight + spaceBetweenImagesY + labelYOffset;
      doc.addImage(dataUrl4, "PNG", img4X, img4Y, imgWidth, imgHeight);
      doc.setLineWidth(borderWidth);
      doc.rect(
        img4X - borderWidth / 2,
        img4Y - borderWidth / 2,
        imgWidth + borderWidth,
        imgHeight + borderWidth
      );

      // Add the labels for the bottom row
      doc.text("FLOOD ASSESSMENT", img3X, img3Y + imgHeight + labelYOffset);
      // Add "SEISMIC ANALYSIS" label
      doc.text("SEISMIC ANALYSIS", img4X, img4Y + imgHeight + labelYOffset);
      // Add "Credits to:" above the URL for "FLOOD ASSESSMENT"
      doc.setFontSize(10); // Optional: reduce font size for credits
      doc.text("Credits to:", img3X, img3Y + imgHeight + labelYOffset + 10); // Place it below the label

      // Calculate the position for the URL (place it beside "Credits to:")
      const urlX = img3X + doc.getTextWidth("Credits to:") + 5;

      // Set the color to blue for the URL
      doc.setTextColor(0, 0, 255); // RGB for blue

      // Add the visible URL as text
      const urlText = "https://noah.up.edu.ph/";
      doc.text(urlText, urlX, img3Y + imgHeight + labelYOffset + 10); // Align it with "Credits to:"

      // Add a clickable link on the URL text
      doc.link(
        urlX,
        img3Y + imgHeight + labelYOffset + 7, // Adjust Y-position for clickable area
        doc.getTextWidth(urlText),
        10,
        {
          url: urlText,
        }
      );

      // Reset text color back to black before adding "SEISMIC ANALYSIS"
      doc.setTextColor(0, 0, 0);

      // Add "Credits to:" above the URL for "SEISMIC ANALYSIS"
      doc.text("Credits to:", img4X, img4Y + imgHeight + labelYOffset + 10);

      // Calculate the position for the URL (place it beside "Credits to:")
      const seismicUrlX = img4X + doc.getTextWidth("Credits to:") + 5;
      const seismicUrlText = "https://hazardhunter.georisk.gov.ph/";

      // Set text color to blue for the URL
      doc.setTextColor(0, 0, 255); // RGB for blue
      doc.text(
        seismicUrlText,
        seismicUrlX,
        img4Y + imgHeight + labelYOffset + 10
      );

      // Add a clickable link on the URL text
      doc.link(
        seismicUrlX,
        img4Y + imgHeight + labelYOffset + 7, // Adjust Y-position for clickable area
        doc.getTextWidth(seismicUrlText),
        10,
        { url: seismicUrlText }
      );

      // Reset text color to black for further content
      doc.setTextColor(0, 0, 0);
    }
    const bidsaleawardee =
      inventoryprofile.pbtype === "Negotiated Sale"
        ? inventoryprofile.pbawardee
        : inventoryprofile.pbtype === "Sale"
        ? inventoryprofile.pbsaleawardee
        : "";
    const bidsaledateaward =
      inventoryprofile.pbtype === "Negotiated Sale"
        ? inventoryprofile.pbdateaward
        : inventoryprofile.pbtype === "Sale"
        ? inventoryprofile.pbsaledateaward
        : "";
    const bidsaleselprice =
      inventoryprofile.pbtype === "Negotiated Sale"
        ? inventoryprofile.pbselprice
        : inventoryprofile.pbtype === "Sale"
        ? inventoryprofile.pbsaleselprice
        : "";
    const bidsalecontype =
      inventoryprofile.pbtype === "Negotiated Sale"
        ? inventoryprofile.pbcontype
        : inventoryprofile.pbtype === "Sale"
        ? inventoryprofile.pbsalecontype
        : "";

    const bidleaselessee =
      inventoryprofile.pbtype === "Negotiated Lease"
        ? inventoryprofile.pblessee
        : inventoryprofile.pbtype === "Lease"
        ? inventoryprofile.pbleaselessee
        : "";
    const bidleasedateaward =
      inventoryprofile.pbtype === "Negotiated Lease"
        ? inventoryprofile.pbdateawardlease
        : inventoryprofile.pbtype === "Lease"
        ? inventoryprofile.pbleasedateaward
        : "";
    const bidleaseactoccu =
      inventoryprofile.pbtype === "Negotiated Lease"
        ? inventoryprofile.pbactoccu
        : inventoryprofile.pbtype === "Lease"
        ? inventoryprofile.pbleaseactoccu
        : "";
    const bidleaserate =
      inventoryprofile.pbtype === "Negotiated Lease"
        ? inventoryprofile.pbleaserate
        : inventoryprofile.pbtype === "Lease"
        ? inventoryprofile.pbleaseratepermon
        : "";

    const bidleaseterm =
      inventoryprofile.pbtype === "Negotiated Lease"
        ? inventoryprofile.pbleaseterm
        : inventoryprofile.pbtype === "Lease"
        ? inventoryprofile.pbleaseleaseterm
        : "";

    //  SOLD
    const rightColumn1 =
      inventoryprofile.awardstat === "Sold/Disposed"
        ? [
            [
              {
                content: "1. LOT CLASSIFICATION",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],
            ["Zoning Ordinance:", `${inventoryprofile.zoneordinace}`],
            [
              inventoryprofile.catvalue === "Estate" ? "As per LIS:" : "",

              `${inventoryprofile.catvalue}/${
                inventoryprofile.classvalue === "1" ||
                inventoryprofile.classvalue === "5"
                  ? "Commercial"
                  : inventoryprofile.classvalue === "2" ||
                    inventoryprofile.classvalue === "6"
                  ? "Industrial"
                  : inventoryprofile.classvalue === "3" ||
                    inventoryprofile.classvalue === "8"
                  ? "Institutional"
                  : inventoryprofile.classvalue === "4"
                  ? "Foreclosed/Acquired Property"
                  : inventoryprofile.classvalue === "7"
                  ? "Mixed-use"
                  : ""
              }${
                inventoryprofile.foreclosed_prop
                  ? `/${inventoryprofile.foreclosed_prop}`
                  : ""
              }       `,
            ],
            [
              {
                content: "2. LOT INFORMATION",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],
            [{ content: "2.1 Lot Identification", colSpan: 2 }],
            ["Lot:", `${inventoryprofile.lot}`],
            ["Block:", `${inventoryprofile.blk}`],
            ["Sub-block:", `${inventoryprofile.sub_blk}`],
            ["Superblock:", `${inventoryprofile.sup_blk}`],
            ["Phase:", `${inventoryprofile.phase}`],
            ["Package:", `${inventoryprofile.pack}`],
            ["Area:", `${inventoryprofile.area}`],
            [{ content: "2.2 Lot Information", colSpan: 2 }],
            ["Lot Area (sq.m):", `${inventoryprofile.lotarea}`],
            ["Stall No.:", `${inventoryprofile.stallno || ""}`],
            ["Floor Area (sq.m):", `${inventoryprofile.flrarea}`],
            [{ content: "2.3 Subdivision Plan", colSpan: 2 }],
            ["Subdivision Plan No.:", `${inventoryprofile.subplanno}`],
            [
              "Status:",
              `${inventoryprofile.subplan} / Filed on ${inventoryprofile.subplanfiledate}`,
            ],
            [{ content: "2.4 Status of Title", colSpan: 2 }],
            ["Registered Owner:", `${inventoryprofile.otherowner}`],
            ["TCT/OCT No.:", `${inventoryprofile.tctno}`],
            [
              "Title:",
              `${inventoryprofile.titlestat}${
                inventoryprofile.wit_ano ? ` / ${inventoryprofile.wit_ano}` : ""
              }`,
            ],

            [
              {
                content: "3. PHYSICAL PROFILE AND UTILITIES",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],
            ["Frontage width:", `${inventoryprofile.frontage}`],
            ["Shape:", `${inventoryprofile.shape}`],
            ["Topography:", `${inventoryprofile.topography}`],
            ["Flood Assessment:", `${inventoryprofile.foodassessment}`],
            ["Seismic Analysis:", `${inventoryprofile.sysmicanalysis}`],
            ["Easements/Right-of-way:", `${inventoryprofile.road}`],
            ["Electricity Provider:", `${inventoryprofile.electricprov}`],
            ["Water Provider:", `${inventoryprofile.waterprov}`],

            [
              {
                content: "4. OCCUPANCY STATUS",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],

            [
              {
                content: `${
                  inventoryprofile.physicalstat === "Others" ||
                  inventoryprofile.physicalstat === "Occupied"
                    ? inventoryprofile.physicalstat + ":"
                    : inventoryprofile.physicalstat + " "
                }`,
                colSpan: 2,
              },
            ],

            [
              {
                content: `${
                  inventoryprofile.occupiedstat[0] !== undefined
                    ? inventoryprofile.occupiedstat[0]
                    : ""
                }`,
                colSpan: 2,
              },
            ],

            [
              {
                content: `${
                  inventoryprofile.occupiedstat[1] !== undefined
                    ? inventoryprofile.occupiedstat[1]
                    : ""
                }`,
                colSpan: 2,
              },
            ],

            [
              {
                content: `${
                  inventoryprofile.occupiedstat[2] !== undefined
                    ? inventoryprofile.occupiedstat[2]
                    : ""
                }`,
                colSpan: 2,
              },
            ],

            [
              {
                content: `${
                  inventoryprofile.physicalstat === "Others"
                    ? inventoryprofile.physicalstatothers
                    : ""
                }`,
                colSpan: 2,
              },
            ],

            [
              {
                content: `${
                  inventoryprofile.structuredesc
                    ? inventoryprofile.structuredesc
                    : ""
                }`,
                colSpan: 2,
              },
            ],

            [
              {
                content: "5. MODE OF DISPOSITION",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],
            [
              `${
                inventoryprofile.modedispo === "1"
                  ? "Public Bidding"
                  : inventoryprofile.modedispo === "2"
                  ? "With Agreement"
                  : inventoryprofile.modedispo === "3"
                  ? "Others"
                  : ""
              } `,

              inventoryprofile.pbtype
                ? `${inventoryprofile.pbtype}`
                : inventoryprofile.awardstatagreement
                ? `${inventoryprofile.awardstatagreement}`
                : inventoryprofile.othersmodedipo
                ? `${inventoryprofile.othersmodedipo}`
                : "",
            ],

            ["Awardee:", `${bidsaleawardee}`],

            ["Date of Award:", `${bidsaledateaward}`],

            ["Selling Price per m2:", `${bidsaleselprice}`],

            ["Contract Type:", ` ${bidsalecontype}`],

            ["Lessee:", `${bidleaselessee}`],

            ["Date of Award:", `${bidleasedateaward ? bidleasedateaward : ""}`],

            ["Actual Occupant:", `${bidleaseactoccu}`],

            ["Lease Rate per m2/mo:", `${bidleaserate}`],

            ["Lease Term:", `${bidleaseterm}`],

            [
              "Name of Entity/Person:",
              `${
                inventoryprofile.usufructentity
                  ? inventoryprofile.usufructentity
                  : ""
              }`,
            ],

            [
              "Term (No. of Years):",
              `${
                inventoryprofile.agreementterm
                  ? inventoryprofile.agreementterm
                  : ""
              }`,
            ],

            [
              {
                content: "6. ACCOUNT STATUS",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],
            [
              {
                content: `${inventoryprofile.acctstatus}`,
                colSpan: 2,
              },
            ],
          ]
        : inventoryprofile.awardstat === "Unsold/Undisposed"
        ? [
            [
              {
                content: "1. LOT CLASSIFICATION",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],
            ["Zoning Ordinance:", `${inventoryprofile.zoneordinace}`],
            [
              inventoryprofile.catvalue === "Estate" ? "As per LIS:" : "",
              `${inventoryprofile.catvalue}/${
                inventoryprofile.classvalue === "1" ||
                inventoryprofile.classvalue === "5"
                  ? "Commercial"
                  : inventoryprofile.classvalue === "2" ||
                    inventoryprofile.classvalue === "6"
                  ? "Industrial"
                  : inventoryprofile.classvalue === "3" ||
                    inventoryprofile.classvalue === "8"
                  ? "Institutional"
                  : inventoryprofile.classvalue === "4"
                  ? "Foreclosed/Acquired Property"
                  : inventoryprofile.classvalue === "7"
                  ? "Mixed-use"
                  : ""
              }${
                inventoryprofile.foreclosed_prop
                  ? `/${inventoryprofile.foreclosed_prop}`
                  : ""
              }`,
            ],
            [
              {
                content: "2. LOT INFORMATION",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],
            [{ content: "2.1 Lot Identification", colSpan: 2 }],
            ["Lot:", `${inventoryprofile.lot}`],
            ["Block:", `${inventoryprofile.blk}`],
            ["Sub-block:", `${inventoryprofile.sub_blk}`],
            ["Superblock:", `${inventoryprofile.sup_blk}`],
            ["Phase:", `${inventoryprofile.phase}`],
            ["Package:", `${inventoryprofile.pack}`],
            ["Area:", `${inventoryprofile.area}`],
            [{ content: "2.2 Lot Information", colSpan: 2 }],
            ["Lot Area (sq.m):", `${inventoryprofile.lotarea}`],
            ["Stall No.:", `${inventoryprofile.stallno || ""}`],
            ["Floor Area (sq.m):", `${inventoryprofile.flrarea}`],
            [{ content: "2.3 Subdivision Plan", colSpan: 2 }],
            ["Subdivision Plan No.:", `${inventoryprofile.subplanno}`],
            ["Status:", `${inventoryprofile.subplan}`],
            [{ content: "2.4 Status of Title", colSpan: 2 }],
            ["Registered Owner:", `${inventoryprofile.otherowner}`],
            ["TCT/OCT No.:", `${inventoryprofile.tctno}`],
            [
              "Title:",
              `${inventoryprofile.titlestat}${
                inventoryprofile.wit_ano ? ` / ${inventoryprofile.wit_ano}` : ""
              }`,
            ],

            [
              {
                content: "3. OCCUPANCY STATUS",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],

            [
              {
                content: `${
                  inventoryprofile.physicalstat === "Others" ||
                  inventoryprofile.physicalstat === "Occupied"
                    ? inventoryprofile.physicalstat + ":"
                    : inventoryprofile.physicalstat + " "
                }`,
                colSpan: 2,
              },
            ],

            [
              {
                content: `${
                  inventoryprofile.occupiedstat[0] !== undefined
                    ? inventoryprofile.occupiedstat[0]
                    : ""
                }`,
                colSpan: 2,
              },
            ],

            [
              {
                content: `${
                  inventoryprofile.occupiedstat[1] !== undefined
                    ? inventoryprofile.occupiedstat[1]
                    : ""
                }`,
                colSpan: 2,
              },
            ],

            [
              {
                content: `${
                  inventoryprofile.occupiedstat[2] !== undefined
                    ? inventoryprofile.occupiedstat[2]
                    : ""
                }`,
                colSpan: 2,
              },
            ],

            [
              {
                content: `${
                  inventoryprofile.physicalstat === "Others"
                    ? inventoryprofile.physicalstatothers
                    : ""
                }`,
                colSpan: 2,
              },
            ],
            [
              {
                content: `${
                  inventoryprofile.structuredesc
                    ? inventoryprofile.structuredesc
                    : ""
                }`,
                colSpan: 2,
              },
            ],
            [
              {
                content: "4. PHYSICAL PROFILE AND UTILITIES",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],
            ["Frontage width:", `${inventoryprofile.frontage}`],
            ["Shape:", `${inventoryprofile.shape}`],
            ["Topography:", `${inventoryprofile.topography}`],
            ["Flood Assessment:", `${inventoryprofile.foodassessment}`],
            ["Seismic Analysis:", `${inventoryprofile.sysmicanalysis}`],
            ["Easements/Right-of-way:", `${inventoryprofile.road}`],
            ["Electricity Provider:", `${inventoryprofile.electricprov}`],
            ["Water Provider:", `${inventoryprofile.waterprov}`],

            [
              {
                content:
                  "5. SOCIO-ECONOMIC CLASS OF THE PLACE OF ITS OCCUPANTS",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],
            [
              {
                content: `${inventoryprofile.sesu}`,
                colSpan: 2,
              },
            ],

            [
              {
                content: "6. LEGAL STATUS",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],
            [
              {
                content: `${
                  inventoryprofile.legalstat === "1"
                    ? "With pending case"
                    : inventoryprofile.legalstat === "2"
                    ? "Without pending case"
                    : ""
                }  `,

                colSpan: 2,
              },
            ],
            [
              {
                content: "Action taken/to be taken, if any:",
                colSpan: 2,
              },
            ],
            [
              {
                content: `${inventoryprofile.actiontaken}`,
                colSpan: 2,
              },
            ],

            [
              {
                content: "7. HISTORICAL BACKGROUND",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],
            [
              {
                content: `${inventoryprofile.executiveissuance}`,
                colSpan: 2,
              },
            ],

            [
              {
                content: "8. APPRAISAL STATUS",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],
            [
              {
                content: `${
                  inventoryprofile.appstat === "1"
                    ? "With appraised values"
                    : inventoryprofile.appstat === "2"
                    ? "For appraisal"
                    : ""
                }`,

                colSpan: 2,
              },
            ],

            inventoryprofile.appstat === "2"
              ? [""]
              : [
                  {
                    content: `${
                      inventoryprofile.appmarketval
                        ? `Market value per sqm.: ${inventoryprofile.appmarketval}`
                        : ""
                    }`,

                    colSpan: 2,
                  },
                ],
            [
              {
                content: `${
                  inventoryprofile.apprentval
                    ? `Market rent per sqm.: ${inventoryprofile.apprentval}`
                    : ""
                }`,

                colSpan: 2,
              },
            ],
            [
              {
                content: `${
                  inventoryprofile.appvalid
                    ? `Valid until: ${inventoryprofile.appvalid}`
                    : ""
                }`,

                colSpan: 2,
              },
            ],

            [
              {
                content: "9. PRICING STATUS",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],
            [
              {
                content: `${
                  inventoryprofile.pricingstat === "1"
                    ? "With Valid Pricing"
                    : inventoryprofile.pricingstat === "2"
                    ? "Expired Pricing"
                    : ""
                }`,

                colSpan: 2,
              },
            ],

            inventoryprofile.pricingstat === "1"
              ? ["Valid until:", `${inventoryprofile.pricevalid}`]
              : [
                  {
                    content: "",
                    colSpan: 2,
                  },
                ],

            [
              {
                content: "10. PROPOSED MODE OF DISPOSITION",
                colSpan: 2,
                styles: {
                  fillColor: "#D3D3D3",
                },
              },
            ],

            inventoryprofile.propmodedispo === "1"
              ? ["Public Bidding: ", `${inventoryprofile.proppbtype}`]
              : [
                  {
                    content: "",
                    colSpan: 2,
                  },
                ],

            inventoryprofile.propmodedispo === "2"
              ? ["Direct Award ", ""]
              : [
                  {
                    content: "",
                    colSpan: 2,
                  },
                ],

            inventoryprofile.propmodedispo === "3"
              ? ["Others: ", `${inventoryprofile.propothersmodedipo}`]
              : [
                  {
                    content: "",
                    colSpan: 2,
                  },
                ],
          ]
        : [];

    doc.addPage();
    doc.setFontSize(12);
    doc.setLineWidth(0);
    doc.addImage(NhaLogo, "PNG", logoX, logoY, logoWidth, logoHeight);
    const padding2 = 1.5;
    doc.rect(
      logoX - padding2, // X position adjusted for padding
      logoY - padding2, // Y position adjusted for padding
      logoWidth + 2 * padding2, // Width adjusted for padding
      logoHeight + 2 * padding2 // Height adjusted for padding
    );
    autoTable(doc, {
      body: rowsHeader,

      startY: 15,
      theme: "plain",
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },

      margin: { left: tableX, right: 15 }, // Set the margin to only apply on the left
    });

    const finalycol = doc.lastAutoTable.finalY;
    // Calculate remaining height on the first page
    const remainingHeight = doc.internal.pageSize.height - finalycol - 30; //

    // Estimate the height of rightColumn1 data
    const estimatedHeight = rightColumn1.length * 10; // 10
    if (estimatedHeight > remainingHeight) {
      // If the content overflows, split into two columns
      const middleIndex = Math.ceil(rightColumn1.length / 2);
      const leftColumn = rightColumn1.slice(0, middleIndex);
      const rightColumn = rightColumn1.slice(middleIndex);

      // Render the left column on the left side
      autoTable(doc, {
        startY: starY2,
        theme: "plain",
        styles: {
          fontSize: 7, // Set the global font size
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
          padding: 0,
          cellPadding: 2,
        },
        columnStyles: {
          0: { cellWidth: 180 },
          1: { cellWidth: 180 }, // Fixed column width
        },
        margin: { left: 35 }, // Center the left column
        body: leftColumn,
      });

      // Render the right column on the right side
      autoTable(doc, {
        startY: starY2,
        theme: "plain",
        styles: {
          fontSize: 7, // Set the global font size
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
          padding: 0,
          cellPadding: 2,
        },
        columnStyles: {
          0: { cellWidth: 180 }, // Fixed column width
          1: { cellWidth: 180 }, // Fixed column width
        },
        margin: { left: 450 }, // Center the right column with spacing
        body: rightColumn,
      });
    } else {
      // If no overflow, render the data normally
      autoTable(doc, {
        startY: starY2,
        theme: "plain",
        styles: {
          fontSize: 7, // Set the global font size
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
          padding: 0,
          cellPadding: 2,
        },

        columnStyles: {
          0: { cellWidth: 180 },
          1: { cellWidth: 180 },
        },
        margin: { left: 50, right: 50 }, // Equal left and right margins
        body: rightColumn1,
      });
    }

    const prepared_By =
      responseResult.name +
      " " +
      responseResult.mname +
      " " +
      responseResult.lname;

    const prepared_By_Position = responseResult.position;

    const finaly = doc.lastAutoTable.finalY;
    doc.setFontSize(9); //set the fontsize of the prepared by
    doc.text(100, finaly + 70, "Prepared by:");
    doc.text(100, finaly + 105, prepared_By);
    doc.text(100, finaly + 115, prepared_By_Position);

    console.log(doc.internal.pageSize.height); //595
    console.log(doc.lastAutoTable.finalY); //397
    doc.setFontSize(8); //set the fontsize page number
    const pageCount = doc.internal.getNumberOfPages();
    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);

      const footer = `Page ${i} of ${pageCount}`;

      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 15,
        { baseline: "bottom" }
      );
    }

    var blob = doc.output("blob");
    window.open(URL.createObjectURL(blob));
  };

  useEffect(() => {
    // handleGetSignatories();
    fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setresponseResult(responseJson.response);

        setAccessLevel(responseJson.response.useraccess);
        setReg(responseJson.response.reg);
        setDist(responseJson.response.dist);
      })
      .catch((error) => {
        notifyWarning(
          "Session not Established... Please Check back-end server.."
        );
        console.log(error);
      });
  }, []);

  return (
    <div>
      <Modal
        // size="xl"
        dialogClassName="modal-full-width"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => {
          closeModal(false);
        }}
      >
        <Modal.Header className="bg-info">
          <Modal.Title>Inventory Profile</Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => {
              closeModal(false);
            }}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="row ">
            <div className="col-12 mb-3 p-0">
              <div className="float-right">
                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={handlePrint}
                >
                  Print
                </button>
              </div>
              <div className="float-right mr-2">
                {(inventoryprofile.tbl_trxstatus === "2" &&
                  accesslevel === "2") || //validated by region
                (accesslevel === "2" &&
                  inventoryprofile.tbl_trxvalstatus === "2") || //complied by region the returned by admin
                (inventoryprofile.tbl_trxstatus === "4" &&
                  accesslevel === "4") || //validated by admin-checker
                (inventoryprofile.tbl_trxstatus === "1" &&
                  accesslevel === "1") || //validated by admin
                (inventoryprofile.tbl_trxstatus === "4" &&
                  accesslevel === "1") || //validated by admin-checker
                (inventoryprofile.tbl_trxvalstatus === "4" && //validated by admin the returned of admin-checker
                  accesslevel === "1") ? (
                  <button
                    type="button"
                    className="btn btn-warning  mr-2"
                    style={{ pointerEvents: "none" }}
                  >
                    Validated
                  </button>
                ) : (accesslevel === "2" &&
                    inventoryprofile.tbl_trxstatus === "3" &&
                    (inventoryprofile.tbl_trxvalstatus === "" ||
                      inventoryprofile.tbl_trxvalstatus === null) &&
                    inventoryprofile.catvalue === "Estate") || //validate by region
                  (accesslevel === "2" &&
                    inventoryprofile.tbl_trxvalstatus === "1") || // validate by region returned by admin
                  (accesslevel === "1" &&
                    inventoryprofile.tbl_trxvalstatus === "5") || // validate by admin returned by admin-checker
                  (accesslevel === "1" &&
                    inventoryprofile.tbl_trxvalstatus === "2") || //validate by admin complied by region
                  (accesslevel === "1" &&
                    inventoryprofile.tbl_trxstatus === "2") || // validate by admin the validated by region
                  (accesslevel === "4" &&
                    inventoryprofile.tbl_trxstatus === "3" &&
                    (inventoryprofile.tbl_trxvalstatus === "" ||
                      inventoryprofile.tbl_trxvalstatus === null) && //validate by admin-checker submitted by admin (non-estate prop)
                    inventoryprofile.catvalue === "Non-estate") ||
                  (accesslevel === "4" && //validate by admin-checker complied by admin
                    inventoryprofile.tbl_trxvalstatus === "4") ? (
                  <button
                    type="button"
                    className=" btn btn-primary  mr-2"
                    data-dismiss="modal"
                    onClick={() => {
                      handleValidate();
                    }}
                  >
                    Validate
                  </button>
                ) : (
                  ""
                )}
              </div>
              {(accesslevel === "1" &&
                inventoryprofile.tbl_trxstatus === "2" &&
                inventoryprofile.catvalue === "Estate") ||
              (accesslevel === "1" &&
                inventoryprofile.tbl_trxstatus === "1" &&
                inventoryprofile.catvalue === "Estate") ||
              (accesslevel === "1" &&
                inventoryprofile.tbl_trxvalstatus === "2" &&
                inventoryprofile.catvalue === "Estate") ||
              (accesslevel === "1" &&
                inventoryprofile.tbl_trxvalstatus === "1" &&
                inventoryprofile.catvalue === "Estate") ? (
                <div className="d-flex ml-2   ">
                  <textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    className="form-control w-50   mr-2"
                    rows={1}
                    style={{
                      resize: "none",
                    }}
                  />{" "}
                  <button
                    disabled={text.trim() === ""}
                    type="button"
                    className=" btn btn-primary "
                    onClick={() => {
                      handleValidation(text);
                    }}
                  >
                    Return
                  </button>
                </div>
              ) : accesslevel === "4" &&
                inventoryprofile.catvalue === "Non-estate" ? (
                <div className="d-flex ml-2   ">
                  <textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    className="form-control w-50   mr-2"
                    rows={1}
                    style={{
                      resize: "none",
                    }}
                  />{" "}
                  <button
                    disabled={text.trim() === ""}
                    type="button"
                    className=" btn btn-primary "
                    onClick={() => {
                      handleValidation(text);
                    }}
                  >
                    Return
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row">
            <div className="section col-lg-5">
              <div className="overflow-auto " style={{ height: "600px" }}>
                <div
                  className=" bg-info mb-2"
                  style={{ height: "500px" }}
                  ref={mapRef}
                >
                  <MapContainer
                    style={{
                      height: "100%",
                      minHeight: "100%",
                    }}
                    center={passcenteroid}
                    zoom={17}
                    scrollWheelZoom={false}
                  >
                    <TileLayer
                      url="https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}"
                      zoom={13}
                      maxZoom={21}
                    />

                    {passcenteroid.length === 0 ? (
                      ""
                    ) : (
                      <Marker
                        position={passcenteroid}
                        icon={
                          new Icon({
                            iconUrl: markerIconPng,
                            iconSize: [25, 41],
                            iconAnchor: [12, 41],
                          })
                        }
                      ></Marker>
                    )}
                    <Polygon
                      pathOptions={borderOptions}
                      positions={multiPolygon}
                    />
                    {passcenteroid.length === 0 ? "" : ""}
                    <LayersControl>
                      <LayersControl.Overlay checked name="CII">
                        <WMSTileLayer
                          url="https://maps.nha.gov.ph/geoserver/wms?"
                          opacity={1}
                          transparent
                          format="image/png"
                          layers="NHA_PH:trial1_acdue_cii"
                          minZoom={8}
                          maxZoom={21}
                        />
                      </LayersControl.Overlay>
                      <LayersControl.Overlay checked name="CII Non-estate">
                        <WMSTileLayer
                          url="https://maps.nha.gov.ph/geoserver/wms?"
                          opacity={1}
                          transparent
                          format="image/png"
                          layers="NHA_PH:nha_cii"
                          minZoom={8}
                          maxZoom={21}
                        />
                      </LayersControl.Overlay>
                      <LayersControl.Overlay checked name="CII Marker">
                        <WMSTileLayer
                          url="https://maps.nha.gov.ph/geoserver/wms?"
                          opacity={1}
                          transparent
                          format="image/png"
                          layers="NHA_PH:nha_cii_loc"
                          minZoom={8}
                          maxZoom={21}
                        />
                      </LayersControl.Overlay>
                    </LayersControl>
                  </MapContainer>
                </div>

                <div className="mb-2" style={{ height: "600px" }} ref={mapRef2}>
                  <div className="overflow-auto" style={{ height: "100%" }}>
                    <img
                      src={fetchimagepass}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "fill",
                      }}
                    />
                  </div>
                </div>

                <div className="mb-2" style={{ height: "600px" }} ref={mapRef3}>
                  <div className="overflow-auto" style={{ height: "100%" }}>
                    <img
                      src={fetchimageflood}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "fill",
                      }}
                    />
                  </div>
                </div>

                <div className="" style={{ height: "600px" }} ref={mapRef4}>
                  <div className="overflow-auto" style={{ height: "100%" }}>
                    <img
                      src={fetchimageseis}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "fill",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {inventoryprofile.awardstat === "Sold/Disposed" ? (
              <div
                className="section col-lg-7 overflow-auto"
                style={{ height: "500px" }}
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div className="border">
                      <h6 className="bg-gray pl-1">1. LOT CLASSIFICATION</h6>
                      <div className="p-1 ">
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Property ID</span>
                          <span className="w-50 text-right-wrap">
                            {inventoryprofile.propertyid}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">As Per Zoning Ordinance</span>
                          <span className="w-50 text-right-wrap">
                            {inventoryprofile.zoneordinace}
                          </span>
                        </div>
                        <div>
                          {inventoryprofile.catvalue === "Estate" ? (
                            <span className="w-50">As Per LIS:</span>
                          ) : (
                            <span className=""></span>
                          )}
                        </div>

                        <div className="d-flex ">
                          <span className="w-50">
                            {inventoryprofile.catvalue}
                          </span>
                          <div className="w-50 ">
                            <span className=" ">
                              {inventoryprofile.classvalue === "1" ||
                              inventoryprofile.classvalue === "5"
                                ? "Commercial"
                                : inventoryprofile.classvalue === "2" ||
                                  inventoryprofile.classvalue === "6"
                                ? "Industrial"
                                : inventoryprofile.classvalue === "3" ||
                                  inventoryprofile.classvalue === "8"
                                ? "Institutional"
                                : inventoryprofile.classvalue === "4"
                                ? "Foreclosed/Acquired Property"
                                : inventoryprofile.classvalue === "7"
                                ? "Mixed-use"
                                : ""}
                            </span>
                            {inventoryprofile.foreclosed_prop && (
                              <div>
                                <span className="">
                                  /{inventoryprofile.foreclosed_prop}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border">
                      <h6 className="bg-gray pl-1">2. LOT INFORMATION</h6>
                      <div className="ml-2 mr-2">
                        <div className="mb-4">
                          {" "}
                          <h6 className="border-bottom">
                            2.1 Lot Identification
                          </h6>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Lot</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.lot}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Block</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.blk}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Sub-block</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.sub_blk}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Superblock</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.sup_blk}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Phase</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.phase}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Package</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.pack}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Area</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.area}
                            </span>
                          </div>
                        </div>

                        <div className="mb-4">
                          <h6 className="border-bottom">2.2 Lot Information</h6>

                          {inventoryprofile.stallno ? (
                            <div className="d-flex ">
                              <span className="w-50">Stall No.:</span>
                              <span className="w-50 text-right-wrap">
                                {inventoryprofile.stallno}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          {inventoryprofile.lotarea ? (
                            <div className="d-flex ">
                              <span className="w-50">Lot Area (sq.m):</span>
                              <span className="w-50 text-right-wrap">
                                {inventoryprofile.lotarea}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          {inventoryprofile.flrarea ? (
                            <div className="d-flex ">
                              <span className="w-50">Floor Area (sq.m):</span>
                              <span className="w-50 text-right-wrap">
                                {inventoryprofile.flrarea}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="mb-4">
                          <h6 className="border-bottom">
                            2.3 Lot Subdivision Plan
                          </h6>

                          {inventoryprofile.subplanno ? (
                            <div className="d-flex ">
                              <span className="w-50">
                                {inventoryprofile.subplan ===
                                "Subdivision Plan No."
                                  ? `${inventoryprofile.subplan} ` + ":"
                                  : ""}
                              </span>
                              <span className="w-50 text-right-wrap">
                                {inventoryprofile.subplanno}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          {inventoryprofile.subplan === "Not yet Subdivided" ? (
                            <div className="d-flex ">
                              <span className="w-50">Status:</span>
                              <span className="w-50 text-right-wrap">
                                {inventoryprofile.subplan}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          {inventoryprofile.subplan === "For Subdivision" ? (
                            <>
                              <div className="d-flex ">
                                <span className="w-50">Status:</span>
                                <span className="w-50 text-right-wrap">
                                  {inventoryprofile.subplan}
                                </span>
                              </div>
                              <div className="d-flex ">
                                <span className="w-50">Filed on:</span>
                                <span className="w-50 text-right-wrap">
                                  {inventoryprofile.subplanfiledate}
                                </span>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="mb-4">
                          <h6 className="border-bottom">
                            2.4 Lot Status of Title
                          </h6>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Registered Owner:</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.otherowner}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">TCT/OCT no.:</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.tctno}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Title:</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.titlestat}
                              {inventoryprofile.wit_ano &&
                                `/${inventoryprofile.wit_ano}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="border">
                      <h6 className="bg-gray pl-1">
                        3. PHYSICAL PROFILE and UTILITIES
                      </h6>
                      <div className="p-1">
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Frontage width:</span>
                          <span className="w-50 text-right-wrap">
                            {" "}
                            {inventoryprofile.frontage}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Shape:</span>
                          <span className="w-50 text-right-wrap">
                            {inventoryprofile.shape}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Topography:</span>
                          <span className="w-50 text-right-wrap">
                            {" "}
                            {inventoryprofile.topography}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Flood Assessment:</span>
                          <span className="w-50 text-right-wrap">
                            {" "}
                            {inventoryprofile.foodassessment}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Seismic Analysis:</span>
                          <span className="w-50 text-right-wrap">
                            {" "}
                            {inventoryprofile.sysmicanalysis}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Easement/Right-of-way</span>
                          <span className="w-50 text-right-wrap">
                            {inventoryprofile.road.join(", ")}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Electricity Provider:</span>
                          <span className="w-50 text-right-wrap">
                            {" "}
                            {inventoryprofile.electricprov}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Water Provider:</span>
                          <span className="w-50 text-right-wrap">
                            {" "}
                            {inventoryprofile.waterprov}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="border">
                      <h6 className="bg-gray pl-1">4. OCCUPANCY STATUS</h6>
                      <div className="pl-1">
                        {inventoryprofile.physicalstat === "Occupied" ||
                        inventoryprofile.physicalstat === "Vacant" ||
                        inventoryprofile.physicalstat === "Others" ? (
                          <div className="d-flex ">
                            <span className="">
                              {inventoryprofile.physicalstat}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}

                        {inventoryprofile.physicalstat === "Occupied" ? (
                          <div className="d-flex">
                            <span className="w-50">Occupied by:</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.occupiedstat
                                .filter(Boolean)
                                .join(", ")}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}

                        {inventoryprofile.physicalstat === "Others" ? (
                          <div className="d-flex ">
                            <span className="w-50">Occupied by:</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.physicalstatothers}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}

                        {inventoryprofile.structuredesc ? (
                          <>
                            <span className="w-50">
                              Short Description of existing structure/s <br />
                              or utilization:
                            </span>
                            <div>
                              <span className="w-50 text-right-wrap">
                                {inventoryprofile.structuredesc}
                              </span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="border">
                      <h6 className="bg-gray pl-1">5. MODE OF DISPOSITION</h6>
                      <div className="p-1">
                        <div className="d-flex ">
                          <span className="w-50">
                            {inventoryprofile.modedispo === "1"
                              ? "Public Bidding" + ":"
                              : inventoryprofile.modedispo === "2"
                              ? "With Agreement" + ":"
                              : inventoryprofile.modedispo === "3"
                              ? "Others" + ":"
                              : ""}
                          </span>

                          <span className="w-50  ">
                            <span className="w-50">
                              {inventoryprofile.pbtype
                                ? `${inventoryprofile.pbtype} `
                                : ""}
                              {inventoryprofile.awardstatagreement
                                ? `${inventoryprofile.awardstatagreement} `
                                : ""}
                              {inventoryprofile.othersmodedipo
                                ? `${inventoryprofile.othersmodedipo} `
                                : ""}
                            </span>
                          </span>
                        </div>

                        {inventoryprofile.modedispo === "2" ? (
                          <div className="d-flex ">
                            <span className="w-50">
                              {" "}
                              Usufructuary (Name of Entity/Person):
                            </span>

                            <span className="w-50  ">
                              {inventoryprofile.usufructentity}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}

                        {inventoryprofile.modedispo === "2" ? (
                          <div className="d-flex ">
                            <span className="w-50">Term (No. of Years):</span>

                            <span className="w-50  ">
                              {inventoryprofile.agreementterm}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}

                        {/* public bidding SALE */}
                        {inventoryprofile.pbtype === "Sale" ? (
                          <>
                            <div className="d-flex">
                              <span className="w-50">Awardee:</span>
                              <span className="w-50">
                                {inventoryprofile.pbsaleawardee}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="w-50">Date of Award:</span>
                              <span className="w-50">
                                {inventoryprofile.pbsaledateaward}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="w-50 ">
                                Selling Price per m2:
                              </span>
                              <span className="w-50">
                                {inventoryprofile.pbsaleselprice}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="w-50">Contract Type:</span>
                              <span className="w-50">
                                {inventoryprofile.pbsalecontype}
                              </span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        {/* public bidding LEASE */}

                        {inventoryprofile.pbtype === "Lease" ? (
                          <>
                            <div className="d-flex">
                              <span className="w-50">Lessee:</span>
                              <span className="w-50">
                                {inventoryprofile.pbleaselessee}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="w-50">Date of Award:</span>
                              <span className="w-50">
                                {inventoryprofile.pbleasedateaward}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="w-50">Actual Occupant:</span>
                              <span className="w-50">
                                {inventoryprofile.pbleaseactoccu}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="w-50 ">
                                Lease Rate per m2/mo:
                              </span>
                              <span className="w-50">
                                {inventoryprofile.pbleaseratepermon}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="w-50">Lease Term:</span>
                              <span className="w-50">
                                {inventoryprofile.pbleaseleaseterm}
                              </span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        {inventoryprofile.pbtype === "Negotiated Sale" ? (
                          <>
                            <div className="d-flex">
                              <span className="w-50">Awardee:</span>
                              <span className="w-50">
                                {inventoryprofile.pbawardee}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="w-50">Date of Award:</span>
                              <span className="w-50">
                                {inventoryprofile.pbdateaward}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="w-50 ">
                                Selling Price per m2:
                              </span>
                              <span className="w-50">
                                {inventoryprofile.pbselprice}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="w-50">Contract Type:</span>
                              <span className="w-50">
                                {inventoryprofile.pbcontype}
                              </span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {inventoryprofile.pbtype === "Negotiated Lease" ? (
                          <>
                            <div className="d-flex">
                              <span className="w-50">Lessee:</span>
                              <span className="w-50">
                                {inventoryprofile.pblessee}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="w-50">Date of Award:</span>
                              <span className="w-50">
                                {inventoryprofile.pbdateawardlease}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="w-50">Actual Occupant:</span>
                              <span className="w-50">
                                {inventoryprofile.pbactoccu}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="w-50 ">
                                Lease Rate per m2/mo:
                              </span>
                              <span className="w-50">
                                {inventoryprofile.pbleaserate}
                              </span>
                            </div>
                            <div className="d-flex">
                              <span className="w-50">Lease Term:</span>
                              <span className="w-50">
                                {inventoryprofile.pbleaseterm}
                              </span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="border">
                      <h6 className="bg-gray pl-1">6. ACCOUNT STATUS</h6>
                      <div className="p-1">
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">
                            {" "}
                            {inventoryprofile.acctstatus}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : inventoryprofile.awardstat === "Unsold/Undisposed" ? (
              <div
                className="section col-lg-7 overflow-auto"
                style={{ height: "500px" }}
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div className="border">
                      <h6 className="bg-gray pl-1">1. LOT CLASSIFICATION</h6>
                      <div className="p-1 ">
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Property ID</span>
                          <span className="w-50 text-right-wrap">
                            {inventoryprofile.propertyid}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">
                            Land Use Classification, as per Zoning Ordinance:
                          </span>
                          <span className="w-50 text-right-wrap">
                            {inventoryprofile.zoneordinace}
                          </span>
                        </div>
                        <div>
                          {inventoryprofile.catvalue === "Estate" ? (
                            <span className="w-50">As Per LIS:</span>
                          ) : (
                            <span className=""></span>
                          )}
                        </div>

                        <div className="d-flex ">
                          <span className="w-50">
                            {inventoryprofile.catvalue}
                          </span>
                          <div className="w-50 ">
                            <span className=" ">
                              {inventoryprofile.classvalue === "1" ||
                              inventoryprofile.classvalue === "5"
                                ? "Commercial"
                                : inventoryprofile.classvalue === "2" ||
                                  inventoryprofile.classvalue === "6"
                                ? "Industrial"
                                : inventoryprofile.classvalue === "3" ||
                                  inventoryprofile.classvalue === "8"
                                ? "Institutional"
                                : inventoryprofile.classvalue === "4"
                                ? "Foreclosed/Acquired Property"
                                : inventoryprofile.classvalue === "7"
                                ? "Mixed-use"
                                : ""}
                            </span>
                            {inventoryprofile.foreclosed_prop && (
                              <div>
                                <span className="">
                                  /{inventoryprofile.foreclosed_prop}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border">
                      <h6 className="bg-gray pl-1">2. LOT INFORMATION</h6>
                      <div className="ml-2 mr-2">
                        <div className="mb-4">
                          {" "}
                          <h6 className="border-bottom">
                            2.1 Lot Identification
                          </h6>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Lot</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.lot}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Block</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.blk}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Sub-block</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.sub_blk}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Superblock</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.sup_blk}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Phase</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.phase}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Package</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.pack}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Area</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.area}
                            </span>
                          </div>
                        </div>

                        <div className="mb-4">
                          <h6 className="border-bottom">2.2 Lot Information</h6>
                          {inventoryprofile.stallno ? (
                            <div className="d-flex ">
                              <span className="w-50">Stall No.:</span>
                              <span className="w-50 text-right-wrap">
                                {inventoryprofile.stallno}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          {inventoryprofile.lotarea ? (
                            <div className="d-flex ">
                              <span className="w-50">Lot Area (sq.m):</span>
                              <span className="w-50 text-right-wrap">
                                {inventoryprofile.lotarea}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          {inventoryprofile.flrarea ? (
                            <div className="d-flex ">
                              <span className="w-50">Floor Area (sq.m):</span>
                              <span className="w-50 text-right-wrap">
                                {inventoryprofile.flrarea}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="mb-4">
                          <h6 className="border-bottom">
                            2.3 Lot Subdivision Plan
                          </h6>

                          {inventoryprofile.subplanno ? (
                            <div className="d-flex ">
                              <span className="w-50">
                                {inventoryprofile.subplan ===
                                "Subdivision Plan No."
                                  ? `${inventoryprofile.subplan} ` + ":"
                                  : ""}
                              </span>
                              <span className="w-50 text-right-wrap">
                                {inventoryprofile.subplanno}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          {inventoryprofile.subplan === "Not yet Subdivided" ? (
                            <div className="d-flex ">
                              <span className="w-50">Status:</span>
                              <span className="w-50 text-right-wrap">
                                {inventoryprofile.subplan}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          {inventoryprofile.subplan === "For Subdivision" ? (
                            <>
                              <div className="d-flex ">
                                <span className="w-50">Status:</span>
                                <span className="w-50 text-right-wrap">
                                  {inventoryprofile.subplan}
                                </span>
                              </div>
                              <div className="d-flex ">
                                <span className="w-50">Filed on:</span>
                                <span className="w-50 text-right-wrap">
                                  {inventoryprofile.subplanfiledate}
                                </span>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="mb-4">
                          <h6 className="border-bottom">
                            2.4 Lot Status of Title
                          </h6>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Registered Owner:</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.otherowner}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">TCT/OCT no.:</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.tctno}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="w-50">Title:</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.titlestat}
                              {inventoryprofile.wit_ano &&
                                `/${inventoryprofile.wit_ano}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border">
                      <h6 className="bg-gray pl-1">3. OCCUPANCY STATUS</h6>
                      <div className="pl-1">
                        {inventoryprofile.physicalstat === "Occupied" ||
                        inventoryprofile.physicalstat === "Vacant" ||
                        inventoryprofile.physicalstat === "Others" ? (
                          <div className="d-flex ">
                            <span className="">
                              {inventoryprofile.physicalstat}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}

                        {inventoryprofile.physicalstat === "Occupied" ? (
                          <div className="d-flex">
                            <span className="w-50">Occupied by:</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.occupiedstat
                                .filter(Boolean)
                                .join(", ")}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}

                        {inventoryprofile.physicalstat === "Others" ? (
                          <div className="d-flex ">
                            <span className="w-50">Occupied by:</span>
                            <span className="w-50 text-right-wrap">
                              {inventoryprofile.physicalstatothers}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}

                        {inventoryprofile.structuredesc ? (
                          <>
                            <span className="w-50">
                              Short Description of existing structure/s
                              <br /> or utilization:
                            </span>
                            <div>
                              <span className="w-50 text-right-wrap">
                                {inventoryprofile.structuredesc}
                              </span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="border">
                      <h6 className="bg-gray pl-1">
                        4. PHYSICAL PROFILE and UTILITIES
                      </h6>
                      <div className="p-1">
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Frontage width:</span>
                          <span className="w-50 text-right-wrap">
                            {" "}
                            {inventoryprofile.frontage}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Shape:</span>
                          <span className="w-50 text-right-wrap">
                            {inventoryprofile.shape}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Topography:</span>
                          <span className="w-50 text-right-wrap">
                            {" "}
                            {inventoryprofile.topography}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Flood Assessment:</span>
                          <span className="w-50 text-right-wrap">
                            {" "}
                            {inventoryprofile.foodassessment}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Seismic Analysis:</span>
                          <span className="w-50 text-right-wrap">
                            {" "}
                            {inventoryprofile.sysmicanalysis}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Easement/Right-of-way:</span>
                          <span className="w-50 text-right-wrap">
                            {inventoryprofile.road.join(", ")}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Electricity Provider:</span>
                          <span className="w-50 text-right-wrap">
                            {" "}
                            {inventoryprofile.electricprov}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <span className="w-50">Water Provider:</span>
                          <span className="w-50 text-right-wrap">
                            {" "}
                            {inventoryprofile.waterprov}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="border">
                      <h6 className="bg-gray pl-1">
                        5. SOCIO ECONOMIC CLASS OF THE PLACE OR ITS OCCUPANTS
                      </h6>
                      <div className="p-1">
                        <span className="w-50">{inventoryprofile.sesu}</span>
                      </div>
                    </div>
                    <div className="border">
                      <h6 className="bg-gray pl-1">6. LEGAL STATUS</h6>
                      <div className="p-1">
                        <span className="w-50">
                          {" "}
                          {inventoryprofile.legalstat === "1"
                            ? "With pending case"
                            : inventoryprofile.legalstat === "2"
                            ? "Without pending case"
                            : ""}
                        </span>
                      </div>

                      {inventoryprofile.actiontaken ? (
                        <>
                          <div className="p-1">
                            <span className="w-50">
                              Action taken/to be taken, if any:
                            </span>
                          </div>

                          <span className="w-50 pl-1">
                            {inventoryprofile.actiontaken}
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="border">
                      <h6 className="bg-gray pl-1">7. HISTORICAL BACKGROUND</h6>
                      <div className="p-1">
                        <span
                          className="w-50"
                          style={{
                            overflowWrap: "break-word",
                          }}
                        >
                          {inventoryprofile.executiveissuance}
                        </span>
                      </div>
                    </div>

                    <div className="border">
                      <h6 className="bg-gray pl-1">8. APPRAISAL STATUS</h6>
                      <div className="p-1">
                        <span className="w-50">
                          {inventoryprofile.appstat === "1"
                            ? "With appraised values"
                            : inventoryprofile.appstat === "2"
                            ? "For appraisal"
                            : ""}
                        </span>

                        <div className="d-flex ">
                          <span className="w-50">
                            {inventoryprofile.appstat === "1"
                              ? "Market value per sqm.:"
                              : ""}
                          </span>
                          <span className="w-50 text-right-wrap">
                            {inventoryprofile.appmarketval
                              ? `${inventoryprofile.appmarketval} `
                              : ""}
                          </span>
                        </div>

                        <div className="d-flex ">
                          <span className="w-50">
                            {inventoryprofile.appstat === "1"
                              ? "Market rent per sqm.:"
                              : ""}
                          </span>
                          <span className="w-50 text-right-wrap">
                            {inventoryprofile.apprentval
                              ? `${inventoryprofile.apprentval} `
                              : ""}
                          </span>
                        </div>

                        <div className="d-flex ">
                          <span className="w-50">
                            {inventoryprofile.appstat === "1"
                              ? "Valid until:"
                              : ""}
                          </span>
                          <span className="w-50 text-right-wrap">
                            {inventoryprofile.appvalid
                              ? `${inventoryprofile.appvalid} `
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="border">
                      <h6 className="bg-gray pl-1">9. PRICING STATUS</h6>
                      <div className="p-1">
                        <span className="w-50">
                          {inventoryprofile.pricingstat === "1"
                            ? "With Valid Pricing"
                            : inventoryprofile.pricingstat === "2"
                            ? "Expired Pricing"
                            : ""}
                        </span>
                        <div className="d-flex ">
                          <span className="w-50">
                            {inventoryprofile.pricingstat === "1"
                              ? "Valid until:"
                              : ""}
                          </span>
                          <span className="w-50 text-right-wrap">
                            {inventoryprofile.pricevalid
                              ? `${inventoryprofile.pricevalid} `
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="border">
                      <h6 className="bg-gray pl-1">
                        10. PROPOSED MODE OF DISPOSITION
                      </h6>
                      <div className="p-1">
                        <div className="d-flex ">
                          <span className="w-50">
                            {inventoryprofile.propmodedispo === "1"
                              ? "Public Bidding" + ":"
                              : inventoryprofile.propmodedispo === "2"
                              ? "Direct Award"
                              : inventoryprofile.propmodedispo === "3"
                              ? "Others" + ":"
                              : ""}
                          </span>

                          <span className="w-50">
                            {inventoryprofile.proppbtype
                              ? `${inventoryprofile.proppbtype} `
                              : ""}
                          </span>
                        </div>
                        <span className="w-50">
                          {inventoryprofile.propothersmodedipo}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              closeModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default InventoryProfile;
