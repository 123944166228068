import React from "react";

import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

function Sidenav() {
  const [accesslevel, setAccessLevel] = useState("");

  useEffect(() => {
    fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setAccessLevel(responseJson.response.useraccess);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      {" "}
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-light-info elevation-2">
        {/* Brand Logo */}
        <a href="#/dashboard" className="brand-link">
          <img
            src="dist/img/nhalogo.png"
            alt="NHA Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light">NHA | CIED-CIIS</span>
        </a>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}

          {/* SidebarSearch Form */}

          {/* Sidebar Menu */}

          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <NavLink
                  to="/dashboard"
                  className="nav-link bg-white elevation-0"
                >
                  <i className="nav-icon fas fa-th-large mr-3" />
                  <p>Dashboard</p>
                </NavLink>
              </li>

              {accesslevel === "3" || accesslevel === "1" ? (
                <>
                  <li className="nav-item">
                    <NavLink to="/form" className="nav-link">
                      <i className="nav-icon fas fa-clipboard mr-3" />
                      <p>Form Sold</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/form2" className="nav-link">
                      <i className="nav-icon fas fa-clipboard mr-3" />
                      <p>Form Unsold</p>
                    </NavLink>
                  </li>
                </>
              ) : null}
              {accesslevel === "1" ||
              accesslevel === "2" ||
              accesslevel === "3" ||
              accesslevel === "4" ? (
                <li className="nav-item">
                  <NavLink to="/inventory" className="nav-link">
                    <i className="nav-icon fas fa-list-alt mr-3" />
                    <p>Inventory</p>
                  </NavLink>
                </li>
              ) : null}

              {accesslevel === "1" ? (
                <li className="nav-item">
                  <NavLink to="/user" className="nav-link">
                    <i className="nav-icon fas fa-users mr-3" />
                    <p>User</p>
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </nav>

          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
}

export default Sidenav;
