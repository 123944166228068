import React from "react";
import { useState, useEffect, usesMemo, useRef } from "react";
import Header from "../layoutcomponents/Header";
import Sidebar from "../layoutcomponents/Sidebar";
import Footer from "../layoutcomponents/Footer";
import { pdf_report_A } from "../functions/fx_print_pdf";
import { Modal, Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import InventoryProfile from "../components/modal/InventoryProfile";

import { BsEye, BsPencilSquare, BsCircle, BsTrash } from "react-icons/bs";
import { GetDistIndi, GetRegionalOffice } from "../functions/fx_initialdata";
import myImage from "../images/noimage.png";
import {
  GetAllInventory,
  GetImage,
  GetGeomEstate,
  GetGeomNonEstate,
  GetSortbyLISClass,
  GetSortbyLISCat,
  GetSortbyLISAwardStat,
  GetSortbyProject,
  GetInventorybyRegion,
  GetInventorybyDistrict,
  GetSortByDisModeDispo,
  GetSortbyLISClassNationwide,
  GetSortbyLISClassAndDistrict,
  GetSortbyAwardStatNationwide,
  GetSortbyLisClassAndAwardStatNationwide,
  GetSortbyDistLisclassAwardStat,
  GetSortProjectbyDistLisclassAwardstatPropname,
  GetSortProjectbyPropnameWithAgreement,
  GetSortbyLISClassWithAgreementNationwide,
  GetSortbyLISClassAndDistrictWithAgreement,
  GetSortByModeDispoNationwide,
  GetSortWithAgreeNationwide,
  GetSortbyLISClassAndRegion,
  GetSortbyLISClassAndRegionWithAgreement,
  GetSortbyRegionAwardStat,
  GetSortByRegModeDispo,
  GetSortbyDistrictAwardStat,
  GetSortbyLISClassAllDistrict,
  GetSortbyLISClassAllDistWithAgreement,
  GetSortbyAwardStatByRegion,
  GetSortbyLisClassAndAwardStatAllDist,
  GetSortbyDistrictLisclassAwardStat,
  GetSortbyDistrictLisclassAwardStatWithAgreement,
  GetSortbyRegionLisclassAwardstat,
  GetSortbyRegionLisclassAwardstatWithAgreement,
  GetSortByDistLisclassAwardstatPropnameModeDispo,
  GetSortByDistLisclassAwardstatPropnameModeDispoWithAgreement,
  GetSortbyLisClassAndAwardStatAllDistWithAgreement,
  GetSortByModeDispoAllDistrict,
  GetSortByLisclassAwardstatModedispo,
  GetSortByLisclassAwardstatModedispoWithAgreement,
  GetSortByAwardstatModedispo,
  GetSortByLisclassModeDispoAllDistrict,
  GetSortByLisclassModeDispoAllDistrictWithAgreement,
  GetSortByDistLisclassModeDispo,
  GetSortByDistLisclassModeDispoWithAgreement,
  GetSortByDistAwardStatModeDispo,
  GetSortByDistAwardStatPropnameModeDispo,
  GetSortByDistAwardstatPropname,
  GetSortByDistLisclassPropname,
  GetSortByDistLisclassPropnameWithAgreement,
  GetSortByDistLisclassPropnameModeDispo,
  GetSortByDistLisclassPropnameModeDispoWithAgreement,
  GetLisclassByDistAcct,
  GetLisclassWithAgreementByDistAcct,
  GetAwardStatByDistAcct,
  GetLisClassAwardStatByDistAcct,
  GetLisClassAwardStatWithAgreementByDistAcct,
  GetLisClassAwardStatPropNameByDistAcct,
  GetLisClassAwardStatPropNameWithAgreementByDistAcct,
  GetLisClassPropNameByDistAcct,
  GetLisClassPropNameWithAgreementByDistAcct,
  GetAwardStatPropNameByDistAcct,
  GetLisClassAwardStatPropNameLeaseAgreeByDistAcct,
  GetLisClassAwardStatPropNameLeaseAgreeWithAgreeByDistAcct,
  GetAwardStatPropNameLeaseAgreeByDistAcct,
  GetLisClassLeaseAgreeByDistAcct,
  GetLisClassLeaseAgreeWithAgreeByDistAcct,
  GetAwardStatLeaseAgreeByDistAcct,
  GetSortbyLisClassAndAwardStatNationwideWithAgree,
  GetSortByLisclassAwardstatModedispoAdmin,
  GetSortByLisclassAwardstatModedispoWithAgreeAdmin,
  GetSortByAwardstatModedispoAdmin,
  GetSortByLisclassModeDispoNationWide,
  GetSortByLisclassModeDispoWithAgreeNationWide,
  GetSortByDistLisclassAwardstatModeDispo,
  GetSortByDistLisclassAwardstatModeDispoWithAgree,
  GetSortByModeDispoByDistrict,
  GetSortByRegWithAgreement,
  GetSortWithAgreementAllDistrict,
  GetSortWithAgreementPerDist,
  GetImageFlood,
  GetImageSeis,
  GetLisclassPropnameModeDispo,
  GetLisclassPropnameNegoLeaseAgree,
  GetDistAcctWithAgree,
  GetAwardStatWithAgree,
  GetAwardStatPropNameWithAgree,
  GetAwardStatPropNameReg,
  GetAwardStatPropNameWithAgreeReg,
  GetSetLotClassification,
  GetSetLotClassificationForClosedProp,
  GetSetLotClassificationForClosedPropByReg,
  GetSetLotClassificationByReg,
  GetSetLotClassificationForClosedPropByDist,
  GetSetLotClassificationByDist,
  GetSetLotClassificationForClosedReg,
  GetSetLotClassificationReg,
  GetForeClosedByDistRegAcct,
  GetLotClassByDistRegAcct,
  GetSetLotClassificationForClosedDist,
  GetSetLotClassificationDist,
  GetAllUnvalidate,
  GetAllReturned,
  GetAllValidatedByRegion,
  GetAdminAllReturned,
  GetComplied,
  GetDistAllReturned,
  GetAdminCheckUnvalidate,
  GetAdminCheckComplied,
  GetRegLisLotClassForClosed,
  GetRegLisLotClass,
  GetRegDisLisLotClassForClosed,
  GetRegDisLisLotClass,
  GetRegAwardStatLotClassForClosed,
  GetRegAwardStatLotClass,
  GetLisLotClassForeclosed,
  GetLisLotClass,
  GetAwardStatLotClassForeClosedNationWide,
  GetAwardStatLotClassNationWide,
  GetRegDistAwardStatLotClassForClosed,
  GetRegDistAwardStatLotClass,
  GetSortbyLISClassAndDistrictReg,
  GetSortbyDistrictLisclassAwardStatReg,
  GetSortProjectbyDistLisclassAwardstatPropnameReg,
  GetSortByDistAwardstatPropnameReg,
  GetSortbyDistrictAwardStatReg,
  GetSortByDistLisclassPropnameReg,
  GetSortByDistLisclassAwardstatPropnameModeDispoReg,
  GetSortByDistLisclassAwardstatPropnameModeDispoWithAgreementReg,
  GetSortByDistLisclassModeDispoReg,
  GetSortByDistLisclassModeDispoWithAgreementReg,
  GetSortByDistAwardStatModeDispoReg,
  GetSortByDistAwardStatPropnameModeDispoReg,
  GetSortByDistLisclassPropnameModeDispoReg,
  GetSortByDistLisclassPropnameModeDispoWithAgreementReg,
  GetSortByDistLisclassAwardstatModeDispoReg,
  GetSortByDistLisclassAwardstatModeDispoWithAgreeReg,
  LisClassAwardStatLotClassForeClosed,
  LisClassAwardStatLotClass,
  HandleDeleteData,
} from "../functions/fx_inventory";

import {
  GetSortbyLISClassExcel,
  GetSortbyLISClassExcelB,
  GetSortbyLISClassExcelC,
  GetSortbyLISClassExcelD,
  GetPrintNationWideLisClass,
  GetPrintNationWideAwardStatus,
} from "../functions/fx_printexcel";

import { GetSignatories } from "../functions/fx_signatories";
import PaginationInv from "../components/PaginationInv";

function Inventory() {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { triggerFetch } = location.state || {}; // Destructure notifTag from location.state
  const [printing, setPrinting] = useState("");
  const [region, setRegion] = useState([]);
  const [lotclassification, setLotClassification] = useState("");
  const [deleinvdata, setDeleteDataID] = useState("");
  const [regionValue, setRegionValue] = useState([]);
  const [districtValue, setDistrictValue] = useState("");
  const [district, setDistrict] = useState([]);
  const [allinventoryprojects, setAllInventoryProject] = useState([]);
  const [inventoryprofile, setInventoryProfile] = useState([]);
  const [inventorybyclass, setInventorybyClass] = useState([]);
  const [selectedproperty, setSelectedProperty] = useState("");
  const [selectedpropertyname, setSelectedPropertyName] = useState("");
  const [reporttype, setReportType] = useState("");
  const [modeofdispo, setModeofDispo] = useState("");

  const [fetchimagepass, setFetchedImagePass] = useState("");
  const [passgeom, setPassGeom] = useState("");
  const [passcenteroid, setPassCenteroid] = useState("");
  const [accesslevel, setAccessLevel] = useState("");
  const [lisclass, setLISClass] = useState("");
  const [lisclassadmin, setLisClassAdmin] = useState([]);
  const [awardstatadmin, setAwardStatAdmin] = useState([]);
  const [lotclassadmin, setLotClassAdmin] = useState([]);
  const [awardstatus, setLISAwardStat] = useState("");
  const [liscat, setLISCat] = useState("");
  const [Signatory, setSignatory] = useState([]);
  const [fetchimageflood, setFetchImageFlood] = useState("");
  const [fetchimageseis, setFetchImageSeis] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifyWarning = (message) =>
    toast.warn(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  //////////SORTING DATA////
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState(null);

  const sortData = (column) => {
    setSortOrder("asc");
    const sortedData = allinventoryprojects.sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      if (valueA === null || valueB === null) {
        return valueA === null ? 1 : -1;
      }

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });
    setAllInventoryProject(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };

  const renderSortingArrow = (column) => {
    if (sortColumn === column) {
      return sortOrder === "asc" ? "↑" : "↓";
    }
    return null;
  };

  const getImagePass = (uuid) => {
    GetImage(uuid)
      .then((value) => {
        if (value.concatFile) {
          setFetchedImagePass(value.concatFile);
        } else {
          //ADD PLACEHOLDER
          console.log(value.data);
          setFetchedImagePass(myImage);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    GetImageFlood(uuid)
      .then((value) => {
        if (value.concatFile) {
          setFetchImageFlood(value.concatFile);
          console.log(value.concatFile);
        } else {
          console.log("Seis Flood image not get");
          setFetchImageFlood(myImage);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    GetImageSeis(uuid)
      .then((value) => {
        if (value.concatFile) {
          setFetchImageSeis(value.concatFile);
          console.log(value.concatFile);
        } else {
          console.log("Seis Flood image not get");
          setFetchImageSeis(myImage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [openModalRemark, SetOpenModalRemark] = useState("");
  const handlePassDataModalValue = (data) => {
    SetOpenModalRemark(data.checkremark);
  };

  const [openInventoryModal, setopenInventoryModal] = useState(false);

  const handlePassDataValue = async (data) => {
    console.log(data.geomid);
    console.log(data.tbl_uuid);
    console.log(data.catvalue);
    getImagePass(data.tbl_uuid);

    if (data.catvalue === "Estate") {
      try {
        const value = await GetGeomEstate(data.geomid, data.tbl_uuid);
        let obj = JSON.parse(value.data[0].geojson);
        var coordinatesestate = obj.coordinates;
        let sumLat = 0;
        let sumLng = 0;
        let numPoints = 0;

        obj.coordinates.forEach((polygon) => {
          polygon.forEach((ring) => {
            ring.forEach((point) => {
              sumLat += point[1];
              sumLng += point[0];
              numPoints++;
            });
          });
        });

        const centerLat = sumLat / numPoints;
        const centerLng = sumLng / numPoints;
        var centeroid = [centerLng, centerLat];

        setPassGeom(coordinatesestate);
        setPassCenteroid(centeroid);
        setInventoryProfile(data);
        setopenInventoryModal(true);
      } catch (error) {
        console.log(error);
      }
    } else if (data.catvalue === "Non-estate") {
      try {
        const value = await GetGeomNonEstate(data.geomid, data.tbl_uuid);
        let obj = JSON.parse(value.data[0].geojson);
        var coordinatesestate = obj.coordinates;
        let sumLat = 0;
        let sumLng = 0;
        let numPoints = 0;

        obj.coordinates.forEach((polygon) => {
          polygon.forEach((ring) => {
            ring.forEach((point) => {
              sumLat += point[1];
              sumLng += point[0];
              numPoints++;
            });
          });
        });

        const centerLat = sumLat / numPoints;
        const centerLng = sumLng / numPoints;
        var centeroid = [centerLng, centerLat];

        setPassGeom(coordinatesestate);
        setPassCenteroid(centeroid);
        setInventoryProfile(data);
        setopenInventoryModal(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getAllInventoryProjects = () => {
    GetAllInventory()
      .then((value) => {
        setAllInventoryProject(value.data);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getRegionalOffice = () => {
    GetRegionalOffice()
      .then((value) => {
        setRegion(value.data);
      })
      .catch((error) => {
        alert(error);
      });
  };
  const handleGetDistrict = () => {
    GetDistIndi(null)
      .then((value) => {
        setDistrict(value.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeRegion = (selectedOptions) => {
    console.log(selectedOptions);
    if (!selectedOptions.length) {
      // Handle case when no option is selected
      setRegionValue([]);
      setSelectedProperty("");
      setLISAwardStat("");
      setModeofDispo("");
      setLISClass("");
      setDistrictValue("");
      setAllInventoryProject([]);
      setDistrict([]);
      setPrinting("");
      setLotClassification("");
      getAllInventoryProjects();

      return;
    }

    const selectedValues = selectedOptions.map((option) => option.value);
    setRegionValue(selectedValues);
    setSelectedProperty("");
    setLISAwardStat("");
    setModeofDispo("");
    setLISClass("");
    setDistrictValue("");

    GetInventorybyRegion(selectedValues)
      .then((value) => {
        console.log(value.data);
        setAllInventoryProject(value.data);
        setPrinting("printallprojbyreg");
      })
      .catch((error) => {
        alert(error);
      });

    GetDistIndi(selectedValues)
      .then((value) => {
        setDistrict(value.data);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleGetDistValue = (e) => {
    if (accesslevel === "2" && e.target.value === "") {
      getAllInventoryProjects();
      setDistrictValue("");
      return;
    } else if (accesslevel === "1" && e.target.value === "") {
      setRegionValue([]);
      getAllInventoryProjects();
      setDistrictValue("");
      return;
    } else if (accesslevel === "4" && e.target.value === "") {
      setRegionValue([]);
      getAllInventoryProjects();
      setDistrictValue("");
      return;
    }

    setLISAwardStat("");
    setLISClass("");
    setSelectedProperty("");
    setModeofDispo("");
    setLotClassification("");

    setDistrictValue(e.target.value);
    if (
      lisclass === "" &&
      awardstatus === "" &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      GetInventorybyDistrict(e.target.value)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printprojbydist");
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const [occupiedstat, setOccupiedStat] = useState([]);
  const [checkall, setCheckAll] = useState(false);

  const handleChangeCheck = (event) => {
    setCheckAll(false);
    const { value, checked } = event.target;
    if (checked) {
      setOccupiedStat([...occupiedstat, value]); // add the value to the array
    } else {
      setOccupiedStat(occupiedstat.filter((item) => item !== value)); // remove the value from the array
    }
  };

  const handleLISClass = (selectedOptions) => {
    console.log(selectedOptions);

    if (!selectedOptions.length) {
      // Handle case when no option is selected
      setLisClassAdmin([]);
      setSelectedProperty("");
      setLISAwardStat("");
      setModeofDispo("");

      setDistrictValue("");
      setAllInventoryProject([]);
      setDistrict([]);
      setPrinting("");
      setLotClassification("");
      getAllInventoryProjects();

      return;
    }

    const selectedValues = selectedOptions.map((option) => option.value);

    setModeofDispo("");
    setSelectedProperty("");
    setLISAwardStat("");
    setLisClassAdmin(selectedValues);

    //Nationwide
    if (
      districtValue === "" &&
      regionValue.length === 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      modeofdispo === "" &&
      lotclassadmin.length === 0
    ) {
      GetSortbyLISClassNationwide(selectedValues)
        .then((value) => {
          setAllInventoryProject(value.data);
          console.log("admin -- lisclass nationwide");
          setPrinting("printnationwideA");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    //

    //per district
    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      modeofdispo === "" &&
      lotclassadmin.length === 0
    ) {
      GetSortbyLISClassAndDistrict(selectedValues, districtValue)
        .then((value) => {
          setAllInventoryProject(value.data);
          setInventorybyClass(value.data);
          setPrinting("printnationwideA");
          console.log("admin acct --dist,lisclass");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // lisclass per region
    if (
      regionValue.length !== 0 &&
      districtValue === "" &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      modeofdispo === "" &&
      lotclassadmin.length === 0
    ) {
      GetSortbyLISClassAndRegion(selectedValues, regionValue)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printnationwideA");
          console.log("let A");

          console.log("admin acct  -- lisclass per region");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleProperty = (event) => {
    const selectedLabel = event.target.selectedOptions[0].label;
    setSelectedProperty(event.target.value); //project code
    setSelectedPropertyName(selectedLabel); //property name
    setModeofDispo("");

    //dist,lisclass,awardstat,propertyname
    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length !== 0 &&
      modeofdispo === "" &&
      lotclassadmin.length === 0
    ) {
      GetSortProjectbyDistLisclassAwardstatPropname(
        event.target.value,
        lisclassadmin,
        awardstatadmin,
        districtValue
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingA");
          console.log("admin acct --- dist,lisclass,awardstat,propname");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //dist,awardstat, propname

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length === 0 &&
      awardstatadmin.length !== 0 &&
      modeofdispo === "" &&
      lotclassadmin.length === 0
    ) {
      //GetSortProjectbyPropnameWithAgreement
      GetSortByDistAwardstatPropname(
        event.target.value,
        districtValue,
        awardstatadmin
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("admin acct --- dist,awardstat,propname");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //dist,lisclass, propname

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length === 0 &&
      modeofdispo === "" &&
      lotclassadmin.length === 0
    ) {
      //GetSortProjectbyDistLisclassAwardstatPropname
      GetSortByDistLisclassPropname(
        event.target.value,
        districtValue,
        lisclassadmin
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("admin acct -- dist,lisclass,propertid");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handlesetLISCat = (event) => {
    setLISCat(event.target.value);
    GetSortbyLISCat(event.target.value, lisclass)
      .then((value) => {
        setAllInventoryProject(value.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSetAwardStat = (selectedOptions) => {
    if (!selectedOptions.length) {
      // Handle case when no option is selected
      setLisClassAdmin([]);
      setSelectedProperty("");
      setAwardStatAdmin([]);
      setModeofDispo("");

      setDistrictValue("");
      setAllInventoryProject([]);
      setDistrict([]);
      setPrinting("");
      setLotClassification("");
      getAllInventoryProjects();

      return;
    }

    // SOLD / UNSOLD NATIONWIDE

    const selectedValues = selectedOptions.map((option) => option.value);

    setAwardStatAdmin(selectedValues);
    setSelectedProperty("");
    setModeofDispo("");

    if (
      regionValue.length === 0 &&
      districtValue === "" &&
      lisclassadmin.length === 0 &&
      selectedproperty === "" &&
      modeofdispo === "" &&
      lotclassadmin.length === 0
    ) {
      GetSortbyAwardStatNationwide(selectedValues)
        .then((value) => {
          setAllInventoryProject(value.data);
          // setInventorybyClass(value.data);
          console.log("admin -- awardstat - nationwide");

          setPrinting("printnationwideB");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // NON-EST/ESTATE + SOLD/UNSOLD NATIONWIDE

    if (
      regionValue.length === 0 &&
      districtValue === "" &&
      lisclassadmin.length !== 0 &&
      selectedproperty === "" &&
      modeofdispo === "" &&
      lotclassadmin.length === 0
    ) {
      GetSortbyLisClassAndAwardStatNationwide(selectedValues, lisclassadmin)
        .then((value) => {
          setAllInventoryProject(value.data);
          // setInventorybyClass(value.data);
          setPrinting("printnationwideB");
          console.log("admin acct ==lisclass,awardstat==nationwide");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // award stat per region

    if (
      regionValue.length !== 0 &&
      districtValue === "" &&
      lisclassadmin.length === 0 &&
      selectedproperty === "" &&
      modeofdispo === "" &&
      lotclassadmin.length === 0
    ) {
      GetSortbyRegionAwardStat(selectedValues, regionValue)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printnationwideB");
          // setInventorybyClass(value.data);
          console.log("setd   --awardstat per region  --admin acct");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //award stat per district
    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length === 0 &&
      selectedproperty === "" &&
      modeofdispo === "" &&
      lotclassadmin.length === 0
    ) {
      GetSortbyDistrictAwardStat(selectedValues, districtValue)
        .then((value) => {
          setAllInventoryProject(value.data);
          console.log("admin acct--- dist,awardstat  ");
          setInventorybyClass(value.data);
          setPrinting("printnationwideB");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //, dist,lisclass,awardstat,

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length !== 0 &&
      selectedproperty === "" &&
      modeofdispo === "" &&
      lotclassadmin.length === 0
    ) {
      //GetSortbyDistrictAwardStat
      GetSortbyDistrictLisclassAwardStat(
        selectedValues,
        lisclassadmin,
        districtValue
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          console.log("admin acct dist,lisclass,awardstat  ");
          setInventorybyClass(value.data);
          setPrinting("printingnone");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //reg,lisclas, awardstat   (per region)
    if (
      regionValue.length !== 0 &&
      districtValue === "" &&
      lisclassadmin.length !== 0 &&
      selectedproperty === "" &&
      modeofdispo === "" &&
      lotclassadmin.length === 0
    ) {
      GetSortbyRegionLisclassAwardstat(
        selectedValues,
        regionValue,
        lisclassadmin
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          console.log(
            "admin acct ---- region,lisclass,award stat (per region)  "
          );
          // setInventorybyClass(value.data);
          setPrinting("printingnone");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSetModeDispo = (event) => {
    setModeofDispo(event.target.value);

    // per region with agreement

    if (
      regionValue.length !== 0 &&
      districtValue === "" &&
      lisclassadmin.length === 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      event.target.value !== "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByRegWithAgreement(event.target.value, regionValue)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("admin --per region with agreemnt(doda,etc.)");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lease agreement per district
    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length === 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      event.target.value === "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByDisModeDispo(event.target.value, districtValue)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("admin acct -- district with lease agreemnt");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    //With lease agreement NATIONWIDE
    if (
      regionValue.length === 0 &&
      districtValue === "" &&
      lisclassadmin.length === 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      event.target.value === "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByModeDispoNationwide(event.target.value)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "admin acct - -with lease agreement(negotiated lease) nationwide"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //with agreement (doda, memo,etc.) nationwide
    if (
      regionValue.length === 0 &&
      districtValue === "" &&
      lisclassadmin.length === 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      event.target.value !== "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortWithAgreeNationwide(event.target.value)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("admin -- with agreement nationwide");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // with lease agreement per region
    if (
      regionValue.length !== 0 &&
      districtValue === "" &&
      lisclassadmin.length === 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      event.target.value === "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByRegModeDispo(event.target.value, regionValue)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("admin acct -- per region with lease agreemnt");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // reg, dist,lis,awardstat,propetyname,lease agreement

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length !== 0 &&
      selectedproperty !== "" &&
      event.target.value === "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByDistLisclassAwardstatPropnameModeDispo(
        event.target.value,
        districtValue,
        lisclassadmin,
        awardstatadmin,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingC");
          console.log(
            "admin acct -- dis,lisclass,awardstat,proname,modedispo with lease agreemnt"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // reg, dist,lis,awardstat,propetyname--with agreement(doda,memo etc)

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length !== 0 &&
      selectedproperty !== "" &&
      event.target.value !== "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByDistLisclassAwardstatPropnameModeDispoWithAgreement(
        event.target.value,
        districtValue,
        lisclassadmin,
        awardstatadmin,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");

          console.log(
            "admin acct == dis,lisclass,awardstat,proname,modedispo==WITH AGREEMENT(doda, moa, etc"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lisclass, awardstat, lease agrement (nationwide)
    if (
      regionValue.length === 0 &&
      districtValue === "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length !== 0 &&
      selectedproperty === "" &&
      event.target.value === "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByLisclassAwardstatModedispoAdmin(
        event.target.value,
        lisclassadmin,
        awardstatadmin
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "admin acct -- lisclass,awardstat,lease agreemnt-- nationwide"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lisclass, awardstat, -- with agreement(doda,memo, etc)  nationwide
    if (
      regionValue.length === 0 &&
      districtValue === "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length !== 0 &&
      selectedproperty === "" &&
      event.target.value !== "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByLisclassAwardstatModedispoWithAgreeAdmin(
        event.target.value,
        lisclassadmin,
        awardstatadmin
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "admin acct -- lisclass,awardstat -- with agreement nationwide"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //awardstat, lease agrement (nationwide)
    if (
      regionValue.length === 0 &&
      districtValue === "" &&
      lisclassadmin.length === 0 &&
      awardstatadmin.length !== 0 &&
      selectedproperty === "" &&
      event.target.value === "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByAwardstatModedispoAdmin(event.target.value, awardstatadmin)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("admin acct -- awardstat + modeofdispo-- nationwide");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lisclass, lease agrement (nationwide)

    if (
      regionValue.length === 0 &&
      districtValue === "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      event.target.value === "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByLisclassModeDispoNationWide(event.target.value, lisclassadmin)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("admin acct -- lisclass  + lease agrement -- nationwide");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lisclass, -- with agreement(doda,memo etc)  nationwide

    if (
      regionValue.length === 0 &&
      districtValue === "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      event.target.value !== "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByLisclassModeDispoWithAgreeNationWide(
        event.target.value,
        lisclassadmin
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "admin acct -- lisclass  + modedispo -- with agreement nationwide"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //dist , lisclass , lease agreement per district

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      event.target.value === "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByDistLisclassModeDispo(
        event.target.value,
        districtValue,
        lisclassadmin
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "admin acct -- dist + lisclass  + modedispo  with lease agreement "
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // dist,lisclass, -- with agreement(doda, memo etc)  per district

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      event.target.value !== "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByDistLisclassModeDispoWithAgreement(
        event.target.value,
        districtValue,
        lisclassadmin
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("admin acct -- dist + lisclass  - with Agreement");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //dist,awardstat,lease agreement

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length === 0 &&
      awardstatadmin.length !== 0 &&
      selectedproperty === "" &&
      event.target.value === "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByDistAwardStatModeDispo(
        event.target.value,
        awardstatadmin,
        districtValue
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "admin acct -- dist + awardstat  + modedispo  --lease agreement per district"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //dist, awardstat,proname,lease agreement    -  per district

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length === 0 &&
      awardstatadmin.length !== 0 &&
      selectedproperty !== "" &&
      event.target.value === "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByDistAwardStatPropnameModeDispo(
        event.target.value,
        districtValue,
        awardstatadmin,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "admin acct -- dist + awardstat  + propname + modedispo "
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //dist, lisclass,propname,leaset agreement

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty !== "" &&
      event.target.value === "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByDistLisclassPropnameModeDispo(
        event.target.value,
        districtValue,
        lisclassadmin,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("admin acct -- dist + lisclass  + propname + modedispo ");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //dist, lisclass,propname,--- with agreement(doda,memo etc)  per district

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty !== "" &&
      event.target.value !== "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      //GetSortByDistLisclassPropnameModeDispo
      GetSortByDistLisclassPropnameModeDispoWithAgreement(
        event.target.value,
        districtValue,
        lisclassadmin,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "admin acct -- dist + lisclass  + propname + modedispo -- with agreement "
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //  dist,lis,awardstat,lease agreement  -- per district

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length !== 0 &&
      selectedproperty === "" &&
      event.target.value === "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      //
      GetSortByDistLisclassAwardstatModeDispo(
        event.target.value,
        districtValue,
        lisclassadmin,
        awardstatadmin
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "admin acct -- dis,lisclass,awardstat,modedispo  -- per district"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //  dist,lis,awardstat  --- with agreement (doda, memo etc)   per district

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length !== 0 &&
      selectedproperty === "" &&
      event.target.value !== "Negotiated Lease" &&
      lotclassadmin.length === 0
    ) {
      GetSortByDistLisclassAwardstatModeDispoWithAgree(
        event.target.value,
        districtValue,
        lisclassadmin,
        awardstatadmin
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "admin acct -- dis,lisclass,awardstat,modedispo-- with agreement"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handlePrintPdf = () => {
    let res_pdf_report_A = pdf_report_A(
      allinventoryprojects,
      lisclass,
      awardstatus,
      accesslevel,
      printing,
      selectedpropertyname,
      lisclassadmin,
      awardstatadmin,
      lotclassadmin,
      lotclassification
    );
    res_pdf_report_A
      .then((value) => {
        return value;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleprintExcel = () => {
    console.log(allinventoryprojects);
    let m_names = new Array(
      "JANAUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER"
    );
    let objectDate = new Date();
    let day = objectDate.getDate();
    let month = objectDate.getMonth();
    let monthname = m_names[month];
    let year = objectDate.getFullYear();
    let finalDateFormat = `${monthname}  ${day}, ${year}`;
    let titleReport = "";

    if (accesslevel === "2" || accesslevel === "1" || accesslevel === "4") {
      if (
        ((lisclass === "Estate" || lisclassadmin.includes("Estate")) &&
          (awardstatus === "Unsold/Undisposed" ||
            awardstatadmin.includes("Unsold/Undisposed")) &&
          printing === "printingA") ||
        ((lisclass === "Non-estate" || lisclassadmin.includes("Non-estate")) &&
          (awardstatus === "Unsold/Undisposed" ||
            awardstatadmin.includes("Unsold/Undisposed")) &&
          printing === "printingA")
      ) {
        if (
          (lisclass === "Estate" || lisclassadmin.includes("Estate")) &&
          (awardstatus === "Unsold/Undisposed" ||
            awardstatadmin.includes("Unsold/Undisposed"))
        ) {
          titleReport =
            `INVENTORY OF UNSOLD/UNDISPOSED ESTATE BASED 
              COMMERCIAL, INSTITUTIONAL, INDUSTRIAL AND INSTITUTIONAL LOTS/PROPERTIES
              AS OF ` + finalDateFormat;
        } else if (
          (lisclass === "Non-estate" || lisclassadmin.includes("Non-estate")) &&
          (awardstatus === "Unsold/Undisposed" ||
            awardstatadmin.includes("Unsold/Undisposed"))
        ) {
          titleReport =
            `INVENTORY OF UNSOLD/UNDISPOSED NON-ESTATE BASED 
              COMMERCIAL, INSTITUTIONAL, INDUSTRIAL, INSTITUTIONAL AND ACQUIRED/FORECLOSED LOTS/PROPERTIES
              AS OF ` + finalDateFormat;
        }

        GetSortbyLISClassExcel(
          allinventoryprojects,
          "",
          "",
          "",
          "",
          "",
          "",
          selectedpropertyname,
          titleReport
        );
      } else if (
        ((lisclass === "Non-estate" || lisclassadmin.includes("Non-estate")) &&
          (awardstatus === "Sold/Disposed" ||
            awardstatadmin.includes("Sold/Disposed")) &&
          printing === "printingA") ||
        ((lisclass === "Estate" || lisclassadmin.includes("Estate")) &&
          (awardstatus === "Sold/Disposed" ||
            awardstatadmin.includes("Sold/Disposed")) &&
          printing === "printingA")
      ) {
        if (
          (lisclass === "Non-estate" || lisclassadmin.includes("Non-estate")) &&
          (awardstatus === "Sold/Disposed" ||
            awardstatadmin.includes("Sold/Disposed"))
        ) {
          titleReport =
            `INVENTORY OF SOLD/DISPOSED NON-ESTATE BASED 
              COMMERCIAL, INDUSTRIAL, INSTITUTIONAL AND ACQUIRED/FORECLOSED LOTS/PROPERTIES
              AS OF ` + finalDateFormat;
        } else if (
          (lisclass === "Estate" || lisclassadmin.includes("Estate")) &&
          (awardstatus === "Sold/Disposed" ||
            awardstatadmin.includes("Sold/Disposed"))
        ) {
          titleReport =
            `INVENTORY OF SOLD/DISPOSED ESTATE BASED 
              COMMERCIAL, INDUSTRIAL, AND INSTITUTIONAL LOTS/PROPERTIES
              AS OF ` + finalDateFormat;
        }

        GetSortbyLISClassExcelB(
          allinventoryprojects,
          "",
          "",
          "",
          "",
          "",
          "",
          selectedpropertyname,
          titleReport
        );
      } else if (
        ((lisclass === "Non-estate" || lisclassadmin.includes("Non-estate")) &&
          (awardstatus === "Sold/Disposed" ||
            awardstatadmin.includes("Sold/Disposed")) &&
          printing === "printingC") ||
        ((lisclass === "Estate" || lisclassadmin.includes("Estate")) &&
          (awardstatus === "Sold/Disposed" ||
            awardstatadmin.includes("Sold/Disposed")) &&
          printing === "printingC")
      ) {
        if (lisclass === "Non-estate" || lisclassadmin.includes("Non-estate")) {
          titleReport =
            `INVENTORY OF NON-ESTATE BASED 
        COMMERCIAL, INDUSTRIAL, INSTITUTIONAL AND ACQUIRED/FORECLOSED LOTS/PROPERTIES WITH LEASE AGREEMENTS
        AS OF ` + finalDateFormat;
        } else if (lisclass === "Estate" || lisclassadmin.includes("Estate")) {
          titleReport =
            `INVENTORY OF ESTATE BASED 
              COMMERCIAL, INDUSTRIAL AND INSTITUTIONAL LOTS/PROPERTIES WITH LEASE AGREEMENTS
              AS OF ` + finalDateFormat;
        }

        GetSortbyLISClassExcelC(
          allinventoryprojects,
          "",
          "",
          "",
          "",
          "",
          "",
          selectedpropertyname,
          titleReport
        );
      } else if (printing === "printingB") {
        titleReport =
          `INVENTORY OF ESTATE AND NON-ESTATE BASED 
          COMMERCIAL, INDUSTRIAL AND INSTITUTIONAL LOTS/PROPERTIES WITH AGREEMENT (Usufruct, Deed of Donation, Memorandum of Agreement, Memorandum of Understanding, Etc.)
          AS OF ` + finalDateFormat;

        GetSortbyLISClassExcelD(
          allinventoryprojects,
          "",
          "",
          "",
          "",
          "",
          "",
          selectedpropertyname,
          titleReport
        );
      } else if (printing === "printnationwideA") {
        if (
          lisclass ||
          lisclassadmin.length > 0 ||
          lotclassadmin.length > 0 ||
          lotclassification
        ) {
          titleReport =
            `INVENTORY OF UNSOLD/UNDISPOSED AND SOLD/DISPOSED ESTATE AND NON-ESTATE BASED
            COMMERCIAL, INSTITUTIONAL, INDUSTRIAL AND ACQUIRED/FORECLOSED PROPERTIES
          AS OF ` + finalDateFormat;
        }

        GetPrintNationWideLisClass(
          allinventoryprojects,
          "",
          "",
          "",
          "",
          "",
          "",
          titleReport
        );
      } else if (printing === "printnationwideC") {
        titleReport =
          `INVENTORY OF UNSOLD/UNDISPOSED AND SOLD/DISPOSED ESTATE AND NON-ESTATE BASED
            COMMERCIAL, INSTITUTIONAL, INDUSTRIAL AND ACQUIRED/FORECLOSED PROPERTIES
          AS OF ` + finalDateFormat;

        GetPrintNationWideLisClass(
          allinventoryprojects,
          "",
          "",
          "",
          "",
          "",
          "",
          titleReport
        );
      } else if (printing === "printnationwideB") {
        if (
          awardstatus === "Sold/Disposed" ||
          awardstatadmin.includes("Sold/Disposed")
        ) {
          titleReport =
            `INVENTORY OF SOLD/DISPOSED ESTATE BASED AND NON-ESTATE BASED
          COMMERCIAL, INSTITUTIONAL, INDUSTRIAL AND ACQUIRED/FORECLOSED PROPERTIES
          AS OF ` + finalDateFormat;
        } else if (
          awardstatus === "Unsold/Undisposed" ||
          awardstatadmin.includes("Unsold/Undisposed")
        ) {
          titleReport =
            `INVENTORY OF UNSOLD/UNDISPOSED ESTATE BASED AND NON-ESTATE BASED
            COMMERCIAL, INSTITUTIONAL, INDUSTRIAL AND ACQUIRED/FORECLOSED PROPERTIES
          AS OF ` + finalDateFormat;
        }

        GetPrintNationWideAwardStatus(
          allinventoryprojects,
          "",
          "",
          "",
          "",
          "",
          "",
          titleReport
        );
      }
      ///////END ADMIN AND REGION ACCESS   END END /////////////
    } else {
      // district account number 3
      GetSignatories()
        .then((value) => {
          if (
            (lisclass === "Estate" &&
              awardstatus === "Unsold/Undisposed" &&
              printing === "printingA") ||
            (lisclass === "Non-estate" &&
              awardstatus === "Unsold/Undisposed" &&
              printing === "printingA")
          ) {
            if (lisclass === "Estate" && awardstatus === "Unsold/Undisposed") {
              titleReport =
                `INVENTORY OF UNSOLD/UNDISPOSED ESTATE BASED 
                COMMERCIAL, INSTITUTIONAL, INDUSTRIAL AND INSTITUTIONAL LOTS/PROPERTIES
                  AS OF ` + finalDateFormat;
            } else if (
              lisclass === "Non-estate" &&
              awardstatus === "Unsold/Undisposed"
            ) {
              titleReport =
                `INVENTORY OF UNSOLD/UNDISPOSED NON-ESTATE BASED 
                  COMMERCIAL, INDUSTRIAL, INSTITUTIONAL AND ACQUIRED/FORECLOSED LOTS/PROPERTIES
                  AS OF ` + finalDateFormat;
            }

            GetSortbyLISClassExcel(
              allinventoryprojects,
              value.data[0].sig_preparedby,
              value.data[0].sig_preparedby_dg,
              value.data[0].sig_revieweby,
              value.data[0].sig_revieweby_dg,
              value.data[0].sig_notedby,
              value.data[0].sig_notedby_dg,
              selectedpropertyname,
              titleReport
            );
          } else if (
            (lisclass === "Non-estate" &&
              awardstatus === "Sold/Disposed" &&
              printing === "printingA") ||
            (lisclass === "Estate" &&
              awardstatus === "Sold/Disposed" &&
              printing === "printingA")
          ) {
            if (lisclass === "Non-estate" && awardstatus === "Sold/Disposed") {
              titleReport =
                `INVENTORY OF SOLD/DISPOSED NON-ESTATE BASED 
                  COMMERCIAL, INDUSTRIAL, INSTITUTIONAL AND ACQUIRED/FORECLOSED LOTS/PROPERTIES
                  AS OF ` + finalDateFormat;
            } else if (
              lisclass === "Estate" &&
              awardstatus === "Sold/Disposed"
            ) {
              titleReport =
                `INVENTORY OF SOLD/DISPOSED ESTATE BASED 
                  COMMERCIAL, INDUSTRIAL, AND INSTITUTIONAL LOTS/PROPERTIES
                  AS OF ` + finalDateFormat;
            }

            GetSortbyLISClassExcelB(
              allinventoryprojects,
              value.data[0].sig_preparedby,
              value.data[0].sig_preparedby_dg,
              value.data[0].sig_revieweby,
              value.data[0].sig_revieweby_dg,
              value.data[0].sig_notedby,
              value.data[0].sig_notedby_dg,
              selectedpropertyname,
              titleReport
            );
          } else if (
            (lisclass === "Non-estate" &&
              printing === "printingC" &&
              awardstatus === "Sold/Disposed") ||
            (lisclass === "Estate" &&
              printing === "printingC" &&
              awardstatus === "Sold/Disposed")
          ) {
            if (lisclass === "Non-estate") {
              titleReport =
                `INVENTORY OF NON-ESTATE BASED 
            COMMERCIAL, INDUSTRIAL, INSTITUTIONAL AND ACQUIRED/FORECLOSED LOTS/PROPERTIES WITH LEASE AGREEMENTS
            AS OF ` + finalDateFormat;
            } else if (lisclass === "Estate") {
              titleReport =
                `INVENTORY OF ESTATE BASED 
                  COMMERCIAL, INDUSTRIAL, AND INSTITUTIONAL LOTS/PROPERTIES WITH LEASE AGREEMENTS
                  AS OF ` + finalDateFormat;
            }

            GetSortbyLISClassExcelC(
              allinventoryprojects,
              value.data[0].sig_preparedby,
              value.data[0].sig_preparedby_dg,
              value.data[0].sig_revieweby,
              value.data[0].sig_revieweby_dg,
              value.data[0].sig_notedby,
              value.data[0].sig_notedby_dg,
              selectedpropertyname,
              titleReport
            );
          }

          //FOR ESTATE AND NON-ESTATE PRINTING
          else if (printing === "printingB") {
            titleReport =
              `INVENTORY OF ESTATE AND NON-ESTATE BASED 
              COMMERCIAL, INDUSTRIAL AND INSTITUTIONAL LOTS/PROPERTIES WITH AGREEMENT (Usufruct, Deed of Donation, Memorandum of Agreement, Memorandum of Understanding, Etc.)
              AS OF ` + finalDateFormat;

            GetSortbyLISClassExcelD(
              allinventoryprojects,
              value.data[0].sig_preparedby,
              value.data[0].sig_preparedby_dg,
              value.data[0].sig_revieweby,
              value.data[0].sig_revieweby_dg,
              value.data[0].sig_notedby,
              value.data[0].sig_notedby_dg,
              selectedpropertyname,
              titleReport
            );
          } else if (printing === "printnationwideA") {
            if (
              lisclass === "Estate" ||
              lisclass === "Non-estate" ||
              lotclassification
            ) {
              titleReport =
                `INVENTORY OF UNSOLD/UNDISPOSED AND SOLD/DISPOSED ESTATE AND NON-ESTATE BASED
                COMMERCIAL, INSTITUTIONAL, INDUSTRIAL AND ACQUIRED/FORECLOSED PROPERTIES
              AS OF ` + finalDateFormat;
            }

            GetPrintNationWideLisClass(
              allinventoryprojects,
              value.data[0].sig_preparedby,
              value.data[0].sig_preparedby_dg,
              value.data[0].sig_revieweby,
              value.data[0].sig_revieweby_dg,
              value.data[0].sig_notedby,
              value.data[0].sig_notedby_dg,
              titleReport
            );
          } else if (printing === "printnationwideB") {
            if (awardstatus === "Sold/Disposed") {
              titleReport =
                `INVENTORY OF SOLD/DISPOSED ESTATE BASED AND NON-ESTATE BASED
              COMMERCIAL, INSTITUTIONAL, INDUSTRIAL AND ACQUIRED/FORECLOSED PROPERTIES
              AS OF ` + finalDateFormat;
            } else if (awardstatus === "Unsold/Undisposed") {
              titleReport =
                `INVENTORY OF UNSOLD/UNDISPOSED ESTATE BASED AND NON-ESTATE BASED
                COMMERCIAL, INSTITUTIONAL, INDUSTRIAL AND ACQUIRED/FORECLOSED PROPERTIES
              AS OF ` + finalDateFormat;
            }

            GetPrintNationWideAwardStatus(
              allinventoryprojects,
              value.data[0].sig_preparedby,
              value.data[0].sig_preparedby_dg,
              value.data[0].sig_revieweby,
              value.data[0].sig_revieweby_dg,
              value.data[0].sig_notedby,
              value.data[0].sig_notedby_dg,
              titleReport
            );
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const handlesetReportType = (event) => {
    setReportType(event.target.value);
  };

  const getSignatories = async () => {
    GetSignatories()
      .then((value) => {
        setSignatory(value);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // REGION ACCT
  const handleLISClassReg = (event) => {
    setModeofDispo("");
    setSelectedProperty("");
    setLISAwardStat("");
    setLISClass(event.target.value);

    //lisclass (all district)
    if (
      (event.target.value === "Estate" ||
        event.target.value === "Non-estate") &&
      districtValue === ""
    ) {
      GetSortbyLISClassAllDistrict(event.target.value)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printnationwideA");
          console.log("reg acct -- lisclass (all district)");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lis class per district
    if (
      districtValue !== "" &&
      (event.target.value === "Non-estate" ||
        event.target.value === "Estate") &&
      awardstatus === ""
    ) {
      //GetSortbyLISClassAndDistrictDist
      GetSortbyLISClassAndDistrictReg(event.target.value, districtValue)
        .then((value) => {
          setAllInventoryProject(value.data);
          setInventorybyClass(value.data);
          setPrinting("printingnone");
          console.log("lisclass per dist-- reg acct");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // lisclass per district with agreement
    if (
      districtValue !== "" &&
      event.target.value === "Estate and Non-estate with Agreement" &&
      awardstatus === ""
    ) {
      GetSortbyLISClassAndDistrictWithAgreement(districtValue)
        .then((value) => {
          setAllInventoryProject(value.data);
          setInventorybyClass(value.data);
          setPrinting("printingnone");
          console.log("lisclass per district (reg acct) with agreement");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSetAwardStatReg = (event) => {
    setLISAwardStat(event.target.value);
    setSelectedProperty("");
    setModeofDispo("");

    // award stat  --all district
    if (districtValue === "" && lisclass === "" && event.target.value !== "") {
      GetSortbyAwardStatByRegion(event.target.value)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printnationwideB");
          setInventorybyClass(value.data);
          console.log("award stat  --all district");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // lisclass + awardstat   all district

    if (
      districtValue === "" &&
      (lisclass === "Non-estate" || lisclass === "Estate") &&
      event.target.value !== ""
    ) {
      GetSortbyLisClassAndAwardStatAllDist(event.target.value, lisclass)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          // setInventorybyClass(value.data);
          console.log("reg acct --lisclass,award stat -- all district");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //district  + awardstat
    if (
      districtValue &&
      !lisclass &&
      !selectedproperty &&
      !modeofdispo &&
      !lotclassification
    ) {
      //GetSortbyDistrictAwardStat
      GetSortbyDistrictAwardStatReg(event.target.value, districtValue)
        .then((value) => {
          setAllInventoryProject(value.data);
          console.log("reg acct-- award stat + district  ");
          setInventorybyClass(value.data);
          setPrinting("printingnone");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //, dist,lisclass,awardstat,

    if (
      districtValue &&
      lisclass &&
      !selectedproperty &&
      !modeofdispo &&
      !lotclassification
    ) {
      GetSortbyDistrictLisclassAwardStatReg(
        event.target.value,
        lisclass,
        districtValue
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          console.log("reg acct dist,lisclass,awardstat  ");
          setInventorybyClass(value.data);
          setPrinting("printingnone");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handlePropertyReg = (event) => {
    const selectedLabel = event.target.selectedOptions[0].label;
    setSelectedProperty(event.target.value); //project code
    setSelectedPropertyName(selectedLabel); //property name
    setModeofDispo("");

    //dist,lisclass,awardstat,propertyname
    if (
      districtValue &&
      lisclass &&
      awardstatus &&
      !modeofdispo &&
      !lotclassification
    ) {
      //GetSortProjectbyDistLisclassAwardstatPropname
      GetSortProjectbyDistLisclassAwardstatPropnameReg(
        event.target.value,
        lisclass,
        awardstatus,
        districtValue
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingA");
          console.log("reg acct -- dist,lisclass,awardstat,propertyid");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //dist,awardstat, propname

    if (
      districtValue &&
      !lisclass &&
      awardstatus &&
      !modeofdispo &&
      !lotclassification
    ) {
      //GetSortByDistAwardstatPropname
      GetSortByDistAwardstatPropnameReg(
        event.target.value,
        districtValue,
        awardstatus
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("reg acct --- dist,awardstat,propname");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //,awardstat, propname

    if (
      !districtValue &&
      !lisclass &&
      awardstatus &&
      !modeofdispo &&
      !lotclassification
    ) {
      //GetSortByDistAwardstatPropname
      GetAwardStatPropNameReg(event.target.value, awardstatus)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("reg acct --- awardstat,propname");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //dist,lisclass, propname

    if (
      districtValue &&
      lisclass &&
      !awardstatus &&
      !modeofdispo &&
      !lotclassification
    ) {
      //GetSortByDistLisclassPropname
      GetSortByDistLisclassPropnameReg(
        event.target.value,
        districtValue,
        lisclass
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("reg acct -- dist,lisclass,propertid");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSetModeDispoReg = (event) => {
    setModeofDispo(event.target.value);

    // with agreement per district
    if (
      districtValue !== "" &&
      lisclass === "" &&
      awardstatus === "" &&
      selectedproperty === "" &&
      event.target.value !== "Negotiated Lease"
    ) {
      GetSortWithAgreementPerDist(event.target.value, districtValue)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("reg acc  -- with agreement per district");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lease agreement per district
    if (
      districtValue &&
      !lisclass &&
      !awardstatus &&
      !selectedproperty &&
      event.target.value === "Negotiated Lease" &&
      !lotclassification
    ) {
      //GetSortByDisModeDispo
      GetSortByDisModeDispo(event.target.value, districtValue)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("reg acct --- district with lease agreemnt");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //With lease agreement ALL DISTRICT

    if (
      districtValue === "" &&
      selectedproperty === "" &&
      lisclass === "" &&
      awardstatus === "" &&
      event.target.value === "Negotiated Lease"
    ) {
      GetSortByModeDispoAllDistrict(event.target.value)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "reg acct -- with lease agreement(negotiated lease) all district"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //with agreement all district

    if (
      districtValue === "" &&
      selectedproperty === "" &&
      lisclass === "" &&
      awardstatus === "" &&
      event.target.value !== "Negotiated Lease"
    ) {
      GetSortWithAgreementAllDistrict(event.target.value)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("reg acct -- with agreement all district(doda etc.)");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //  dist,lis,awardstat,propetyname,lease agreement

    if (
      districtValue &&
      lisclass &&
      awardstatus &&
      selectedproperty &&
      event.target.value === "Negotiated Lease" &&
      !lotclassification
    ) {
      //GetSortByDistLisclassAwardstatPropnameModeDispo
      GetSortByDistLisclassAwardstatPropnameModeDispoReg(
        event.target.value,
        districtValue,
        lisclass,
        awardstatus,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingC");
          console.log("reg acct -- dis,lisclass,awardstat,proname,modedispo");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // dist,lis,awardstat,propetyname,with agreement

    if (
      districtValue &&
      lisclass &&
      awardstatus &&
      selectedproperty &&
      event.target.value !== "Negotiated Lease" &&
      !lotclassification
    ) {
      //GetSortByDistLisclassAwardstatPropnameModeDispoWithAgreement
      GetSortByDistLisclassAwardstatPropnameModeDispoWithAgreementReg(
        event.target.value,
        districtValue,
        lisclass,
        awardstatus,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "reg acct --- dis,lisclass,awardstat,proname, modedispo, ==WITH AGREEMENT(doda,moa etc)"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lisclass, awardstat, lease agrement (all district)
    if (
      !districtValue &&
      lisclass &&
      awardstatus &&
      !selectedproperty &&
      event.target.value === "Negotiated Lease" &&
      !lotclassification
    ) {
      GetSortByLisclassAwardstatModedispo(
        event.target.value,
        lisclass,
        awardstatus
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "reg acct -- with lease agreement(negotiated lease) all district"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lisclass, awardstat, lease agrement -- with agreement (alldistrit)

    if (
      !districtValue &&
      lisclass &&
      awardstatus &&
      !selectedproperty &&
      event.target.value !== "Negotiated Lease" &&
      !lotclassification
    ) {
      GetSortByLisclassAwardstatModedispoWithAgreement(
        event.target.value,
        awardstatus,
        lisclass
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "reg acct -- lisclass,awardstat, agreement all district -- WITH AGREEMENT(doda, moa, etc)"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //awardstat, lease agrement (all district)
    if (
      districtValue === "" &&
      selectedproperty === "" &&
      lisclass === "" &&
      awardstatus !== "" &&
      event.target.value === "Negotiated Lease"
    ) {
      GetSortByAwardstatModedispo(event.target.value, awardstatus)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("reg acct -- awardstat + modeofdispo-- all district");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lisclass, lease agrement (all district)

    if (
      districtValue === "" &&
      selectedproperty === "" &&
      (lisclass === "Non-estate" || lisclass === "Estate") &&
      awardstatus === "" &&
      event.target.value === "Negotiated Lease"
    ) {
      GetSortByLisclassModeDispoAllDistrict(event.target.value, lisclass)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("reg acct -- lisclass  + modedispo -- all district");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lisclass,  -- with agreement  -all district

    if (
      districtValue === "" &&
      selectedproperty === "" &&
      lisclass !== "" &&
      awardstatus === "" &&
      event.target.value !== "Negotiated Lease"
    ) {
      GetSortByLisclassModeDispoAllDistrictWithAgreement(
        event.target.value,
        lisclass
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "reg acct -- lisclass,agreement- all district -- with Agreement"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //dist , lisclass , lease agreement

    if (
      districtValue &&
      lisclass &&
      !awardstatus &&
      !selectedproperty &&
      event.target.value === "Negotiated Lease" &&
      !lotclassification
    ) {
      //GetSortByDistLisclassModeDispo
      GetSortByDistLisclassModeDispoReg(
        event.target.value,
        districtValue,
        lisclass
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "reg acct -- dist + lisclass  + modedispo -- lease agreemnt "
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // dist,lisclass, with agreement

    if (
      districtValue &&
      lisclass &&
      !awardstatus &&
      !selectedproperty &&
      event.target.value !== "Negotiated Lease" &&
      !lotclassification
    ) {
      //GetSortByDistLisclassModeDispoWithAgreement
      GetSortByDistLisclassModeDispoWithAgreementReg(
        event.target.value,
        districtValue,
        lisclass
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "reg acct -- dist,lisclass,modedispo  - with Agreement(doda, moa)"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //dist,awardstat,lease agreement

    if (
      districtValue &&
      !lisclass &&
      awardstatus &&
      !selectedproperty &&
      event.target.value === "Negotiated Lease" &&
      !lotclassification
    ) {
      //GetSortByDistAwardStatModeDispo
      GetSortByDistAwardStatModeDispoReg(
        event.target.value,
        awardstatus,
        districtValue
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("reg acct -- dist + awardstat  + modedispo ");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //dist, awardstat,proname,lease agreement

    if (
      districtValue &&
      !lisclass &&
      awardstatus &&
      selectedproperty &&
      event.target.value === "Negotiated Lease" &&
      !lotclassification
    ) {
      GetSortByDistAwardStatPropnameModeDispoReg(
        event.target.value,
        districtValue,
        awardstatus,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("reg acct -- dist + awardstat  + propname + modedispo ");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //dist, awardstat,proname,with agreement

    if (
      districtValue !== "" &&
      lisclass === "" &&
      awardstatus !== "" &&
      selectedproperty !== "" &&
      event.target.value !== "Negotiated Lease"
    ) {
      GetAwardStatPropNameWithAgreeReg(
        event.target.value,
        districtValue,
        awardstatus,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "reg acct -- dist + awardstat  + propname + modedispo -- with agreement "
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //dist, lisclass,propname,leaset agreement

    if (
      districtValue &&
      lisclass &&
      !awardstatus &&
      selectedproperty &&
      event.target.value === "Negotiated Lease" &&
      !lotclassification
    ) {
      //GetSortByDistLisclassPropnameModeDispo
      GetSortByDistLisclassPropnameModeDispoReg(
        event.target.value,
        districtValue,
        lisclass,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("reg acct -- dist + lisclass  + propname + modedispo ");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //dist, lisclass,propname,--- with agreement

    if (
      districtValue &&
      lisclass &&
      !awardstatus &&
      selectedproperty &&
      event.target.value !== "Negotiated Lease" &&
      !lotclassification
    ) {
      //GetSortByDistLisclassPropnameModeDispoWithAgreement
      GetSortByDistLisclassPropnameModeDispoWithAgreementReg(
        event.target.value,
        districtValue,
        lisclass,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "reg acct -- dist + lisclass  + propname + modedispo -- with agreement(doda, moa, ) "
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //  dist,lis,awardstat,lease agreement

    if (
      districtValue &&
      lisclass &&
      awardstatus &&
      !selectedproperty &&
      event.target.value === "Negotiated Lease" &&
      !lotclassification
    ) {
      GetSortByDistLisclassAwardstatModeDispoReg(
        event.target.value,
        districtValue,
        lisclass,
        awardstatus
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("reg acct -- dis,lisclass,awardstat,modedispo");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //  dist,lis,awardstat, --- with agreement

    if (
      districtValue &&
      lisclass &&
      awardstatus &&
      !selectedproperty &&
      event.target.value !== "Negotiated Lease" &&
      !lotclassification
    ) {
      //GetSortByDistLisclassAwardstatModeDispoWithAgree
      GetSortByDistLisclassAwardstatModeDispoWithAgreeReg(
        event.target.value,
        districtValue,
        lisclass,
        awardstatus
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "reg acct -- dis,lisclass,awardstat,modedispo-- with agreement"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleLISClassDist = (event) => {
    if (event.target.value === "") {
      getAllInventoryProjects();
      setLISClass("");
      return;
    }

    setModeofDispo("");
    setSelectedProperty("");
    setLISAwardStat("");
    setLISClass(event.target.value);

    //lisclass by district

    if (
      (event.target.value === "Non-estate" ||
        event.target.value === "Estate") &&
      awardstatus === "" &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      GetLisclassByDistAcct(event.target.value)
        .then((value) => {
          setAllInventoryProject(value.data);
          setInventorybyClass(value.data);
          setPrinting("printnationwideA");
          console.log("dist acct --lisclass ");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSetAwardStatDist = (event) => {
    setLISAwardStat(event.target.value);
    setSelectedProperty("");
    setModeofDispo("");

    //awardstat by district

    if (
      event.target.value !== "" &&
      lisclass === "" &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      GetAwardStatByDistAcct(event.target.value)
        .then((value) => {
          setAllInventoryProject(value.data);
          setInventorybyClass(value.data);
          setPrinting("printnationwideB");

          console.log("dist acct -- awardstat ");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lisclass,awardstat
    if (
      event.target.value !== "" &&
      (lisclass === "Non-estate" || lisclass === "Estate") &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      GetLisClassAwardStatByDistAcct(event.target.value, lisclass)
        .then((value) => {
          setAllInventoryProject(value.data);
          setInventorybyClass(value.data);
          setPrinting("printingnone");
          console.log("dist acct -- lisclass,awardstat");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lisclass,awardstat -- with agreement

    if (
      event.target.value !== "" &&
      lisclass === "Estate and Non-estate with Agreement" &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      GetLisClassAwardStatWithAgreementByDistAcct(event.target.value)
        .then((value) => {
          setAllInventoryProject(value.data);
          setInventorybyClass(value.data);
          setPrinting("printingnone");
          console.log("dist acct -- lisclass,awardstat--with agreement");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handlePropertyDist = (event) => {
    const selectedLabel = event.target.selectedOptions[0].label;
    setSelectedProperty(event.target.value); //project code
    setSelectedPropertyName(selectedLabel); //property name
    setModeofDispo("");

    //lisclass,awardstat,propertyname
    if (
      (lisclass === "Non-estate" || lisclass === "Estate") &&
      awardstatus !== "" &&
      event.target.value !== ""
    ) {
      GetLisClassAwardStatPropNameByDistAcct(
        event.target.value,
        lisclass,
        awardstatus
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingA");
          console.log("dist acct -- lisclass,awardstat,propname");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lisclass,awardstat,propertyname --with agreement

    if (
      lisclass === "Estate and Non-estate with Agreement" &&
      awardstatus !== "" &&
      event.target.value !== ""
    ) {
      //
      GetLisClassAwardStatPropNameWithAgreementByDistAcct(
        event.target.value,
        awardstatus
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingB");
          console.log(
            "dist acct -- lisclass,awardstat,propname -- with agreement"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lisclass,propertyname
    if (
      event.target.value !== "" &&
      (lisclass === "Non-estate" || lisclass === "Estate") &&
      awardstatus === "" &&
      modeofdispo === ""
    ) {
      //
      GetLisClassPropNameByDistAcct(event.target.value, lisclass)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("dist acct -- lisclass,propname");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lisclass,propertyname -- with agreement

    if (
      event.target.value !== "" &&
      lisclass === "Estate and Non-estate with Agreement" &&
      awardstatus === "" &&
      modeofdispo === ""
    ) {
      //
      GetLisClassPropNameWithAgreementByDistAcct(event.target.value)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("dist acct -- lisclass,propname --with agreement");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //awardstat,propname

    if (
      event.target.value !== "" &&
      lisclass === "" &&
      awardstatus !== "" &&
      modeofdispo === ""
    ) {
      //
      GetAwardStatPropNameByDistAcct(event.target.value, awardstatus)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("dist acct -- awardstat,propname");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSetModeDispoDist = (event) => {
    setModeofDispo(event.target.value);

    //lisclass,awardstat, propname, lease agreement

    if (
      event.target.value === "Negotiated Lease" &&
      (lisclass === "Non-estate" || lisclass === "Estate") &&
      awardstatus !== "" &&
      selectedproperty !== ""
    ) {
      GetLisClassAwardStatPropNameLeaseAgreeByDistAcct(
        event.target.value,
        lisclass,
        awardstatus,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingC");
          console.log(
            "dist acct == lisclass,awardstat,proname,modedispo,lease agreemnt"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lisclass,awardstat, propname,  -- with agreement
    if (
      lisclass !== "" &&
      awardstatus !== "" &&
      selectedproperty !== "" &&
      event.target.value !== "Negotiated Lease"
    ) {
      GetLisClassAwardStatPropNameLeaseAgreeWithAgreeByDistAcct(
        event.target.value,
        lisclass,
        awardstatus,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "dist acct ===lisclass,awardstat,proname,modedispo==WITH AGREEMENT"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // awardstat,proname,lease agreement

    if (
      lisclass === "" &&
      awardstatus !== "" &&
      selectedproperty !== "" &&
      event.target.value === "Negotiated Lease"
    ) {
      // GetSortByDistAwardStatPropnameModeDispo
      GetAwardStatPropNameLeaseAgreeByDistAcct(
        event.target.value,
        awardstatus,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("dist acct -- awardstat  + propname + modedispo");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // awardstat,proname,with agreement

    if (
      lisclass === "" &&
      awardstatus !== "" &&
      selectedproperty !== "" &&
      event.target.value !== "Negotiated Lease"
    ) {
      //
      GetAwardStatPropNameWithAgree(
        event.target.value,
        awardstatus,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "dist acct -- awardstat  + propname + modedispo -- with agreement"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // lisclass,proname,lease agreement

    if (
      lisclass !== "" &&
      awardstatus === "" &&
      selectedproperty !== "" &&
      event.target.value === "Negotiated Lease"
    ) {
      //
      GetLisclassPropnameNegoLeaseAgree(
        event.target.value,
        lisclass,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("dist acct -- lisclass  + propname + modedispo");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //  lisclass , lease agreement

    if (
      selectedproperty === "" &&
      (lisclass === "Non-estate" || lisclass === "Estate") &&
      awardstatus === "" &&
      event.target.value === "Negotiated Lease"
    ) {
      GetLisClassLeaseAgreeByDistAcct(event.target.value, lisclass)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("dist acct -- lisclass  + modedispo  lease agreement ");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //  lisclass  -- with agreement

    if (
      selectedproperty === "" &&
      lisclass !== "" &&
      awardstatus === "" &&
      event.target.value !== "Negotiated Lease"
    ) {
      //
      GetLisClassLeaseAgreeWithAgreeByDistAcct(event.target.value, lisclass)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("dist acct -- lisclass  + modedispo --- with agreement ");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //awardstat,lease agreement

    if (
      selectedproperty === "" &&
      lisclass === "" &&
      awardstatus !== "" &&
      event.target.value === "Negotiated Lease"
    ) {
      GetAwardStatLeaseAgreeByDistAcct(event.target.value, awardstatus)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("dist acct -- awardstat  + modedispo-- lease agreement ");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //awardstat,modedispo   -- with agreement

    if (
      selectedproperty === "" &&
      lisclass === "" &&
      awardstatus !== "" &&
      event.target.value !== "Negotiated Lease"
    ) {
      //
      GetAwardStatWithAgree(event.target.value, awardstatus)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("dist acct -- awardstat  + with agrement ");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //With lease agreement
    if (
      selectedproperty === "" &&
      lisclass === "" &&
      awardstatus === "" &&
      event.target.value === "Negotiated Lease"
    ) {
      GetSortByModeDispoByDistrict(event.target.value)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "dist acct -- with lease agreement(negotiated lease) by district"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //With  agreement
    if (
      selectedproperty === "" &&
      lisclass === "" &&
      awardstatus === "" &&
      event.target.value !== "Negotiated Lease"
    ) {
      //
      GetDistAcctWithAgree(event.target.value)
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log("dist acct -- with agreement by district");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //lisclass,propname,modedispo--- with agreement

    if (
      lisclass !== "" &&
      awardstatus === "" &&
      selectedproperty !== "" &&
      event.target.value !== "Negotiated Lease"
    ) {
      GetLisclassPropnameModeDispo(
        event.target.value,
        lisclass,
        selectedproperty
      )
        .then((value) => {
          setAllInventoryProject(value.data);
          setPrinting("printingnone");
          console.log(
            "dist acc --  lisclass  + propname + modedispo -- with agreement "
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSetLotClassification = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    const containsForeclosed = selectedOptions.some((option) =>
      option.label.includes("Foreclosed")
    );

    setLotClassAdmin(selectedValues);
    console.log(selectedValues);

    //lot classication nationwide
    if (
      regionValue.length === 0 &&
      districtValue === "" &&
      lisclassadmin.length === 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      if (!selectedOptions.length) {
        setLotClassAdmin([]);
        getAllInventoryProjects();
        return;
      }

      if (containsForeclosed) {
        //GetSetLotClassification
        GetSetLotClassificationForClosedProp(selectedValues)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log(
              "admin acc -- get lotclassification --Foreclosed/Acquired Property--nationwide"
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        GetSetLotClassification(selectedValues)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log("admin acc -- get lotclassification --nationwide");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    //lot classification by region

    if (
      regionValue.length !== 0 &&
      districtValue === "" &&
      lisclassadmin.length === 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      if (!selectedOptions.length) {
        setLotClassAdmin([]);
        getAllInventoryProjects();
        setRegionValue([]);
        setDistrictValue("");
        return;
      }

      if (containsForeclosed) {
        //GetSetLotClassificationForClosedProp
        GetSetLotClassificationForClosedPropByReg(selectedValues, regionValue)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log(
              "admin acc -- get lotclassification --Foreclosed/Acquired Property--by region"
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //GetSetLotClassification
        GetSetLotClassificationByReg(selectedValues, regionValue)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log("admin acc -- get lotclassification--by region");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    //lot classification by district

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length === 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      if (!selectedOptions.length) {
        setLotClassAdmin([]);
        getAllInventoryProjects();
        setRegionValue([]);
        setDistrictValue("");
        return;
      }

      if (containsForeclosed) {
        //GetSetLotClassificationForClosedPropByReg
        GetSetLotClassificationForClosedPropByDist(
          regionValue,
          districtValue,
          selectedValues
        )
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log(
              "admin acc -- get lotclassification --Foreclosed/Acquired Property--by district"
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //GetSetLotClassificationByReg
        GetSetLotClassificationByDist(
          regionValue,
          districtValue,
          selectedValues
        )
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log("admin acc -- get lotclassification--by district");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    //region,lisclass,lotclassification

    if (
      regionValue.length !== 0 &&
      districtValue === "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      if (!selectedOptions.length) {
        setLotClassAdmin([]);
        getAllInventoryProjects();
        setRegionValue([]);
        setDistrictValue("");

        setLisClassAdmin([]);
        return;
      }

      if (containsForeclosed) {
        //GetSetLotClassificationForClosedPropByReg
        GetRegLisLotClassForClosed(selectedValues, regionValue, lisclassadmin)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log("admin acc -- reg,lisclass,lotclass--foreclosed");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //GetSetLotClassificationByReg
        GetRegLisLotClass(selectedValues, regionValue, lisclassadmin)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log("admin acc -- reg,lisclass,lotclass");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    //reg,dist,lisclass,lot class

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      if (!selectedOptions.length) {
        setLotClassAdmin([]);
        getAllInventoryProjects();
        setRegionValue([]);
        setDistrictValue("");
        setLisClassAdmin([]);
        return;
      }

      if (containsForeclosed) {
        //GetSetLotClassificationForClosedPropByDist
        GetRegDisLisLotClassForClosed(
          regionValue,
          districtValue,
          lisclassadmin,
          selectedValues
        )
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log("admin acc -- reg,dist,lisclass,lotclass --foreclosed");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //GetSetLotClassificationByDist
        GetRegDisLisLotClass(
          regionValue,
          districtValue,
          lisclassadmin,
          selectedValues
        )
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log("admin acc -- admin acc -- reg,dist,lisclass,lotclass");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    //reg,awardstat,lot class

    if (
      regionValue.length !== 0 &&
      districtValue === "" &&
      lisclassadmin.length === 0 &&
      awardstatadmin.length !== 0 &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      if (!selectedOptions.length) {
        setLotClassAdmin([]);
        getAllInventoryProjects();
        setRegionValue([]);
        setDistrictValue("");
        setAwardStatAdmin([]);
        return;
      }

      if (containsForeclosed) {
        //GetRegLisLotClassForClosed
        GetRegAwardStatLotClassForClosed(
          selectedValues,
          regionValue,
          awardstatadmin
        )
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideB");
            console.log("admin acc -- reg,awardstat,lotclass--foreclosed");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //GetRegLisLotClass
        GetRegAwardStatLotClass(selectedValues, regionValue, awardstatadmin)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideB");
            console.log("admin acc -- reg,awardstat,lotclass");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    //reg,dist,awardstat,lot class

    if (
      regionValue.length !== 0 &&
      districtValue !== "" &&
      lisclassadmin.length === 0 &&
      awardstatadmin.length !== 0 &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      if (!selectedOptions.length) {
        setLotClassAdmin([]);
        getAllInventoryProjects();
        setRegionValue([]);
        setDistrictValue("");
        setAwardStatAdmin([]);

        return;
      }

      if (containsForeclosed) {
        //GetRegAwardStatLotClassForClosed
        GetRegDistAwardStatLotClassForClosed(
          selectedValues,
          regionValue,
          districtValue,
          awardstatadmin
        )
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideB");
            console.log("admin acc -- reg,dist,awardstat,lotclass--foreclosed");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //GetRegLisLotClass
        GetRegDistAwardStatLotClass(
          selectedValues,
          regionValue,
          districtValue,
          awardstatadmin
        )
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideB");
            console.log("admin acc -- reg, dist,awardstat,lotclass");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    //liclass, lot class   - nationwide
    if (
      regionValue.length === 0 &&
      districtValue === "" &&
      lisclassadmin.length !== 0 &&
      awardstatadmin.length === 0 &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      if (!selectedOptions.length) {
        setLotClassAdmin([]);
        getAllInventoryProjects();
        setLisClassAdmin([]);
        return;
      }

      if (containsForeclosed) {
        //GetSetLotClassificationForClosedProp
        GetLisLotClassForeclosed(selectedValues, lisclassadmin)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log(
              "admin acc -- lisclass,lotclass --foreclosed -nationwide"
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //GetSetLotClassification
        GetLisLotClass(selectedValues, lisclassadmin)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log("admin acc -- lisclass,lotclass - -nationwide");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    //awardstat,lotclass     -- nationwide
    if (
      regionValue.length === 0 &&
      districtValue === "" &&
      lisclassadmin.length === 0 &&
      awardstatadmin.length !== 0 &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      if (!selectedOptions.length) {
        setLotClassAdmin([]);
        getAllInventoryProjects();
        setAwardStatAdmin([]);
        return;
      }

      if (containsForeclosed) {
        //GetRegAwardStatLotClassForClosed
        GetAwardStatLotClassForeClosedNationWide(selectedValues, awardstatadmin)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideB");
            console.log(
              "admin acc -- awardstat,lotclass--foreclosed --nationwide"
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //GetRegAwardStatLotClass

        GetAwardStatLotClassNationWide(selectedValues, awardstatadmin)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideB");
            console.log("admin acc -- awardstat,lotclass---nationwide");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    ////////////////////////
    //lisclass,awardstat,lotclass     -- nationwide
    if (
      regionValue.length === 0 &&
      districtValue === "" &&
      lisclassadmin.length > 0 &&
      awardstatadmin.length > 0 &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      if (!selectedOptions.length) {
        setLotClassAdmin([]);
        getAllInventoryProjects();
        setAwardStatAdmin([]);
        return;
      }

      if (containsForeclosed) {
        //GetAwardStatLotClassForeClosedNationWide
        LisClassAwardStatLotClassForeClosed(
          selectedValues,
          lisclassadmin,
          awardstatadmin
        )
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideC");
            console.log(
              "admin acc -- lisclass,awardstat,lotclass--foreclosed --nationwide"
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //GetAwardStatLotClassNationWide

        LisClassAwardStatLotClass(selectedValues, lisclassadmin, awardstatadmin)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideC");
            console.log(
              "admin acc -- lisclass,awardstat,lotclass---nationwide"
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const handleSetLotClassificationReg = (event) => {
    const targetvalue = event.target.value;
    setLotClassification(targetvalue);
    console.log(targetvalue);

    const selectedText = event.target.options[event.target.selectedIndex].text;
    console.log(selectedText);
    // Check if the selected option text contains "Acquired Property"
    const isForeclosed = selectedText.includes("Foreclosed");

    //lot classication all district     -- region acct
    if (
      districtValue === "" &&
      lisclass === "" &&
      awardstatus === "" &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      if (!targetvalue.length) {
        setLotClassification("");
        getAllInventoryProjects();
        return;
      }

      if (isForeclosed) {
        //GetSetLotClassificationForClosedProp
        GetSetLotClassificationForClosedReg(targetvalue)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log(
              "region acc -- get lotclassification --Foreclosed/Acquired Property--all district"
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //GetSetLotClassification
        GetSetLotClassificationReg(targetvalue)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log("region acc -- get lotclassification --all district");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    //lot classification by district - - region acct

    if (
      districtValue !== "" &&
      lisclass === "" &&
      awardstatus === "" &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      if (!targetvalue.length) {
        setLotClassification("");
        // getAllInventoryProjects();

        GetInventorybyDistrict(districtValue)
          .then((value) => {
            setAllInventoryProject(value.data);
            setPrinting("printprojbydist");
          })
          .catch((error) => {
            alert(error);
          });

        return;
      }

      if (isForeclosed) {
        //GetSetLotClassificationForClosedPropByDist
        GetForeClosedByDistRegAcct(districtValue, targetvalue)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log(
              "region acc -- get lotclassification --Foreclosed/Acquired Property--by district"
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //GetSetLotClassificationByDist
        GetLotClassByDistRegAcct(districtValue, targetvalue)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log("region acc -- get lotclassification--by district");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const handleSetLotClassificationDist = (event) => {
    const targetvalue = event.target.value;
    setLotClassification(targetvalue);
    console.log(targetvalue);

    const selectedText = event.target.options[event.target.selectedIndex].text;
    console.log(selectedText);
    // Check if the selected option text contains "Acquired Property"
    const isForeclosed = selectedText.includes("Foreclosed");

    //lot classication    -- district acct
    if (
      lisclass === "" &&
      awardstatus === "" &&
      selectedproperty === "" &&
      modeofdispo === ""
    ) {
      if (!targetvalue.length) {
        setLotClassification("");
        getAllInventoryProjects();
        return;
      }

      if (isForeclosed) {
        //
        GetSetLotClassificationForClosedDist(targetvalue)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log(
              "district acc -- get lotclassification --Foreclosed/Acquired Property"
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        //
        GetSetLotClassificationDist(targetvalue)
          .then((response) => {
            setAllInventoryProject(response.data);
            setPrinting("printnationwideA");
            console.log("district acc -- get lotclassification");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const handleGetAllUnvalidate = async () => {
    GetAllUnvalidate()
      .then((value) => {
        setAllInventoryProject(value.data);
        console.log(value.data);
      })
      .catch((error) => {
        // alert(error);
      });
  };

  const handleGetAllReturned = async () => {
    GetAllReturned()
      .then((value) => {
        setAllInventoryProject(value.data);
        console.log(value.data);
      })
      .catch((error) => {
        // alert(error);
      });
  };

  const handleGetAllValidatedByRegion = async () => {
    GetAllValidatedByRegion()
      .then((value) => {
        setAllInventoryProject(value.data);
        console.log(value.data);
      })
      .catch((error) => {
        // alert(error);
      });
  };

  const handleAdminGetAllReturned = async () => {
    GetAdminAllReturned()
      .then((value) => {
        setAllInventoryProject(value.data);
        console.log(value.data);
      })
      .catch((error) => {
        // alert(error);
      });
  };

  const handleAdminGetComplied = async () => {
    GetComplied()
      .then((value) => {
        setAllInventoryProject(value.data);
        console.log(value.data);
      })
      .catch((error) => {
        // alert(error);
      });
  };

  const handleDistGetAllReturned = async () => {
    GetDistAllReturned()
      .then((value) => {
        setAllInventoryProject(value.data);
        console.log(value.data);
      })
      .catch((error) => {
        // alert(error);
      });
  };

  const handleGetAdminCheckUnvalidate = async () => {
    GetAdminCheckUnvalidate()
      .then((value) => {
        setAllInventoryProject(value.data);
        console.log(value.data);
      })
      .catch((error) => {
        // alert(error);
      });
  };

  const handleGetAdminCheckComplied = async () => {
    GetAdminCheckComplied()
      .then((value) => {
        setAllInventoryProject(value.data);
        console.log(value.data);
      })
      .catch((error) => {});
  };

  const handleFilterResetAll = () => {
    handleFilterReset();

    handleFilterResetReg();

    handleFilterResetDist();
  };

  const handleFilterReset = () => {
    setRegionValue([]);
    setDistrictValue("");
    setLisClassAdmin([]);
    setAwardStatAdmin([]);
    setSelectedProperty("");
    setModeofDispo("");
    setLotClassAdmin([]);
    getAllInventoryProjects();
  };

  const handleFilterResetReg = () => {
    setDistrictValue("");
    setLISClass("");
    setLISAwardStat("");
    setSelectedProperty("");
    setModeofDispo("");
    setLotClassification("");
    getAllInventoryProjects();
  };

  const handleFilterResetDist = () => {
    setLISClass("");
    setLISAwardStat("");
    setSelectedProperty("");
    setModeofDispo("");
    setLotClassification("");
    getAllInventoryProjects();
  };

  const handleClickEdit = async (selectedData) => {
    if (selectedData.awardstat === "Sold/Disposed") {
      navigate("/form", { state: { triggerFetch: "1", selectedData } });
    } else if (selectedData.awardstat === "Unsold/Undisposed") {
      navigate("/form2", { state: { triggerFetch: "1", selectedData } });
    }
  };

  const handleClickDelete = async (id) => {
    setDeleteDataID(id);
    handleShow();
  };
  const handleConfirmDelete = async (dataid) => {
    try {
      const result = await HandleDeleteData(dataid);
      if (result === "0000") {
        handleClose();
        notifySuccess("Deleted Successfully!");
        getAllInventoryProjects();
      } else {
        notifyWarning("Delete Failed!");
        handleClose();
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while deleting the data.");
    }
  };

  useEffect(() => {
    fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setAccessLevel(responseJson.response.useraccess);
      })
      .catch((error) => {});
    getRegionalOffice();
    handleGetDistrict();

    if (!triggerFetch) {
      getAllInventoryProjects();
    }
  }, []);

  useEffect(() => {
    // Function to handle data fetching based on triggerFetch value
    const handleFetch = async () => {
      try {
        if (triggerFetch === "1") {
          await handleGetAllValidatedByRegion();
        } else if (triggerFetch === "2") {
          await handleAdminGetComplied();
        } else if (triggerFetch === "3") {
          await handleAdminGetAllReturned();
        } else if (triggerFetch === "4") {
          await handleGetAllReturned();
        } else if (triggerFetch === "5") {
          await handleGetAllUnvalidate();
        } else if (triggerFetch === "6") {
          await handleDistGetAllReturned();
        } else if (triggerFetch === "7") {
          await handleGetAdminCheckUnvalidate();
        } else if (triggerFetch === "8") {
          await handleGetAdminCheckComplied();
        }

        // Resetting state after action
        navigate(location.pathname, { state: {} });
      } catch (error) {
        console.error("Error in handleFetch:", error);
      }
    };

    if (triggerFetch) {
      handleFetch();
    }
  }, [triggerFetch, navigate, location.pathname]);

  /////////////START OF SEARCH INPUT/////////////////////

  const [isOpenpagetag, setIsOpenpagetag] = useState(true);
  const [query, setQuery] = useState("");

  const handlesetQuery = (event) => {
    if (event.target.value === "") {
      console.log(isOpenpagetag);
      setCurrentPage(1);
      setInvPerPage(30);
      setIsOpenpagetag(true);
    } else {
      setCurrentPage(1);
      setInvPerPage(allinventoryprojects.length);
      setIsOpenpagetag(false);
    }

    setQuery(event.target.value);
  };

  //////////////END OF SEARCH INPUT///////////////
  //////////////PAGINATION///////////////

  const [currentPage, setCurrentPage] = useState(1);
  const [invPerPage, setInvPerPage] = useState(30);

  const indexOfLastInv = currentPage * invPerPage;
  const indexOfFirstInv = indexOfLastInv - invPerPage;
  const currentInv = allinventoryprojects.slice(
    indexOfFirstInv,
    indexOfLastInv
  );
  const onPageChange = (pageNumber) => setCurrentPage(pageNumber);

  //////////////END OF PAGINATION///////////////

  const regionOptions = region.map((data) => ({
    value: data.BP_ID,
    label: data.BP_REGION,
  }));

  const lisclassoptionadmin = [
    { value: "Estate", label: "Estate" },
    { value: "Non-estate", label: "Non-estate" },
  ];

  const awardstatoptionadmin = [
    { value: "Sold/Disposed", label: "Sold/Disposed" },
    { value: "Unsold/Undisposed", label: "Unsold/Undisposed" },
  ];

  const lotclassoptionadmin = [
    { value: "1,5", label: "Commercial" },
    { value: "2,6", label: "Industrial" },
    { value: "3,8", label: "Institutional" },

    { value: "1,5,99", label: "Commercial-Foreclosed/Acquired Property" },
    { value: "2,6,99", label: "Industrial-Foreclosed/Acquired Property" },
    { value: "3,8,99", label: "Institutional-Foreclosed/Acquired Property" },
  ];

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Header inventoryProjects={allinventoryprojects} />

      <Sidebar />
      <div>
        <div className="content-wrapper bg-webapp ">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Report</h1>
                </div>
              </div>
            </div>
          </div>
          <section className="content  ">
            <div className="container-fluid">
              <div className="row ">
                <div className="col-lg-12">
                  <div
                    className="card card-info card-outline"
                    style={{ maxHeight: "calc(100vh - 100px)" }}
                  >
                    {/* region acct */}
                    {accesslevel === "2" ? (
                      <div className="card-header ">
                        <div className="row">
                          <div className="col-lg-2">
                            <div className="form-group">
                              <label>Choose District</label>
                              <select
                                className="form-control"
                                onChange={handleGetDistValue}
                                value={districtValue}
                              >
                                <option value="">Select Options </option>
                                {district.map((data) => (
                                  <option
                                    key={data.BP_DO_ID}
                                    value={data.BP_DO_ID}
                                  >
                                    {data.BP_DO_OFFICE}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-group">
                              <label>Category</label>
                              <select
                                className="form-control"
                                value={lisclass}
                                onChange={handleLISClassReg}
                              >
                                <option value="">Select Options</option>
                                <option>Non-estate</option>
                                <option>Estate</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-2">
                            <div className="form-group">
                              <label>Award Status</label>
                              <select
                                className="form-control"
                                value={awardstatus}
                                onChange={handleSetAwardStatReg}
                              >
                                <option value="">Select Options</option>
                                <option>Sold/Disposed</option>
                                <option>Unsold/Undisposed</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-2">
                            <div className="form-group">
                              <label>Property Name</label>
                              <select
                                className="form-control"
                                value={selectedproperty}
                                onChange={handlePropertyReg}
                              >
                                <option value="">Select Options</option>
                                {inventorybyclass
                                  .filter(
                                    (value, index, self) =>
                                      self.findIndex(
                                        (data) =>
                                          data.propertycode ===
                                          value.propertycode
                                      ) === index
                                  )
                                  .map((data, index) => (
                                    <option
                                      key={index}
                                      value={data.propertycode}
                                    >
                                      {data.propertyid}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-2">
                            <div className="form-group">
                              <label>With Agreement</label>
                              <select
                                className="form-control"
                                value={modeofdispo}
                                onChange={handleSetModeDispoReg}
                              >
                                {" "}
                                <option value="">Select Options </option>
                                <option value={"Negotiated Lease"}>
                                  Lease
                                </option>
                                <option value={"Usufruct"}>Usufruct</option>
                                <option value={"Deed of Donation"}>
                                  Deed of Donation
                                </option>
                                <option value={"Memorandum of Agreement"}>
                                  Memorandum of Agreement
                                </option>
                                <option value={"Memorandum of Understanding"}>
                                  Memorandum of Understanding
                                </option>
                                <option value={"Compromise Agreement"}>
                                  Compromise Agreement
                                </option>
                                <option value={"Others"}>Others</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-2">
                            <div className="form-group">
                              <label>Lot Classification</label>
                              <select
                                className="form-control"
                                value={lotclassification}
                                onChange={handleSetLotClassificationReg}
                              >
                                <option value="">Select Options</option>
                                <option value="1,5">Commercial</option>
                                <option value="2,6">Industrial</option>
                                <option value="3,8">Institutional</option>

                                <option value="1,5,99">
                                  Commercial-Foreclosed/Acquired Property
                                </option>
                                <option value="2,6,99">
                                  Industrial-Foreclosed/Acquired Property
                                </option>
                                <option value="3,8,99">
                                  Institutional-Foreclosed/Acquired Property
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <button
                              className="btn btn-primary "
                              style={{ height: "40px", width: "100px" }}
                              onClick={handleFilterResetReg}
                            >
                              Reset
                            </button>
                          </div>
                        </div>

                        {/* main acct */}
                      </div>
                    ) : accesslevel === "1" || accesslevel === "4" ? (
                      <div className="card-header   ">
                        <div className="row">
                          <div className="col-lg-2 ">
                            <label>Choose Region</label>
                            <Select
                              isMulti
                              options={regionOptions}
                              value={regionOptions.filter((option) =>
                                regionValue.includes(option.value)
                              )}
                              onChange={handleChangeRegion}
                              placeholder="Select Options"
                            />
                          </div>
                          <div className="col-lg-2">
                            <div className="form-group">
                              <label>Choose District</label>
                              <select
                                className="form-control"
                                onChange={handleGetDistValue}
                                value={districtValue}
                              >
                                <option value="">Select Options </option>
                                {district.map((data) => (
                                  <option
                                    key={data.BP_DO_ID}
                                    value={data.BP_DO_ID}
                                  >
                                    {data.BP_DO_OFFICE}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-group">
                              <label>Category</label>
                              <Select
                                isMulti
                                options={lisclassoptionadmin}
                                value={lisclassoptionadmin.filter((option) =>
                                  lisclassadmin.includes(option.value)
                                )}
                                onChange={handleLISClass}
                                placeholder="Select Options"
                              />
                            </div>
                          </div>

                          <div className="col-lg-2">
                            <div className="form-group">
                              <label>Award Status</label>
                              <Select
                                isMulti
                                options={awardstatoptionadmin}
                                value={awardstatoptionadmin.filter((option) =>
                                  awardstatadmin.includes(option.value)
                                )}
                                onChange={handleSetAwardStat}
                                placeholder="Select Options"
                              />
                            </div>
                          </div>

                          <div className="col-lg-2">
                            <div className="form-group">
                              {/* ADMIN ACCT */}
                              <label>Property Name</label>
                              <select
                                className="form-control"
                                value={selectedproperty}
                                onChange={handleProperty}
                              >
                                <option value="">Select Options</option>
                                {inventorybyclass
                                  .filter(
                                    (value, index, self) =>
                                      self.findIndex(
                                        (data) =>
                                          data.propertycode ===
                                          value.propertycode
                                      ) === index
                                  )
                                  .map((data, index) => (
                                    <option
                                      key={index}
                                      value={data.propertycode}
                                    >
                                      {data.propertyid}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-2">
                            <div className="form-group">
                              <label>With Agreement</label>
                              <select
                                className="form-control"
                                value={modeofdispo}
                                onChange={handleSetModeDispo}
                              >
                                {" "}
                                <option value="">Select Options </option>
                                <option value={"Negotiated Lease"}>
                                  Lease
                                </option>
                                <option value={"Usufruct"}>Usufruct</option>
                                <option value={"Deed of Donation"}>
                                  Deed of Donation
                                </option>
                                <option value={"Memorandum of Agreement"}>
                                  Memorandum of Agreement
                                </option>
                                <option value={"Memorandum of Understanding"}>
                                  Memorandum of Understanding
                                </option>
                                <option value={"Compromise Agreement"}>
                                  Compromise Agreement
                                </option>
                                <option value={"Others"}>Others</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-2">
                            <div className="form-group">
                              <label>Lot Classification</label>
                              <Select
                                isMulti
                                options={lotclassoptionadmin}
                                value={lotclassoptionadmin.filter((option) =>
                                  lotclassadmin.includes(option.value)
                                )}
                                onChange={handleSetLotClassification}
                                placeholder="Select Options"
                              />{" "}
                            </div>
                          </div>

                          <div className="col-lg-2 ">
                            <div className="form-group ">
                              <div>
                                <label
                                  className=""
                                  style={{ color: "transparent" }}
                                >
                                  Lot Classification
                                </label>
                              </div>
                              <button
                                className="btn btn-primary"
                                style={{ height: "40px", width: "100px" }}
                                onClick={handleFilterReset}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : accesslevel === "3" ? (
                      <div className="card-header  ">
                        <div className="row">
                          <div className={"col-lg-2"}>
                            <div className="form-group">
                              <label>Category</label>
                              <select
                                className="form-control"
                                value={lisclass}
                                onChange={handleLISClassDist}
                              >
                                <option value="">Select Options</option>

                                <option>Non-estate</option>
                                <option>Estate</option>
                              </select>
                            </div>
                          </div>

                          <div className={"col-lg-2"}>
                            <div className="form-group">
                              <label>Award Status</label>
                              <select
                                className="form-control"
                                value={awardstatus}
                                //dist acct
                                onChange={handleSetAwardStatDist}
                              >
                                {" "}
                                <option value="">Select Options </option>
                                <option>Sold/Disposed</option>
                                <option>Unsold/Undisposed</option>
                              </select>
                            </div>
                          </div>

                          <div className={"col-lg-2"}>
                            <div className="form-group">
                              <label>Property Name</label>
                              <select
                                className="form-control"
                                value={selectedproperty}
                                onChange={handlePropertyDist}
                              >
                                <option value="">Select Options </option>
                                {inventorybyclass
                                  .filter(
                                    (value, index, self) =>
                                      self.findIndex(
                                        (data) =>
                                          data.propertycode ===
                                          value.propertycode
                                      ) === index
                                  )
                                  .map((data, index) => (
                                    <option
                                      key={index}
                                      value={data.propertycode}
                                    >
                                      {data.propertyid}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-2">
                            <div className="form-group">
                              <label>With Agreement</label>
                              <select
                                className="form-control"
                                value={modeofdispo}
                                //dist acct
                                onChange={handleSetModeDispoDist}
                              >
                                {" "}
                                <option value="">Select Options </option>
                                <option value={"Negotiated Lease"}>
                                  Lease
                                </option>
                                <option value={"Usufruct"}>Usufruct</option>
                                <option value={"Deed of Donation"}>
                                  Deed of Donation
                                </option>
                                <option value={"Memorandum of Agreement"}>
                                  Memorandum of Agreement
                                </option>
                                <option value={"Memorandum of Understanding"}>
                                  Memorandum of Understanding
                                </option>
                                <option value={"Compromise Agreement"}>
                                  Compromise Agreement
                                </option>
                                <option value={"Others"}>Others</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-2 ">
                            <div className="form-group">
                              <label>Lot Classification</label>
                              <select
                                className="form-control"
                                value={lotclassification}
                                onChange={handleSetLotClassificationDist}
                              >
                                <option value="">Select Options</option>
                                <option value="1,5">Commercial</option>
                                <option value="2,6">Industrial</option>
                                <option value="3,8">Institutional</option>

                                <option value="1,5,99">
                                  Commercial-Foreclosed/Acquired Property
                                </option>
                                <option value="2,6,99">
                                  Industrial-Foreclosed/Acquired Property
                                </option>
                                <option value="3,8,99">
                                  Institutional-Foreclosed/Acquired Property
                                </option>
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-2"></div>

                          <div className="col-lg-2 ">
                            <button
                              className="btn btn-primary "
                              style={{ height: "40px", width: "100px" }}
                              onClick={handleFilterResetDist}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="card-header border-0 d-flex  ">
                      <div className="input-group w-25">
                        <input
                          type="text"
                          name="table_search"
                          className="form-control float-right"
                          placeholder="Search"
                          value={query}
                          onChange={handlesetQuery}
                        />
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-default">
                            <i className="fas fa-search" />
                          </button>
                        </div>
                      </div>

                      <div className="ml-4">
                        <button
                          className="btn btn-info"
                          tabIndex={0}
                          aria-controls="excel"
                          type="button"
                          onClick={handleprintExcel}
                        >
                          <i className="fas fa-file-excel" />
                        </button>
                      </div>
                      <div className="ml-4">
                        <button
                          className="btn btn-info"
                          tabIndex={0}
                          aria-controls="excel"
                          type="button"
                          onClick={handlePrintPdf}
                        >
                          <i className="fas fa-file-pdf" />
                        </button>
                      </div>
                    </div>

                    <div className="card-body p-0 overflow-auto">
                      <table className="table table-hover text-nowrap ">
                        {accesslevel === "1" || accesslevel === "4" ? (
                          <thead
                            className="text-nowrap "
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#fff",
                            }}
                          >
                            <tr>
                              <th className="text-center">NO</th>
                              <th
                                onClick={() => sortData("propertyid")}
                                style={{ cursor: "pointer" }}
                              >
                                PROPERTY NAME {renderSortingArrow("propertyid")}
                              </th>

                              <th
                                onClick={() => sortData("BP_REGION")}
                                style={{ cursor: "pointer" }}
                              >
                                REGION {renderSortingArrow("BP_REGION")}
                              </th>

                              <th
                                onClick={() => sortData("BP_DO_OFFICE")}
                                style={{ cursor: "pointer" }}
                              >
                                DISTRICT {renderSortingArrow("BP_DO_OFFICE")}
                              </th>

                              <th
                                onClick={() => sortData("catvalue")}
                                style={{ cursor: "pointer" }}
                              >
                                CATEGORY {renderSortingArrow("catvalue")}
                              </th>
                              <th
                                onClick={() => sortData("awardstat")}
                                style={{ cursor: "pointer" }}
                              >
                                AWARD STATUS {renderSortingArrow("awardstat")}
                              </th>

                              <th
                                onClick={() => sortData("classvalue")}
                                style={{ cursor: "pointer" }}
                              >
                                LOT CLASSIFICATION{" "}
                                {renderSortingArrow("classvalue")}
                              </th>
                              <th>BLOCK</th>
                              <th>LOT</th>
                              {/* <th
                                className="text-center"
                                onClick={() => sortData("tbl_trxstatus")}
                                style={{ cursor: "pointer" }}
                              >
                                STATUS {renderSortingArrow("tbl_trxstatus")}
                              </th> */}
                              <th
                                className="text-center"
                                onClick={() => sortData("tbl_trxvalstatus")}
                                style={{ cursor: "pointer" }}
                              >
                                CHECK STATUS{" "}
                                {renderSortingArrow("tbl_trxvalstatus")}
                              </th>

                              <th className="text-center">ACTION</th>
                            </tr>
                          </thead>
                        ) : accesslevel === "2" ? (
                          <thead
                            className="text-nowrap"
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#fff",
                            }}
                          >
                            <tr>
                              <th className="text-center">NO</th>
                              <th
                                onClick={() => sortData("propertyid")}
                                style={{ cursor: "pointer" }}
                              >
                                PROPERTY NAME {renderSortingArrow("propertyid")}
                              </th>

                              <th
                                onClick={() => sortData("BP_DO_OFFICE")}
                                style={{ cursor: "pointer" }}
                              >
                                DISTRICT {renderSortingArrow("BP_DO_OFFICE")}
                              </th>

                              <th
                                onClick={() => sortData("catvalue")}
                                style={{ cursor: "pointer" }}
                              >
                                CATEGORY {renderSortingArrow("catvalue")}
                              </th>
                              <th
                                onClick={() => sortData("awardstat")}
                                style={{ cursor: "pointer" }}
                              >
                                AWARD STATUS {renderSortingArrow("awardstat")}
                              </th>

                              <th
                                onClick={() => sortData("classvalue")}
                                style={{ cursor: "pointer" }}
                              >
                                LOT CLASSIFICATION{" "}
                                {renderSortingArrow("classvalue")}
                              </th>
                              <th>BLOCK</th>
                              <th>LOT</th>
                              {/* <th
                                className="text-center"
                                onClick={() => sortData("tbl_trxstatus")}
                                style={{ cursor: "pointer" }}
                              >
                                STATUS {renderSortingArrow("tbl_trxstatus")}
                              </th> */}
                              <th
                                className="text-center"
                                onClick={() => sortData("tbl_trxvalstatus")}
                                style={{ cursor: "pointer" }}
                              >
                                CHECK STATUS{" "}
                                {renderSortingArrow("tbl_trxvalstatus")}
                              </th>

                              <th className="text-center">ACTION</th>
                            </tr>
                          </thead>
                        ) : accesslevel === "3" ? (
                          <thead
                            className="text-nowrap"
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#fff",
                            }}
                          >
                            <tr>
                              <th className="text-center">NO</th>
                              <th
                                onClick={() => sortData("propertyid")}
                                style={{ cursor: "pointer" }}
                              >
                                PROPERTY NAME {renderSortingArrow("propertyid")}
                              </th>

                              <th
                                onClick={() => sortData("catvalue")}
                                style={{ cursor: "pointer" }}
                              >
                                CATEGORY {renderSortingArrow("catvalue")}
                              </th>
                              <th
                                onClick={() => sortData("awardstat")}
                                style={{ cursor: "pointer" }}
                              >
                                AWARD STATUS {renderSortingArrow("awardstat")}
                              </th>

                              <th
                                onClick={() => sortData("classvalue")}
                                style={{ cursor: "pointer" }}
                              >
                                LOT CLASSIFICATION{" "}
                                {renderSortingArrow("classvalue")}
                              </th>
                              <th>BLOCK</th>
                              <th>LOT</th>
                              {/* <th
                                className="text-center"
                                onClick={() => sortData("tbl_trxstatus")}
                                style={{ cursor: "pointer" }}
                              >
                                STATUS {renderSortingArrow("tbl_trxstatus")}
                              </th> */}
                              <th
                                className="text-center"
                                onClick={() => sortData("tbl_trxvalstatus")}
                                style={{ cursor: "pointer" }}
                              >
                                CHECK STATUS{" "}
                                {renderSortingArrow("tbl_trxvalstatus")}
                              </th>

                              <th className="text-center">ACTION</th>
                            </tr>
                          </thead>
                        ) : (
                          ""
                        )}

                        {accesslevel === "1" || accesslevel === "4" ? (
                          <tbody>
                            {currentInv

                              .filter(
                                (data) =>
                                  data.propertyid
                                    .toLowerCase()
                                    .includes(query) ||
                                  data.catvalue.toLowerCase().includes(query)
                              )
                              .map((data, index) => (
                                <tr key={index}>
                                  <td className="text-center">
                                    {" "}
                                    {index + 1 + indexOfFirstInv}
                                  </td>
                                  <td>{data.propertyid}</td>
                                  {/* reg dist */}

                                  <td>{data.BP_REGION}</td>
                                  <td>{data.BP_DO_OFFICE}</td>

                                  <td>{data.catvalue}</td>
                                  <td>{data.awardstat}</td>
                                  <td>
                                    {data.classvalue === "1" ||
                                    data.classvalue === "5"
                                      ? `Commercial ${
                                          data.foreclosed_prop
                                            ? `(${data.foreclosed_prop})`
                                            : ""
                                        }`
                                      : data.classvalue === "2" ||
                                        data.classvalue === "6"
                                      ? `Industrial ${
                                          data.foreclosed_prop
                                            ? `(${data.foreclosed_prop})`
                                            : ""
                                        }`
                                      : data.classvalue === "3" ||
                                        data.classvalue === "8"
                                      ? `Institutional ${
                                          data.foreclosed_prop
                                            ? `(${data.foreclosed_prop})`
                                            : ""
                                        }`
                                      : data.classvalue === "4"
                                      ? `Foreclosed/Acquired Property ${
                                          data.foreclosed_prop
                                            ? `(${data.foreclosed_prop})`
                                            : ""
                                        }`
                                      : data.classvalue === "7"
                                      ? `Mixed-use ${
                                          data.foreclosed_prop
                                            ? `(${data.foreclosed_prop})`
                                            : ""
                                        }`
                                      : ""}
                                  </td>

                                  <td>{data.blk}</td>
                                  <td>{data.lot}</td>

                                  <td className="text-center">
                                    {" "}
                                    <span
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#modal-sm"
                                      onClick={() => {
                                        handlePassDataModalValue(data);
                                      }}
                                      className={
                                        "badge " +
                                        (data.tbl_trxvalstatus === "1" ||
                                        data.tbl_trxvalstatus === "5"
                                          ? "badge-danger"
                                          : data.tbl_trxvalstatus === "2" ||
                                            data.tbl_trxvalstatus === "4"
                                          ? "badge-success"
                                          : data.tbl_trxstatus === "1"
                                          ? "badge-primary"
                                          : data.tbl_trxstatus === "2"
                                          ? "badge-danger"
                                          : data.tbl_trxstatus === "4"
                                          ? "badge-info"
                                          : "")
                                      }
                                      style={{
                                        pointerEvents:
                                          data.tbl_trxstatus === "1" ||
                                          data.tbl_trxstatus === "2" ||
                                          (data.tbl_trxstatus === "3" &&
                                            (data.tbl_trxvalstatus === "" ||
                                              data.tbl_trxvalstatus ===
                                                null)) ||
                                          data.tbl_trxstatus === "4"
                                            ? "auto"
                                            : "auto",
                                      }}
                                    >
                                      {" "}
                                      {data.tbl_trxvalstatus === "1" ||
                                      data.tbl_trxvalstatus === "5"
                                        ? "Returned"
                                        : data.tbl_trxvalstatus === "2" ||
                                          data.tbl_trxvalstatus === "4"
                                        ? "Complied"
                                        : data.tbl_trxstatus === "1"
                                        ? "Validated"
                                        : data.tbl_trxstatus === "2"
                                        ? "Validated"
                                        : data.tbl_trxstatus === "4"
                                        ? "Validated"
                                        : ""}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="text-center">
                                      <button
                                        type="button"
                                        className="btn btn-info mx-1"
                                        onClick={() => {
                                          handlePassDataValue(data);
                                        }}
                                        style={{ cursor: "pointer" }}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="View"
                                      >
                                        <BsEye />
                                      </button>
                                      {accesslevel === "1" &&
                                        data.catvalue === "Non-estate" &&
                                        data.tbl_trxstatus === "3" && (
                                          <button
                                            type="button"
                                            className="btn btn-success mx-1"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleClickEdit(data)
                                            }
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Edit"
                                          >
                                            <BsPencilSquare />
                                          </button>
                                        )}
                                      {accesslevel === "1" &&
                                        data.catvalue === "Non-estate" && (
                                          <button
                                            type="button"
                                            className="btn btn-danger mx-1"
                                            data-toggle="modal"
                                            data-target="#confirmationModal"
                                            onClick={() =>
                                              handleClickDelete(data.tbl_id)
                                            }
                                            style={{ cursor: "pointer" }}
                                            data-placement="top"
                                            title="Delete"
                                          >
                                            <BsTrash />
                                          </button>
                                        )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        ) : accesslevel === "2" ? (
                          <tbody>
                            {currentInv

                              .filter(
                                (data) =>
                                  data.propertyid
                                    .toLowerCase()
                                    .includes(query) ||
                                  data.catvalue.toLowerCase().includes(query)
                              )
                              .map((data, index) => (
                                <tr key={index}>
                                  <td className="text-center">
                                    {" "}
                                    {index + 1 + indexOfFirstInv}
                                  </td>
                                  <td>{data.propertyid}</td>
                                  {/* reg dist */}

                                  <td>{data.BP_DO_OFFICE}</td>

                                  <td>{data.catvalue}</td>
                                  <td>{data.awardstat}</td>
                                  <td>
                                    {data.classvalue === "1" ||
                                    data.classvalue === "5"
                                      ? `Commercial ${
                                          data.foreclosed_prop
                                            ? `(${data.foreclosed_prop})`
                                            : ""
                                        }`
                                      : data.classvalue === "2" ||
                                        data.classvalue === "6"
                                      ? `Industrial ${
                                          data.foreclosed_prop
                                            ? `(${data.foreclosed_prop})`
                                            : ""
                                        }`
                                      : data.classvalue === "3" ||
                                        data.classvalue === "8"
                                      ? `Institutional ${
                                          data.foreclosed_prop
                                            ? `(${data.foreclosed_prop})`
                                            : ""
                                        }`
                                      : data.classvalue === "4"
                                      ? `Foreclosed/Acquired Property ${
                                          data.foreclosed_prop
                                            ? `(${data.foreclosed_prop})`
                                            : ""
                                        }`
                                      : data.classvalue === "7"
                                      ? `Mixed-use ${
                                          data.foreclosed_prop
                                            ? `(${data.foreclosed_prop})`
                                            : ""
                                        }`
                                      : ""}
                                  </td>

                                  <td>{data.blk}</td>
                                  <td>{data.lot}</td>

                                  <td className="text-center">
                                    {" "}
                                    <span
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#modal-sm"
                                      onClick={() => {
                                        handlePassDataModalValue(data);
                                      }}
                                      className={
                                        "badge " +
                                        (data.tbl_trxvalstatus === "1" ||
                                        data.tbl_trxvalstatus === "5"
                                          ? "badge-danger"
                                          : data.tbl_trxvalstatus === "2" ||
                                            data.tbl_trxvalstatus === "4"
                                          ? "badge-success"
                                          : data.tbl_trxstatus === "1"
                                          ? "badge-primary"
                                          : data.tbl_trxstatus === "2"
                                          ? "badge-danger"
                                          : data.tbl_trxstatus === "4"
                                          ? "badge-info"
                                          : "")
                                      }
                                    >
                                      {" "}
                                      {data.tbl_trxvalstatus === "1" ||
                                      data.tbl_trxvalstatus === "5"
                                        ? "Returned"
                                        : data.tbl_trxvalstatus === "2" ||
                                          data.tbl_trxvalstatus === "4"
                                        ? "Complied"
                                        : data.tbl_trxstatus === "1"
                                        ? "Validated"
                                        : data.tbl_trxstatus === "2"
                                        ? "Validated"
                                        : data.tbl_trxstatus === "4"
                                        ? "Validated"
                                        : ""}
                                    </span>
                                  </td>

                                  <td>
                                    <div className="text-center">
                                      <button
                                        type="button"
                                        className="btn btn-info mx-1"
                                        onClick={() => {
                                          handlePassDataValue(data);
                                        }}
                                        style={{ cursor: "pointer" }}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="View"
                                      >
                                        <BsEye />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        ) : accesslevel === "3" ? (
                          <tbody>
                            {currentInv

                              .filter(
                                (data) =>
                                  data.propertyid
                                    .toLowerCase()
                                    .includes(query) ||
                                  data.catvalue.toLowerCase().includes(query)
                              )
                              .map((data, index) => (
                                <tr key={index}>
                                  <td className="text-center">
                                    {" "}
                                    {index + 1 + indexOfFirstInv}
                                  </td>
                                  <td>{data.propertyid}</td>
                                  {/* reg dist */}

                                  <td>{data.catvalue}</td>
                                  <td>{data.awardstat}</td>
                                  <td>
                                    {data.classvalue === "1" ||
                                    data.classvalue === "5"
                                      ? `Commercial ${
                                          data.foreclosed_prop
                                            ? `(${data.foreclosed_prop})`
                                            : ""
                                        }`
                                      : data.classvalue === "2" ||
                                        data.classvalue === "6"
                                      ? `Industrial ${
                                          data.foreclosed_prop
                                            ? `(${data.foreclosed_prop})`
                                            : ""
                                        }`
                                      : data.classvalue === "3" ||
                                        data.classvalue === "8"
                                      ? `Institutional ${
                                          data.foreclosed_prop
                                            ? `(${data.foreclosed_prop})`
                                            : ""
                                        }`
                                      : data.classvalue === "4"
                                      ? `Foreclosed/Acquired Property ${
                                          data.foreclosed_prop
                                            ? `(${data.foreclosed_prop})`
                                            : ""
                                        }`
                                      : data.classvalue === "7"
                                      ? `Mixed-use ${
                                          data.foreclosed_prop
                                            ? `(${data.foreclosed_prop})`
                                            : ""
                                        }`
                                      : ""}
                                  </td>

                                  <td>{data.blk}</td>
                                  <td>{data.lot}</td>
                                  {/* <td className="text-center">
                                    {data.tbl_trxstatus === "3" ? (
                                      <BsCircle />
                                    ) : data.tbl_trxstatus === "2" ? (
                                      <BsCheckCircleFill
                                        style={{ color: "red" }}
                                      />
                                    ) : (
                                      <BsCheckCircleFill
                                        style={{ color: "blue" }}
                                      />
                                    )}
                                  </td> */}
                                  <td className="text-center">
                                    {" "}
                                    <span
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#modal-sm"
                                      onClick={() => {
                                        handlePassDataModalValue(data);
                                      }}
                                      className={
                                        "badge " +
                                        (data.tbl_trxvalstatus === "1" ||
                                        data.tbl_trxvalstatus === "5"
                                          ? "badge-danger"
                                          : data.tbl_trxvalstatus === "2" ||
                                            data.tbl_trxvalstatus === "4"
                                          ? "badge-success"
                                          : data.tbl_trxstatus === "1"
                                          ? "badge-primary"
                                          : data.tbl_trxstatus === "2"
                                          ? "badge-danger"
                                          : data.tbl_trxstatus === "4"
                                          ? "badge-info"
                                          : "")
                                      }
                                    >
                                      {" "}
                                      {data.tbl_trxvalstatus === "1" ||
                                      data.tbl_trxvalstatus === "5"
                                        ? "Returned"
                                        : data.tbl_trxvalstatus === "2" ||
                                          data.tbl_trxvalstatus === "4"
                                        ? "Complied"
                                        : data.tbl_trxstatus === "1"
                                        ? "Validated"
                                        : data.tbl_trxstatus === "2"
                                        ? "Validated"
                                        : data.tbl_trxstatus === "4"
                                        ? "Validated"
                                        : ""}
                                    </span>
                                  </td>

                                  <td>
                                    <div className="text-center">
                                      <button
                                        type="button"
                                        className="btn btn-info mx-1 "
                                        onClick={() => {
                                          handlePassDataValue(data);
                                        }}
                                        style={{ cursor: "pointer" }}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="View"
                                      >
                                        <BsEye />
                                      </button>
                                      {accesslevel === "3" &&
                                      data.catvalue === "Estate" &&
                                      data.tbl_trxstatus === "3" ? (
                                        <button
                                          type="button"
                                          className="btn btn-success mx-1 "
                                          style={{ cursor: "pointer" }}
                                          onClick={() => handleClickEdit(data)}
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit"
                                        >
                                          <BsPencilSquare />
                                        </button>
                                      ) : (
                                        ""
                                      )}

                                      {accesslevel === "3" &&
                                      data.catvalue === "Estate" ? (
                                        <button
                                          type="button"
                                          className="btn btn-danger mx-1 "
                                          data-toggle="modal"
                                          data-target="#confirmationModal"
                                          onClick={() =>
                                            handleClickDelete(data.tbl_id)
                                          }
                                          style={{ cursor: "pointer" }}
                                          data-placement="top"
                                          title="Delete"
                                        >
                                          <BsTrash />
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        ) : (
                          ""
                        )}
                      </table>
                    </div>
                    {isOpenpagetag ? (
                      <div className="card-footer clearfix bg-transparent ">
                        <PaginationInv
                          currentPage={currentPage}
                          totalPages={Math.ceil(
                            allinventoryprojects.length / invPerPage
                          )}
                          onPageChange={onPageChange}
                        />

                        <div className="float-right">
                          Showing {indexOfFirstInv + 1} to{" "}
                          {Math.min(
                            indexOfLastInv,
                            allinventoryprojects.length
                          )}{" "}
                          of {allinventoryprojects.length} result(s)
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* REMARK MODAL */}

        <div
          className="modal fade "
          id="modal-sm"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog">
            {" "}
            {/* Added modal-sm class */}
            <div className="modal-content">
              <div className="modal-header bg-info ">
                <div className="p-0">
                  <h4 className="modal-title">Remark</h4>
                </div>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="modal-body p-0 ">
                <div className="m-2"> {openModalRemark} </div>
              </div>

              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        {/*END REMARK MODAL */}

        {/* DELETE MODAL */}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header className="bg-danger">
            <Modal.Title>Delete Confirmation</Modal.Title>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => {
                handleClose();
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body> Are you sure you want to delete this data?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button
              variant="primary"
              onClick={() => handleConfirmDelete(deleinvdata)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/*END DELETE MODAL */}

        {/* MODAL  */}

        <InventoryProfile
          show={openInventoryModal}
          closeModal={setopenInventoryModal}
          inventoryprofile={inventoryprofile}
          passgeom={passgeom}
          passcenteroid={passcenteroid}
          fetchimagepass={fetchimagepass}
          getAllInventoryProjects={getAllInventoryProjects}
          fetchimageflood={fetchimageflood}
          fetchimageseis={fetchimageseis}
          handleFilterResetAll={handleFilterResetAll}
        />

        {/* END MODAL  */}
      </div>

      <Footer />
    </div>
  );
}

export default Inventory;
