import XLSX from "xlsx-js-style";
import * as FileSaver from "file-saver";
import { useState, useMemo } from "react";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const GetSortbyLISClassExcel = async (
  allinventoryprojects,
  sig_preparedby,
  sig_preparedby_dg,
  sig_revieweby,
  sig_revieweby_dg,
  sig_notedby,
  sig_notedby_dg,
  selectedpropertyname,
  titleReport
) => {
  const unsoldRowStyle = {
    font: { name: "Arial", sz: 8 },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
    alignment: { vertical: "center", wrapText: true },
  };

  const rows = allinventoryprojects.map((row, index) => {
    return [
      {
        v: index + 1,
        t: "s",
        s: unsoldRowStyle,
      },
      {
        v: row.area === null ? "" : row.area,
        t: "s",
        s: unsoldRowStyle,
      },
      {
        v: row.pack === null ? "" : row.pack,
        t: "s",
        s: unsoldRowStyle,
      },
      {
        v: row.phase === null ? "" : row.phase,
        t: "s",
        s: unsoldRowStyle,
      },
      {
        v: row.sup_blk === null ? "" : row.sup_blk,
        t: "s",
        s: unsoldRowStyle,
      },
      {
        v: row.sub_blk === null ? "" : row.sub_blk,
        t: "s",
        s: unsoldRowStyle,
      },
      {
        v: row.blk === null ? "" : row.blk,
        t: "s",
        s: unsoldRowStyle,
      },
      {
        v: row.lot === null ? "" : row.lot,
        t: "s",
        s: unsoldRowStyle,
      },
      {
        v: row.subplanno === null ? "" : row.subplanno,
        t: "s",
        s: unsoldRowStyle,
      },
      {
        v: row.tctno === null ? "" : row.tctno,
        t: "s",
        s: unsoldRowStyle,
      },
      {
        v: row.lotarea === null ? "" : row.lotarea,
        t: "s",
        s: unsoldRowStyle,
      },
      {
        v:
          row.classvalue === "1" || row.classvalue === "5"
            ? `Commercial ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "2" || row.classvalue === "6"
            ? `Industrial ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "3" || row.classvalue === "8"
            ? `Institutional ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "4"
            ? `Foreclosed/Acquired Property ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "7"
            ? `Mixed-use ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : "",
        t: "s",
        s: unsoldRowStyle,
      },
      {
        v:
          row.physicalstat === "Occupied"
            ? row.physicalstat +
              ":" +
              " " +
              (row.occupiedstat[0] ? row.occupiedstat[0] : "") +
              "," +
              (row.occupiedstat[1] ? row.occupiedstat[1] : "") +
              "," +
              (row.occupiedstat[2] ? row.occupiedstat[2] : "")
            : row.physicalstat === "Others"
            ? row.physicalstat + ":" + row.physicalstatothers
            : row.physicalstat,
        t: "s",
        s: unsoldRowStyle,
      },

      {
        v:
          row.appstat === "2"
            ? "For Appraisal"
            : row.appstat === "1"
            ? "With appraised values: " +
              "(" +
              "Market value: " +
              row.appmarketval +
              ", " +
              "Market Rent: " +
              row.apprentval +
              ", " +
              "Valid Until: " +
              row.appvalid +
              ")"
            : "",
        t: "s",
        s: unsoldRowStyle,
      },

      {
        v:
          row.pricingstat === "1"
            ? "With valid pricing" +
              ":" +
              " " +
              "Valid until:" +
              " " +
              row.pricevalid
            : row.pricingstat === "2"
            ? "Expired pricing"
            : "",
        t: "s",
        s: unsoldRowStyle,
      },
    ];
  });

  //Fetch Arrays of Array to Excel
  var ws = XLSX.utils.aoa_to_sheet(rows, { origin: "B12" });
  //REPORT TITLE
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: titleReport,
          t: "s",
          s: {
            font: { name: "Arial", sz: 12 },
            alignment: { horizontal: "center" },
          },
        },
      ],
    ],
    {
      origin: "B2",
    }
  );

  await fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.response.useraccess === "3") {
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "REGION: " + responseJson.response.reg,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B6",
          }
        );
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "DISTRICT: " + responseJson.response.dist,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B7",
          }
        );
      } else if (
        responseJson.response.useraccess === "2" ||
        responseJson.response.useraccess === "1" ||
        responseJson.response.useraccess === "4"
      ) {
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "REGION: " + allinventoryprojects[0].BP_REGION,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B6",
          }
        );
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "DISTRICT: " + allinventoryprojects[0].BP_DO_OFFICE,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B7",
          }
        );
      }
    })
    .catch((error) => {
      console.log(error);
    });

  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: "PROJECT NAME: " + selectedpropertyname,
          t: "s",
          s: {
            font: { name: "Arial", sz: 11 },
            alignment: { horizontal: "left" },
          },
        },
      ],
    ],
    {
      origin: "B8",
    }
  );

  const originCell = XLSX.utils.decode_cell("B12");
  const lastRowIndex = originCell.r + rows.length + 4; // Add the number of rows
  const lastColumnIndex = originCell.c;

  const endOrigin = XLSX.utils.encode_cell({
    r: lastRowIndex,
    c: lastColumnIndex,
  });
  let newOrigin = endOrigin.substring(1);
  let originLabel = newOrigin;
  let originName = parseInt(newOrigin) + 3;
  let originPosition = parseInt(newOrigin) + 4;

  await fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      const prepared_By =
        responseJson.response.name +
        " " +
        responseJson.response.mname +
        " " +
        responseJson.response.lname;

      const prepared_By_Position = responseJson.response.position;

      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: "Prepared by: ",
              t: "s",
              s: {
                font: { name: "Arial", sz: 11 },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originLabel,
        }
      );
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: prepared_By,
              t: "s",
              s: {
                font: { name: "Arial", sz: 11, b: true },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originName.toString(),
        }
      );
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: prepared_By_Position,
              t: "s",
              s: {
                font: { name: "Arial", sz: 11 },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originPosition.toString(),
        }
      );

      //COLUMN H
    });

  // HEADER PROPERTIES
  const unsoldHeaderStyle = {
    fill: { fgColor: { rgb: "efc184" } },
    alignment: { horizontal: "center" },
    font: {
      bold: true,
      name: "Arial",
      sz: 10,
      color: { rgb: "000000" },
    },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };

  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: "No.",
          t: "s",
          s: unsoldHeaderStyle,
        },
        {
          v: "Area.",
          t: "s",
          s: unsoldHeaderStyle,
        },
        {
          v: "Package",
          t: "s",
          s: unsoldHeaderStyle,
        },
        {
          v: "Phase",
          t: "s",
          s: unsoldHeaderStyle,
        },
        {
          v: "Super Block",
          t: "s",
          s: unsoldHeaderStyle,
        },
        {
          v: "Sub Block",
          t: "s",
          s: unsoldHeaderStyle,
        },
        {
          v: "Block",
          t: "s",
          s: unsoldHeaderStyle,
        },
        {
          v: "Lot No.",
          t: "s",
          s: unsoldHeaderStyle,
        },
        {
          v: " Subdivision Plan No.",
          t: "s",
          s: unsoldHeaderStyle,
        },
        {
          v: "OCT/TCT No.",
          t: "s",
          s: unsoldHeaderStyle,
        },
        {
          v: "Area (m²)",
          t: "s",
          s: unsoldHeaderStyle,
        },
        {
          v: "Land Use Classification",
          t: "s",
          s: unsoldHeaderStyle,
        },
        {
          v: "Occupancy Status",
          t: "s",
          s: unsoldHeaderStyle,
        },
        {
          v: "Appraisal Status",
          t: "s",
          s: unsoldHeaderStyle,
        },
        {
          v: "Pricing Status",
          t: "s",
          s: unsoldHeaderStyle,
        },
      ],
    ],
    {
      origin: "B11",
    }
  );

  var merge = { s: { r: 1, c: 1 }, e: { r: 3, c: 15 } };
  /* add merges */
  if (!ws["!merges"]) ws["!merges"] = [];
  ws["!merges"].push(merge);

  // Column Width

  var wscols = [
    { wch: 5 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
    { wch: 15 },
    { wch: 10 },
    { wch: 10 },
    { wch: 20 },
    { wch: 25 },
    { wch: 20 },
    { wch: 30 },
    { wch: 25 },
    { wch: 30 },
    { wch: 30 },
  ];
  ws["!cols"] = wscols;

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    data,
    "UNSOLD_UNDISPOSED_PROPERTIES" + new Date().toISOString() + fileExtension
  );
};

//END OF GetSortbyLISClassExcel

const GetSortbyLISClassExcelB = async (
  allinventoryprojects,
  sig_preparedby,
  sig_preparedby_dg,
  sig_revieweby,
  sig_revieweby_dg,
  sig_notedby,
  sig_notedby_dg,
  selectedpropertyname,
  titleReport
) => {
  const soldRowStyle = {
    font: { name: "Arial", sz: 8 },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
    alignment: { vertical: "center", wrapText: true },
  };

  const rows = allinventoryprojects.map((row, index) => {
    return [
      {
        v: index + 1,
        t: "s",
        s: soldRowStyle,
      },
      {
        v: row.area === null ? "" : row.area,
        t: "s",
        s: soldRowStyle,
      },
      {
        v: row.pack === null ? "" : row.pack,
        t: "s",
        s: soldRowStyle,
      },
      {
        v: row.phase === null ? "" : row.phase,
        t: "s",
        s: soldRowStyle,
      },
      {
        v: row.sup_blk === null ? "" : row.sup_blk,
        t: "s",
        s: soldRowStyle,
      },
      {
        v: row.sub_blk === null ? "" : row.sub_blk,
        t: "s",
        s: soldRowStyle,
      },
      {
        v: row.blk === null ? "" : row.blk,
        t: "s",
        s: soldRowStyle,
      },
      {
        v: row.lot === null ? "" : row.lot,
        t: "s",
        s: soldRowStyle,
      },
      {
        v: row.subplanno === null ? "" : row.subplanno,
        t: "s",
        s: soldRowStyle,
      },
      {
        v: row.tctno === null ? "" : row.tctno,
        t: "s",
        s: soldRowStyle,
      },
      {
        v: row.lotarea === null ? "" : row.lotarea,
        t: "s",
        s: soldRowStyle,
      },
      {
        v:
          row.classvalue === "1" || row.classvalue === "5"
            ? `Commercial ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "2" || row.classvalue === "6"
            ? `Industrial ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "3" || row.classvalue === "8"
            ? `Institutional ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "4"
            ? `Foreclosed/Acquired Property ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "7"
            ? `Mixed-use ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : "",

        t: "s",
        s: soldRowStyle,
      },
      {
        v: row.pbawardee === null ? "" : row.pbawardee,
        t: "s",
        s: soldRowStyle,
      },
      {
        v: row.pbdateaward === null ? "" : row.pbdateaward,
        t: "s",
        s: soldRowStyle,
      },
      {
        v: "",
        t: "s",
        s: soldRowStyle,
      },
      {
        v: row.pbselprice === null ? "" : row.pbselprice,
        t: "s",
        s: soldRowStyle,
      },

      {
        v: row.pbcontype === null ? "" : row.pbcontype,
        t: "s",
        s: soldRowStyle,
      },

      {
        v: row.acctstatus === null ? "" : row.acctstatus,
        t: "s",
        s: soldRowStyle,
      },
      {
        v: "",
        t: "s",
        s: soldRowStyle,
      },
    ];
  });

  //Fetch Arrays of Array to Excel
  var ws = XLSX.utils.aoa_to_sheet(rows, { origin: "B12" });
  //REPORT TITLE
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: titleReport,
          t: "s",
          s: {
            font: { name: "Arial", sz: 12 },
            alignment: { horizontal: "center" },
          },
        },
      ],
    ],
    {
      origin: "B2",
    }
  );

  await fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.response.useraccess === "3") {
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "REGION: " + responseJson.response.reg,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B6",
          }
        );
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "DISTRICT: " + responseJson.response.dist,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B7",
          }
        );
      } else if (
        responseJson.response.useraccess === "2" ||
        responseJson.response.useraccess === "1" ||
        responseJson.response.useraccess === "4"
      ) {
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "REGION: " + allinventoryprojects[0].BP_REGION,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B6",
          }
        );
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "DISTRICT: " + allinventoryprojects[0].BP_DO_OFFICE,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B7",
          }
        );
      }
    })
    .catch((error) => {});

  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: "PROJECT NAME: " + selectedpropertyname,
          t: "s",
          s: {
            font: { name: "Arial", sz: 11 },
            alignment: { horizontal: "left" },
          },
        },
      ],
    ],
    {
      origin: "B8",
    }
  );

  const originCell = XLSX.utils.decode_cell("B12");
  const lastRowIndex = originCell.r + rows.length + 4; // Add the number of rows
  const lastColumnIndex = originCell.c;

  const endOrigin = XLSX.utils.encode_cell({
    r: lastRowIndex,
    c: lastColumnIndex,
  });
  let newOrigin = endOrigin.substring(1);
  let originLabel = newOrigin;
  let originName = parseInt(newOrigin) + 3;
  let originPosition = parseInt(newOrigin) + 4;

  //PREPARED BY PLOT
  //COLUMN C

  await fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      const prepared_By =
        responseJson.response.name +
        " " +
        responseJson.response.mname +
        " " +
        responseJson.response.lname;

      const prepared_By_Position = responseJson.response.position;

      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: "Prepared by: ",
              t: "s",
              s: {
                font: { name: "Arial", sz: 11 },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originLabel,
        }
      );
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: prepared_By,
              t: "s",
              s: {
                font: { name: "Arial", sz: 11, bold: true },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originName.toString(),
        }
      );
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: prepared_By_Position,
              t: "s",
              s: {
                font: { name: "Arial", sz: 11 },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originPosition.toString(),
        }
      );

      //COLUMN H

      //COLUMN K
    });

  // HEADER PROPERTIES
  const soldHeaderStyle = {
    fill: { fgColor: { rgb: "efc184" } },
    alignment: { horizontal: "center" },
    font: {
      bold: true,
      name: "Arial",
      sz: 10,
      color: { rgb: "000000" },
    },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: "No.",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Area",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Package",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Phase",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Super Block",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Sub Block",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Blk No.",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Lot No.",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Subdivision Plan No.",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "TCT/OCT No.",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Lot Area (m2)",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Land Use Classification",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Name of Awardee",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Date of Award (MOA/NOA)",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Total Selling Price",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Selling Price per m2",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Contract Type",
          t: "s",
          s: soldHeaderStyle,
        },

        {
          v: "Account Status",
          t: "s",
          s: soldHeaderStyle,
        },
        {
          v: "Remarks",
          t: "s",
          s: soldHeaderStyle,
        },
      ],
    ],
    {
      origin: "B11",
    }
  );

  var merge = { s: { r: 1, c: 1 }, e: { r: 3, c: 19 } };
  /* add merges */
  if (!ws["!merges"]) ws["!merges"] = [];
  ws["!merges"].push(merge);

  // Column Width
  var wscols = [
    { wch: 5 },
    { wch: 3 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 25 },
    { wch: 25 },
    { wch: 15 },
    { wch: 15 },
    { wch: 25 },
    { wch: 30 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 30 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 20 },
    { wch: 20 },
    { wch: 5 },
  ];

  ws["!cols"] = wscols;

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    data,
    "SOLD_DISPOSED_PROPERTIES" + new Date().toISOString() + fileExtension
  );
};

//END OF GetSortbyLISClassExcelB

//GetSortbyLISClassExcelC

const GetSortbyLISClassExcelC = async (
  allinventoryprojects,
  sig_preparedby,
  sig_preparedby_dg,
  sig_revieweby,
  sig_revieweby_dg,
  sig_notedby,
  sig_notedby_dg,
  selectedpropertyname,
  titleReport
) => {
  const agreementsoldRowStyle = {
    font: { name: "Arial", sz: 8 },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
    alignment: { vertical: "center", wrapText: true },
  };

  const rows = allinventoryprojects.map((row, index) => {
    return [
      {
        v: index + 1,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.area === null ? "" : row.area,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.pack === null ? "" : row.pack,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.phase === null ? "" : row.phase,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.sup_blk === null ? "" : row.sup_blk,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.sub_blk === null ? "" : row.sub_blk,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.blk === null ? "" : row.blk,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.lot === null ? "" : row.lot,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.subplanno === null ? "" : row.subplanno,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.tctno === null ? "" : row.tctno,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.lotarea === null ? "" : row.lotarea,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v:
          row.classvalue === "1" || row.classvalue === "5"
            ? `Commercial ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "2" || row.classvalue === "6"
            ? `Industrial ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "3" || row.classvalue === "8"
            ? `Institutional ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "4"
            ? `Foreclosed/Acquired Property ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "7"
            ? `Mixed-use ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : "",
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.pblessee === null ? "" : row.pblessee,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.pbactoccu === null ? "" : row.pbactoccu,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.pbdateawardlease === null ? "" : row.pbdateawardlease,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.pbleaserate === null ? "" : row.pbleaserate,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.pbleaseterm === null ? "" : row.pbleaseterm,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.acctstatus === null ? "" : row.acctstatus,
        t: "s",
        s: agreementsoldRowStyle,
      },
    ];
  });

  //Fetch Arrays of Array to Excel
  var ws = XLSX.utils.aoa_to_sheet(rows, { origin: "B12" });
  //REPORT TITLE
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: titleReport,
          t: "s",
          s: {
            font: { name: "Arial", sz: 12 },
            alignment: { horizontal: "center" },
          },
        },
      ],
    ],
    {
      origin: "B2",
    }
  );

  await fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.response.useraccess === "3") {
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "REGION: " + responseJson.response.reg,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B6",
          }
        );
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "DISTRICT: " + responseJson.response.dist,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B7",
          }
        );
      } else if (
        responseJson.response.useraccess === "2" ||
        responseJson.response.useraccess === "1" ||
        responseJson.response.useraccess === "4"
      ) {
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "REGION: " + allinventoryprojects[0].BP_REGION,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B6",
          }
        );
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "DISTRICT: " + allinventoryprojects[0].BP_DO_OFFICE,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B7",
          }
        );
      }
    })
    .catch((error) => {});

  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: "PROJECT NAME: " + selectedpropertyname,
          t: "s",
          s: {
            font: { name: "Arial", sz: 11 },
            alignment: { horizontal: "left" },
          },
        },
      ],
    ],
    {
      origin: "B8",
    }
  );

  const originCell = XLSX.utils.decode_cell("B12");
  const lastRowIndex = originCell.r + rows.length + 4; // Add the number of rows
  const lastColumnIndex = originCell.c;

  const endOrigin = XLSX.utils.encode_cell({
    r: lastRowIndex,
    c: lastColumnIndex,
  });
  let newOrigin = endOrigin.substring(1);
  let originLabel = newOrigin;
  let originName = parseInt(newOrigin) + 3;
  let originPosition = parseInt(newOrigin) + 4;

  //PREPARED BY PLOT
  //COLUMN C
  await fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      const prepared_By =
        responseJson.response.name +
        " " +
        responseJson.response.mname +
        " " +
        responseJson.response.lname;

      const prepared_By_Position = responseJson.response.position;

      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: "Prepared by: ",
              t: "s",
              s: {
                font: { name: "Arial", sz: 11 },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originLabel,
        }
      );
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: prepared_By,
              t: "s",
              s: {
                font: { name: "Arial", sz: 11, bold: true },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originName.toString(),
        }
      );
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: prepared_By_Position,
              t: "s",
              s: {
                font: { name: "Arial", sz: 11 },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originPosition.toString(),
        }
      );

      //COLUMN H

      //COLUMN K
    });

  // HEADER PROPERTIES
  const agreementHeaderStyle = {
    fill: { fgColor: { rgb: "efc184" } },
    alignment: { horizontal: "center" },
    font: {
      bold: true,
      name: "Arial",
      sz: 10,
      color: { rgb: "000000" },
    },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };

  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: "No.",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Area",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Package",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Phase",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Super Block",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Sub Block",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Blk No.",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Lot No.",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Subdivision Plan No.",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "TCT No. or TD",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Area (sq.m)",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Land Use Classification",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Lessee",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Occupant",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Date of Award (MOA/NOA)",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Lease per Month",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Lease Term",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Status of Account",
          t: "s",
          s: agreementHeaderStyle,
        },
      ],
    ],
    {
      origin: "B11",
    }
  );

  var merge = { s: { r: 1, c: 1 }, e: { r: 3, c: 18 } };
  /* add merges */
  if (!ws["!merges"]) ws["!merges"] = [];
  ws["!merges"].push(merge);

  // Column Width

  var wscols = [
    { wch: 5 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    { wch: 20 },
    { wch: 30 },
    { wch: 25 },
    { wch: 30 },
    { wch: 25 },
    { wch: 25 },
    { wch: 30 },
    { wch: 30 },
    { wch: 5 },
  ];
  ws["!cols"] = wscols;

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
  });

  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(
    data,
    "PROPERTIES_WITH_LEASE_AGREEMENT" + new Date().toISOString() + fileExtension
  );
};

const GetSortbyLISClassExcelD = async (
  allinventoryprojects,
  sig_preparedby,
  sig_preparedby_dg,
  sig_revieweby,
  sig_revieweby_dg,
  sig_notedby,
  sig_notedby_dg,
  selectedpropertyname,
  titleReport
) => {
  const agreementsoldRowStyle = {
    font: { name: "Arial", sz: 8 },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
    alignment: { vertical: "center", wrapText: true },
  };

  const rows = allinventoryprojects.map((row, index) => {
    return [
      {
        v: index + 1,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.area === null ? "" : row.area,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.pack === null ? "" : row.pack,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.phase === null ? "" : row.phase,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.sup_blk === null ? "" : row.sup_blk,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.sub_blk === null ? "" : row.sub_blk,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.blk === null ? "" : row.blk,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.lot === null ? "" : row.lot,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.subplanno === null ? "" : row.subplanno,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.tctno === null ? "" : row.tctno,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v: row.lotarea === null ? "" : row.lotarea,
        t: "s",
        s: agreementsoldRowStyle,
      },
      {
        v:
          row.classvalue === "1" || row.classvalue === "5"
            ? `Commercial ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "2" || row.classvalue === "6"
            ? `Industrial ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "3" || row.classvalue === "8"
            ? `Institutional ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "4"
            ? `Foreclosed/Acquired Property ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "7"
            ? `Mixed-use ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : "",
        t: "s",
        s: agreementsoldRowStyle,
      },

      {
        v: row.usufructentity === null ? "" : row.usufructentity,
        t: "s",
        s: agreementsoldRowStyle,
      },

      {
        v: row.agreementterm === null ? "" : row.agreementterm,
        t: "s",
        s: agreementsoldRowStyle,
      },
    ];
  });

  //Fetch Arrays of Array to Excel
  var ws = XLSX.utils.aoa_to_sheet(rows, { origin: "B12" });
  //REPORT TITLE
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: titleReport,
          t: "s",
          s: {
            font: { name: "Arial", sz: 12 },
            alignment: { horizontal: "center" },
          },
        },
      ],
    ],
    {
      origin: "B2",
    }
  );

  await fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.response.useraccess === "3") {
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "REGION: " + responseJson.response.reg,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B6",
          }
        );
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "DISTRICT: " + responseJson.response.dist,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B7",
          }
        );
      } else if (
        responseJson.response.useraccess === "2" ||
        responseJson.response.useraccess === "1" ||
        responseJson.response.useraccess === "4"
      ) {
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "REGION: " + allinventoryprojects[0].BP_REGION,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B6",
          }
        );
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              {
                v: "DISTRICT: " + allinventoryprojects[0].BP_DO_OFFICE,
                t: "s",
                s: {
                  font: { name: "Arial", sz: 11 },
                  alignment: { horizontal: "left" },
                },
              },
            ],
          ],
          {
            origin: "B7",
          }
        );
      }
    })
    .catch((error) => {});

  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: "PROJECT NAME: " + selectedpropertyname,
          t: "s",
          s: {
            font: { name: "Arial", sz: 11 },
            alignment: { horizontal: "left" },
          },
        },
      ],
    ],
    {
      origin: "B8",
    }
  );

  const originCell = XLSX.utils.decode_cell("B12");
  const lastRowIndex = originCell.r + rows.length + 4; // Add the number of rows
  const lastColumnIndex = originCell.c;

  const endOrigin = XLSX.utils.encode_cell({
    r: lastRowIndex,
    c: lastColumnIndex,
  });
  let newOrigin = endOrigin.substring(1);
  let originLabel = newOrigin;
  let originName = parseInt(newOrigin) + 3;
  let originPosition = parseInt(newOrigin) + 4;

  //PREPARED BY PLOT
  //COLUMN C
  await fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      const prepared_By =
        responseJson.response.name +
        " " +
        responseJson.response.mname +
        " " +
        responseJson.response.lname;

      const prepared_By_Position = responseJson.response.position;

      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: "Prepared by: ",
              t: "s",
              s: {
                font: { name: "Arial", sz: 11 },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originLabel,
        }
      );
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: prepared_By,
              t: "s",
              s: {
                font: { name: "Arial", sz: 11, b: true },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originName.toString(),
        }
      );
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: prepared_By_Position,
              t: "s",
              s: {
                font: { name: "Arial", sz: 11 },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originPosition.toString(),
        }
      );

      //COLUMN H
    });

  // HEADER PROPERTIES
  const agreementHeaderStyle = {
    fill: { fgColor: { rgb: "efc184" } },
    alignment: { horizontal: "center" },
    font: {
      bold: true,
      name: "Arial",
      sz: 10,
      color: { rgb: "000000" },
    },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };

  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: "No.",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Area",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Package",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Phase",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Super Block",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Sub Block",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Blk No.",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Lot No.",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Subdivision Plan No.",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "OCT/TCT NO.",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Area (m²)",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Land Use Classification",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Usufructuary (Name of Entity/Person)",
          t: "s",
          s: agreementHeaderStyle,
        },
        {
          v: "Term (No. of Years)",
          t: "s",
          s: agreementHeaderStyle,
        },
      ],
    ],
    {
      origin: "B11",
    }
  );

  var merge = { s: { r: 1, c: 1 }, e: { r: 3, c: 14 } };
  /* add merges */
  if (!ws["!merges"]) ws["!merges"] = [];
  ws["!merges"].push(merge);

  // Column Width

  var wscols = [
    { wch: 5 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    { wch: 20 },
    { wch: 30 },
    { wch: 35 },
    { wch: 25 },
    { wch: 5 },
  ];
  ws["!cols"] = wscols;

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(
    data,
    "PROPERTIES_WITH_AGREEMENT" + new Date().toISOString() + fileExtension
  );
};
// NATIONWIDE LISCLASS

const GetPrintNationWideLisClass = async (
  allinventoryprojects,
  sig_preparedby,
  sig_preparedby_dg,
  sig_revieweby,
  sig_revieweby_dg,
  sig_notedby,
  sig_notedby_dg,
  titleReport
) => {
  //awardStatNationWideRowStyle
  const lisClassNationWideRowStyle = {
    font: { name: "Arial", sz: 8 },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
    alignment: { vertical: "center", wrapText: true },
  };

  const rows = allinventoryprojects.map((row, index) => {
    return [
      {
        v: index + 1,
        t: "s",
        s: lisClassNationWideRowStyle,
      },

      {
        v: row.BP_REGION === null ? "" : row.BP_REGION,
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v: row.BP_DO_OFFICE === null ? "" : row.BP_DO_OFFICE,
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v: row.propertyid === null ? "" : row.propertyid,
        t: "s",
        s: lisClassNationWideRowStyle,
      },

      {
        v: row.area === null ? "" : row.area,
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v: row.pack === null ? "" : row.pack,
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v: row.phase === null ? "" : row.phase,
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v: row.sup_blk === null ? "" : row.sup_blk,
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v: row.sub_blk === null ? "" : row.sub_blk,
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v: row.blk === null ? "" : row.blk,
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v: row.lot === null ? "" : row.lot,
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v: row.subplanno === null ? "" : row.subplanno,
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v: row.tctno === null ? "" : row.tctno,
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v: row.lotarea === null ? "" : row.lotarea,
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v:
          row.classvalue === "1" || row.classvalue === "5"
            ? `Commercial ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "2" || row.classvalue === "6"
            ? `Industrial ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "3" || row.classvalue === "8"
            ? `Institutional ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "4"
            ? `Foreclosed/Acquired Property ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "7"
            ? `Mixed-use ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : "",

        t: "s",
        s: lisClassNationWideRowStyle,
      },

      {
        v:
          (row.pbawardee === null ? "" : row.pbawardee) ||
          (row.pblessee === null ? "" : row.pblessee),
        t: "s", //nobita
        s: lisClassNationWideRowStyle,
      },

      {
        v:
          (row.pbdateaward === null ? "" : row.pbdateaward) ||
          (row.pbdateawardlease === null ? "" : row.pbdateawardlease),
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v: "", //total selling price
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v:
          (row.pbselprice === null ? "" : row.pbselprice) ||
          (row.pbleaserate === null ? "" : row.pbleaserate),
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v: row.pbcontype === null ? "" : row.pbcontype,
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v: row.acctstatus === null ? "" : row.acctstatus,
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v:
          row.physicalstat === "Occupied"
            ? row.physicalstat +
              ":" +
              " " +
              (row.occupiedstat[0] ? row.occupiedstat[0] : "") +
              "," +
              (row.occupiedstat[1] ? row.occupiedstat[1] : "") +
              "," +
              (row.occupiedstat[2] ? row.occupiedstat[2] : "")
            : row.physicalstat === "Others"
            ? row.physicalstat + ":" + row.physicalstatothers
            : row.physicalstat,
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v:
          row.appstat === "2"
            ? "For Appraisal"
            : row.appstat === "1"
            ? "With appraised values: " +
              "(" +
              "Market value: " +
              row.appmarketval +
              ", " +
              "Market Rent: " +
              row.apprentval +
              ", " +
              "Valid Until: " +
              row.appvalid +
              ")"
            : "",
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v:
          row.pricingstat === "1"
            ? "With valid pricing" +
              ":" +
              " " +
              "Valid until:" +
              " " +
              row.pricevalid
            : row.pricingstat === "2"
            ? "Expired pricing"
            : "",
        t: "s",
        s: lisClassNationWideRowStyle,
      },
      {
        v: "", //remarks
        t: "s",
        s: lisClassNationWideRowStyle,
      },
    ];
  });

  //Fetch Arrays of Array to Excel
  var ws = XLSX.utils.aoa_to_sheet(rows, { origin: "B9" });
  //REPORT TITLE
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: titleReport,
          t: "s",
          s: {
            font: { name: "Arial", sz: 12 },
            alignment: { horizontal: "center" },
          },
        },
      ],
    ],
    {
      origin: "B2",
    }
  );

  await fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.response.useraccess === "3") {
        //   XLSX.utils.sheet_add_aoa(
        //     ws,
        //     [
        //       [
        //         {
        //           v: "REGION: " + responseJson.response.reg,
        //           t: "s",
        //           s: {
        //             font: { name: "Arial", sz: 11 },
        //             alignment: { horizontal: "left" },
        //           },
        //         },
        //       ],
        //     ],
        //     {
        //       origin: "B6",
        //     }
        //   );
        //   XLSX.utils.sheet_add_aoa(
        //     ws,
        //     [
        //       [
        //         {
        //           v: "DISTRICT: " + responseJson.response.dist,
        //           t: "s",
        //           s: {
        //             font: { name: "Arial", sz: 11 },
        //             alignment: { horizontal: "left" },
        //           },
        //         },
        //       ],
        //     ],
        //     {
        //       origin: "B7",
        //     }
        //   );
      } else if (
        responseJson.response.useraccess === "2" ||
        responseJson.response.useraccess === "1" ||
        responseJson.response.useraccess === "4"
      ) {
        // XLSX.utils.sheet_add_aoa(
        //   ws,
        //   [
        //     [
        //       {
        //         v: "REGION: " + allinventoryprojects[0].BP_REGION,
        //         t: "s",
        //         s: {
        //           font: { name: "Arial", sz: 11 },
        //           alignment: { horizontal: "left" },
        //         },
        //       },
        //     ],
        //   ],
        //   {
        //     origin: "B6",
        //   }
        // );
        // XLSX.utils.sheet_add_aoa(
        //   ws,
        //   [
        //     [
        //       {
        //         v: "DISTRICT: " + allinventoryprojects[0].BP_DO_OFFICE,
        //         t: "s",
        //         s: {
        //           font: { name: "Arial", sz: 11 },
        //           alignment: { horizontal: "left" },
        //         },
        //       },
        //     ],
        //   ],
        //   {
        //     origin: "B7",
        //   }
        // );
      }
    })
    .catch((error) => {});

  // XLSX.utils.sheet_add_aoa(
  //   ws,
  //   [
  //     [
  //       {
  //         v: "PROJECT NAME: " + selectedpropertyname,
  //         t: "s",
  //         s: {
  //           font: { name: "Arial", sz: 11 },
  //           alignment: { horizontal: "left" },
  //         },
  //       },
  //     ],
  //   ],
  //   {
  //     origin: "B8",
  //   }
  // );

  const originCell = XLSX.utils.decode_cell("B12");
  const lastRowIndex = originCell.r + rows.length + 4; // Add the number of rows
  const lastColumnIndex = originCell.c;

  const endOrigin = XLSX.utils.encode_cell({
    r: lastRowIndex,
    c: lastColumnIndex,
  });
  let newOrigin = endOrigin.substring(1);
  let originLabel = newOrigin;
  let originName = parseInt(newOrigin) + 3;
  let originPosition = parseInt(newOrigin) + 4;

  //PREPARED BY PLOT
  //COLUMN C
  await fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      const prepared_By =
        responseJson.response.name +
        " " +
        responseJson.response.mname +
        " " +
        responseJson.response.lname;

      const prepared_By_Position = responseJson.response.position;

      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: "Prepared by: ",
              t: "s",
              s: {
                font: { name: "Arial", sz: 11 },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originLabel,
        }
      );
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: prepared_By,
              t: "s",
              s: {
                font: { name: "Arial", sz: 11, b: true },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originName.toString(),
        }
      );
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: prepared_By_Position,
              t: "s",
              s: {
                font: { name: "Arial", sz: 11 },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originPosition.toString(),
        }
      );

      //COLUMN H
    });

  // HEADER PROPERTIES
  //awardStatNationWideRowStyle
  const lisClassNationWideHeaderStyle = {
    fill: { fgColor: { rgb: "efc184" } },
    alignment: { horizontal: "center" },
    font: {
      bold: true,
      name: "Arial",
      sz: 10,
      color: { rgb: "000000" },
    },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };

  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: "No.",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },

        {
          v: "REGION",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },

        {
          v: "DISTRICT",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },

        {
          v: "Project Name/Location",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },

        {
          v: "Area",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Package",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Phase",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Super Block",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Sub Block",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Blk No.",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Lot No.",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Subdivision Plan No.",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "TCT/OCT NO.",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Lot Area (m²)",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Land Use Classification",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Name of Awardee/Lessee",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Date of Award (MOA/NOA)",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Total Selling Price",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Selling Price per m²/Lease Rate per Mo. per m²",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Contract Type)",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Account Status",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Occupancy Status",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Appraisal Status",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Pricing Status",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
        {
          v: "Remarks",
          t: "s",
          s: lisClassNationWideHeaderStyle,
        },
      ],
    ],
    {
      origin: "B8",
    }
  );

  var merge = { s: { r: 1, c: 1 }, e: { r: 3, c: 25 } };
  /* add merges */
  if (!ws["!merges"]) ws["!merges"] = [];
  ws["!merges"].push(merge);

  // Column Width

  var wscols = [
    { wch: 5 },
    { wch: 10 }, //no
    { wch: 25 }, //reg
    { wch: 25 }, //dist
    { wch: 25 }, //proj name
    { wch: 10 }, //area
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 }, //lot no
    { wch: 25 }, //sublplan
    { wch: 25 },
    { wch: 20 },
    { wch: 30 },
    { wch: 35 }, //name awardee
    { wch: 30 }, //date award
    { wch: 25 }, //total selling
    { wch: 40 }, //selling price
    { wch: 25 }, //con type
    { wch: 25 }, //acct status
    { wch: 25 }, //occ status
    { wch: 40 }, //app status
    { wch: 35 }, //acct status
    { wch: 25 }, //remarks
    { wch: 5 },
  ];
  ws["!cols"] = wscols;

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(
    data,
    "ESTATE_NONESTATE_GENERAL" + new Date().toISOString() + fileExtension
  );
};

// NATIONWIDE AWARD STATUS
//GetSortbyLISClassExcelAllNonestate
const GetPrintNationWideAwardStatus = async (
  allinventoryprojects,
  sig_preparedby,
  sig_preparedby_dg,
  sig_revieweby,
  sig_revieweby_dg,
  sig_notedby,
  sig_notedby_dg,
  titleReport
) => {
  //awardStatNationWideRowStyle
  const awardStatNationWideRowStyle = {
    font: { name: "Arial", sz: 8 },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
    alignment: { vertical: "center", wrapText: true },
  };

  const rows = allinventoryprojects.map((row, index) => {
    return [
      {
        v: index + 1,
        t: "s",
        s: awardStatNationWideRowStyle,
      },

      {
        v: row.BP_REGION === null ? "" : row.BP_REGION,
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v: row.BP_DO_OFFICE === null ? "" : row.BP_DO_OFFICE,
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v: row.propertyid === null ? "" : row.propertyid,
        t: "s",
        s: awardStatNationWideRowStyle,
      },

      {
        v: row.area === null ? "" : row.area,
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v: row.pack === null ? "" : row.pack,
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v: row.phase === null ? "" : row.phase,
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v: row.sup_blk === null ? "" : row.sup_blk,
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v: row.sub_blk === null ? "" : row.sub_blk,
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v: row.blk === null ? "" : row.blk,
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v: row.lot === null ? "" : row.lot,
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v: row.subplanno === null ? "" : row.subplanno,
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v: row.tctno === null ? "" : row.tctno,
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v: row.lotarea === null ? "" : row.lotarea,
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v:
          row.classvalue === "1" || row.classvalue === "5"
            ? `Commercial ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "2" || row.classvalue === "6"
            ? `Industrial ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "3" || row.classvalue === "8"
            ? `Institutional ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "4"
            ? `Foreclosed/Acquired Property ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : row.classvalue === "7"
            ? `Mixed-use ${
                row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
              }`
            : "",

        t: "s",
        s: awardStatNationWideRowStyle,
      },

      {
        v:
          (row.pbawardee === null ? "" : row.pbawardee) ||
          (row.pblessee === null ? "" : row.pblessee),
        t: "s",
        s: awardStatNationWideRowStyle,
      },

      {
        v:
          (row.pbdateaward === null ? "" : row.pbdateaward) ||
          (row.pbdateawardlease === null ? "" : row.pbdateawardlease),
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v: "", //total selling price
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v:
          (row.pbselprice === null ? "" : row.pbselprice) ||
          (row.pbleaserate === null ? "" : row.pbleaserate),
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v: row.pbcontype === null ? "" : row.pbcontype,
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v: row.acctstatus === null ? "" : row.acctstatus,
        t: "s",
        s: awardStatNationWideRowStyle,
      },
      {
        v: "",
        t: "s",
        s: awardStatNationWideRowStyle,
      },
    ];
  });

  //Fetch Arrays of Array to Excel
  var ws = XLSX.utils.aoa_to_sheet(rows, { origin: "B9" });
  //REPORT TITLE
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: titleReport,
          t: "s",
          s: {
            font: { name: "Arial", sz: 12 },
            alignment: { horizontal: "center" },
          },
        },
      ],
    ],
    {
      origin: "B2",
    }
  );

  await fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.response.useraccess === "3") {
        //   XLSX.utils.sheet_add_aoa(
        //     ws,
        //     [
        //       [
        //         {
        //           v: "REGION: " + responseJson.response.reg,
        //           t: "s",
        //           s: {
        //             font: { name: "Arial", sz: 11 },
        //             alignment: { horizontal: "left" },
        //           },
        //         },
        //       ],
        //     ],
        //     {
        //       origin: "B6",
        //     }
        //   );
        //   XLSX.utils.sheet_add_aoa(
        //     ws,
        //     [
        //       [
        //         {
        //           v: "DISTRICT: " + responseJson.response.dist,
        //           t: "s",
        //           s: {
        //             font: { name: "Arial", sz: 11 },
        //             alignment: { horizontal: "left" },
        //           },
        //         },
        //       ],
        //     ],
        //     {
        //       origin: "B7",
        //     }
        //   );
      } else if (
        responseJson.response.useraccess === "2" ||
        responseJson.response.useraccess === "1" ||
        responseJson.response.useraccess === "4"
      ) {
        // XLSX.utils.sheet_add_aoa(
        //   ws,
        //   [
        //     [
        //       {
        //         v: "REGION: " + allinventoryprojects[0].BP_REGION,
        //         t: "s",
        //         s: {
        //           font: { name: "Arial", sz: 11 },
        //           alignment: { horizontal: "left" },
        //         },
        //       },
        //     ],
        //   ],
        //   {
        //     origin: "B6",
        //   }
        // );
        // XLSX.utils.sheet_add_aoa(
        //   ws,
        //   [
        //     [
        //       {
        //         v: "DISTRICT: " + allinventoryprojects[0].BP_DO_OFFICE,
        //         t: "s",
        //         s: {
        //           font: { name: "Arial", sz: 11 },
        //           alignment: { horizontal: "left" },
        //         },
        //       },
        //     ],
        //   ],
        //   {
        //     origin: "B7",
        //   }
        // );
      }
    })
    .catch((error) => {});

  // XLSX.utils.sheet_add_aoa(
  //   ws,
  //   [
  //     [
  //       {
  //         v: "PROJECT NAME: " + selectedpropertyname,
  //         t: "s",
  //         s: {
  //           font: { name: "Arial", sz: 11 },
  //           alignment: { horizontal: "left" },
  //         },
  //       },
  //     ],
  //   ],
  //   {
  //     origin: "B8",
  //   }
  // );

  const originCell = XLSX.utils.decode_cell("B12");
  const lastRowIndex = originCell.r + rows.length + 4; // Add the number of rows
  const lastColumnIndex = originCell.c;

  const endOrigin = XLSX.utils.encode_cell({
    r: lastRowIndex,
    c: lastColumnIndex,
  });
  let newOrigin = endOrigin.substring(1);
  let originLabel = newOrigin;
  let originName = parseInt(newOrigin) + 3;
  let originPosition = parseInt(newOrigin) + 4;

  //PREPARED BY PLOT
  //COLUMN C
  await fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
    credentials: "include",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      const prepared_By =
        responseJson.response.name +
        " " +
        responseJson.response.mname +
        " " +
        responseJson.response.lname;

      const prepared_By_Position = responseJson.response.position;

      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: "Prepared by: ",
              t: "s",
              s: {
                font: { name: "Arial", sz: 11 },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originLabel,
        }
      );
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: prepared_By,
              t: "s",
              s: {
                font: { name: "Arial", sz: 11, b: true },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originName.toString(),
        }
      );
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            {
              v: prepared_By_Position,
              t: "s",
              s: {
                font: { name: "Arial", sz: 11 },
                alignment: { horizontal: "left" },
              },
            },
          ],
        ],
        {
          origin: "B" + originPosition.toString(),
        }
      );

      //COLUMN H
    });

  // HEADER PROPERTIES
  //awardStatNationWideRowStyle
  const awardStatNationWideHeaderStyle = {
    fill: { fgColor: { rgb: "efc184" } },
    alignment: { horizontal: "center" },
    font: {
      bold: true,
      name: "Arial",
      sz: 10,
      color: { rgb: "000000" },
    },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };

  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        {
          v: "No.",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },

        {
          v: "REGION",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },

        {
          v: "DISTRICT",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },

        {
          v: "Project Name/Location",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },

        {
          v: "Area",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Package",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Phase",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Super Block",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Sub Block",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Blk No.",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Lot No.",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Subdivision Plan No.",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "TCT/OCT NO.",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Lot Area (m2)",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Land Use Classification",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Name of Awardee/Lessee",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Date of Award (MOA/NOA)",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Total Selling Price",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Selling Price per m²/Lease Rate per Mo. per m²",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Contract Type)",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Account Status",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
        {
          v: "Remarks",
          t: "s",
          s: awardStatNationWideHeaderStyle,
        },
      ],
    ],
    {
      origin: "B8",
    }
  );

  var merge = { s: { r: 1, c: 1 }, e: { r: 3, c: 22 } };
  /* add merges */
  if (!ws["!merges"]) ws["!merges"] = [];
  ws["!merges"].push(merge);

  // Column Width

  var wscols = [
    { wch: 5 },
    { wch: 10 }, //no
    { wch: 25 }, //reg
    { wch: 25 }, //dist
    { wch: 25 }, //proj name
    { wch: 10 }, //area
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 }, //lot no
    { wch: 25 }, //sublplan
    { wch: 25 },
    { wch: 20 },
    { wch: 30 },
    { wch: 35 }, //name awardee
    { wch: 25 }, //date award
    { wch: 25 }, //total selling
    { wch: 35 }, //selling price
    { wch: 25 }, //con type
    { wch: 25 }, //acct status
    { wch: 25 }, //remarks
    { wch: 5 },
  ];
  ws["!cols"] = wscols;

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(
    data,
    "SOLD_UNSOLD_GENERAL" + new Date().toISOString() + fileExtension
  );
};

export {
  GetSortbyLISClassExcel,
  GetSortbyLISClassExcelB,
  GetSortbyLISClassExcelC,
  GetSortbyLISClassExcelD,
  GetPrintNationWideLisClass,
  GetPrintNationWideAwardStatus,
};
