const AddSignatories = async (
  preparedby,
  preparedby_dg,
  reviewedby,
  reviewedby_dg,
  notedby,
  notedby_dg
) => {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  try {
    let res = await fetch("https://api.nha.gov.ph/cied/addsignatories", {
      mode: "cors",
      method: "POST",
      headers: headers,
      credentials: "include",
      body: JSON.stringify({
        Preparedby: preparedby,
        Preparedby_dg: preparedby_dg,
        Reviewedby: reviewedby,
        Reviewedby_dg: reviewedby_dg,
        Notedby: notedby,
        Notedby_dg: notedby_dg,
      }),
    });
    if (res.status === 200) {
      return "0000";
    } else {
      return "1111";
    }
  } catch (err) {
    console.log(err);
  }
};

const GetSignatories = async (regionId) => {
  let GetSignatories;
  GetSignatories = await fetch("https://api.nha.gov.ph/cied/fetchsignatories", {
    credentials: "include",
  }).then((res) => res.json());
  return GetSignatories;
};

const UpdateSignatories = async (
  signatoryid,
  preparedby,
  preparedby_dg,
  reviewedby,
  reviewedby_dg,
  notedby,
  notedby_dg
) => {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  try {
    let res = await fetch("https://api.nha.gov.ph/cied/updateusignatories", {
      mode: "cors",
      method: "PUT",
      headers: headers,
      credentials: "include",
      body: JSON.stringify({
        Signatoryid: signatoryid,
        Preparedby: preparedby,
        Preparedby_dg: preparedby_dg,
        Reviewedby: reviewedby,
        Reviewedby_dg: reviewedby_dg,
        Notedby: notedby,
        Notedby_dg: notedby_dg,
      }),
    });
    if (res.status === 200) {
      return "0000";
    } else {
      return "1111";
    }
  } catch (err) {
    console.log(err);
  }
};
export { AddSignatories, GetSignatories, UpdateSignatories };
