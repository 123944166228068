import React from "react";

function PaginationInv({ currentPage, totalPages, onPageChange }) {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div>
      <ul className="pagination pagination-sm m-0 float-left">
        {pageNumbers.map((page, index) => (
          <li key={index} className="page-item page-item-primary">
            <a
              className={"page-link"}
              href={"#"}
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                onPageChange(page);
              }}
            >
              {page}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PaginationInv;
