import jsPDF from "jspdf";
import image_base64 from "./image_base64";
import { useState } from "react";
const currentDate = new Date();

const day = currentDate.getDate();
const monthIndex = currentDate.getMonth();
const year = currentDate.getFullYear();

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const month = months[monthIndex];

const formattedDate = `${month} ${day}, ${year}`;

const pdf_report_A = async (
  allinventoryprojects,
  lisclass,
  awardstatus,
  accesslevel,
  printing,
  selectedpropertyname,
  lisclassadmin,
  awardstatadmin,
  lotclassadmin,
  lotclassification
) => {
  console.log(allinventoryprojects);
  const response = await fetch("https://api.nha.gov.ph/cied/checkaccesslevel", {
    mode: "cors",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  });

  const responseJson_data = await response.json();
  const responseResult = responseJson_data.response;
  // const useaccess = responseResult.useraccess;

  var doc = new jsPDF("l", "pt", "legal");
  const pageSize_header = doc.internal.pageSize;
  const pageWidth_header = pageSize_header.width
    ? pageSize_header.width
    : pageSize_header.getWidth();

  doc.addImage(image_base64, "PNG", 300, 10);
  doc.setFontSize(12);

  let title = "";
  let account_description = "";
  let account_description_2 = "";

  if (
    (lisclass === "Estate" || lisclassadmin.includes("Estate")) &&
    (awardstatus === "Unsold/Undisposed" ||
      awardstatadmin.includes("Unsold/Undisposed")) &&
    printing === "printingA"
  ) {
    title = `INVENTORY OF UNSOLD/UNDISPOSED ESTATE BASED`;
    account_description = `COMMERCIAL, INSTITUTIONAL, INDUSTRIAL, AND INSTITUTIONAL LOTS/PROPERTIES `;
  } else if (
    (lisclass === "Non-estate" || lisclassadmin.includes("Non-estate")) &&
    (awardstatus === "Unsold/Undisposed" ||
      awardstatadmin.includes("Unsold/Undisposed")) &&
    printing === "printingA"
  ) {
    title = `INVENTORY OF UNSOLD/UNDISPOSED NON-ESTATE BASED`;
    account_description = `COMMERCIAL, INSTITUTIONAL, INDUSTRIAL, INSTITUTIONAL AND ACQUIRED/FORECLOSED LOTS/PROPERTIES `;
  } else if (
    (lisclass === "Non-estate" || lisclassadmin.includes("Non-estate")) &&
    (awardstatus === "Sold/Disposed" ||
      awardstatadmin.includes("Sold/Disposed")) &&
    printing === "printingA"
  ) {
    title = `INVENTORY OF SOLD/DISPOSED NON-ESTATE BASED`;
    account_description = `COMMERCIAL, INDUSTRIAL, INSTITUTIONAL AND ACQUIRED/FORECLOSED LOTS/PROPERTIES `;
  } else if (
    (lisclass === "Estate" || lisclassadmin.includes("Estate")) &&
    (awardstatus === "Sold/Disposed" ||
      awardstatadmin.includes("Sold/Disposed")) &&
    printing === "printingA"
  ) {
    title = `INVENTORY OF SOLD/DISPOSED ESTATE BASED`;
    account_description = `COMMERCIAL, INDUSTRIAL, INSTITUTIONAL LOTS/PROPERTIES `;
  } else if (
    (lisclass === "Non-estate" || lisclassadmin.includes("Non-estate")) &&
    (awardstatus === "Sold/Disposed" ||
      awardstatadmin.includes("Sold/Disposed")) &&
    printing === "printingC"
  ) {
    title = `INVENTORY OF NON-ESTATE BASED`;
    account_description = `COMMERCIAL, INDUSTRIAL, INSTITUTIONAL AND ACQUIRED/FORECLOSED LOTS/PROPERTIES WITH LEASE AGREEMENTS `;
  } else if (
    (lisclass === "Estate" || lisclassadmin.includes("Estate")) &&
    (awardstatus === "Sold/Disposed" ||
      awardstatadmin.includes("Sold/Disposed")) &&
    printing === "printingC"
  ) {
    title = `INVENTORY OF ESTATE BASED`;
    account_description = `COMMERCIAL, INDUSTRIAL, AND INSTITUTIONAL LOTS/PROPERTIES WITH LEASE AGREEMENTS `;
  } else if (printing === "printingB") {
    title = `INVENTORY OF ESTATE AND NON-ESTATE BASED`;
    account_description = `COMMERCIAL, INDUSTRIAL AND INSTITUTIONAL LOTS/PROPERTIES WITH AGREEMENT`;
    account_description_2 = `(Usufruct, Deed of Donation, Memorandum of Agreement, Memorandum of Understanding, Etc.) `;
  } else if (
    (printing === "printnationwideA" &&
      (lisclass === "Estate" ||
        lisclass === "Non-estate" ||
        lisclassadmin.length > 0)) ||
    lotclassadmin.length > 0 ||
    lotclassification ||
    printing === "printnationwideC"
  ) {
    title = `INVENTORY OF UNSOLD/UNDISPOSED AND SOLD/DISPOSED ESTATE AND NON-ESTATE BASED`;
    account_description = `COMMERCIAL, INSTITUTIONAL, INDUSTRIAL AND ACQUIRED/FORECLOSED PROPERTIES `;
  } else if (
    printing === "printnationwideB" &&
    (awardstatus === "Sold/Disposed" ||
      awardstatadmin.includes("Sold/Disposed"))
  ) {
    title = `INVENTORY OF SOLD/DISPOSED ESTATE BASED AND NON-ESTATE BASED`;
    account_description = `COMMERCIAL, INSTITUTIONAL, INDUSTRIAL AND ACQUIRED/FORECLOSED PROPERTIES `;
  } else if (
    printing === "printnationwideB" &&
    (awardstatus === "Unsold/Undisposed" ||
      awardstatadmin.includes("Unsold/Undisposed"))
  ) {
    title = `INVENTORY OF UNSOLD/UNDISPOSED ESTATE BASED AND NON-ESTATE BASED`;
    account_description = `COMMERCIAL, INSTITUTIONAL, INDUSTRIAL AND ACQUIRED/FORECLOSED PROPERTIES `;
  }

  const as_of_date = `AS OF ${formattedDate}`;
  let region = "";
  let district = "";
  if (accesslevel === "1" || accesslevel === "2" || accesslevel === "4") {
    region = allinventoryprojects[0].BP_REGION;
    district = allinventoryprojects[0].BP_DO_OFFICE;
  } else if (accesslevel === "3") {
    region = responseResult.reg;
    district = responseResult.dist;
  }

  doc.text(title, pageWidth_header / 2 - doc.getTextWidth(title) / 2, 80, {
    baseline: "top",
  });

  if (printing === "printingB") {
    doc.text(title, pageWidth_header / 2 - doc.getTextWidth(title) / 2, 80, {
      baseline: "top",
    });
    doc.text(
      account_description,
      pageWidth_header / 2 - doc.getTextWidth(account_description) / 2,
      95,
      { baseline: "top" }
    );
    doc.text(
      account_description_2,
      pageWidth_header / 2 - doc.getTextWidth(account_description_2) / 2,
      110,
      { baseline: "top" }
    );
    doc.text(
      as_of_date,
      pageWidth_header / 2 - doc.getTextWidth(as_of_date) / 2,
      125,
      { baseline: "top" }
    );
  } else {
    doc.text(title, pageWidth_header / 2 - doc.getTextWidth(title) / 2, 80, {
      baseline: "top",
    });
    doc.text(
      account_description,
      pageWidth_header / 2 - doc.getTextWidth(account_description) / 2,
      95,
      { baseline: "top" }
    );
    doc.text(
      as_of_date,
      pageWidth_header / 2 - doc.getTextWidth(as_of_date) / 2,
      110,
      { baseline: "top" }
    );
  }

  if (
    printing === "printnationwideA" ||
    printing === "printnationwideB" ||
    printing === "printnationwideC"
  ) {
    doc.text("", 15, 140, { baseline: "top" });
    doc.text("", 15, 155, { baseline: "top" });
    doc.text("", 15, 170, {
      baseline: "top",
    });
  } else if (printing === "printingA") {
    doc.text(`REGION: ${region}`, 15, 140, { baseline: "top" });
    doc.text(`DISTRICT OFFICE: ${district}`, 15, 155, { baseline: "top" });
    doc.text(`PROJECT NAME: ${selectedpropertyname}`, 15, 170, {
      baseline: "top",
    });
  } else if (printing === "printingB") {
    doc.text(`REGION: ${region}`, 15, 140, { baseline: "top" });
    doc.text(`DISTRICT OFFICE: ${district}`, 15, 155, { baseline: "top" });
    doc.text(`PROJECT NAME: ${selectedpropertyname}`, 15, 170, {
      baseline: "top",
    });
  } else if (printing === "printingC") {
    doc.text(`REGION: ${region}`, 15, 140, { baseline: "top" });
    doc.text(`DISTRICT OFFICE: ${district}`, 15, 155, { baseline: "top" });
    doc.text(`PROJECT NAME: ${selectedpropertyname}`, 15, 170, {
      baseline: "top",
    });
  }

  const rows = allinventoryprojects.map((row, index) => {
    if (
      ((lisclass === "Estate" || lisclassadmin.includes("Estate")) &&
        (awardstatus === "Unsold/Undisposed" ||
          awardstatadmin.includes("Unsold/Undisposed")) &&
        printing === "printingA") ||
      ((lisclass === "Non-estate" || lisclassadmin.includes("Non-estate")) &&
        (awardstatus === "Unsold/Undisposed" ||
          awardstatadmin.includes("Unsold/Undisposed")) &&
        printing === "printingA")
    ) {
      return [
        index + 1,
        row.area,
        row.pack,
        row.phase,
        row.sup_blk,
        row.sub_blk,
        row.blk,
        row.lot,
        row.subplanno,
        row.tctno,
        row.lotarea,
        row.classvalue === "1" || row.classvalue === "5"
          ? `Commercial ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "2" || row.classvalue === "6"
          ? `Industrial ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "3" || row.classvalue === "8"
          ? `Institutional ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "4"
          ? `Foreclosed/Acquired Property ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "7"
          ? `Mixed-use ${row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""}`
          : "",

        row.physicalstat === "Occupied"
          ? row.physicalstat +
            ":" +
            " " +
            (row.occupiedstat[0] ? row.occupiedstat[0] : "") +
            "," +
            (row.occupiedstat[1] ? row.occupiedstat[1] : "") +
            "," +
            (row.occupiedstat[2] ? row.occupiedstat[2] : "")
          : row.physicalstat === "Others"
          ? row.physicalstat + ":" + row.physicalstatothers
          : row.physicalstat,
        row.appstat === "2"
          ? "For appraisal "
          : row.appstat === "1"
          ? "With appraised values: " +
            "(" +
            "Market value: " +
            row.appmarketval +
            ", " +
            "Market Rent: " +
            row.apprentval +
            ", " +
            "Valid Until: " +
            row.appvalid +
            ")"
          : "",
        row.pricingstat === "1"
          ? "With valid pricing" +
            ":" +
            " " +
            "Valid until:" +
            " " +
            row.pricevalid
          : row.pricingstat === "2"
          ? "Expired pricing"
          : "",
      ];
    } else if (
      ((lisclass === "Non-estate" || lisclassadmin.includes("Non-estate")) &&
        (awardstatus === "Sold/Disposed" ||
          awardstatadmin.includes("Sold/Disposed")) &&
        printing === "printingA") ||
      ((lisclass === "Estate" || lisclassadmin.includes("Estate")) &&
        (awardstatus === "Sold/Disposed" ||
          awardstatadmin.includes("Sold/Disposed")) &&
        printing === "printingA")
    ) {
      return [
        index + 1,
        row.area,
        row.pack,
        row.phase,
        row.sup_blk,
        row.sub_blk,
        row.blk,
        row.lot,
        row.subplanno,
        row.tctno,
        row.lotarea,
        row.classvalue === "1" || row.classvalue === "5"
          ? `Commercial ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "2" || row.classvalue === "6"
          ? `Industrial ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "3" || row.classvalue === "8"
          ? `Institutional ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "4"
          ? `Foreclosed/Acquired Property ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "7"
          ? `Mixed-use ${row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""}`
          : "",
        row.pbawardee,
        row.pbdateaward,
        "", // total selling price
        row.pbselprice,
        row.pbcontype,
        row.acctstatus,
        "", //remarks
      ];
    } else if (
      ((lisclass === "Non-estate" || lisclassadmin.includes("Non-estate")) &&
        (awardstatus === "Sold/Disposed" ||
          awardstatadmin.includes("Sold/Disposed")) &&
        printing === "printingC") ||
      ((lisclass === "Estate" || lisclassadmin.includes("Estate")) &&
        (awardstatus === "Sold/Disposed" ||
          awardstatadmin.includes("Sold/Disposed")) &&
        printing === "printingC")
    ) {
      return [
        index + 1,
        row.area,
        row.pack,
        row.phase,
        row.sup_blk,
        row.sub_blk,
        row.blk,
        row.lot,
        row.subplanno,
        row.tctno,
        row.lotarea,
        row.classvalue === "1" || row.classvalue === "5"
          ? `Commercial ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "2" || row.classvalue === "6"
          ? `Industrial ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "3" || row.classvalue === "8"
          ? `Institutional ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "4"
          ? `Foreclosed/Acquired Property ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "7"
          ? `Mixed-use ${row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""}`
          : "",
        row.pblessee,
        row.pbactoccu,
        row.pbdateawardlease,
        row.pbleaserate,
        row.pbleaseterm,
        row.acctstatus,
      ];
    } else if (printing === "printingB") {
      return [
        index + 1,
        row.area,
        row.pack,
        row.phase,
        row.sup_blk,
        row.sub_blk,
        row.blk,
        row.lot,
        row.subplanno,
        row.tctno,
        row.lotarea,
        row.classvalue === "1" || row.classvalue === "5"
          ? `Commercial ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "2" || row.classvalue === "6"
          ? `Industrial ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "3" || row.classvalue === "8"
          ? `Institutional ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "4"
          ? `Foreclosed/Acquired Property ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "7"
          ? `Mixed-use ${row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""}`
          : "",
        row.usufructentity,
        row.agreementterm,
      ];
    }

    //nationwide lisclass
    else if (
      printing === "printnationwideA" ||
      printing === "printnationwideC"
    ) {
      return [
        index + 1,
        row.BP_REGION,
        row.BP_DO_OFFICE,
        row.propertyid,
        row.area,
        row.pack,
        row.phase,
        row.sup_blk,
        row.sub_blk,
        row.blk,
        row.lot,
        row.subplanno,
        row.tctno,
        row.lotarea,
        row.classvalue === "1" || row.classvalue === "5"
          ? `Commercial ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "2" || row.classvalue === "6"
          ? `Industrial ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "3" || row.classvalue === "8"
          ? `Institutional ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "4"
          ? `Foreclosed/Acquired Property ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "7"
          ? `Mixed-use ${row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""}`
          : "",
        (row.pbawardee === null ? "" : row.pbawardee) ||
          (row.pblessee === null ? "" : row.pblessee),
        (row.pbdateaward === null ? "" : row.pbdateaward) ||
          (row.pbdateawardlease === null ? "" : row.pbdateawardlease),
        "", // total selling price
        (row.pbselprice === null ? "" : row.pbselprice) ||
          (row.pbleaserate === null ? "" : row.pbleaserate),
        row.pbcontype,
        row.acctstatus,
        row.physicalstat === "Occupied"
          ? row.physicalstat +
            ":" +
            " " +
            (row.occupiedstat[0] ? row.occupiedstat[0] : "") +
            "," +
            (row.occupiedstat[1] ? row.occupiedstat[1] : "") +
            "," +
            (row.occupiedstat[2] ? row.occupiedstat[2] : "")
          : row.physicalstat === "Others"
          ? row.physicalstat + ":" + row.physicalstatothers
          : row.physicalstat,
        row.appstat === "2"
          ? "For Appraisal"
          : row.appstat === "1"
          ? "With appraised values: " +
            "(" +
            "Market value: " +
            row.appmarketval +
            ", " +
            "Market Rent: " +
            row.apprentval +
            ", " +
            "Valid Until: " +
            row.appvalid +
            ")"
          : "",
        row.pricingstat === "1"
          ? "With valid pricing" +
            ":" +
            " " +
            "Valid until:" +
            " " +
            row.pricevalid
          : row.pricingstat === "2"
          ? "Expired pricing"
          : "",

        "", // remarks
      ];
    }
    //nationwide awardstat
    else if (printing === "printnationwideB") {
      return [
        index + 1,
        row.BP_REGION,
        row.BP_DO_OFFICE,
        row.propertyid,
        row.area,
        row.pack,
        row.phase,
        row.sup_blk,
        row.sub_blk,
        row.blk,
        row.lot,
        row.subplanno,
        row.tctno,
        row.lotarea,
        row.classvalue === "1" || row.classvalue === "5"
          ? `Commercial ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "2" || row.classvalue === "6"
          ? `Industrial ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "3" || row.classvalue === "8"
          ? `Institutional ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "4"
          ? `Foreclosed/Acquired Property ${
              row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""
            }`
          : row.classvalue === "7"
          ? `Mixed-use ${row.foreclosed_prop ? `(${row.foreclosed_prop})` : ""}`
          : "",
        (row.pbawardee === null ? "" : row.pbawardee) ||
          (row.pblessee === null ? "" : row.pblessee),
        (row.pbdateaward === null ? "" : row.pbdateaward) ||
          (row.pbdateawardlease === null ? "" : row.pbdateawardlease),
        "", //totalselling
        (row.pbselprice === null ? "" : row.pbselprice) ||
          (row.pbleaserate === null ? "" : row.pbleaserate),
        row.pbcontype,
        row.acctstatus,
        "",
      ];
    }
    //nationwide lisclas,awardstat
  });

  const header =
    ((lisclass === "Estate" || lisclassadmin.includes("Estate")) &&
      (awardstatus === "Unsold/Undisposed" ||
        awardstatadmin.includes("Unsold/Undisposed")) &&
      printing === "printingA") ||
    ((lisclass === "Non-estate" || lisclassadmin.includes("Non-estate")) &&
      (awardstatus === "Unsold/Undisposed" ||
        awardstatadmin.includes("Unsold/Undisposed")) &&
      printing === "printingA")
      ? [
          [
            { content: "No.", styles: { halign: "center" } },
            { content: "Area", styles: { halign: "center" } },
            { content: "Package", styles: { halign: "center" } },
            { content: "Phase", styles: { halign: "center" } },
            { content: "Super Block", styles: { halign: "center" } },
            { content: "Sub Block", styles: { halign: "center" } },
            { content: "Block No.", styles: { halign: "center" } },
            { content: "Lot No.", styles: { halign: "center" } },
            { content: "Subdivision Plan No.", styles: { halign: "center" } },
            { content: "OCT/TCT No.", styles: { halign: "center" } },
            { content: "Area (m2)", styles: { halign: "center" } },
            {
              content: "Land Use Classification",
              styles: { halign: "center" },
            },
            { content: "Occupancy Status", styles: { halign: "center" } },
            { content: "Appraisal Status", styles: { halign: "center" } },
            { content: "Pricing Status", styles: { halign: "center" } },
          ],
        ]
      : ((lisclass === "Non-estate" || lisclassadmin.includes("Non-estate")) &&
          (awardstatus === "Sold/Disposed") |
            awardstatadmin.includes("Sold/Disposed") &&
          printing === "printingA") ||
        ((lisclass === "Estate" || lisclassadmin.includes("Estate")) &&
          (awardstatus === "Sold/Disposed" ||
            awardstatadmin.includes("Sold/Disposed")) &&
          printing === "printingA")
      ? [
          [
            { content: "No.", styles: { halign: "center" } },
            { content: "Area", styles: { halign: "center" } },
            { content: "Package", styles: { halign: "center" } },
            { content: "Phase", styles: { halign: "center" } },
            { content: "Super Block", styles: { halign: "center" } },
            { content: "Sub Block", styles: { halign: "center" } },
            { content: "Block No.", styles: { halign: "center" } },
            { content: "Lot No.", styles: { halign: "center" } },
            { content: "Subdivision Plan No.", styles: { halign: "center" } },
            { content: "TCT/OCT No.", styles: { halign: "center" } },
            { content: "Lot Area (m²)", styles: { halign: "center" } },
            {
              content: "Land Use Classification",
              styles: { halign: "center" },
            },
            { content: "Name of Awardee", styles: { halign: "center" } },
            {
              content: "Date of Award (MOA/NOA)",
              styles: { halign: "center" },
            },
            { content: "Total Selling Price", styles: { halign: "center" } },
            { content: "Selling Price per m²", styles: { halign: "center" } },
            { content: "Contract Type", styles: { halign: "center" } },
            { content: "Account Status", styles: { halign: "center" } },
            { content: "Remarks", styles: { halign: "center" } },
          ],
        ]
      : ((lisclass === "Non-estate" || lisclassadmin.includes("Non-estate")) &&
          (awardstatus === "Sold/Disposed" ||
            awardstatadmin.includes("Sold/Disposed")) &&
          printing === "printingC") ||
        ((lisclass === "Estate" || lisclassadmin.includes("Estate")) &&
          (awardstatus === "Sold/Disposed" ||
            awardstatadmin.includes("Sold/Disposed")) &&
          printing === "printingC")
      ? [
          [
            { content: "No.", styles: { halign: "center" } },
            { content: "Area", styles: { halign: "center" } },
            { content: "Package", styles: { halign: "center" } },
            { content: "Phase", styles: { halign: "center" } },
            { content: "Super Block", styles: { halign: "center" } },
            { content: "Sub Block", styles: { halign: "center" } },
            { content: "Block No.", styles: { halign: "center" } },
            { content: "Lot No.", styles: { halign: "center" } },
            { content: "Subdivision Plan No.", styles: { halign: "center" } },
            { content: "TCT No. or TD", styles: { halign: "center" } },
            { content: "Area (sq.m)", styles: { halign: "center" } },
            {
              content: "Land Use Classification",
              styles: { halign: "center" },
            },
            { content: "Lessee", styles: { halign: "center" } },
            { content: "Occupant", styles: { halign: "center" } },
            {
              content: "Date of Award (MOA/NOA)",
              styles: { halign: "center" },
            },
            { content: "Lease per Month", styles: { halign: "center" } },
            { content: "Lease Term", styles: { halign: "center" } },
            { content: "Status of Account", styles: { halign: "center" } },
          ],
        ]
      : //nationwide lisclass
      printing === "printnationwideA" || printing === "printnationwideC"
      ? [
          [
            { content: "No.", styles: { halign: "center" } },
            { content: "REGION", styles: { halign: "center" } },
            { content: "DISTRICT", styles: { halign: "center" } },
            { content: "Project Name/Location", styles: { halign: "center" } },
            { content: "Area ", styles: { halign: "center" } },
            { content: "Package", styles: { halign: "center" } },
            { content: "Phase", styles: { halign: "center" } },
            { content: "Super Block", styles: { halign: "center" } },
            { content: "Sub Block", styles: { halign: "center" } },
            { content: "Blk No.", styles: { halign: "center" } },
            { content: "Lot No.", styles: { halign: "center" } },
            {
              content: "Subdivision Plan No.",
              styles: { halign: "center" },
            },
            {
              content: "TCT/OCT NO.)",
              styles: { halign: "center" },
            },
            { content: "Lot Area (m²)", styles: { halign: "center" } },
            {
              content: "Land Use Classification",
              styles: { halign: "center" },
            },
            { content: "Name of Awardee/Lessee", styles: { halign: "center" } },
            {
              content: "Date of Award (MOA/NOA)",
              styles: { halign: "center" },
            },
            { content: "Total Selling Price", styles: { halign: "center" } },
            {
              content: "Selling Price per m²/Lease Rate per Mo. per m²",
              styles: { halign: "center" },
            },
            { content: "Contract Type", styles: { halign: "center" } },
            { content: "Account Status", styles: { halign: "center" } },
            { content: "Occupancy Status", styles: { halign: "center" } },
            { content: "Appraisal Status", styles: { halign: "center" } },
            { content: "Pricing Status", styles: { halign: "center" } },
            { content: "Remarks", styles: { halign: "center" } },
          ],
        ]
      : //nationwide awardstat
      printing === "printnationwideB"
      ? [
          [
            { content: "No.", styles: { halign: "center" } },
            { content: "REGION", styles: { halign: "center" } },
            { content: "DISTRICT", styles: { halign: "center" } },
            { content: "Project Name/Location", styles: { halign: "center" } },
            { content: "Area ", styles: { halign: "center" } },
            { content: "Package", styles: { halign: "center" } },
            { content: "Phase", styles: { halign: "center" } },
            { content: "Super Block", styles: { halign: "center" } },
            { content: "Sub Block", styles: { halign: "center" } },
            { content: "Blk No.", styles: { halign: "center" } },
            { content: "Lot No.", styles: { halign: "center" } },
            {
              content: "Subdivision Plan No.",
              styles: { halign: "center" },
            },
            {
              content: "TCT/OCT NO.)",
              styles: { halign: "center" },
            },
            { content: "Lot Area (m²)", styles: { halign: "center" } },
            {
              content: "Land Use Classification",
              styles: { halign: "center" },
            },
            { content: "Name of Awardee/Lessee", styles: { halign: "center" } },
            {
              content: "Date of Award (MOA/NOA)",
              styles: { halign: "center" },
            },
            { content: "Total Selling Price", styles: { halign: "center" } },
            {
              content: "Selling Price per m²/Lease Rate per Mo. per m²",
              styles: { halign: "center" },
            },
            { content: "Contract Type", styles: { halign: "center" } },
            { content: "Account Status", styles: { halign: "center" } },
            { content: "Remarks", styles: { halign: "center" } },
          ],
        ]
      : printing === "printingB"
      ? [
          [
            { content: "No.", styles: { halign: "center" } },
            { content: "Area", styles: { halign: "center" } },
            { content: "Package", styles: { halign: "center" } },
            { content: "Phase", styles: { halign: "center" } },
            { content: "Super Block", styles: { halign: "center" } },
            { content: "Sub Block", styles: { halign: "center" } },
            { content: "Block No.", styles: { halign: "center" } },
            { content: "Lot No.", styles: { halign: "center" } },
            { content: "Subdivision Plan No.", styles: { halign: "center" } },
            { content: "OCT/TCT No.", styles: { halign: "center" } },
            { content: "Area (m²)", styles: { halign: "center" } },
            {
              content: "Land Use Classification",
              styles: { halign: "center" },
            },
            {
              content: "Usufructuary (Name of Entity/Person)",
              styles: { halign: "center" },
            },
            { content: "Term (No. of Years)", styles: { halign: "center" } },
          ],
        ]
      : [[""]];

  doc.autoTable({
    head: header,
    body: rows,
    startY: 190,
    tableLineColor: [0, 0, 0],
    headStyles: {
      fillColor: [255, 255, 255],
      lineWidth: 0.5,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
    },
    tableLineWidth: 0.75,
    margin: { horizontal: 15 },
    bodyStyles: {
      fillColor: [255, 255, 255],
      lineWidth: 0.5,
      lineColor: [0, 0, 0],
      textColor: [0, 0, 0],
    },

    styles: {
      fontSize: 7, // Reduce the font size if necessary
      cellPadding: 2, // Adjust cell padding
    },
  });

  if (accesslevel) {
    const finalY = doc.lastAutoTable.finalY;
    console.log(finalY);
    console.log(doc.internal.pageSize.height);

    const prepared_By =
      responseResult.name +
      " " +
      responseResult.mname +
      " " +
      responseResult.lname;

    const prepared_By_Position = responseResult.position;

    // const reviewed_By = signatoryRes.data[0].sig_revieweby;
    // const reviewed_By_Position = signatoryRes.data[0].sig_revieweby_dg;

    // const noted_By = signatoryRes.data[0].sig_notedby;
    // const noted_By_Position = signatoryRes.data[0].sig_notedby_dg;

    let preparedby = `Prepared By:`;
    let preparedby_name = `${prepared_By}`;
    let firstSignatoryL = `_______________________________`;
    let firstSignatory = `${prepared_By_Position}`;

    // let reviewedby = `Reviewed By:`;
    // let reviewedby_name = `${reviewed_By}`;
    // let secondSignatorL = `_______________________________`;
    // let secondSignatory = `${reviewed_By_Position}`;

    // let notedby = `Noted By:`;
    // let notedby_name = `${noted_By}`;
    // let thirdSignatorL = `_______________________________`;
    // let thirdSignatory = `${noted_By_Position}`;

    if (finalY >= 365) {
      doc.addPage();
      doc.setFontSize(12);

      doc.setFontSize(12);

      doc.setLineWidth(0.8);
      doc.rect(15, 130, 978, 100);

      doc.setFont("normal");

      doc.text(35, 165, preparedby);
      doc.text(
        preparedby_name,
        135 - doc.getTextWidth(preparedby_name) / 2,
        195
      );
      doc.text(
        firstSignatoryL,
        135 - doc.getTextWidth(firstSignatoryL) / 2,
        200
      );
      doc.text(firstSignatory, 135 - doc.getTextWidth(firstSignatory) / 2, 215);

      // doc.text(390, 165, reviewedby);
      // doc.text(
      //   reviewedby_name,
      //   404 - doc.getTextWidth(reviewedby_name) / 2,
      //   195
      // );
      // doc.text(
      //   secondSignatorL,
      //   404 - doc.getTextWidth(secondSignatorL) / 2,
      //   200
      // );
      // doc.text(
      //   secondSignatory,
      //   404 - doc.getTextWidth(secondSignatory) / 2,
      //   215
      // );

      // doc.text(770, 490, notedby);
      // doc.text(notedby_name, 870 - doc.getTextWidth(notedby_name) / 2, 195);
      // doc.text(thirdSignatorL, 870 - doc.getTextWidth(thirdSignatorL) / 2, 200);
      // doc.text(thirdSignatory, 870 - doc.getTextWidth(thirdSignatory) / 2, 215);
    } else {
      doc.setLineWidth(0.8);
      doc.rect(15, doc.lastAutoTable.finalY, 978, 100);

      doc.setFont("normal");

      doc.text(35, doc.lastAutoTable.finalY + 35, preparedby);
      doc.text(
        preparedby_name,
        135 - doc.getTextWidth(preparedby_name) / 2,
        doc.lastAutoTable.finalY + 60
      );
      doc.text(
        firstSignatoryL,
        135 - doc.getTextWidth(firstSignatoryL) / 2,
        doc.lastAutoTable.finalY + 65
      );
      doc.text(
        firstSignatory,
        135 - doc.getTextWidth(firstSignatory) / 2,
        doc.lastAutoTable.finalY + 80
      );

      // doc.text(390, doc.lastAutoTable.finalY + 35, reviewedby);
      // doc.text(
      //   reviewedby_name,
      //   490 - doc.getTextWidth(reviewedby_name) / 2,
      //   doc.lastAutoTable.finalY + 60
      // );
      // doc.text(
      //   secondSignatorL,
      //   490 - doc.getTextWidth(secondSignatorL) / 2,
      //   doc.lastAutoTable.finalY + 65
      // );
      // doc.text(
      //   secondSignatory,
      //   490 - doc.getTextWidth(secondSignatory) / 2,
      //   doc.lastAutoTable.finalY + 80
      // );

      // doc.text(770, doc.lastAutoTable.finalY + 35, notedby);
      // doc.text(
      //   notedby_name,
      //   870 - doc.getTextWidth(notedby_name) / 2,
      //   doc.lastAutoTable.finalY + 60
      // );
      // doc.text(
      //   thirdSignatorL,
      //   870 - doc.getTextWidth(thirdSignatorL) / 2,
      //   doc.lastAutoTable.finalY + 65
      // );
      // doc.text(
      //   thirdSignatory,
      //   870 - doc.getTextWidth(thirdSignatory) / 2,
      //   doc.lastAutoTable.finalY + 80
      // );
    }
  }

  const pageCount = doc.internal.getNumberOfPages();
  const pageSize = doc.internal.pageSize;
  const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
  const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    const footer = `Page ${i} of ${pageCount}`;

    doc.text(
      footer,
      pageWidth / 2 - doc.getTextWidth(footer) / 2,
      pageHeight - 15,
      { baseline: "bottom" }
    );
  }

  for (let x = 1; x <= pageCount; x++) {
    doc.setPage(x);
    doc.setTextColor(150);
    doc.text(
      15,
      doc.internal.pageSize.height - 15,
      "Copyright © 2023 National Housing Authority. All rights reserved."
    );
    doc.text(910, doc.internal.pageSize.height - 15, "ciedcii.nha.gov.ph");
  }

  var blob = doc.output("blob");
  window.open(URL.createObjectURL(blob));
};

export { pdf_report_A };
