const AddInventory = async (
  propertyid,
  zoneordinace,
  catvalue,
  classvalue,
  lot,
  blk,
  phase,
  pack,
  lotarea,
  flrarea,
  subplan,
  subplanno,
  tctno,
  titlestat,
  statowner,
  otherowner,
  awardstat,
  physicalstat,
  physicalstatothers,
  structuredesc,
  frontage,
  shape,
  topography,
  foodassessment,
  sysmicanalysis,
  road,
  electricprov,
  waterprov,
  sesu,
  legalstat,
  actiontaken,
  executiveissuance,
  appstat,
  appvalid,
  pricingstat,
  pricevalid,
  modedispo,
  pbtype,
  othersmodedipo,
  propertycode,
  occupiedstat,
  imagedata,
  geomid,
  comcode,
  acctstatus,
  awardstatusagreement,
  subplanfiledate,
  awardstatusagreementwith,
  area,
  subblk,
  supblk,
  pbawardee,
  pbdateaward,
  pbsellprice,
  pbcontype,
  pblessee,
  pbactoccu,
  pbleaserate,
  pbleaseterm,
  propmodedispo,
  proppbtype,
  propothersmodedipo,
  pbdateawardlease,
  usufructentity,
  agreementterm,
  stallno,
  appmarketval,
  apprentval,
  appvaldate,
  regionValue,
  districtValue,
  imagedataflood,
  imagedataseis,
  firstbiddate,
  secbiddate,
  foreclosedprop,
  pbsaleawardee,
  pbsaledateaward,
  pbsaleselprice,
  pbsalecontype,
  pbleaselessee,
  pbleasedateaward,
  pbleaseactoccu,
  pbleaseratepermon,
  pbleaseleaseterm,
  withanodata
) => {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  try {
    let res = await fetch("https://api.nha.gov.ph/cied/addinventory", {
      mode: "cors",
      method: "POST",
      headers: headers,
      credentials: "include",
      body: JSON.stringify({
        Propertyid: propertyid,
        Zoneordinace: zoneordinace,
        Catvalue: catvalue,
        Classvalue: classvalue,
        Lot: lot,
        Blk: blk,
        Phase: phase,
        Pack: pack,
        Lotarea: lotarea,
        Flrarea: flrarea,
        Subplan: subplan,
        Subplanno: subplanno,
        Tctno: tctno,
        Titlestat: titlestat,
        Statowner: statowner,
        Otherowner: otherowner,
        Awardstat: awardstat,
        Physicalstat: physicalstat,
        Physicalstatothers: physicalstatothers,
        Structuredesc: structuredesc,
        Frontage: frontage,
        Shape: shape,
        Topography: topography,
        Foodassessment: foodassessment,
        Sysmicanalysis: sysmicanalysis,
        Road: road,
        Electricprov: electricprov,
        Waterprov: waterprov,
        Sesu: sesu,
        Lgalstat: legalstat,
        Actiontaken: actiontaken,
        Executiveissuance: executiveissuance,
        Appstat: appstat,
        Appvalid: appvalid,
        Pricingstat: pricingstat,
        Pricevalid: pricevalid,
        Modedispo: modedispo,
        Pbtype: pbtype,
        Othersmodedipo: othersmodedipo,
        Propertycode: propertycode,
        Occupiedstat: occupiedstat,
        Imagedata: imagedata,
        Geomid: geomid,
        Comcode: comcode,
        Acctstatus: acctstatus,
        Awardstatusagreement: awardstatusagreement,
        Subplanfiledate: subplanfiledate,
        Awardstatusagreementwith: awardstatusagreementwith,
        Area: area,
        Subblock: subblk,
        Supblock: supblk,
        Pbawardee: pbawardee,
        Pbdateaward: pbdateaward,
        Pbsellprice: pbsellprice,
        Pbcontype: pbcontype,
        Pblessee: pblessee,
        Pbactoccu: pbactoccu,
        Pbleaserate: pbleaserate,
        Pbleaseterm: pbleaseterm,
        Propmodedispo: propmodedispo,
        Proppbtype: proppbtype,
        Propothersmodedipo: propothersmodedipo,
        Pbdateawardlease: pbdateawardlease,
        Usufructentity: usufructentity,
        Agreementterm: agreementterm,
        Stallno: stallno,
        Appmarketval: appmarketval,
        Apprentval: apprentval,
        Appvaldate: appvaldate,
        Regionvalue: regionValue,
        Districtvalue: districtValue,
        Imagedataflood: imagedataflood,
        Imagedataseis: imagedataseis,
        Firstbiddate: firstbiddate,
        Secbiddate: secbiddate,
        Foreclosedprop: foreclosedprop,
        Pbsaleawardee: pbsaleawardee,
        Pbsaledateaward: pbsaledateaward,
        Pbsaleselprice: pbsaleselprice,
        Pbsalecontype: pbsalecontype,
        Pbleaselessee: pbleaselessee,
        Pbleasedateaward: pbleasedateaward,
        Pbleaseactoccu: pbleaseactoccu,
        pbleaseratepermon: pbleaseratepermon,
        Pbleaseleaseterm: pbleaseleaseterm,
        Withanodata: withanodata,
      }),
    });
    if (res.status === 200) {
      return "0000";
    } else {
      return "1111";
    }
  } catch (err) {
    console.log(err);
  }
};

const GetInventory = async (regionId) => {
  let GetInventory;
  GetInventory = await fetch("https://api.nha.gov.ph/cied/getprojectdetails", {
    credentials: "include",
  }).then((res) => res.json());
  return GetInventory;
};

const GetInventoryNonEstate = async (regionId) => {
  let GetInventoryNonEstate;
  GetInventoryNonEstate = await fetch(
    "https://api.nha.gov.ph/cied/getprojectdetailsnonestate",
    { credentials: "include" }
  ).then((res) => res.json());
  return GetInventoryNonEstate;
};

const GetAllInventory = async (regionId) => {
  let GetAllInventory;
  GetAllInventory = await fetch("https://api.nha.gov.ph/cied/getinventory", {
    credentials: "include",
  }).then((res) => res.json());
  return GetAllInventory;
};

const GetAllInventoryUnsold = async () => {
  let GetAllInventoryUnsold;
  GetAllInventoryUnsold = await fetch(
    "https://api.nha.gov.ph/cied/getinventoryunsold",
    {
      credentials: "include",
    }
  ).then((res) => res.json());
  return GetAllInventoryUnsold;
};

const GetAllInventorysold = async () => {
  let GetAllInventorysold;
  GetAllInventorysold = await fetch(
    "https://api.nha.gov.ph/cied/getinventorysold",
    {
      credentials: "include",
    }
  ).then((res) => res.json());
  return GetAllInventorysold;
};

const UpdateInventory = async (
  propertyid,
  zoneordinace,
  catvalue,
  classvalue,
  lot,
  blk,
  phase,
  pack,
  lotarea,
  flrarea,
  subplan,
  subplanno,
  tctno,
  titlestat,
  statowner,
  otherowner,
  awardstat,
  physicalstat,
  physicalstatothers,
  structuredesc,
  frontage,
  shape,
  topography,
  foodassessment,
  sysmicanalysis,
  road,
  electricprov,
  waterprov,
  sesu,
  legalstat,
  actiontaken,
  executiveissuance,
  appstat,
  appvalid,
  pricingstat,
  pricevalid,
  modedispo,
  pbtype,
  othersmodedipo,
  propertycode,
  occupiedstat,
  inventoryid,
  useuuid,
  imagedata,
  acctstatus,
  awardstatusagreement,
  subplanfiledate,
  awardstatusagreementwith,
  area,
  subblk,
  supblk,
  pbawardee,
  pbdateaward,
  pbsellprice,
  pbcontype,
  pblessee,
  pbactoccu,
  pbleaserate,
  pbleaseterm,
  propmodedispo,
  proppbtype,
  propothersmodedipo,
  pbdateawardlease,
  usufructentity,
  agreementterm,
  stallno,
  appmarketval,
  apprentval,
  appvaldate,
  imagedataflood,
  imagedataseis,
  firstbiddate,
  secbiddate,
  foreclosedprop,
  pbsaleawardee,
  pbsaledateaward,
  pbsaleselprice,
  pbsalecontype,
  pbleaselessee,
  pbleasedateaward,
  pbleaseactoccu,
  pbleaseratepermon,
  pbleaseleaseterm,
  withanodata
) => {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  try {
    let res = await fetch("https://api.nha.gov.ph/cied/updateinventory", {
      mode: "cors",
      method: "PUT",
      headers: headers,
      credentials: "include",
      body: JSON.stringify({
        Propertyid: propertyid,
        Zoneordinace: zoneordinace,
        Catvalue: catvalue,
        Classvalue: classvalue,
        Lot: lot,
        Blk: blk,
        Phase: phase,
        Pack: pack,
        Lotarea: lotarea,
        Flrarea: flrarea,
        Subplan: subplan,
        Subplanno: subplanno,
        Tctno: tctno,
        Titlestat: titlestat,
        Statowner: statowner,
        Otherowner: otherowner,
        Awardstat: awardstat,
        Physicalstat: physicalstat,
        Physicalstatothers: physicalstatothers,
        Structuredesc: structuredesc,
        Frontage: frontage,
        Shape: shape,
        Topography: topography,
        Foodassessment: foodassessment,
        Sysmicanalysis: sysmicanalysis,
        Road: road,
        Electricprov: electricprov,
        Waterprov: waterprov,
        Sesu: sesu,
        Lgalstat: legalstat,
        Actiontaken: actiontaken,
        Executiveissuance: executiveissuance,
        Appstat: appstat,
        Appvalid: appvalid,
        Pricingstat: pricingstat,
        Pricevalid: pricevalid,
        Modedispo: modedispo,
        Pbtype: pbtype,
        Othersmodedipo: othersmodedipo,
        Propertycode: propertycode,
        Occupiedstat: occupiedstat,
        Inventoryid: inventoryid,
        Useuuid: useuuid,
        Imagedata: imagedata,
        Acctstatus: acctstatus,
        Awardstatusagreement: awardstatusagreement,
        Subplanfiledate: subplanfiledate,
        Awardstatusagreementwith: awardstatusagreementwith,
        Area: area,
        Subblock: subblk,
        Supblock: supblk,
        Pbawardee: pbawardee,
        Pbdateaward: pbdateaward,
        Pbsellprice: pbsellprice,
        Pbcontype: pbcontype,
        Pblessee: pblessee,
        Pbactoccu: pbactoccu,
        Pbleaserate: pbleaserate,
        Pbleaseterm: pbleaseterm,
        Propmodedispo: propmodedispo,
        Proppbtype: proppbtype,
        Propothersmodedipo: propothersmodedipo,
        Pbdateawardlease: pbdateawardlease,
        Usufructentity: usufructentity,
        Agreementterm: agreementterm,
        Stallno: stallno,
        Appmarketval: appmarketval,
        Apprentval: apprentval,
        Appvaldate: appvaldate,
        Imagedataflood: imagedataflood,
        Imagedataseis: imagedataseis,
        Firstbiddate: firstbiddate,
        Secbiddate: secbiddate,
        Foreclosedprop: foreclosedprop,
        Pbsaleawardee: pbsaleawardee,
        Pbsaledateaward: pbsaledateaward,
        Pbsaleselprice: pbsaleselprice,
        Pbsalecontype: pbsalecontype,
        Pbleaselessee: pbleaselessee,
        Pbleasedateaward: pbleasedateaward,
        Pbleaseactoccu: pbleaseactoccu,
        Pbleaseratepermon: pbleaseratepermon,
        Pbleaseleaseterm: pbleaseleaseterm,
        Withanodata: withanodata,
      }),
    });
    if (res.status === 200) {
      return "0000";
    } else {
      return "1111";
    }
  } catch (err) {
    console.log(err);
  }
};

const GetProjectInfobyCode = async (proj_code) => {
  let GetProjectInfobyCode;
  GetProjectInfobyCode = await fetch(
    "https://api.nha.gov.ph/cied/getprojectinfobcode/" + proj_code,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetProjectInfobyCode;
};

// Updated removed address retain com_code
const GetProjectInfobyBlk = async (proj_code, address) => {
  let GetProjectInfobyBlk;
  GetProjectInfobyBlk = await fetch(
    "https://api.nha.gov.ph/cied/getprojectinfobyblk/" + proj_code,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetProjectInfobyBlk;
};

const GetImage = async (uuid) => {
  let GetImage;
  GetImage = await fetch("https://api.nha.gov.ph/cied/getimage/" + uuid, {
    credentials: "include",
  }).then((res) => res.json());
  return GetImage;
};

const GetImageFlood = async (uuid) => {
  let GetImageFlood;
  GetImageFlood = await fetch(
    "https://api.nha.gov.ph/cied/getimageflood/" + uuid,
    {
      credentials: "include",
    }
  ).then((res) => res.json());
  return GetImageFlood;
};

const GetImageSeis = async (uuid) => {
  let GetImageSeis;
  GetImageSeis = await fetch(
    "https://api.nha.gov.ph/cied/getimageseis/" + uuid,
    {
      credentials: "include",
    }
  ).then((res) => res.json());
  return GetImageSeis;
};

const GetProjectInfobyId = async (nonestateid) => {
  let GetProjectInfobyCode;
  GetProjectInfobyCode = await fetch(
    "https://api.nha.gov.ph/cied/getprojectinfobyid/" + nonestateid,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetProjectInfobyCode;
};

const GetGeomEstate = async (geomid, uuid) => {
  let GetGeomEstate;
  GetGeomEstate = await fetch(
    "https://api.nha.gov.ph/cied/getgeomestate/" + geomid,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetGeomEstate;
};

const GetGeomNonEstate = async (geomid, uuid) => {
  let GetGeomNonEstate;
  GetGeomNonEstate = await fetch(
    "https://api.nha.gov.ph/cied/getgeomnonestate/" + geomid,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetGeomNonEstate;
};

const UpdateInventoryStatus = async (tbl_id, tag, identifier, tag2) => {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  try {
    let res = await fetch("https://api.nha.gov.ph/cied/updateinventorystat", {
      mode: "cors",
      method: "PUT",
      headers: headers,
      credentials: "include",
      body: JSON.stringify({
        Tbl_id: tbl_id,
        Tag: tag,
        Identifier: identifier,
        Tag2: tag2,
      }),
    });
    if (res.status === 200) {
      return "0000";
    } else {
      return "1111";
    }
  } catch (err) {
    console.log(err);
  }
};
const UpdateInventoryStatusValidation = async (tbl_id, remark) => {
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  try {
    let res = await fetch(
      "https://api.nha.gov.ph/cied/updateinventorystatvalidation",
      {
        mode: "cors",
        method: "PUT",
        headers: headers,
        credentials: "include",
        body: JSON.stringify({
          Tbl_id: tbl_id,
          Remark: remark,
        }),
      }
    );
    if (res.status === 200) {
      return "0000";
    } else {
      return "1111";
    }
  } catch (err) {
    console.log(err);
  }
};

const GetSortbyLISClass = async (invclass) => {
  let GetSortbyLISClass;

  GetSortbyLISClass = await fetch(
    "https://api.nha.gov.ph/cied/sortbylisclassall/" + invclass,
    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortbyLISClass;
};

const GetSortbyLISCat = async (invcat, lisclass) => {
  let GetSortbyLISCat;
  GetSortbyLISCat = await fetch(
    "https://api.nha.gov.ph/cied/sortbyliscat/" + invcat + "/" + lisclass,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyLISCat;
};

const GetSortbyLISAwardStat = async (awardstat, lisclass, districtvalue) => {
  console.log(awardstat, lisclass, districtvalue);

  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortbyLISAwardStat;

  if (districtvalue === "") {
    GetSortbyLISAwardStat = await fetch(
      "https://api.nha.gov.ph/cied/sortbylisawardstat/" +
        newAwardStatus +
        "/" +
        lisclass +
        "/null",

      { credentials: "include" }
    ).then((res) => res.json());
    return GetSortbyLISAwardStat;
  } else {
    GetSortbyLISAwardStat = await fetch(
      "https://api.nha.gov.ph/cied/sortbylisawardstat/" +
        newAwardStatus +
        "/" +
        lisclass +
        "/" +
        districtvalue,
      { credentials: "include" }
    ).then((res) => res.json());
    return GetSortbyLISAwardStat;
  }
};

const GetSortWithAgreementPerDist = async (agreement, dist) => {
  let GetSortWithAgreementPerDist;
  //sortbydismodeofdispo
  GetSortWithAgreementPerDist = await fetch(
    "https://api.nha.gov.ph/cied/sortwithagreementperdist/" +
      agreement +
      "/" +
      dist,
    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortWithAgreementPerDist;
};

const GetSortByDisModeDispo = async (modeofdispo, dist) => {
  let GetSortByDisModeDispo;

  GetSortByDisModeDispo = await fetch(
    "https://api.nha.gov.ph/cied/sortbydismodeofdispo/" +
      modeofdispo +
      "/" +
      dist,
    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDisModeDispo;
};

const GetSortbyProject = async (propertid, awardstat, lisclass) => {
  console.log(propertid, awardstat, lisclass);
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortbyProject;
  // DISTRICT ACCT
  if (awardstat === "") {
    GetSortbyProject = await fetch(
      "https://api.nha.gov.ph/cied/sortbyproject/" +
        propertid +
        "/null" +
        "/" +
        lisclass,

      { credentials: "include" }
    ).then((res) => res.json());
  } else {
    GetSortbyProject = await fetch(
      "https://api.nha.gov.ph/cied/sortbyproject/" +
        propertid +
        "/" +
        newAwardStatus +
        "/" +
        lisclass,

      { credentials: "include" }
    ).then((res) => res.json());
  }

  return GetSortbyProject;
};

const GetInventorybyRegion = async (regids) => {
  const regidString = regids.join(","); // Convert array to comma-separated string

  let GetInventorybyRegion;

  GetInventorybyRegion = await fetch(
    "https://api.nha.gov.ph/cied/sortbyregion/" + regidString,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetInventorybyRegion;
};

// const GetInventorybyRegion = async (regid) => {
//   let GetInventorybyRegion;
//   GetInventorybyRegion = await fetch(
//     "https://api.nha.gov.ph/cied/sortbyregion/" + regid,
//     { credentials: "include" }
//   ).then((res) => res.json());
//   return GetInventorybyRegion;
// };

const GetInventorybyDistrict = async (distid) => {
  let GetInventorybyRegion;
  GetInventorybyRegion = await fetch(
    "https://api.nha.gov.ph/cied/sortbydistrict/" + distid,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetInventorybyRegion;
};

const GetInventorySoldbyRegion = async (regionidinv) => {
  let GetInventorySoldbyRegion;
  GetInventorySoldbyRegion = await fetch(
    "https://api.nha.gov.ph/cied/sortsoldbyregion/" + regionidinv,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetInventorySoldbyRegion;
};

const GetSoldNonestateProject = async (disId, regId) => {
  let GetSoldNonestateProject;
  GetSoldNonestateProject = await fetch(
    "https://api.nha.gov.ph/cied/sortsoldbydistrict/" + disId + "/" + regId,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSoldNonestateProject;
};

const GetInventoryUnsoldbyRegion = async (regionidinv) => {
  let GetInventoryUnsoldbyRegion;
  GetInventoryUnsoldbyRegion = await fetch(
    "https://api.nha.gov.ph/cied/sortunsoldbyregion/" + regionidinv,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetInventoryUnsoldbyRegion;
};

const GetUnsoldNonestateProject = async (disId, regId) => {
  let GetSoldNonestateProject;
  GetSoldNonestateProject = await fetch(
    "https://api.nha.gov.ph/cied/sortunsoldbydistrict/" + disId + "/" + regId,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSoldNonestateProject;
};

const GetSortbyLISClassNationwide = async (lisclass) => {
  const lisclassString = lisclass.join(","); // Convert array to comma-separated string

  let GetSortbyLISClassNationwide;
  GetSortbyLISClassNationwide = await fetch(
    "https://api.nha.gov.ph/cied/sortbylisclassnationwide/" + lisclassString,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyLISClassNationwide;
};

const GetSortbyAwardStatNationwide = async (awardstat) => {
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  const awardstatString = newAwardStatus.join(",");

  console.log(awardstatString);

  let GetSortbyAwardStatNationwide;
  GetSortbyAwardStatNationwide = await fetch(
    "https://api.nha.gov.ph/cied/sortbyawardstatnationwide/" + awardstatString,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyAwardStatNationwide;
};

const GetSortbyLISClassAndDistrict = async (lisclass, dist) => {
  let lisclassString = lisclass.join(",");
  let GetSortbyLISClassAndDistrict;
  GetSortbyLISClassAndDistrict = await fetch(
    "https://api.nha.gov.ph/cied/sortbylisclassanddist/" +
      lisclassString +
      "/" +
      dist,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyLISClassAndDistrict;
};

const GetSortbyLISClassAndDistrictReg = async (lisclass, dist) => {
  let GetSortbyLISClassAndDistrictReg;
  //sortbylisclassanddistdist
  GetSortbyLISClassAndDistrictReg = await fetch(
    "https://api.nha.gov.ph/cied/sortbylisclassanddistreg/" +
      lisclass +
      "/" +
      dist,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyLISClassAndDistrictReg;
};

const GetSortbyLisClassAndAwardStatNationwide = async (awardstat, lisclass) => {
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  const lisclassString = lisclass.join(",");
  const awardstatString = newAwardStatus.join(",");

  let GetSortbyLisClassAndAwardStatNationwide;
  GetSortbyLisClassAndAwardStatNationwide = await fetch(
    "https://api.nha.gov.ph/cied/sortbylisclassandawardstatnationwide/" +
      awardstatString +
      "/" +
      lisclassString,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyLisClassAndAwardStatNationwide;
};

const GetSortbyDistLisclassAwardStat = async (awardstat, lisclass, dist) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortbyDistLisclassAwardStat;
  GetSortbyDistLisclassAwardStat = await fetch(
    "https://api.nha.gov.ph/cied/sortbydistlisclassawardstat/" +
      newAwardStatus +
      "/" +
      lisclass +
      "/" +
      dist,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyDistLisclassAwardStat;
};

const GetSortProjectbyDistLisclassAwardstatPropname = async (
  propertid,
  lisclass,
  awardstat,
  dist
) => {
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");
  let lisclassString = lisclass.join(",");

  let GetSortProjectbyDistLisclassAwardstatPropname;
  GetSortProjectbyDistLisclassAwardstatPropname = await fetch(
    "https://api.nha.gov.ph/cied/sortbylisclassawardstatpropname/" +
      dist +
      "/" +
      lisclassString +
      "/" +
      awardstatString +
      "/" +
      propertid,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortProjectbyDistLisclassAwardstatPropname;
};

const GetSortProjectbyDistLisclassAwardstatPropnameReg = async (
  propertid,
  lisclass,
  awardstat,
  dist
) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortProjectbyDistLisclassAwardstatPropnameReg;
  GetSortProjectbyDistLisclassAwardstatPropnameReg = await fetch(
    //sortbylisclassawardstatpropname
    "https://api.nha.gov.ph/cied/sortbylisclassawardstatpropnamereg/" +
      dist +
      "/" +
      lisclass +
      "/" +
      newAwardStatus +
      "/" +
      propertid,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortProjectbyDistLisclassAwardstatPropnameReg;
};

const GetSortProjectbyPropnameWithAgreement = async (
  propertid,
  awardstat,
  district
) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortProjectbyPropnameWithAgreement;
  GetSortProjectbyPropnameWithAgreement = await fetch(
    // sortbylisclasspropname
    "https://api.nha.gov.ph/cied/sortbypropnamewithagreement/" +
      district +
      "/" +
      newAwardStatus +
      "/" +
      propertid,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortProjectbyPropnameWithAgreement;
};

const GetSortbyLISClassWithAgreementNationwide = async () => {
  let GetSortbyLISClassWithAgreementNationwide;
  GetSortbyLISClassWithAgreementNationwide = await fetch(
    "https://api.nha.gov.ph/cied/sortbylisclasswithagreementnationwide",

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyLISClassWithAgreementNationwide;
};

const GetSortbyLISClassAndDistrictWithAgreement = async (dist) => {
  let GetSortbyLISClassAndDistrictWithAgreement;
  GetSortbyLISClassAndDistrictWithAgreement = await fetch(
    "https://api.nha.gov.ph/cied/sortbylisclassanddistwithagreement/" + dist,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyLISClassAndDistrictWithAgreement;
};

const GetSortByModeDispoNationwide = async (modeofdispo) => {
  let GetSortByModeDispoNationwide;

  GetSortByModeDispoNationwide = await fetch(
    //sortbymodeofdispo
    "https://api.nha.gov.ph/cied/sortbymodeofdisponationwide/" + modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByModeDispoNationwide;
};

const GetSortWithAgreeNationwide = async (withagreement) => {
  let GetSortWithAgreeNationwide;

  GetSortWithAgreeNationwide = await fetch(
    "https://api.nha.gov.ph/cied/sortbyawardstatagreenationwide/" +
      withagreement,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortWithAgreeNationwide;
};

const GetSortbyLISClassAndRegion = async (lisclass, region) => {
  const lisclassString = lisclass.join(",");
  const regidString = region.join(","); // Convert array to comma-separated string
  let GetSortbyLISClassAndRegion;
  console.log(regidString);
  GetSortbyLISClassAndRegion = await fetch(
    "https://api.nha.gov.ph/cied/sortbylisclassandregion/" +
      lisclassString +
      "/" +
      regidString,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortbyLISClassAndRegion;
};

const GetSortbyLISClassAndRegionWithAgreement = async (region) => {
  let GetSortbyLISClassAndRegionWithAgreement;

  GetSortbyLISClassAndRegionWithAgreement = await fetch(
    "https://api.nha.gov.ph/cied/sortbylisclassandregionwithagreement/" +
      region,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyLISClassAndRegionWithAgreement;
};

const GetSortbyRegionAwardStat = async (awardstat, region) => {
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");
  const regidString = region.join(",");

  let GetSortbyRegionAwardStat;
  GetSortbyRegionAwardStat = await fetch(
    "https://api.nha.gov.ph/cied/sortbyregionawardstat/" +
      awardstatString +
      "/" +
      regidString,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyRegionAwardStat;
};

const GetSortByRegModeDispo = async (modeofdispo, reg) => {
  let GetSortByRegModeDispo;
  const regidString = reg.join(",");
  GetSortByRegModeDispo = await fetch(
    //sortbydismodeofdispo
    "https://api.nha.gov.ph/cied/sortbyregmodeofdispo/" +
      modeofdispo +
      "/" +
      regidString,
    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByRegModeDispo;
};

const GetSortByRegWithAgreement = async (modeofdispo, reg) => {
  let GetSortByRegWithAgreement;
  const regidString = reg.join(",");
  GetSortByRegWithAgreement = await fetch(
    //sortbyregmodeofdispo
    "https://api.nha.gov.ph/cied/sortbyregwithagreement/" +
      modeofdispo +
      "/" +
      regidString,
    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByRegWithAgreement;
};

const GetSortbyDistrictAwardStat = async (awardstat, dist) => {
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");
  let GetSortbyDistrictAwardStat;
  GetSortbyDistrictAwardStat = await fetch(
    "https://api.nha.gov.ph/cied/sortbydistrictawardstat/" +
      awardstatString +
      "/" +
      dist,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyDistrictAwardStat;
};

const GetSortbyDistrictAwardStatReg = async (awardstat, dist) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortbyDistrictAwardStatReg;
  GetSortbyDistrictAwardStatReg = await fetch(
    //sortbydistrictawardstat
    "https://api.nha.gov.ph/cied/sortbydistrictawardstatreg/" +
      newAwardStatus +
      "/" +
      dist,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyDistrictAwardStatReg;
};

//GetSortbyLISClassByDist
//sortbylisclassbyreg
const GetSortbyLISClassAllDistrict = async (lisclass) => {
  let GetSortbyLISClassAllDistrict;
  GetSortbyLISClassAllDistrict = await fetch(
    "https://api.nha.gov.ph/cied/sortbylisclassalldist/" + lisclass,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyLISClassAllDistrict;
};

const GetSortbyLISClassAllDistWithAgreement = async () => {
  let GetSortbyLISClassAllDistWithAgreement;
  GetSortbyLISClassAllDistWithAgreement = await fetch(
    "https://api.nha.gov.ph/cied/sortbylisclassalldistwithagreement",
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyLISClassAllDistWithAgreement;
};

const GetSortbyAwardStatByRegion = async (awardstat) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortbyAwardStatByRegion;
  GetSortbyAwardStatByRegion = await fetch(
    //sortbyawardstatnationwide
    "https://api.nha.gov.ph/cied/sortbyawardstatbyregion/" + newAwardStatus,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyAwardStatByRegion;
};

const GetSortbyLisClassAndAwardStatAllDist = async (awardstat, lisclass) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortbyLisClassAndAwardStatAllDist;
  GetSortbyLisClassAndAwardStatAllDist = await fetch(
    "https://api.nha.gov.ph/cied/sortbylisclassandawardstatalldist/" +
      newAwardStatus +
      "/" +
      lisclass,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyLisClassAndAwardStatAllDist;
};

const GetSortbyDistrictLisclassAwardStat = async (
  awardstat,
  lisclass,
  dist
) => {
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");
  let lisclassString = lisclass.join(",");

  let GetSortbyDistrictLisclassAwardStat;
  GetSortbyDistrictLisclassAwardStat = await fetch(
    "https://api.nha.gov.ph/cied/sortbydistrictlisclassawardstat/" +
      awardstatString +
      "/" +
      lisclassString +
      "/" +
      dist,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyDistrictLisclassAwardStat;
};

const GetSortbyDistrictLisclassAwardStatReg = async (
  awardstat,
  lisclass,
  dist
) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortbyDistrictLisclassAwardStatReg;
  GetSortbyDistrictLisclassAwardStatReg = await fetch(
    "https://api.nha.gov.ph/cied/sortbydistrictlisclassawardstatreg/" +
      newAwardStatus +
      "/" +
      lisclass +
      "/" +
      dist,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyDistrictLisclassAwardStatReg;
};

const GetSortbyDistrictLisclassAwardStatWithAgreement = async (
  awardstat,
  dist
) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortbyDistrictLisclassAwardStatWithAgreement;
  GetSortbyDistrictLisclassAwardStatWithAgreement = await fetch(
    "https://api.nha.gov.ph/cied/sortbydistrictlisclassawardstatwithagreement/" +
      newAwardStatus +
      "/" +
      dist,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyDistrictLisclassAwardStatWithAgreement;
};

const GetSortbyRegionLisclassAwardstat = async (
  awardstat,
  region,
  lisclass
) => {
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");
  let lisclassString = lisclass.join(",");
  const regidString = region.join(",");

  let GetSortbyRegionLisclassAwardstat;
  GetSortbyRegionLisclassAwardstat = await fetch(
    // sortbylisclasspropname
    "https://api.nha.gov.ph/cied/sortbyregionlisclassawardstatus/" +
      regidString +
      "/" +
      lisclassString +
      "/" +
      awardstatString,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyRegionLisclassAwardstat;
};

const GetSortbyRegionLisclassAwardstatWithAgreement = async (
  awardstat,
  region
) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortbyRegionLisclassAwardstatWithAgreement;
  GetSortbyRegionLisclassAwardstatWithAgreement = await fetch(
    // sortbyregionlisclassawardstatus
    "https://api.nha.gov.ph/cied/sortbyregionlisclassawardstatuswithagreement/" +
      region +
      "/" +
      newAwardStatus,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyRegionLisclassAwardstatWithAgreement;
};

const GetSortByDistLisclassAwardstatPropnameModeDispo = async (
  modeofdispo,
  district,
  lisclass,
  awardstat,
  propertid
) => {
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");
  let lisclassString = lisclass.join(",");

  let GetSortByDistLisclassAwardstatPropnameModeDispo;

  GetSortByDistLisclassAwardstatPropnameModeDispo = await fetch(
    //sortbydismodeofdispo
    "https://api.nha.gov.ph/cied/sortbydistlisclassawardstatpropnamemodeofdispo/" +
      district +
      "/" +
      lisclassString +
      "/" +
      awardstatString +
      "/" +
      propertid +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassAwardstatPropnameModeDispo;
};

const GetSortByDistLisclassAwardstatPropnameModeDispoReg = async (
  modeofdispo,
  district,
  lisclass,
  awardstat,
  propertid
) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortByDistLisclassAwardstatPropnameModeDispoReg;

  GetSortByDistLisclassAwardstatPropnameModeDispoReg = await fetch(
    "https://api.nha.gov.ph/cied/sortbydistlisclassawardstatpropnamemodeofdisporeg/" +
      district +
      "/" +
      lisclass +
      "/" +
      newAwardStatus +
      "/" +
      propertid +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassAwardstatPropnameModeDispoReg;
};

const GetSortByDistLisclassAwardstatPropnameModeDispoWithAgreement = async (
  agreeement,
  district,
  lisclass,
  awardstat,
  propertid
) => {
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");
  let lisclassString = lisclass.join(",");

  let GetSortByDistLisclassAwardstatPropnameModeDispoWithAgreement;

  GetSortByDistLisclassAwardstatPropnameModeDispoWithAgreement = await fetch(
    "https://api.nha.gov.ph/cied/sortbydistlisclassawardstatpropnamemodeofdispowithagreement/" +
      district +
      "/" +
      lisclassString +
      "/" +
      awardstatString +
      "/" +
      propertid +
      "/" +
      agreeement,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassAwardstatPropnameModeDispoWithAgreement;
};

const GetSortByDistLisclassAwardstatPropnameModeDispoWithAgreementReg = async (
  agreeement,
  district,
  lisclass,
  awardstat,
  propertid
) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortByDistLisclassAwardstatPropnameModeDispoWithAgreementReg;

  GetSortByDistLisclassAwardstatPropnameModeDispoWithAgreementReg = await fetch(
    "https://api.nha.gov.ph/cied/sortbydistlisclassawardstatpropnamemodeofdispowithagreementreg/" +
      district +
      "/" +
      lisclass +
      "/" +
      newAwardStatus +
      "/" +
      propertid +
      "/" +
      agreeement,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassAwardstatPropnameModeDispoWithAgreementReg;
};

const GetSortbyLisClassAndAwardStatAllDistWithAgreement = async (awardstat) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortbyLisClassAndAwardStatAllDistWithAgreement;
  //sortbylisclassandawardstatalldist
  GetSortbyLisClassAndAwardStatAllDistWithAgreement = await fetch(
    "https://api.nha.gov.ph/cied/sortbylisclassandawardstatalldistwithagreement/" +
      newAwardStatus,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyLisClassAndAwardStatAllDistWithAgreement;
};

const GetSortByModeDispoAllDistrict = async (modeofdispo) => {
  let GetSortByModeDispoAllDistrict;

  GetSortByModeDispoAllDistrict = await fetch(
    //sortbymodeofdisponationwide
    "https://api.nha.gov.ph/cied/sortbymodeofdispoalldistrict/" + modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByModeDispoAllDistrict;
};

const GetSortWithAgreementAllDistrict = async (withagreement) => {
  let GetSortWithAgreementAllDistrict;

  GetSortWithAgreementAllDistrict = await fetch(
    "https://api.nha.gov.ph/cied/sortwithagreementalldistrict/" + withagreement,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortWithAgreementAllDistrict;
};

const GetSortByModeDispoByDistrict = async (modeofdispo) => {
  let GetSortByModeDispoByDistrict;

  GetSortByModeDispoByDistrict = await fetch(
    //sortbymodeofdispoalldistrict
    "https://api.nha.gov.ph/cied/sortbymodeofdispobydistrict/" + modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByModeDispoByDistrict;
};

const GetDistAcctWithAgree = async (modeofdispo) => {
  let GetDistAcctWithAgree;

  GetDistAcctWithAgree = await fetch(
    //sortbymodeofdispobydistrict
    "https://api.nha.gov.ph/cied/sortdistacctwithagree/" + modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetDistAcctWithAgree;
};

const GetSortByLisclassAwardstatModedispo = async (
  modeofdispo,
  lisclass,
  awardstat
) => {
  let GetSortByLisclassAwardstatModedispo;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetSortByLisclassAwardstatModedispo = await fetch(
    //sortbymodeofdispoalldistrict
    "https://api.nha.gov.ph/cied/sortbylisclassawardstatmodedispo/" +
      lisclass +
      "/" +
      newAwardStatus +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByLisclassAwardstatModedispo;
};

const GetSortByLisclassAwardstatModedispoWithAgreement = async (
  modeofdispo,
  awardstat,
  lisclass
) => {
  let GetSortByLisclassAwardstatModedispoWithAgreement;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetSortByLisclassAwardstatModedispoWithAgreement = await fetch(
    "https://api.nha.gov.ph/cied/sortbylisclassawardstatmodedispowithagreement/" +
      lisclass +
      "/" +
      newAwardStatus +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByLisclassAwardstatModedispoWithAgreement;
};

const GetSortByAwardstatModedispo = async (modeofdispo, awardstat) => {
  let GetSortByAwardstatModedispo;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetSortByAwardstatModedispo = await fetch(
    //sortbylisclassawardstatmodedispo
    "https://api.nha.gov.ph/cied/sortbyawardstatmodedispo/" +
      newAwardStatus +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByAwardstatModedispo;
};

const GetSortByLisclassModeDispoAllDistrict = async (modeofdispo, lisclass) => {
  let GetSortByLisclassModeDispoAllDistrict;

  GetSortByLisclassModeDispoAllDistrict = await fetch(
    //sortbylisclassmodeofdispoalldistrict
    "https://api.nha.gov.ph/cied/sortbylisclassmodeofdispoalldistrict/" +
      lisclass +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByLisclassModeDispoAllDistrict;
};

const GetSortByLisclassModeDispoAllDistrictWithAgreement = async (
  agreement,
  lisclass
) => {
  let GetSortByLisclassModeDispoAllDistrictWithAgreement;

  GetSortByLisclassModeDispoAllDistrictWithAgreement = await fetch(
    //sortbylisclassmodeofdispoalldistrict
    "https://api.nha.gov.ph/cied/sortbylisclassmodeofdispoalldistrictwithagreement/" +
      lisclass +
      "/" +
      agreement,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByLisclassModeDispoAllDistrictWithAgreement;
};

const GetSortByDistLisclassModeDispo = async (
  modeofdispo,
  district,
  lisclass
) => {
  let GetSortByDistLisclassModeDispo;
  let lisclassString = lisclass.join(",");

  GetSortByDistLisclassModeDispo = await fetch(
    //sortbymodeofdispoalldistrict
    "https://api.nha.gov.ph/cied/sortbydistlisclassmodeofdispo/" +
      district +
      "/" +
      lisclassString +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassModeDispo;
};

const GetSortByDistLisclassModeDispoReg = async (
  modeofdispo,
  district,
  lisclass
) => {
  let GetSortByDistLisclassModeDispoReg;

  GetSortByDistLisclassModeDispoReg = await fetch(
    //sortbydistlisclassmodeofdispo
    "https://api.nha.gov.ph/cied/sortbydistlisclassmodeofdisporeg/" +
      district +
      "/" +
      lisclass +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassModeDispoReg;
};

const GetSortByDistLisclassModeDispoWithAgreement = async (
  modeofdispo,
  district,
  lisclass
) => {
  let GetSortByDistLisclassModeDispoWithAgreement;

  let lisclassString = lisclass.join(",");

  GetSortByDistLisclassModeDispoWithAgreement = await fetch(
    //sortbydistlisclassmodeofdispo
    "https://api.nha.gov.ph/cied/sortbydistlisclassmodeofdispowithagreement/" +
      district +
      "/" +
      lisclassString +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassModeDispoWithAgreement;
};

const GetSortByDistLisclassModeDispoWithAgreementReg = async (
  modeofdispo,
  district,
  lisclass
) => {
  let GetSortByDistLisclassModeDispoWithAgreementReg;

  GetSortByDistLisclassModeDispoWithAgreementReg = await fetch(
    //sortbydistlisclassmodeofdispowithagreement
    "https://api.nha.gov.ph/cied/sortbydistlisclassmodeofdispowithagreementreg/" +
      district +
      "/" +
      lisclass +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassModeDispoWithAgreementReg;
};

const GetSortByDistAwardStatModeDispo = async (
  modeofdispo,
  awardstat,
  district
) => {
  let GetSortByDistAwardStatModeDispo;
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");

  GetSortByDistAwardStatModeDispo = await fetch(
    //sortbylisclassawardstatmodedispo
    "https://api.nha.gov.ph/cied/sortbydistawardstatmodedispo/" +
      district +
      "/" +
      awardstatString +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistAwardStatModeDispo;
};

const GetSortByDistAwardStatModeDispoReg = async (
  modeofdispo,
  awardstat,
  district
) => {
  let GetSortByDistAwardStatModeDispoReg;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetSortByDistAwardStatModeDispoReg = await fetch(
    //sortbydistawardstatmodedispo
    "https://api.nha.gov.ph/cied/sortbydistawardstatmodedisporeg/" +
      district +
      "/" +
      newAwardStatus +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistAwardStatModeDispoReg;
};

const GetSortByDistAwardStatPropnameModeDispo = async (
  modeofdispo,
  district,
  awardstat,
  propertid
) => {
  let GetSortByDistAwardStatPropnameModeDispo;
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");

  GetSortByDistAwardStatPropnameModeDispo = await fetch(
    //sortbydistawardstatpropnamemodedispo
    "https://api.nha.gov.ph/cied/sortbydistawardstatpropnamemodedispo/" +
      district +
      "/" +
      awardstatString +
      "/" +
      propertid +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistAwardStatPropnameModeDispo;
};

const GetSortByDistAwardStatPropnameModeDispoReg = async (
  modeofdispo,
  district,
  awardstat,
  propertid
) => {
  let GetSortByDistAwardStatPropnameModeDispoReg;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetSortByDistAwardStatPropnameModeDispoReg = await fetch(
    //sortbydistawardstatpropnamemodedispo
    "https://api.nha.gov.ph/cied/sortbydistawardstatpropnamemodedisporeg/" +
      district +
      "/" +
      newAwardStatus +
      "/" +
      propertid +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistAwardStatPropnameModeDispoReg;
};

const GetAwardStatPropNameWithAgreeReg = async (
  modeofdispo,
  district,
  awardstat,
  propertid
) => {
  let GetAwardStatPropNameWithAgreeReg;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetAwardStatPropNameWithAgreeReg = await fetch(
    //sortbydistawardstatpropnamemodedispo
    "https://api.nha.gov.ph/cied/sortawardstatpropnamewithagreereg/" +
      district +
      "/" +
      newAwardStatus +
      "/" +
      propertid +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetAwardStatPropNameWithAgreeReg;
};

const GetSortByDistAwardstatPropname = async (
  propertid,
  district,
  awardstat
) => {
  let GetSortByDistAwardstatPropname;
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");

  GetSortByDistAwardstatPropname = await fetch(
    // sortbypropnamewithagreement
    "https://api.nha.gov.ph/cied/sortbydistawardstatpropname/" +
      district +
      "/" +
      awardstatString +
      "/" +
      propertid,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortByDistAwardstatPropname;
};

const GetSortByDistAwardstatPropnameReg = async (
  propertid,
  district,
  awardstat
) => {
  let GetSortByDistAwardstatPropnameReg;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetSortByDistAwardstatPropnameReg = await fetch(
    // sortbypropnamewithagreement
    "https://api.nha.gov.ph/cied/sortbydistawardstatpropnamereg/" +
      district +
      "/" +
      newAwardStatus +
      "/" +
      propertid,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortByDistAwardstatPropnameReg;
};

const GetAwardStatPropNameReg = async (propertid, awardstat) => {
  let GetAwardStatPropNameReg;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetAwardStatPropNameReg = await fetch(
    // sortbydistawardstatpropname
    "https://api.nha.gov.ph/cied/sortawardstatpropnamereg/" +
      newAwardStatus +
      "/" +
      propertid,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetAwardStatPropNameReg;
};

const GetSortByDistLisclassPropname = async (propertid, dist, lisclass) => {
  let GetSortByDistLisclassPropname;
  let lisclassString = lisclass.join(",");
  GetSortByDistLisclassPropname = await fetch(
    //sortbylisclassawardstatpropname
    "https://api.nha.gov.ph/cied/sortbydistlisclasspropname/" +
      dist +
      "/" +
      lisclassString +
      "/" +
      propertid,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortByDistLisclassPropname;
};

const GetSortByDistLisclassPropnameReg = async (propertid, dist, lisclass) => {
  let GetSortByDistLisclassPropnameReg;
  GetSortByDistLisclassPropnameReg = await fetch(
    //sortbydistlisclasspropname
    "https://api.nha.gov.ph/cied/sortbydistlisclasspropnamereg/" +
      dist +
      "/" +
      lisclass +
      "/" +
      propertid,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortByDistLisclassPropnameReg;
};

const GetSortByDistLisclassPropnameWithAgreement = async (propertid, dist) => {
  let GetSortByDistLisclassPropnameWithAgreement;
  GetSortByDistLisclassPropnameWithAgreement = await fetch(
    //sortbydistlisclasspropname
    "https://api.nha.gov.ph/cied/sortbydistlisclasspropnamewithagreement/" +
      dist +
      "/" +
      propertid,
    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortByDistLisclassPropnameWithAgreement;
};

const GetSortByDistLisclassPropnameModeDispo = async (
  modeofdispo,
  district,
  lisclass,
  propertid
) => {
  let GetSortByDistLisclassPropnameModeDispo;

  let lisclassString = lisclass.join(",");

  GetSortByDistLisclassPropnameModeDispo = await fetch(
    //sortbydistawardstatpropnamemodedispo
    "https://api.nha.gov.ph/cied/sortbydistlisclasspropnamemodedispo/" +
      district +
      "/" +
      lisclassString +
      "/" +
      propertid +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassPropnameModeDispo;
};

const GetSortByDistLisclassPropnameModeDispoReg = async (
  modeofdispo,
  district,
  lisclass,
  propertid
) => {
  let GetSortByDistLisclassPropnameModeDispoReg;

  GetSortByDistLisclassPropnameModeDispoReg = await fetch(
    //sortbydistlisclasspropnamemodedispo
    "https://api.nha.gov.ph/cied/sortbydistlisclasspropnamemodedisporeg/" +
      district +
      "/" +
      lisclass +
      "/" +
      propertid +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassPropnameModeDispoReg;
};

const GetSortByDistLisclassPropnameModeDispoWithAgreement = async (
  modeofdispo,
  district,
  lisclass,
  propertid
) => {
  let GetSortByDistLisclassPropnameModeDispoWithAgreement;

  let lisclassString = lisclass.join(",");
  GetSortByDistLisclassPropnameModeDispoWithAgreement = await fetch(
    //sortbydistlisclasspropnamemodedispo
    "https://api.nha.gov.ph/cied/sortbydistlisclasspropnamemodedispowithagreement/" +
      district +
      "/" +
      lisclassString +
      "/" +
      propertid +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassPropnameModeDispoWithAgreement;
};

const GetSortByDistLisclassPropnameModeDispoWithAgreementReg = async (
  modeofdispo,
  district,
  lisclass,
  propertid
) => {
  let GetSortByDistLisclassPropnameModeDispoWithAgreementReg;

  GetSortByDistLisclassPropnameModeDispoWithAgreementReg = await fetch(
    //sortbydistlisclasspropnamemodedispowithagreement
    "https://api.nha.gov.ph/cied/sortbydistlisclasspropnamemodedispowithagreementreg/" +
      district +
      "/" +
      lisclass +
      "/" +
      propertid +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassPropnameModeDispoWithAgreementReg;
};

const GetLisclassPropnameModeDispo = async (
  modeofdispo,
  lisclass,
  propertid
) => {
  let GetLisclassPropnameModeDispo;

  GetLisclassPropnameModeDispo = await fetch(
    //sortbydistlisclasspropnamemodedispowithagreement
    "https://api.nha.gov.ph/cied/sortlisclasspropnamemodedispo/" +
      lisclass +
      "/" +
      propertid +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisclassPropnameModeDispo;
};

const GetSetLotClassification = async (lotclass) => {
  let GetSetLotClassification;

  const lotclassString = lotclass.join(","); // Convert array to comma-separated string

  GetSetLotClassification = await fetch(
    "https://api.nha.gov.ph/cied/getlotclassification/" + lotclassString,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSetLotClassification;
};

const GetSetLotClassificationForClosedProp = async (lotclass) => {
  let GetSetLotClassificationForClosedProp;

  const lotclassString = lotclass.join(","); // Convert array to comma-separated string

  GetSetLotClassificationForClosedProp = await fetch(
    //getlotclassification
    "https://api.nha.gov.ph/cied/getlotclassificationforeclosedprop/" +
      lotclassString,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSetLotClassificationForClosedProp;
};

const GetLisLotClassForeclosed = async (lotclass, lisclass) => {
  let GetLisLotClassForeclosed;
  const lisclassString = lisclass.join(","); // Convert array to comma-separated string
  const lotclassString = lotclass.join(","); // Convert array to comma-separated string
  GetLisLotClassForeclosed = await fetch(
    //getlotclassification
    "https://api.nha.gov.ph/cied/getlislotclassforeclosed/" +
      lotclassString +
      "/" +
      lisclassString,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisLotClassForeclosed;
};

const GetLisLotClass = async (lotclass, lisclass) => {
  let GetLisLotClass;

  const lisclassString = lisclass.join(","); // Convert array to comma-separated string
  const lotclassString = lotclass.join(","); // Convert array to comma-separated string

  GetLisLotClass = await fetch(
    //getlotclassification
    "https://api.nha.gov.ph/cied/getlislotclass/" +
      lotclassString +
      "/" +
      lisclassString,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisLotClass;
};

const GetSetLotClassificationForClosedReg = async (lotclass) => {
  let GetSetLotClassificationForClosedReg;

  GetSetLotClassificationForClosedReg = await fetch(
    //getlotclassificationforeclosedprop
    "https://api.nha.gov.ph/cied/getlotclassificationforeclosedreg/" + lotclass,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSetLotClassificationForClosedReg;
};

const GetSetLotClassificationForClosedDist = async (lotclass) => {
  let GetSetLotClassificationForClosedDist;

  GetSetLotClassificationForClosedDist = await fetch(
    //getlotclassificationforeclosedreg
    "https://api.nha.gov.ph/cied/getlotclassificationforecloseddist/" +
      lotclass,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSetLotClassificationForClosedDist;
};

const GetSetLotClassificationReg = async (lotclass) => {
  let GetSetLotClassificationReg;

  GetSetLotClassificationReg = await fetch(
    //getlotclassification
    "https://api.nha.gov.ph/cied/getlotclassificationreg/" + lotclass,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSetLotClassificationReg;
};

const GetSetLotClassificationDist = async (lotclass) => {
  let GetSetLotClassificationDist;

  GetSetLotClassificationDist = await fetch(
    "https://api.nha.gov.ph/cied/getlotclassificationdist/" + lotclass,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSetLotClassificationDist;
};

const GetSetLotClassificationForClosedPropByReg = async (lotclass, region) => {
  let GetSetLotClassificationForClosedPropByReg;

  const regidString = region.join(",");

  GetSetLotClassificationForClosedPropByReg = await fetch(
    //getlotclassificationforeclosedprop
    "https://api.nha.gov.ph/cied/getlotclassificationforeclosedpropbyreg/" +
      lotclass +
      "/" +
      regidString,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSetLotClassificationForClosedPropByReg;
};

const GetRegLisLotClassForClosed = async (lotclass, region, lisclass) => {
  let GetRegLisLotClassForClosed;

  const regidString = region.join(",");

  GetRegLisLotClassForClosed = await fetch(
    //getlotclassificationforeclosedprop
    "https://api.nha.gov.ph/cied/getreglislotclassforclosed/" +
      lotclass +
      "/" +
      regidString +
      "/" +
      lisclass,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetRegLisLotClassForClosed;
};

const GetRegAwardStatLotClassForClosed = async (
  lotclass,
  region,
  awardstatus
) => {
  let newAwardStatus = awardstatus.replace(/\//g, "-");
  let GetRegAwardStatLotClassForClosed;

  const regidString = region.join(",");

  GetRegAwardStatLotClassForClosed = await fetch(
    //getreglislotclassforclosed
    "https://api.nha.gov.ph/cied/getawardstatlotclassforeclosed/" +
      lotclass +
      "/" +
      regidString +
      "/" +
      newAwardStatus,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetRegAwardStatLotClassForClosed;
};

const GetRegDistAwardStatLotClassForClosed = async (
  lotclass,
  region,
  district,
  awardstatus
) => {
  let newAwardStatus = awardstatus.map((item) => item.replace(/\//g, "-"));
  const awardstatString = newAwardStatus.join(",");
  let GetRegDistAwardStatLotClassForClosed;

  const regidString = region.join(",");

  GetRegDistAwardStatLotClassForClosed = await fetch(
    //getawardstatlotclassforeclosed
    "https://api.nha.gov.ph/cied/getregdistawardstatlotclassforeclosed/" +
      lotclass +
      "/" +
      regidString +
      "/" +
      district +
      "/" +
      awardstatString,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetRegDistAwardStatLotClassForClosed;
};

const GetRegDistAwardStatLotClass = async (
  lotclass,
  region,
  district,
  awardstatus
) => {
  let newAwardStatus = awardstatus.map((item) => item.replace(/\//g, "-"));
  const awardstatString = newAwardStatus.join(",");
  let GetRegDistAwardStatLotClass;

  const regidString = region.join(",");

  GetRegDistAwardStatLotClass = await fetch(
    //getawardstatlotclassforeclosed
    "https://api.nha.gov.ph/cied/getregdistawardstatlotclass/" +
      lotclass +
      "/" +
      regidString +
      "/" +
      district +
      "/" +
      awardstatString,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetRegDistAwardStatLotClass;
};

const GetAwardStatLotClassForeClosedNationWide = async (
  lotclass,
  awardstatus
) => {
  let newAwardStatus = awardstatus.map((item) => item.replace(/\//g, "-"));
  let lotclassString = lotclass.join(",");
  let awardstatString = newAwardStatus.join(",");
  let GetAwardStatLotClassForeClosedNationWide;

  GetAwardStatLotClassForeClosedNationWide = await fetch(
    //getreglislotclassforclosed
    "https://api.nha.gov.ph/cied/getawardstatlotclassforeclosednationwide/" +
      lotclassString +
      "/" +
      awardstatString,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetAwardStatLotClassForeClosedNationWide;
};

const LisClassAwardStatLotClassForeClosed = async (
  lotclass,
  lisclass,
  awardstatus
) => {
  let newAwardStatus = awardstatus.map((item) => item.replace(/\//g, "-"));
  let lotclassString = lotclass.join(",");
  let lisclassString = lisclass.join(",");
  let awardstatString = newAwardStatus.join(",");

  let LisClassAwardStatLotClassForeClosed;

  LisClassAwardStatLotClassForeClosed = await fetch(
    //getawardstatlotclassforeclosednationwide
    "https://api.nha.gov.ph/cied/lisclassawardstatLotclassforeclosed/" +
      lotclassString +
      "/" +
      lisclassString +
      "/" +
      awardstatString,

    { credentials: "include" }
  ).then((res) => res.json());

  return LisClassAwardStatLotClassForeClosed;
};

const LisClassAwardStatLotClass = async (lotclass, lisclass, awardstatus) => {
  let newAwardStatus = awardstatus.map((item) => item.replace(/\//g, "-"));
  let lotclassString = lotclass.join(",");
  let lisclassString = lisclass.join(",");
  let awardstatString = newAwardStatus.join(",");

  let LisClassAwardStatLotClass;

  LisClassAwardStatLotClass = await fetch(
    //lisclassawardstatLotclassforeclosed
    "https://api.nha.gov.ph/cied/lisclassawardstatLotclass/" +
      lotclassString +
      "/" +
      lisclassString +
      "/" +
      awardstatString,

    { credentials: "include" }
  ).then((res) => res.json());

  return LisClassAwardStatLotClass;
};

const GetAwardStatLotClassNationWide = async (lotclass, awardstatus) => {
  let newAwardStatus = awardstatus.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");
  let lotclassString = lotclass.join(",");
  let GetAwardStatLotClassNationWide;

  GetAwardStatLotClassNationWide = await fetch(
    //getreglislotclassforclosed
    "https://api.nha.gov.ph/cied/getawardstatlotclassnationwide/" +
      lotclassString +
      "/" +
      awardstatString,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetAwardStatLotClassNationWide;
};

const GetRegAwardStatLotClass = async (lotclass, region, awardstatus) => {
  let newAwardStatus = awardstatus.replace(/\//g, "-");
  let GetRegAwardStatLotClass;

  const regidString = region.join(",");

  GetRegAwardStatLotClass = await fetch(
    //getreglislotclassforclosed
    "https://api.nha.gov.ph/cied/getawardstatlotclass/" +
      lotclass +
      "/" +
      regidString +
      "/" +
      newAwardStatus,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetRegAwardStatLotClass;
};

const GetRegLisLotClass = async (lotclass, region, lisclass) => {
  let GetRegLisLotClass;

  const regidString = region.join(",");

  GetRegLisLotClass = await fetch(
    //getlotclassificationforeclosedprop
    "https://api.nha.gov.ph/cied/getreglislotclass/" +
      lotclass +
      "/" +
      regidString +
      "/" +
      lisclass,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetRegLisLotClass;
};

const GetSetLotClassificationForClosedPropByDist = async (
  region,
  district,
  lotclass
) => {
  let GetSetLotClassificationForClosedPropByDist;

  const regidString = region.join(",");

  GetSetLotClassificationForClosedPropByDist = await fetch(
    //getlotclassificationforeclosedpropbyreg
    "https://api.nha.gov.ph/cied/getlotclassificationforeclosedpropbydist/" +
      regidString +
      "/" +
      district +
      "/" +
      lotclass,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSetLotClassificationForClosedPropByDist;
};

const GetRegDisLisLotClassForClosed = async (
  region,
  district,
  lisclass,
  lotclass
) => {
  let GetRegDisLisLotClassForClosed;

  const regidString = region.join(",");

  GetRegDisLisLotClassForClosed = await fetch(
    //getlotclassificationforeclosedpropbyreg
    "https://api.nha.gov.ph/cied/getregdislislotclassforeclosed/" +
      regidString +
      "/" +
      district +
      "/" +
      lisclass +
      "/" +
      lotclass,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetRegDisLisLotClassForClosed;
};

const GetRegDisLisLotClass = async (region, district, lisclass, lotclass) => {
  let GetRegDisLisLotClass;

  const regidString = region.join(",");

  GetRegDisLisLotClass = await fetch(
    // getregdislislotclassforeclosed
    "https://api.nha.gov.ph/cied/getregdislislotclass/" +
      regidString +
      "/" +
      district +
      "/" +
      lisclass +
      "/" +
      lotclass,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetRegDisLisLotClass;
};

const GetForeClosedByDistRegAcct = async (district, lotclass) => {
  let GetForeClosedByDistRegAcct;

  GetForeClosedByDistRegAcct = await fetch(
    //getlotclassificationforeclosedpropbydist
    "https://api.nha.gov.ph/cied/getforeclosedbydistregacct/" +
      district +
      "/" +
      lotclass,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetForeClosedByDistRegAcct;
};

const GetSetLotClassificationByDist = async (region, district, lotclass) => {
  let GetSetLotClassificationByDist;

  const regidString = region.join(",");

  GetSetLotClassificationByDist = await fetch(
    //getlotclassificationforeclosedprop
    "https://api.nha.gov.ph/cied/getlotclassificationbydist/" +
      regidString +
      "/" +
      district +
      "/" +
      lotclass,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSetLotClassificationByDist;
};

const GetLotClassByDistRegAcct = async (district, lotclass) => {
  let GetLotClassByDistRegAcct;

  GetLotClassByDistRegAcct = await fetch(
    //getlotclassificationbydist
    "https://api.nha.gov.ph/cied/getlotclassbydistregacct/" +
      district +
      "/" +
      lotclass,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLotClassByDistRegAcct;
};

const GetSetLotClassificationByReg = async (lotclass, region) => {
  let GetSetLotClassificationByReg;

  const regidString = region.join(",");

  GetSetLotClassificationByReg = await fetch(
    //getlotclassificationforeclosedprop
    "https://api.nha.gov.ph/cied/getlotclassificationbyreg/" +
      lotclass +
      "/" +
      regidString,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSetLotClassificationByReg;
};

const GetLisclassByDistAcct = async (lisclass) => {
  let GetLisclassByDistAcct;

  GetLisclassByDistAcct = await fetch(
    "https://api.nha.gov.ph/cied/sortlisclassbydistacct/" + lisclass,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisclassByDistAcct;
};

const GetLisclassWithAgreementByDistAcct = async () => {
  let GetLisclassWithAgreementByDistAcct;

  GetLisclassWithAgreementByDistAcct = await fetch(
    "https://api.nha.gov.ph/cied/sortlisclasswithagreementbydistacct",

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisclassWithAgreementByDistAcct;
};

const GetAwardStatByDistAcct = async (awardstat) => {
  let GetAwardStatByDistAcct;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetAwardStatByDistAcct = await fetch(
    "https://api.nha.gov.ph/cied/sortawardstatbydistacct/" + newAwardStatus,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetAwardStatByDistAcct;
};

const GetLisClassAwardStatByDistAcct = async (awardstat, lisclass) => {
  let GetLisClassAwardStatByDistAcct;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetLisClassAwardStatByDistAcct = await fetch(
    "https://api.nha.gov.ph/cied/sortlisclassawardstatbydistacct/" +
      lisclass +
      "/" +
      newAwardStatus,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisClassAwardStatByDistAcct;
};

//GetLisClassAwardStatByDistAcct
const GetLisClassAwardStatWithAgreementByDistAcct = async (awardstat) => {
  let GetLisClassAwardStatWithAgreementByDistAcct;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetLisClassAwardStatWithAgreementByDistAcct = await fetch(
    //sortlisclassawardstatbydistacct
    "https://api.nha.gov.ph/cied/sortlisclassawardstatwithagreementbydistacct/" +
      newAwardStatus,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisClassAwardStatWithAgreementByDistAcct;
};

const GetLisClassAwardStatPropNameByDistAcct = async (
  propertid,
  lisclass,
  awardstat
) => {
  let GetLisClassAwardStatPropNameByDistAcct;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetLisClassAwardStatPropNameByDistAcct = await fetch(
    //sortlisclassawardstatbydistacct
    "https://api.nha.gov.ph/cied/sortlisclassawardstatpropnamebydistacct/" +
      lisclass +
      "/" +
      newAwardStatus +
      "/" +
      propertid,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisClassAwardStatPropNameByDistAcct;
};

const GetLisClassAwardStatPropNameWithAgreementByDistAcct = async (
  propertid,
  awardstat
) => {
  let GetLisClassAwardStatPropNameWithAgreementByDistAcct;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetLisClassAwardStatPropNameWithAgreementByDistAcct = await fetch(
    //sortlisclassawardstatbydistacct
    "https://api.nha.gov.ph/cied/sortlisclassawardstatpropnamewithagreementbydistacct/" +
      newAwardStatus +
      "/" +
      propertid,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisClassAwardStatPropNameWithAgreementByDistAcct;
};

const GetLisClassPropNameByDistAcct = async (propertid, lisclass) => {
  let GetLisClassPropNameByDistAcct;

  GetLisClassPropNameByDistAcct = await fetch(
    "https://api.nha.gov.ph/cied/sortlisclasspropnamebydistacct/" +
      lisclass +
      "/" +
      propertid,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisClassPropNameByDistAcct;
};

const GetLisClassPropNameWithAgreementByDistAcct = async (propertid) => {
  let GetLisClassPropNameWithAgreementByDistAcct;

  GetLisClassPropNameWithAgreementByDistAcct = await fetch(
    //sortlisclasspropnamebydistacct
    "https://api.nha.gov.ph/cied/sortlisclasspropnamewithagreementbydistacct/" +
      propertid,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisClassPropNameWithAgreementByDistAcct;
};

const GetAwardStatPropNameByDistAcct = async (propertid, awardstat) => {
  let GetAwardStatPropNameByDistAcct;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetAwardStatPropNameByDistAcct = await fetch(
    //sortlisclassawardstatpropnamewithagreementbydistacct
    "https://api.nha.gov.ph/cied/sortawardstatpropnamebydistacct/" +
      newAwardStatus +
      "/" +
      propertid,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetAwardStatPropNameByDistAcct;
};

const GetLisClassAwardStatPropNameLeaseAgreeByDistAcct = async (
  modeofdispo,
  lisclass,
  awardstat,
  propertid
) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetLisClassAwardStatPropNameLeaseAgreeByDistAcct;

  GetLisClassAwardStatPropNameLeaseAgreeByDistAcct = await fetch(
    //sortbydistlisclassawardstatpropnamemodeofdispo
    "https://api.nha.gov.ph/cied/sortlisclassawardstatpropnamemodedispobydistacct/" +
      lisclass +
      "/" +
      newAwardStatus +
      "/" +
      propertid +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisClassAwardStatPropNameLeaseAgreeByDistAcct;
};

const GetLisClassAwardStatPropNameLeaseAgreeWithAgreeByDistAcct = async (
  modeofdispo,
  lisclass,
  awardstat,
  propertid
) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetLisClassAwardStatPropNameLeaseAgreeWithAgreeByDistAcct;

  GetLisClassAwardStatPropNameLeaseAgreeWithAgreeByDistAcct = await fetch(
    //sortbydistlisclassawardstatpropnamemodeofdispowithagreement
    "https://api.nha.gov.ph/cied/sortlisclassawardstatpropnamemodedispowithagreebydistacct/" +
      lisclass +
      "/" +
      newAwardStatus +
      "/" +
      propertid +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisClassAwardStatPropNameLeaseAgreeWithAgreeByDistAcct;
};

const GetAwardStatPropNameLeaseAgreeByDistAcct = async (
  modeofdispo,
  awardstat,
  propertid
) => {
  let GetAwardStatPropNameLeaseAgreeByDistAcct;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetAwardStatPropNameLeaseAgreeByDistAcct = await fetch(
    //sortbydistawardstatpropnamemodedispo
    "https://api.nha.gov.ph/cied/sortawardstatpropnamemodedispobydistacct/" +
      newAwardStatus +
      "/" +
      propertid +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetAwardStatPropNameLeaseAgreeByDistAcct;
};

const GetAwardStatPropNameWithAgree = async (
  modeofdispo,
  awardstat,
  propertid
) => {
  let GetAwardStatPropNameWithAgree;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetAwardStatPropNameWithAgree = await fetch(
    //sortawardstatpropnamemodedispobydistacct
    "https://api.nha.gov.ph/cied/sortawardstatpropnamewithagree/" +
      newAwardStatus +
      "/" +
      propertid +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetAwardStatPropNameWithAgree;
};

const GetLisclassPropnameNegoLeaseAgree = async (
  modeofdispo,
  lisclass,
  propertid
) => {
  let GetLisclassPropnameNegoLeaseAgree;

  GetLisclassPropnameNegoLeaseAgree = await fetch(
    //sortawardstatpropnamemodedispobydistacct
    "https://api.nha.gov.ph/cied/sortlisclasspropnamenegolease/" +
      lisclass +
      "/" +
      propertid +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisclassPropnameNegoLeaseAgree;
};

const GetLisClassLeaseAgreeByDistAcct = async (modeofdispo, lisclass) => {
  let GetLisClassLeaseAgreeByDistAcct;

  GetLisClassLeaseAgreeByDistAcct = await fetch(
    //sortbydistlisclassmodeofdispo
    "https://api.nha.gov.ph/cied/sortlisclassmodedispobydistacct/" +
      lisclass +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisClassLeaseAgreeByDistAcct;
};

const GetLisClassLeaseAgreeWithAgreeByDistAcct = async (
  modeofdispo,
  lisclass
) => {
  let GetLisClassLeaseAgreeWithAgreeByDistAcct;

  GetLisClassLeaseAgreeWithAgreeByDistAcct = await fetch(
    //sortlisclassmodedispobydistacct
    "https://api.nha.gov.ph/cied/sortlisclassmodedispowithagreebydistacct/" +
      modeofdispo +
      "/" +
      lisclass,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetLisClassLeaseAgreeWithAgreeByDistAcct;
};

const GetAwardStatLeaseAgreeByDistAcct = async (modeofdispo, awardstat) => {
  let GetAwardStatLeaseAgreeByDistAcct;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetAwardStatLeaseAgreeByDistAcct = await fetch(
    //sortbydistawardstatmodedispo
    "https://api.nha.gov.ph/cied/sortlisclassmodedispobydistrictacct/" +
      newAwardStatus +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetAwardStatLeaseAgreeByDistAcct;
};

const GetAwardStatWithAgree = async (modeofdispo, awardstat) => {
  let GetAwardStatWithAgree;
  let newAwardStatus = awardstat.replace(/\//g, "-");

  GetAwardStatWithAgree = await fetch(
    //sortlisclassmodedispobydistrictacct
    "https://api.nha.gov.ph/cied/sortawardstatwithagree/" +
      newAwardStatus +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetAwardStatWithAgree;
};

const GetSortbyLisClassAndAwardStatNationwideWithAgree = async (awardstat) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortbyLisClassAndAwardStatNationwideWithAgree;
  GetSortbyLisClassAndAwardStatNationwideWithAgree = await fetch(
    //sortbylisclassandawardstatnationwide
    "https://api.nha.gov.ph/cied/sortbylisclassandawardstatnationwidewithagree/" +
      newAwardStatus,

    { credentials: "include" }
  ).then((res) => res.json());
  return GetSortbyLisClassAndAwardStatNationwideWithAgree;
};

const GetSortByLisclassAwardstatModedispoAdmin = async (
  modeofdispo,
  lisclass,
  awardstat
) => {
  let GetSortByLisclassAwardstatModedispoAdmin;
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");
  let lisclassString = lisclass.join(",");

  GetSortByLisclassAwardstatModedispoAdmin = await fetch(
    //sortbylisclassawardstatmodedispo
    "https://api.nha.gov.ph/cied/sortbylisclassawardstatmodedispoadmin/" +
      lisclassString +
      "/" +
      awardstatString +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByLisclassAwardstatModedispoAdmin;
};

const GetSortByLisclassAwardstatModedispoWithAgreeAdmin = async (
  modeofdispo,
  lisclass,
  awardstat
) => {
  let GetSortByLisclassAwardstatModedispoWithAgreeAdmin;
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");
  let lisclassString = lisclass.join(",");

  GetSortByLisclassAwardstatModedispoWithAgreeAdmin = await fetch(
    //sortbylisclassawardstatmodedispoadmin
    "https://api.nha.gov.ph/cied/sortbylisclassawardstatmodedispowithagreeadmin/" +
      lisclassString +
      "/" +
      awardstatString +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByLisclassAwardstatModedispoWithAgreeAdmin;
};

const GetSortByAwardstatModedispoAdmin = async (modeofdispo, awardstat) => {
  let GetSortByAwardstatModedispoAdmin;
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");

  GetSortByAwardstatModedispoAdmin = await fetch(
    //sortbyawardstatmodedispo
    "https://api.nha.gov.ph/cied/sortbyawardstatmodedispoadmin/" +
      awardstatString +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByAwardstatModedispoAdmin;
};

const GetSortByLisclassModeDispoNationWide = async (modeofdispo, lisclass) => {
  let GetSortByLisclassModeDispoNationWide;
  let lisclassString = lisclass.join(",");

  GetSortByLisclassModeDispoNationWide = await fetch(
    //sortbylisclassmodeofdispoalldistrict
    "https://api.nha.gov.ph/cied/sortbylisclassmodeofdisponationwide/" +
      lisclassString +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByLisclassModeDispoNationWide;
};

const GetSortByLisclassModeDispoWithAgreeNationWide = async (
  modeofdispo,
  lisclass
) => {
  let GetSortByLisclassModeDispoWithAgreeNationWide;
  let lisclassString = lisclass.join(",");

  GetSortByLisclassModeDispoWithAgreeNationWide = await fetch(
    //sortbylisclassmodeofdisponationwide
    "https://api.nha.gov.ph/cied/sortbylisclassmodeofdispowithagreenationwide/" +
      modeofdispo +
      "/" +
      lisclassString,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByLisclassModeDispoWithAgreeNationWide;
};

const GetSortByDistLisclassAwardstatModeDispo = async (
  modeofdispo,
  district,
  lisclass,
  awardstat
) => {
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");
  let lisclassString = lisclass.join(",");
  let GetSortByDistLisclassAwardstatModeDispo;

  GetSortByDistLisclassAwardstatModeDispo = await fetch(
    //sortbydistlisclassawardstatpropnamemodeofdispo
    "https://api.nha.gov.ph/cied/sortbydistlisclassawardstatmodeofdispo/" +
      district +
      "/" +
      lisclassString +
      "/" +
      awardstatString +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassAwardstatModeDispo;
};

const GetSortByDistLisclassAwardstatModeDispoReg = async (
  modeofdispo,
  district,
  lisclass,
  awardstat
) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortByDistLisclassAwardstatModeDispoReg;

  GetSortByDistLisclassAwardstatModeDispoReg = await fetch(
    //sortbydistlisclassawardstatmodeofdispo
    "https://api.nha.gov.ph/cied/sortbydistlisclassawardstatmodeofdisporeg/" +
      district +
      "/" +
      lisclass +
      "/" +
      newAwardStatus +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassAwardstatModeDispoReg;
};

const GetSortByDistLisclassAwardstatModeDispoWithAgree = async (
  modeofdispo,
  district,
  lisclass,
  awardstat
) => {
  let newAwardStatus = awardstat.map((item) => item.replace(/\//g, "-"));
  let awardstatString = newAwardStatus.join(",");
  let lisclassString = lisclass.join(",");

  let GetSortByDistLisclassAwardstatModeDispoWithAgree;

  GetSortByDistLisclassAwardstatModeDispoWithAgree = await fetch(
    //sortbydistlisclassawardstatmodeofdispo
    "https://api.nha.gov.ph/cied/sortbydistlisclassawardstatmodeofdispowithagree/" +
      district +
      "/" +
      lisclassString +
      "/" +
      awardstatString +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassAwardstatModeDispoWithAgree;
};

const GetSortByDistLisclassAwardstatModeDispoWithAgreeReg = async (
  modeofdispo,
  district,
  lisclass,
  awardstat
) => {
  let newAwardStatus = awardstat.replace(/\//g, "-");
  let GetSortByDistLisclassAwardstatModeDispoWithAgreeReg;

  GetSortByDistLisclassAwardstatModeDispoWithAgreeReg = await fetch(
    //sortbydistlisclassawardstatmodeofdispowithagree
    "https://api.nha.gov.ph/cied/sortbydistlisclassawardstatmodeofdispowithagreereg/" +
      district +
      "/" +
      lisclass +
      "/" +
      newAwardStatus +
      "/" +
      modeofdispo,

    { credentials: "include" }
  ).then((res) => res.json());

  return GetSortByDistLisclassAwardstatModeDispoWithAgreeReg;
};

const GetAllNotification = async () => {
  let GetAllNotification;
  GetAllNotification = await fetch("https://api.nha.gov.ph/cied/getallnotif", {
    credentials: "include",
  }).then((res) => res.json());
  return GetAllNotification;
};

const GetNotif = async () => {
  let GetNotif;
  GetNotif = await fetch("https://api.nha.gov.ph/cied/getnotif", {
    credentials: "include",
  }).then((res) => res.json());
  return GetNotif;
};

const GetAllUnvalidate = async () => {
  let GetAllUnvalidate;
  GetAllUnvalidate = await fetch(
    "https://api.nha.gov.ph/cied/getallunvalidate",
    {
      credentials: "include",
    }
  ).then((res) => res.json());
  return GetAllUnvalidate;
};

const GetAllReturned = async () => {
  let GetAllReturned;
  GetAllReturned = await fetch("https://api.nha.gov.ph/cied/getallreturned", {
    credentials: "include",
  }).then((res) => res.json());
  return GetAllReturned;
};

const GetAllValidatedByRegion = async () => {
  let GetAllValidatedByRegion;
  GetAllValidatedByRegion = await fetch(
    //getallunvalidate
    "https://api.nha.gov.ph/cied/getallvalidatedbyregion",
    {
      credentials: "include",
    }
  ).then((res) => res.json());
  return GetAllValidatedByRegion;
};

const GetAdminAllReturned = async () => {
  let GetAdminAllReturned;
  GetAdminAllReturned = await fetch(
    "https://api.nha.gov.ph/cied/getadminallreturned",
    {
      credentials: "include",
    }
  ).then((res) => res.json());
  return GetAdminAllReturned;
};

const GetComplied = async () => {
  let GetComplied;
  GetComplied = await fetch("https://api.nha.gov.ph/cied/getcomplied", {
    credentials: "include",
  }).then((res) => res.json());
  return GetComplied;
};

const GetDistAllReturned = async () => {
  let GetDistAllReturned;
  GetDistAllReturned = await fetch(
    "https://api.nha.gov.ph/cied/getdistallreturned",
    {
      credentials: "include",
    }
  ).then((res) => res.json());
  return GetDistAllReturned;
};

//GetDistAllReturned
//getdistallreturned

const GetAdminCheckUnvalidate = async () => {
  let GetAdminCheckUnvalidate;
  GetAdminCheckUnvalidate = await fetch(
    "https://api.nha.gov.ph/cied/getalladmincheckunvalidate",
    {
      credentials: "include",
    }
  ).then((res) => res.json());
  return GetAdminCheckUnvalidate;
};

const GetAdminCheckComplied = async () => {
  let GetAdminCheckComplied;
  GetAdminCheckComplied = await fetch(
    "https://api.nha.gov.ph/cied/getalladmincheckcomplied",
    {
      credentials: "include",
    }
  ).then((res) => res.json());
  return GetAdminCheckComplied;
};

const HandleDeleteData = async (id) => {
  try {
    let res = await fetch("https://api.nha.gov.ph/cied/deletedata/" + id, {
      credentials: "include",
      method: "DELETE",
    });

    if (res.status === 200) {
      return "0000"; // Success
    } else {
      return "1111"; // Failure, non-200 status code
    }
  } catch (error) {
    console.error("Error:", error);
    return "1111"; // Failure, error occurred during fetch
  }
};

export {
  AddInventory,
  GetInventory,
  UpdateInventory,
  GetInventoryNonEstate,
  GetAllInventory,
  GetProjectInfobyCode,
  GetProjectInfobyBlk,
  GetImage,
  GetProjectInfobyId,
  GetGeomEstate,
  GetGeomNonEstate,
  UpdateInventoryStatus,
  UpdateInventoryStatusValidation,
  GetSortbyLISClass,
  GetSortbyLISCat,
  GetSortbyLISAwardStat,
  GetSortbyProject,
  GetInventorybyRegion,
  GetInventorybyDistrict,
  GetAllInventoryUnsold,
  GetAllInventorysold,
  GetSortByDisModeDispo,
  GetInventorySoldbyRegion,
  GetSoldNonestateProject,
  GetInventoryUnsoldbyRegion,
  GetUnsoldNonestateProject,
  GetSortbyLISClassNationwide,
  GetSortbyAwardStatNationwide,
  GetSortbyLISClassAndDistrict,
  GetSortbyLisClassAndAwardStatNationwide,
  GetSortbyDistLisclassAwardStat,
  GetSortProjectbyDistLisclassAwardstatPropname,
  GetSortProjectbyPropnameWithAgreement,
  GetSortbyLISClassWithAgreementNationwide,
  GetSortbyLISClassAndDistrictWithAgreement,
  GetSortByModeDispoNationwide,
  GetSortbyLISClassAndRegion,
  GetSortbyLISClassAndRegionWithAgreement,
  GetSortbyRegionAwardStat,
  GetSortByRegModeDispo,
  GetSortbyDistrictAwardStat,
  GetSortbyLISClassAllDistrict,
  GetSortbyLISClassAllDistWithAgreement,
  GetSortbyAwardStatByRegion,
  GetSortbyLisClassAndAwardStatAllDist,
  GetSortbyDistrictLisclassAwardStat,
  GetSortbyDistrictLisclassAwardStatWithAgreement,
  GetSortbyRegionLisclassAwardstat,
  GetSortbyRegionLisclassAwardstatWithAgreement,
  GetSortByDistLisclassAwardstatPropnameModeDispo,
  GetSortByDistLisclassAwardstatPropnameModeDispoWithAgreement,
  GetSortbyLisClassAndAwardStatAllDistWithAgreement,
  GetSortByModeDispoAllDistrict,
  GetSortByLisclassAwardstatModedispo,
  GetSortByLisclassAwardstatModedispoWithAgreement,
  GetSortByAwardstatModedispo,
  GetSortByLisclassModeDispoAllDistrict,
  GetSortByLisclassModeDispoAllDistrictWithAgreement,
  GetSortByDistLisclassModeDispo,
  GetSortByDistLisclassModeDispoWithAgreement,
  GetSortByDistAwardStatModeDispo,
  GetSortByDistAwardStatPropnameModeDispo,
  GetSortByDistAwardstatPropname,
  GetSortByDistLisclassPropname,
  GetSortByDistLisclassPropnameWithAgreement,
  GetSortByDistLisclassPropnameModeDispo,
  GetSortByDistLisclassPropnameModeDispoWithAgreement,
  GetLisclassByDistAcct,
  GetLisclassWithAgreementByDistAcct,
  GetAwardStatByDistAcct,
  GetLisClassAwardStatByDistAcct,
  GetLisClassAwardStatWithAgreementByDistAcct,
  GetLisClassAwardStatPropNameByDistAcct,
  GetLisClassAwardStatPropNameWithAgreementByDistAcct,
  GetLisClassPropNameByDistAcct,
  GetLisClassPropNameWithAgreementByDistAcct,
  GetAwardStatPropNameByDistAcct,
  GetLisClassAwardStatPropNameLeaseAgreeByDistAcct,
  GetLisClassAwardStatPropNameLeaseAgreeWithAgreeByDistAcct,
  GetAwardStatPropNameLeaseAgreeByDistAcct,
  GetLisClassLeaseAgreeByDistAcct,
  GetLisClassLeaseAgreeWithAgreeByDistAcct,
  GetAwardStatLeaseAgreeByDistAcct,
  GetSortbyLisClassAndAwardStatNationwideWithAgree,
  GetSortByLisclassAwardstatModedispoAdmin,
  GetSortByLisclassAwardstatModedispoWithAgreeAdmin,
  GetSortByAwardstatModedispoAdmin,
  GetSortByLisclassModeDispoNationWide,
  GetSortByLisclassModeDispoWithAgreeNationWide,
  GetSortByDistLisclassAwardstatModeDispo,
  GetSortByDistLisclassAwardstatModeDispoWithAgree,
  GetSortByModeDispoByDistrict,
  GetNotif,
  GetAllNotification,
  GetSortWithAgreeNationwide,
  GetImageFlood,
  GetImageSeis,
  GetSortByRegWithAgreement,
  GetSortWithAgreementAllDistrict,
  GetSortWithAgreementPerDist,
  GetLisclassPropnameModeDispo,
  GetLisclassPropnameNegoLeaseAgree,
  GetDistAcctWithAgree,
  GetAwardStatWithAgree,
  GetAwardStatPropNameWithAgree,
  GetAwardStatPropNameReg,
  GetAwardStatPropNameWithAgreeReg,
  GetSetLotClassification,
  GetSetLotClassificationForClosedProp,
  GetSetLotClassificationForClosedPropByReg,
  GetSetLotClassificationByReg,
  GetSetLotClassificationByDist,
  GetSetLotClassificationForClosedPropByDist,
  GetSetLotClassificationForClosedReg,
  GetSetLotClassificationReg,
  GetForeClosedByDistRegAcct,
  GetLotClassByDistRegAcct,
  GetSetLotClassificationForClosedDist,
  GetSetLotClassificationDist,
  GetAllUnvalidate,
  GetAllReturned,
  GetAllValidatedByRegion,
  GetAdminAllReturned,
  GetComplied,
  GetDistAllReturned,
  GetAdminCheckUnvalidate,
  GetAdminCheckComplied,
  GetRegLisLotClassForClosed,
  GetRegLisLotClass,
  GetRegDisLisLotClassForClosed,
  GetRegDisLisLotClass,
  GetRegAwardStatLotClassForClosed,
  GetRegAwardStatLotClass,
  GetLisLotClassForeclosed,
  GetLisLotClass,
  GetAwardStatLotClassForeClosedNationWide,
  GetAwardStatLotClassNationWide,
  GetRegDistAwardStatLotClassForClosed,
  GetRegDistAwardStatLotClass,
  GetSortbyLISClassAndDistrictReg,
  GetSortbyDistrictLisclassAwardStatReg,
  GetSortProjectbyDistLisclassAwardstatPropnameReg,
  GetSortByDistAwardstatPropnameReg,
  GetSortbyDistrictAwardStatReg,
  GetSortByDistLisclassPropnameReg,
  GetSortByDistLisclassAwardstatPropnameModeDispoReg,
  GetSortByDistLisclassAwardstatPropnameModeDispoWithAgreementReg,
  GetSortByDistLisclassModeDispoReg,
  GetSortByDistLisclassModeDispoWithAgreementReg,
  GetSortByDistAwardStatModeDispoReg,
  GetSortByDistAwardStatPropnameModeDispoReg,
  GetSortByDistLisclassPropnameModeDispoReg,
  GetSortByDistLisclassPropnameModeDispoWithAgreementReg,
  GetSortByDistLisclassAwardstatModeDispoReg,
  GetSortByDistLisclassAwardstatModeDispoWithAgreeReg,
  LisClassAwardStatLotClassForeClosed,
  LisClassAwardStatLotClass,
  HandleDeleteData,
};
