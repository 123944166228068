const GetTotalInventory = () => {
  try {
    let GetTotalInventory = fetch(
      "https://api.nha.gov.ph/cied/totalInventory",
      {
        credentials: "include",
      }
    ).then((response) => response.json());
    return GetTotalInventory;
  } catch (err) {
    console.log(err);
  }
};

const GetTotalInventoryEstate = () => {
  try {
    let GetTotalInventoryEstate = fetch(
      "https://api.nha.gov.ph/cied/totalInventoryEstate",
      {
        credentials: "include",
      }
    ).then((response) => response.json());
    return GetTotalInventoryEstate;
  } catch (err) {
    console.log(err);
  }
};

const GetTotalInventoryNonEstate = () => {
  try {
    let GetTotalInventoryNonEstate = fetch(
      "https://api.nha.gov.ph/cied/totalInventoryNonEstate",
      {
        credentials: "include",
      }
    ).then((response) => response.json());
    return GetTotalInventoryNonEstate;
  } catch (err) {
    console.log(err);
  }
};

const GetTotalInventoryEstateBrkDwn = () => {
  try {
    let GetTotalInventoryEstateBrkDwn = fetch(
      "https://api.nha.gov.ph/cied/totalInventoryEstateBrkDwn",
      {
        credentials: "include",
      }
    ).then((response) => response.json());
    return GetTotalInventoryEstateBrkDwn;
  } catch (err) {
    console.log(err);
  }
};
const GetTotalInventoryNonEstateBrkDwn = () => {
  try {
    let GetTotalInventoryNonEstateBrkDwn = fetch(
      "https://api.nha.gov.ph/cied/totalInventoryNonEstateBrkDwn",
      {
        credentials: "include",
      }
    ).then((response) => response.json());
    return GetTotalInventoryNonEstateBrkDwn;
  } catch (err) {
    console.log(err);
  }
};

const GetTotalInventoryEstateAwdStat = () => {
  try {
    let GetTotalInventoryEstateAwdStat = fetch(
      "https://api.nha.gov.ph/cied/totalInventoryEstateAwdStat",
      {
        credentials: "include",
      }
    ).then((response) => response.json());
    return GetTotalInventoryEstateAwdStat;
  } catch (err) {
    console.log(err);
  }
};

const GetTotalInventoryNonEstateAwdStat = () => {
  try {
    let GetTotalInventoryNonEstateAwdStat = fetch(
      "https://api.nha.gov.ph/cied/totalInventoryNonEstateAwdStat",
      {
        credentials: "include",
      }
    ).then((response) => response.json());
    return GetTotalInventoryNonEstateAwdStat;
  } catch (err) {
    console.log(err);
  }
};

const GetTotalInventoryNonEstatePhyStat = () => {
  try {
    let GetTotalInventoryNonEstatePhyStat = fetch(
      "https://api.nha.gov.ph/cied/totalInventoryNonEstatePhyStat",
      {
        credentials: "include",
      }
    ).then((response) => response.json());
    return GetTotalInventoryNonEstatePhyStat;
  } catch (err) {
    console.log(err);
  }
};

const GetTotalInventoryEstatePhyStat = () => {
  try {
    let GetTotalInventoryEstatePhyStat = fetch(
      "https://api.nha.gov.ph/cied/totalInventoryEstatePhyStat",
      {
        credentials: "include",
      }
    ).then((response) => response.json());
    return GetTotalInventoryEstatePhyStat;
  } catch (err) {
    console.log(err);
  }
};

const GetTotalInventoryNonEstateSesu = () => {
  try {
    let GetTotalInventoryNonEstateSesu = fetch(
      "https://api.nha.gov.ph/cied/totalInventoryNonEstateSesu",
      {
        credentials: "include",
      }
    ).then((response) => response.json());
    return GetTotalInventoryNonEstateSesu;
  } catch (err) {
    console.log(err);
  }
};

const GetTotalInventoryEstateSesu = () => {
  try {
    let GetTotalInventoryEstateSesu = fetch(
      "https://api.nha.gov.ph/cied/totalInventoryEstateSesu",
      {
        credentials: "include",
      }
    ).then((response) => response.json());
    return GetTotalInventoryEstateSesu;
  } catch (err) {
    console.log(err);
  }
};

const GetBreakdownPerRegion = () => {
  try {
    let GetBreakdownPerRegion = fetch(
      "https://api.nha.gov.ph/cied/breadownPerRegion",
      {
        credentials: "include",
      }
    ).then((response) => response.json());
    return GetBreakdownPerRegion;
  } catch (err) {
    console.log(err);
  }
};

const GetTotalInventoryNonEstateModDispo = () => {
  try {
    let GetTotalInventoryNonEstateModDispo = fetch(
      "https://api.nha.gov.ph/cied/totalInventoryNonEstateModispo",
      {
        credentials: "include",
      }
    ).then((response) => response.json());
    return GetTotalInventoryNonEstateModDispo;
  } catch (err) {
    console.log(err);
  }
};

const GetTotalInventoryEstateModDispo = () => {
  try {
    let GetTotalInventoryEstateModDispo = fetch(
      "https://api.nha.gov.ph/cied/totalInventoryEstateModispo",
      {
        credentials: "include",
      }
    ).then((response) => response.json());
    return GetTotalInventoryEstateModDispo;
  } catch (err) {
    console.log(err);
  }
};

export {
  GetTotalInventory,
  GetTotalInventoryEstate,
  GetTotalInventoryNonEstate,
  GetTotalInventoryEstateBrkDwn,
  GetTotalInventoryNonEstateBrkDwn,
  GetTotalInventoryEstateAwdStat,
  GetTotalInventoryNonEstateAwdStat,
  GetTotalInventoryNonEstatePhyStat,
  GetTotalInventoryEstatePhyStat,
  GetTotalInventoryNonEstateSesu,
  GetTotalInventoryEstateSesu,
  GetBreakdownPerRegion,
  GetTotalInventoryNonEstateModDispo,
  GetTotalInventoryEstateModDispo,
};
