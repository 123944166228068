import React, { useState, useRef } from "react";
// import "./styles/Upload.css";
import * as FiIcons from "react-icons/fi";

function Uploadmap({ handlePreviewImage, getImageData, toggleupload }) {
  const [files, setFiles] = useState(null);
  const [file, setFile] = useState(null);
  const inputRef = useRef();

  const handleDragOvermap = (event) => {
    event.preventDefault();
  };

  const triggerFunction = () => {
    setFile("");
  };

  const handleDropmap = (event) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile) {
      setFiles([selectedFile]);

      handlePreviewImage(URL.createObjectURL(selectedFile));
    }
  };

  const [fileName, setFileName] = useState("");

  const handleUploadmap = () => {
    alert("Image attached and ready for saving.");

    if (file) {
      convertToBase64(file)
        .then((base64Data) => {
          getImageData(base64Data);
          setFiles(null);
        })
        .catch((error) => {
          console.error("Failed to convert image to Base64:", error);
        });
    }
  };
  
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];

        resolve(base64Data);
      };

      reader.onerror = () => {
        reject(new Error("Failed to convert image to Base64"));
      };
    });
  };

  const handleFileSelect = (event) => {
    const selectedFiles = event.target.files;
    setFiles(selectedFiles);
    console.log(selectedFiles);
    setFile(event.target.files[0]);
    setFileName(event.target.files[0].name);

    if (selectedFiles && selectedFiles.length > 0) {
      handlePreviewImage(URL.createObjectURL(selectedFiles[0]));
    }
  };

  const handleCancel = () => {
    setFiles(null);
  };

  // const renderFileNames = () => {
  //   return Array.from(files).map((file) => (
  //     <li key={file.path}>{file.name}</li>
  //   ));
  // };

  const renderFileNames = () => {
    return Array.from(files).map((file, index) => (
      <li key={index}>{file.name}</li>
    ));
  };

  if (files) {
    return (
      <div
        className="p-4"
        style={{ width: "500px", height: "200px", border: "4px dotted gray" }}
      >
        <div className="d-flex flex-column align-items-center">
          <p>File name:</p>
          <ul>{renderFileNames()}</ul>
          <div className="d-flex" style={{ gap: "10px" }}>
            <button className="btn btn-danger" onClick={handleCancel}>
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                toggleupload();
                handleUploadmap();
              }}
              data-dismiss="modal"
            >
              Attach
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="p-4"
      onDragOver={handleDragOvermap}
      onDrop={handleDropmap}
      style={{ width: "500px", height: "200px", border: "4px dotted gray" }}
    >
      <div className="d-flex  flex-column align-items-center">
        <FiIcons.FiUpload
          className=""
          style={{ fontSize: "50px", color: "17a2b8" }}
        />
        <p className="text-center">
          Drag & drop image file here <br />
          or click to select image file
        </p>
        <input type="file" onChange={handleFileSelect} hidden ref={inputRef} />
        <button
          className="btn btn-primary"
          onClick={() => inputRef.current.click()}
        >
          Select Image File
        </button>
      </div>
    </div>
  );
}

export default Uploadmap;
