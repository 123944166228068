const GetRegionalOffice = () => {
  try {
    let getregionlist = fetch("https://api.nha.gov.ph/cied/bpro", {
      credentials: "include",
    }).then((response) => response.json());
    return getregionlist;
  } catch (err) {
    console.log(err);
  }
};

const GetDistIndi = async (regionId) => {
  let districtList;
  districtList = await fetch(
    "https://api.nha.gov.ph/cied/bpdoindi/" + regionId,
    { credentials: "include" }
  ).then((res) => res.json());
  return districtList;
};

export { GetRegionalOffice, GetDistIndi };
