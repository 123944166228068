import React from "react";
import { useEffect, useState, useMemo, useRef } from "react";
import Header from "../layoutcomponents/Header";
import Sidebar from "../layoutcomponents/Sidebar";
import Footer from "../layoutcomponents/Footer";
import md5 from "md5";

import { ToastContainer, toast } from "react-toastify";
import { GetDistIndi, GetRegionalOffice } from "../functions/fx_initialdata";
import { AddUsers, GetUsers, UpdateUsers } from "../functions/fx_users";
import { BsEye, BsPencilSquare } from "react-icons/bs";

import PaginationUser from "../components/PaginationUser";

const User = () => {
  /////////////START OF SEARCH INPUT///
  const [users, setUsers] = useState([]);
  const [isOpenpagetag, setIsOpenpagetag] = useState(true);
  const [query, setQuery] = useState("");

  const handlesetQuery = (event) => {
    if (event.target.value === "") {
      console.log(isOpenpagetag);
      setCurrentPage(1);
      setUsersPerPage(30);
      setIsOpenpagetag(true);
    } else {
      setCurrentPage(1);
      setUsersPerPage(users.length);
      setIsOpenpagetag(false);
    }

    setQuery(event.target.value);
  };

  //////////////END OF SEARCH INPUT///////////////

  //////////////PAGINATION///////////////

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(30);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);
  const onPageChange = (pageNumber) => setCurrentPage(pageNumber);

  //////////////END OF PAGINATION///////////////

  const [isPopperOpen, setIsPopperOpen] = useState(false);

  // Variable for Adding Users //
  const [accesslevel, setAccessLevel] = useState("");
  const [position, setPosition] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [fname, setFName] = useState("");
  const [minitial, setMInitial] = useState("");
  const [lname, setLName] = useState("");
  const [emailaddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [regionValue, setRegionValue] = useState("");
  const [districtValue, setDistrictValue] = useState("");
  const [userstatus, setUserStatus] = useState("");
  // Variable for Adding Users //

  // Variable for Edit Users //
  const [accesslevel_edit, setAccessLevel_edit] = useState("");
  const [position_edit, setPosition_edit] = useState("");
  const [employeeNumber_edit, setEmployeeNumber_edit] = useState("");
  const [fname_edit, setFName_edit] = useState("");
  const [minitial_edit, setMInitial_edit] = useState("");
  const [lname_edit, setLName_edit] = useState("");

  const [origemailaddress_edit, setOrigEmailAddress_edit] = useState("");
  const [emailaddress_edit, setEmailAddress_edit] = useState("");

  const [password_edit, setPassword_edit] = useState("");
  const [regionValue_edit, setRegionValue_edit] = useState("");
  const [districtValue_edit, setDistrictValue_edit] = useState("");
  const [userstatus_edit, setUserStatus_edit] = useState("");
  const [setReadOnly, set_setReadOnly] = useState(true);
  const clear_checkbox = useRef();
  // Variable for Edit Users //

  const [region, setRegion] = useState([]);
  const [district, setDistrict] = useState([]);
  const [disableselect, setDisableselect] = useState(false);
  const [usersProfile, setUsersProfile] = useState([]);
  const [buttonstatus, setButtonStatus] = useState("");
  const [usersid, setUsersId] = useState("");
  const [dataRemove, setDataRemove] = useState(false);

  const handleChangeRegion = (e) => {
    setRegionValue(e.target.value);
    // setSetloading(true);
    GetDistIndi(e.target.value)
      .then((value) => {
        setDistrict(value.data);
        // setSetloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeRegionEdit = (e) => {
    setRegionValue_edit(e.target.value);

    // setSetloading(true);
    GetDistIndi(e.target.value)
      .then((value) => {
        setDistrict(value.data);
        // setSetloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAllUsers = () => {
    // setSetloading(true);
    GetUsers()
      .then((value) => {
        setUsers(value.data);
        // setSetloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //remove data
  const removeData = () => {
    setAccessLevel("");
    setPosition("");
    setEmployeeNumber("");
    setFName("");
    setMInitial("");
    setLName("");
    setEmailAddress("");
    setRegionValue("");
    setDistrictValue("");
    setPassword("NHA" + makeid(5));
  };

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const getRegionalOffice = () => {
    // setSetloading(true);
    GetRegionalOffice()
      .then((value) => {
        setRegion(value.data);
        // setSetloading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdateUser = () => {
    let res = UpdateUsers(
      accesslevel_edit,
      position_edit,
      employeeNumber_edit,
      fname_edit,
      minitial_edit,
      lname_edit,
      emailaddress_edit,
      password_edit,
      regionValue_edit,
      districtValue_edit,
      md5,
      userstatus_edit,
      usersid
    );
    res
      .then((value) => {
        if (value === "0000") {
          notifySuccess("Selected user's account succesfully updated ...");
          setAccessLevel("");
          setPosition("");
          setEmployeeNumber("");
          setFName("");
          setMInitial("");
          setLName("");
          setEmailAddress("");
          setRegionValue("");
          setDistrictValue("");
          setUserStatus("");
          setAccessLevel("");
          setPassword_edit("");
          getAllUsers();
          clear_checkbox.current.checked = false;
          set_setReadOnly(true);
        } else {
          notifyWarning("Error: Entries did not insert into datadase...");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [isValid, setIsValid] = useState(true);
  const [isValidedit, setIsValidEdit] = useState(true);
  const [existedit, setExistEdit] = useState("");
  const [exist, setExist] = useState("");

  const checkEmailExists = async (insertemail) => {
    // Check if any user's email address matches the provided email
    const emailExists = users.some((user) => user.USRX_EMAIL === insertemail);

    return emailExists;
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmailAddress(newEmail);
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(newEmail));

    //Checking of email if already exist in the database
    const emailExists = users.some((user) => user.USRX_EMAIL === newEmail);

    setExist(emailExists);
  };

  const handleAddUser = async () => {
    const trimmedEmail = emailaddress.trim();
    const emailExists = await checkEmailExists(trimmedEmail);

    if (accesslevel === "3" && regionValue && districtValue === "") {
      notifyWarning("Please select district office.");
    } else if (
      accesslevel === "3" &&
      regionValue === "" &&
      districtValue === ""
    ) {
      notifyWarning("Please select regional office and district office.");
    } else if (accesslevel === "2" && regionValue === "") {
      notifyWarning("Please select regional office.");
    } else if (
      accesslevel === "" &&
      districtValue === "" &&
      regionValue === ""
    ) {
      notifyWarning("Please fill out the form properly.");
    } else if (fname === "" || lname === "") {
      notifyWarning("Please fill out the form properly.");
    } else if (emailExists) {
      notifyWarning("Email address already exist.");
    } else {
      let res = AddUsers(
        accesslevel,
        position,
        employeeNumber,
        fname,
        minitial,
        lname,
        emailaddress,
        password,
        regionValue,
        districtValue,
        userstatus,
        md5
      );
      res
        .then((value) => {
          if (value === "0000") {
            notifySuccess("Succefully Added...");
            setAccessLevel("");
            setPosition("");
            setEmployeeNumber("");
            setFName("");
            setMInitial("");
            setLName("");
            setEmailAddress("");
            setRegionValue("");
            setDistrictValue("");
            setPassword("NHA" + makeid(5));
            getAllUsers();
          } else {
            notifyWarning("Error: Entries did not insert into datadase...");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setIsPopperOpen(false);
    }
  };

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyWarning = (message) =>
    toast.warn(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const handleViewProfile = (data, index, tag) => {
    if (tag === "2") {
      setIsPopperOpen(true);

      setUsersProfile(data);
      GetDistIndi(data.BP_ID)
        .then((value) => {
          setDistrict(value.data);
          // setSetloading(false);
        })
        .catch((error) => {
          console.log(error);
        });

      setAccessLevel_edit(data.USRX_USERLEVEL);
      setPosition_edit(data.USRX_POSITION);
      setEmployeeNumber_edit(data.USRX_EMPNO);
      setFName_edit(data.USRX_FINAME);
      setMInitial_edit(data.USRX_MINAME);
      setLName_edit(data.USRX_LANAME);
      setEmailAddress_edit(data.USRX_EMAIL);
      setOrigEmailAddress_edit(data.USRX_EMAIL);
      setRegionValue_edit(data.BP_ID);
      setDistrictValue_edit(data.BP_DO_ID);
      setUsersId(data.USRX_ID);
      setUserStatus_edit("2");
      setUserStatus_edit(data.USRX_STATUS);
    } else if (tag === "1") {
      setIsPopperOpen(false);
      setAccessLevel("");
      setPosition("");
      setEmployeeNumber("");
      setFName("");
      setMInitial("");
      setLName("");
      setEmailAddress("");
      setPassword("NHA" + makeid(5));
      setRegionValue("");
      setDistrictValue("");
      setUsersProfile(data);
      setButtonStatus("1");
      setUserStatus("");
    }
  };

  const handleCheck = (e) => {
    if (e.target.checked) {
      setPassword_edit("NHA" + makeid(5));
      set_setReadOnly(false);
    } else {
      setPassword_edit("");
      set_setReadOnly(true);
    }
  };

  /////////////SORTING DATA/////////
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState(null);

  const sortData = (column) => {
    setSortOrder("asc");
    const sortedData = users.sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      if (valueA === null || valueB === null) {
        return valueA === null ? 1 : -1;
      }

      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });
    setUsers(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };
  /////////////END OF SORTING DATA///////////////
  /////////////MAKE ARROW APPEAR ONLY ON THE ONE CLICKED///////////////
  const renderSortingArrow = (column) => {
    if (sortColumn === column) {
      return sortOrder === "asc" ? "↑" : "↓";
    }
    return null;
  };

  useEffect(() => {
    getRegionalOffice();
    getAllUsers();
    setPassword("NHA" + makeid(5));
  }, []);

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header />
      <Sidebar />
      <div>
        <div className="content-wrapper bg-webapp">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">System Users</h1>
                </div>
              </div>
            </div>
          </div>
          {/* Account User Information */}
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                {/* <section className="col-lg-6">
                  <div className="card card-info card-outline">
                    <div className="card-header">
                      <h5 className="card-title text-bold">
                        Account User Information
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="form-group d-flex justify-content-between">
                        <div className="d-flex flex-column ">
                          {" "}
                          <label>Employee Name</label>
                          <small className="fs-italic text-muted">
                            {" "}
                            First, Middle and Last Name
                          </small>
                        </div>

                        {dataRemove ? (
                          <div>
                            {usersProfile.USRX_FINAME}{" "}
                            {usersProfile.USRX_MINAME}{" "}
                            {usersProfile.USRX_LANAME}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <hr></hr>
                      <div className="form-group d-flex justify-content-between  ">
                        <div className="d-flex flex-column ">
                          {" "}
                          <label>Employee No.</label>
                        </div>

                        {dataRemove ? (
                          <div className="text-right">
                            {usersProfile.USRX_EMPNO}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <hr></hr>
                      <div className="form-group d-flex justify-content-between">
                        <div className="d-flex flex-column ">
                          {" "}
                          <label>Email Address</label>
                        </div>

                        {dataRemove ? (
                          <div className="text-right">
                            {usersProfile.USRX_EMAIL}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <hr></hr>
                      <div className="form-group d-flex justify-content-between">
                        <div className="d-flex flex-column ">
                          {" "}
                          <label>Position</label>
                        </div>

                        {dataRemove ? (
                          <div className="text-right">
                            {usersProfile.USRX_POSITION}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <hr></hr>
                      <div className="form-group d-flex justify-content-between">
                        <div className="d-flex flex-column ">
                          {" "}
                          <label>Region</label>
                        </div>

                        {dataRemove ? (
                          <div className="text-right">
                            {usersProfile.BP_REGION}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <hr></hr>
                      <div className="form-group d-flex justify-content-between">
                        <div className="d-flex flex-column ">
                          {" "}
                          <label>District</label>
                        </div>

                        {dataRemove ? (
                          <div className="text-right">
                            {usersProfile.BP_DO_OFFICE}{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <hr></hr>
                      <div className="form-group d-flex justify-content-between">
                        <div className="d-flex flex-column ">
                          {" "}
                          <label>Status</label>
                        </div>

                        {dataRemove ? (
                          <div className="text-right">
                            {usersProfile.USRX_STATUS === "1"
                              ? "Active"
                              : usersProfile.USRX_STATUS === "2"
                              ? "Inactive"
                              : ""}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </section> */}
                {/* END - Account User Information */}

                <section className="col-lg">
                  {/* Add User */}
                  <div className="card collapsed-card card-info card-outline ">
                    <div className="card-header ">
                      <div className="d-flex align-items-center justify-content-between">
                        {" "}
                        <h3 className="card-title text-bold">Add User</h3>
                        <div className="card-tools">
                          <button
                            type="button"
                            className="btn btn-info "
                            data-card-widget="collapse"
                          >
                            <i className="fas fa-plus" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="card-body  ">
                      <div className="d-flex " style={{ gap: "10px" }}>
                        <div className="form-group w-100">
                          <label>Access Level</label>
                          <select
                            className="form-control"
                            value={accesslevel}
                            onChange={(e) => {
                              setAccessLevel(e.target.value);
                              if (e.target.value === "1") {
                                setDistrictValue("");
                                setRegionValue("20");
                              } else if (e.target.value === "2") {
                                setRegionValue("");
                                setDistrictValue("");
                              } else if (e.target.value === "3") {
                                setRegionValue("");
                                setDistrictValue("");
                              } else if (e.target.value === "4") {
                                setDistrictValue("");
                                setRegionValue("20");
                              }
                            }}
                          >
                            <option disabled defaultValue value="">
                              Select Options
                            </option>
                            <option value={1}>Admin</option>
                            <option value={2}>Regional Level</option>
                            <option value={3}>District Level</option>
                            <option value={4}>Admin Checker</option>
                          </select>
                        </div>
                        <div className="form-group w-100">
                          <label>Regional Office</label>
                          <select
                            className="form-control"
                            value={regionValue}
                            onChange={handleChangeRegion}
                            disabled={
                              accesslevel === "1" ||
                              accesslevel === "4" ||
                              accesslevel === ""
                            }
                          >
                            <option disabled defaultValue value="">
                              Select Options
                            </option>
                            {region.map((data) => (
                              <option key={data.BP_ID} value={data.BP_ID}>
                                {data.BP_REGION}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group w-100">
                          <label>District Office</label>
                          <select
                            className="form-control"
                            onChange={(e) => setDistrictValue(e.target.value)}
                            value={districtValue}
                            disabled={
                              (accesslevel === "2" && regionValue) ||
                              accesslevel === "1" ||
                              accesslevel === "4" ||
                              accesslevel === ""
                            }
                          >
                            <option disabled defaultValue value="">
                              Select Options
                            </option>
                            {district.map((data) => (
                              <option key={data.BP_DO_ID} value={data.BP_DO_ID}>
                                {data.BP_DO_OFFICE}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group w-100 ">
                          <label>Position</label>
                          <input
                            className="form-control"
                            onChange={(e) => setPosition(e.target.value)}
                            value={position}
                          />
                        </div>
                      </div>
                      <div className="d-flex " style={{ gap: "10px" }}>
                        <div className="form-group w-100 ">
                          <label>Employee No.</label>
                          <input
                            className="form-control"
                            onChange={(e) => setEmployeeNumber(e.target.value)}
                            value={employeeNumber}
                          />
                        </div>
                        <div className="form-group w-100">
                          <label>First Name</label>
                          <input
                            className="form-control"
                            onChange={(e) => setFName(e.target.value)}
                            value={fname}
                          />
                        </div>
                        <div className="form-group w-100">
                          <label>Middle Initial</label>
                          <input
                            className="form-control"
                            onChange={(e) => setMInitial(e.target.value)}
                            value={minitial}
                          />
                        </div>
                        <div className="form-group w-100">
                          <label>Last Name</label>
                          <input
                            className="form-control"
                            onChange={(e) => setLName(e.target.value)}
                            value={lname}
                          />
                        </div>
                      </div>
                      <div className="d-flex" style={{ gap: "10px" }}>
                        <div className="form-group w-100">
                          <label>Email Address</label>
                          <input
                            className="form-control"
                            onChange={handleEmailChange}
                            value={emailaddress}
                          />
                          {!isValid ? (
                            <p style={{ color: "red" }}>
                              Please enter a valid email address
                            </p>
                          ) : exist ? (
                            <p style={{ color: "red" }}>
                              Email address already exist
                            </p>
                          ) : null}
                        </div>

                        <div className="form-group w-100">
                          <label>Password</label>
                          <input
                            readOnly
                            type="password"
                            className="form-control"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                          />
                        </div>
                      </div>
                      <div className="  float-right">
                        <button
                          className="btn btn-primary"
                          onClick={handleAddUser}
                          disabled={!isValid || exist}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End - Add User */}

                  {/* userlist */}
                  <div
                    className="card card-info card-outline"
                    style={{ maxHeight: "calc(100vh - 150px)" }}
                  >
                    <div className="card-header">
                      <h5 className="card-title text-bold">List of Users</h5>
                    </div>

                    <div className="card-header border-0 w-50 ">
                      <div className="input-group  ">
                        <input
                          type="text"
                          name="table_search"
                          className="form-control float-right"
                          placeholder="Search"
                          value={query}
                          onChange={handlesetQuery}
                        />
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-default">
                            <i className="fas fa-search" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card-body p-0 overflow-auto   "
                      // style={{ maxHeight: "calc(100vh - 500px)" }}
                    >
                      <table className="table table-hover text-nowrap">
                        <thead
                          className="text-nowrap"
                          style={{
                            position: "sticky",
                            top: 0,
                            backgroundColor: "#fff",
                          }}
                        >
                          <tr>
                            <th className="text-center">NO.</th>
                            <th
                              onClick={() => sortData("USRX_FINAME")}
                              style={{ cursor: "pointer" }}
                            >
                              EMPLOYEE NAME {renderSortingArrow("USRX_FINAME")}
                            </th>

                            <th
                              onClick={() => sortData("BP_REGION")}
                              style={{ cursor: "pointer" }}
                            >
                              REGION {renderSortingArrow("BP_REGION")}
                            </th>
                            <th
                              onClick={() => sortData("BP_DO_OFFICE")}
                              style={{ cursor: "pointer" }}
                            >
                              DISTRICT {renderSortingArrow("BP_DO_OFFICE")}
                            </th>

                            <th
                              onClick={() => sortData("USRX_EMAIL")}
                              style={{ cursor: "pointer" }}
                            >
                              EMAIL ADDRESS {renderSortingArrow("USRX_EMAIL")}
                            </th>
                            <th
                              className="text-center"
                              onClick={() => sortData("USRX_USERLEVEL")}
                              style={{ cursor: "pointer" }}
                            >
                              ACCESS LEVEL{" "}
                              {renderSortingArrow("USRX_USERLEVEL")}
                            </th>
                            <th
                              className="text-center"
                              onClick={() => sortData("USRX_STATUS")}
                              style={{ cursor: "pointer" }}
                            >
                              STATUS {renderSortingArrow("USRX_STATUS")}
                            </th>
                            <th className="text-center">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentUsers

                            .filter(
                              (item) =>
                                item.USRX_FINAME.toLowerCase().includes(
                                  query
                                ) ||
                                item.USRX_MINAME.toLowerCase().includes(
                                  query
                                ) ||
                                item.USRX_LANAME.toLowerCase().includes(
                                  query
                                ) ||
                                item.USRX_EMAIL.toLowerCase().includes(query)
                            )

                            .map((data, index) => (
                              <tr className="" key={index}>
                                <td className="text-center">
                                  {index + 1 + indexOfFirstUser}
                                </td>
                                <td>
                                  {data.USRX_FINAME} {data.USRX_MINAME}.{" "}
                                  {data.USRX_LANAME}
                                </td>
                                <td>{data.BP_REGION}</td>
                                <td>{data.BP_DO_OFFICE}</td>
                                <td>{data.USRX_EMAIL}</td>

                                <td className="text-center">
                                  <span
                                    className={
                                      "badge " +
                                      (data.USRX_USERLEVEL === "1" ||
                                      data.USRX_USERLEVEL === "4"
                                        ? "badge-primary"
                                        : data.USRX_USERLEVEL === "2"
                                        ? "badge-danger"
                                        : "badge-success")
                                    }
                                  >
                                    {" "}
                                    {data.USRX_USERLEVEL === "1"
                                      ? "Admin"
                                      : data.USRX_USERLEVEL === "2"
                                      ? "Reg Level"
                                      : data.USRX_USERLEVEL === "4"
                                      ? "Admin Checker"
                                      : "Dist level"}
                                  </span>
                                </td>
                                <td>
                                  {data.USRX_STATUS === "1"
                                    ? "Active"
                                    : data.USRX_STATUS === "2"
                                    ? "Inactive"
                                    : "Unknown"}
                                </td>

                                <td className="text-center">
                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-success mx-1"
                                      data-target="#modal-edit"
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handleViewProfile(data, index, "2");
                                        setDataRemove(false);
                                        setDisableselect(true);
                                      }}
                                      data-placement="top"
                                      title="Edit"
                                    >
                                      <BsPencilSquare />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {isOpenpagetag ? (
                      <div className="card-footer clearfix bg-transparent">
                        <PaginationUser
                          currentPage={currentPage}
                          totalPages={Math.ceil(users.length / usersPerPage)}
                          onPageChange={onPageChange}
                        />
                        <div className="float-right">
                          Showing {indexOfFirstUser + 1} to{" "}
                          {Math.min(indexOfLastUser, users.length)} of{" "}
                          {users.length} result(s)
                        </div>
                      </div>
                    ) : null}
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
        {/* EDIT USER MODAL */}

        <div
          className="modal fade "
          id="modal-edit"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header bg-info">
                <div className="p-0">
                  <h4 className="modal-title">Edit User Account</h4>
                </div>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="d-flex " style={{ gap: "10px" }}>
                  <div className="form-group w-100">
                    <label>Access Level</label>
                    <select
                      className="form-control"
                      value={accesslevel_edit}
                      onChange={(e) => {
                        setAccessLevel_edit(e.target.value);
                        if (e.target.value === "1") {
                          setDistrictValue_edit("");
                          setRegionValue_edit("20");
                        } else if (e.target.value === "2") {
                          setRegionValue_edit("");
                          setDistrictValue_edit("");
                        } else if (e.target.value === "3") {
                          setRegionValue_edit("");
                          setDistrictValue_edit("");
                        } else if (e.target.value === "4") {
                          setDistrictValue_edit("");
                          setRegionValue_edit("20");
                        }
                      }}
                    >
                      <option disabled defaultValue value="">
                        Select Options
                      </option>
                      <option value={1}>Admin</option>
                      <option value={2}>Regional Level</option>
                      <option value={3}>District Level</option>
                      <option value={4}>Admin Checker</option>
                    </select>
                  </div>
                  <div className="form-group w-100">
                    <label>Regional Office</label>
                    <select
                      className="form-control"
                      value={regionValue_edit}
                      onChange={handleChangeRegionEdit}
                      disabled={
                        accesslevel_edit === "1" || accesslevel_edit === "4"
                      }
                    >
                      <option disabled defaultValue value="">
                        Select Options
                      </option>
                      {region.map((data) => (
                        <option key={data.BP_ID} value={data.BP_ID}>
                          {data.BP_REGION}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group w-100">
                    <label>District Office</label>
                    <select
                      className="form-control"
                      onChange={(e) => setDistrictValue_edit(e.target.value)}
                      value={districtValue_edit}
                      disabled={
                        (accesslevel_edit === "2" && regionValue_edit) ||
                        accesslevel_edit === "1" ||
                        accesslevel_edit === "4"
                      }
                    >
                      <option disabled defaultValue value="">
                        Select Options
                      </option>
                      {district.map((data) => (
                        <option key={data.BP_DO_ID} value={data.BP_DO_ID}>
                          {data.BP_DO_OFFICE}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group w-100 ">
                    <label>Position</label>
                    <input
                      className="form-control"
                      onChange={(e) => setPosition_edit(e.target.value)}
                      value={position_edit}
                    />
                  </div>
                </div>
                <div className="d-flex " style={{ gap: "10px" }}>
                  <div className="form-group w-100 ">
                    <label>Employee No.</label>
                    <input
                      readOnly
                      className="form-control"
                      onChange={(e) => setEmployeeNumber_edit(e.target.value)}
                      value={employeeNumber_edit}
                    />
                  </div>
                  <div className="form-group w-100">
                    <label>First Name</label>
                    <input
                      className="form-control"
                      onChange={(e) => setFName_edit(e.target.value)}
                      value={fname_edit}
                    />
                  </div>
                  <div className="form-group w-100">
                    <label>Middle Initial</label>
                    <input
                      className="form-control"
                      onChange={(e) => setMInitial_edit(e.target.value)}
                      value={minitial_edit}
                    />
                  </div>
                  <div className="form-group w-100">
                    <label>Last Name</label>
                    <input
                      className="form-control"
                      onChange={(e) => setLName_edit(e.target.value)}
                      value={lname_edit}
                    />
                  </div>
                </div>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <div className="form-group w-100">
                    <label>Email Address</label>
                    <input
                      readOnly
                      className="form-control"
                      onChange={(e) => setEmailAddress_edit(e.target.value)}
                      value={emailaddress_edit}
                    />
                  </div>

                  {disableselect ? (
                    <div className="form-group w-100">
                      <label>Status</label>
                      <select
                        className="form-control"
                        value={userstatus_edit}
                        onChange={(e) => setUserStatus_edit(e.target.value)}
                      >
                        <option disabled defaultValue value="">
                          Select Options
                        </option>
                        <option value={1}>Active</option>
                        <option value={2}>Inactive</option>
                      </select>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-group w-100">
                    <label>Password</label>
                    {setReadOnly ? (
                      <input
                        type="password"
                        className="form-control"
                        value={password_edit}
                        id="password_id"
                        placeholder=""
                        readOnly
                      />
                    ) : (
                      <input
                        type="password"
                        className="form-control"
                        value={password_edit}
                        id="password_id"
                        placeholder=""
                        onChange={(e) => setPassword_edit(e.target.value)}
                        readOnly
                      />
                    )}

                    <div className="d-flex  mt-2">
                      <input
                        ref={clear_checkbox}
                        name="generate_password"
                        type="checkbox"
                        id="User_checkbox"
                        onChange={handleCheck}
                        readOnly
                      />{" "}
                      <small
                        id="generatePassword"
                        className="text-muted   ml-2"
                      >
                        Generate new password
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  aria-label="Close"
                  onClick={handleUpdateUser}
                  data-dismiss="modal"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default User;
