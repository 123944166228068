import React from "react";
import { Navigate } from "react-router-dom";

const Protected = ({ children }) => {
  const [isLoggedIn, setisLoggedIn] = React.useState();

  fetch("https://api.nha.gov.ph/cied/checksession", { credentials: "include" })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.response === "notLoggedIn") {
        return setisLoggedIn(false);
      } else {
        return setisLoggedIn(true);
      }
    })
    .catch((error) => {});
  if (isLoggedIn === true) {
    return children;
  } else if (isLoggedIn === false) {
    return <Navigate to="/" replace />;
  }
};

export default Protected;
